import {
    Types,
    DataTypes,
    SolicitationsActionTypes
} from "./types";

import customToast from "~/components/Toast/index";


/**
 * Reducers
 */

const INITIAL_STATE: DataTypes = {
    data: [],
    loading: false,
    error: false,
    success: false,
    metaSolicitationSelected: null,
    currentTab: '0'
};


/**
 * Actions
 */

export const Creators = {
    setCurrentTab: (indexTab: string): SolicitationsActionTypes => {
        return ({
            type: Types.SET_CURRENT_TAB,
            indexTab
        })
    },

    getSolicitations: (searchText: string = '', status?: string[]): SolicitationsActionTypes => {
        return ({
            type: Types.REQUEST_SOLICITATION_LOAD,
            payload: {
                status,
                searchText
            }
        })
    },

    getSolicitationById: (id: number): SolicitationsActionTypes => {
        return ({
            type: Types.REQUEST_SOLICITATION_LOAD_BY_ID,
            id
        })
    },

    ApprovalSolicitationRequest: (idSolicitacao: number, motivoCancelamento: string | null): SolicitationsActionTypes => {
        return ({
            type: Types.REQUEST_APPROVAL_SOLICITATION,
            payload: {
                idSolicitacao,
                motivoCancelamento
            }
        })
    },

    DisapprovalSolicitation: (idSolicitacao: number, motivoCancelamento: string | null): SolicitationsActionTypes => {
        return ({
            type: Types.REQUEST_DISAPPROVAL_SOLICITATION,
            payload: {
                idSolicitacao,
                motivoCancelamento
            }
        })
    },

    getApprovals: (searchText: string = '', status?: string[]): SolicitationsActionTypes => {
        return ({
            type: Types.REQUEST_LOAD_APPROVALS,
            payload: {
                searchText,
                status
            }
        })
    }

}

export default function positionsReducer(state = INITIAL_STATE, action: SolicitationsActionTypes): DataTypes {
    switch (action.type) {
        case Types.SET_CURRENT_TAB:
            return { ...state, currentTab: action.indexTab };

        case Types.REQUEST_SOLICITATION_LOAD:
            return { ...state, loading: true, error: false, success: false, data: [] };
        case Types.SUCCESS_SOLICITATION_LOAD:
            return { ...state, loading: false, error: false, success: true, data: action.payload };
        case Types.FAILURE_SOLICITATION_LOAD:
            customToast.error(action.message)
            return { ...state, loading: false, error: true, success: false, data: [] };

        case Types.REQUEST_SOLICITATION_LOAD_BY_ID:
            return { ...state, loading: true, error: false, success: false, metaSolicitationSelected: null };
        case Types.SUCCESS_SOLICITATION_LOAD_BY_ID:
            return { ...state, loading: false, error: false, success: true, metaSolicitationSelected: action.payload };
        case Types.FAILURE_SOLICITATION_LOAD_BY_ID:
            customToast.error(action.message)
            return { ...state, loading: false, error: true, success: false, metaSolicitationSelected: null };

        case Types.REQUEST_APPROVAL_SOLICITATION:
            return { ...state, loading: true, error: false, success: false };
        case Types.SUCCESS_APPROVAL_SOLICITATION:
            return {
                ...state,
                loading: false,
                error: false,
                success: true,
                metaSolicitationSelected: {
                    ...state.metaSolicitationSelected!,
                    motivoCancelamento: action.payload.motivoCancelamento,
                    dataHoraAprovacao: new Date(),
                    dataHoraFinalizada: action.payload.status === 'f' ? new Date() : state.metaSolicitationSelected!.dataHoraFinalizada,
                    status: action.payload.status,
                    FlagPermiteAprovar: false,
                }
            };
        case Types.FAILURE_APPROVAL_SOLICITATION:
            customToast.error(action.message)
            return { ...state, loading: false, error: true, success: false };

        case Types.REQUEST_LOAD_APPROVALS:
            return { ...state, loading: true, error: false, success: false, data: [] };
        case Types.SUCCESS_LOAD_APPROVALS:
            return { ...state, loading: false, error: false, success: true, data: action.payload };
        case Types.FAILURE_LOAD_APPROVALS:
            customToast.error(action.message)
            return { ...state, loading: false, error: true, success: false };

        case Types.REQUEST_DISAPPROVAL_SOLICITATION:
            return { ...state, loading: true, error: false, success: false };
        case Types.SUCCESS_DISAPPROVAL_SOLICITATION:
            return {
                ...state,
                loading: false,
                error: false,
                success: true,
                metaSolicitationSelected: {
                    ...state.metaSolicitationSelected!,
                    motivoCancelamento: action.payload.motivoCancelamento,
                    dataHoraAprovacao: new Date(),
                    status: action.payload.status,
                    FlagPermiteAprovar: false,
                }
            };
        case Types.FAILURE_DISAPPROVAL_SOLICITATION:
            customToast.error(action.message)
            return { ...state, loading: false, error: true, success: false };

        default:
            return state;
    }
}
