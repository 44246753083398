import customToast from "~/components/Toast/index"

import {
  Types,
  DataTypes,
  ValidationCycleActionTypes
} from './types';

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: {
    status: '',
    validacoes: []
  },
  error: false,
  loadingData: false,
  success: false,
  flagStartValidation: false
};

/**
 * Actions
 */
export const Creators = {
  getValidationCycle: (idCiclo: number): ValidationCycleActionTypes => ({
    type: Types.REQUEST_CYCLE_VALIDATION,
    idCiclo
  }),

  resetCanRefreshValidation: (): ValidationCycleActionTypes => ({
    type: Types.CAN_REFRESH_VALIDATION
  })
}


export default function cycleReducer(state = INITIAL_STATE, action: ValidationCycleActionTypes): DataTypes {
  switch (action.type) {

    case Types.REQUEST_CYCLE_VALIDATION:
      return { ...state, data: { status: '', validacoes: [] }, loadingData: true, error: false, success: false };
    case Types.SUCCESS_CYCLE_VALIDATION:
      return { ...state, data: action.payload.data, loadingData: false, error: false, success: true, flagStartValidation: action.payload.flagStartValidation };
    case Types.FAILURE_CYCLE_VALIDATION:
      customToast.error(action.message);
      return { ...state, data: { status: '', validacoes: [] }, loadingData: false, error: true, success: false };

    case Types.CAN_REFRESH_VALIDATION:
      return { ...state, flagStartValidation: false }
    default:
      return state;
  }
}
