import api from "~/services/api";
import { takeLatest, put, call, all } from "redux-saga/effects";
import { Types, IUnit, IRequestUnitAddAction, IRequestUnitEditAction, IRequestUnitDeleteAction, IRequestUnitsLoadAction, IRequestUnitsLoadByIdAction } from "../ducks/units/types";

const apiCallGet = (search: any) => {
    const searchFilter = search.searchText !== "" ? `?SearchText=${search.searchText}`: "?";
    const filter = search.filterAtivo !== null ? `&FilterAtivo=${search.filterAtivo}`: "";

    return api.get(`/unidades${searchFilter}${filter}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallGetById = (id: number) => {
    return api.get(`/unidades/${id}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallPost = (item: IUnit) => {
    return api.post('/unidades', {
        ...item,
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallPut = (item: IUnit) => {
    return api.put(`unidades/${item.idUnidade}`, {
        ...item
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallDel = (id: number) => {
    return api.delete(`/unidades/${id}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

function* loadUnits(action: IRequestUnitsLoadAction) {
    try {
        const items = yield call(apiCallGet, action.search);
        yield put({ type: Types.SUCCESS_UNITS_LOAD, payload: items });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar as unidades.';
        yield put({ type: Types.FAILURE_UNITS_LOAD, message });
    };
};

function* loadUnitsById(action: IRequestUnitsLoadByIdAction) {
    try {
        const item = yield call(apiCallGetById, action.unit.idUnidade!);
        yield put({ type: Types.SUCCESS_UNITS_LOAD_ID, unit: {...action.unit, ...item } });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar a unidade.';
        yield put({ type: Types.FAILURE_UNITS_LOAD_ID, message });
    };
};

function* add(action: IRequestUnitAddAction) {
    try {
        const idUnidade = yield call(apiCallPost, action.unit);
        yield put({ type: Types.SUCCESS_UNIT_ADD, unit:{...action.unit, ...idUnidade}});
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar unidade';
        yield put({ type: Types.FAILURE_UNIT_ADD, message });
    };
};

function* edit(action: IRequestUnitEditAction) {
    try {
        yield call(apiCallPut, action.unit);
        yield put({ type: Types.SUCCESS_UNIT_EDIT, unit: action.unit });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível editar unidade';
        yield put({ type: Types.FAILURE_UNIT_EDIT, message });
    };
};

function* del(action: IRequestUnitDeleteAction) {
    try {
        yield call(apiCallDel, action.id);
        yield put({ type: Types.SUCCESS_UNIT_DELETE, id: action.id });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluir unidade';
        yield put({ type: Types.FAILURE_UNIT_DELETE, message });
    };
};

export default function* companiesRoot() {
    yield all([
        yield takeLatest(Types.REQUEST_UNITS_LOAD, loadUnits),
        yield takeLatest(Types.REQUEST_UNITS_LOAD_ID, loadUnitsById),
        yield takeLatest(Types.REQUEST_UNIT_ADD, add),
        yield takeLatest(Types.REQUEST_UNIT_EDIT, edit),
        yield takeLatest(Types.REQUEST_UNIT_DELETE, del)
    ]);
};