import api from "~/services/api";
import { takeLatest, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestLoadProfileAction,
  IRequestProfileMetasAction,
  IRequestAcceptMetaAction,
  IRequestGetProjectsAction,
  IRequestAddProjectAction,
  IRequestEditProjectAction,
  IRequestDelProjectAction,
  IRequestGetProjectsByIdAction,
  IRequestAprovacaoAction,
  ISuccessLoadProfileAction,
  ISuccessGetProjectsAction,
  ISuccessProfileMetasAction,
  ISuccessAcceptMetaAction,
  ISuccessAddProjectAction,
  ISuccessEditProjectAction,
  ISuccessDelProjectAction,
  ISuccessGetProjectsByIdAction,
  ISuccessAprovacaoAction
} from "../ducks/profile/types";

const apiLoadProfile = (id?: number) => {
  return api.get(`/perfil${id ? `/${id}` : ''}`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiCallGetMetas = (payload: IRequestProfileMetasAction["payload"]) => {
  const { idFuncionario, idCicloColaborador, idCiclo } = payload;

  return api.get(`/perfil${idFuncionario ? `/${idFuncionario}` : ''}/metas${idCiclo ? `/${idCiclo}${idCicloColaborador ? `/${idCicloColaborador}` : ''}` : ''}`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiCallGetProjects = (payload: IRequestGetProjectsAction["payload"]) => {
  const { idFuncionario, idCicloColaborador, idCiclo } = payload;

  return api.get(`/perfil${idFuncionario ? `/${idFuncionario}` : ''}/metas/${idCiclo}/${idCicloColaborador}/projetos`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiCallGetProjectById = (payload: IRequestGetProjectsByIdAction["payload"]) => {
  const { idFuncionario, idCicloColaborador, idCicloColaboradorProjeto} = payload;
  return api.get(`/perfil${idFuncionario ? `/${idFuncionario}` : ''}/metas/${idCicloColaborador}/projetos/${idCicloColaboradorProjeto}`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiCallAddProject = (payload: IRequestAddProjectAction["payload"]) => {
  const { idFuncionario } = payload.project;
  return api.post(`/perfil${idFuncionario ? `/${idFuncionario}` : ''}/metas/${payload.idCicloColaborador}/projetos`, {
    ...payload.project
  })
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiCallEditProject = (payload: IRequestEditProjectAction["payload"]) => {
  const { idFuncionario, idCicloColaboradorProjeto  } = payload.project;
  return api.put(`/perfil${idFuncionario ? `/${idFuncionario}` : ''}/metas/${payload.idCicloColaborador}/projetos/${idCicloColaboradorProjeto}`, {
    ...payload.project
  })
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiCallDelProject = (payload: IRequestDelProjectAction["payload"]) => {
  const { idFuncionario, idCicloColaborador, idCicloColaboradorProjeto } = payload;
  return api.delete(`/perfil${idFuncionario ? `/${idFuncionario}` : ''}/metas/${idCicloColaborador}/projetos/${idCicloColaboradorProjeto}`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiCallReqAprovacao = (payload: IRequestAprovacaoAction["payload"]) => {
  const { idFuncionario, idCiclo, idCicloColaborador } = payload;
  return api.get(`/perfil${idFuncionario ? `/${idFuncionario}` : ''}/metas/${idCiclo}/${idCicloColaborador}/SolicitarAprovacao`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

function* loadProfileData(action: IRequestLoadProfileAction) {
  try {
    const profileInfo = yield call(apiLoadProfile, action.id);
    yield put({
      type: Types.SUCCESS_PROFILE_LOAD,
      payload: profileInfo
    } as ISuccessLoadProfileAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível as informações do perfil.';
    yield put({ type: Types.FAILURE_PROFILE_METAS_LOAD, message });
  };
};

function* loadProfileMetas(action: IRequestProfileMetasAction) {
  try {
    const item = yield call(apiCallGetMetas, action.payload);
    yield put({
      type: Types.SUCCESS_PROFILE_METAS_LOAD,
      payload: item
    } as ISuccessProfileMetasAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar as metas.';
    yield put({ type: Types.FAILURE_PROFILE_METAS_LOAD, message });
  };
};

function* acceptMeta(action: IRequestAcceptMetaAction) {
  try {
    // const item = yield call(apiCallGetMetas, action.id);
    yield put({
      type: Types.SUCCESS_ACCEPT_METAS,
      payload: action.payload
    } as ISuccessAcceptMetaAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível aceitar ou recusar a meta.';
    yield put({ type: Types.FAILURE_ACCEPT_METAS, message });
  };
};

function* getProjects(action: IRequestGetProjectsAction) {
  try {
    const projects = yield call(apiCallGetProjects, action.payload);
    yield put({
      type: Types.SUCCESS_GET_PROJECTS,
      payload: projects
    } as ISuccessGetProjectsAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível adicionar iniciativa chave.';
    yield put({ type: Types.FAILURE_ACCEPT_METAS, message });
  };
};

function* getProjectsById(action: IRequestGetProjectsByIdAction) {
  try {
    const project = yield call(apiCallGetProjectById, action.payload);
    yield put({
      type: Types.SUCCESS_GET_PROJECT_BY_ID,
      payload: project
    } as ISuccessGetProjectsByIdAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar informações da iniciativa chave.';
    yield put({ type: Types.FAILURE_GET_PROJECT_BY_ID, message });
  };
};

function* addProject(action: IRequestAddProjectAction) {
  try {
    const idCicloMetaProjeto = yield call(apiCallAddProject, action.payload);
    yield put({
      type: Types.SUCCESS_ADD_PROJECT,
      payload: {
        ...action.payload.project,
        ...idCicloMetaProjeto
      }
    } as ISuccessAddProjectAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível adicionar iniciativa chave.';
    yield put({ type: Types.FAILURE_ACCEPT_METAS, message });
  };
};

function* editProject(action: IRequestEditProjectAction) {
  try {
    yield call(apiCallEditProject, action.payload);
    yield put({
      type: Types.SUCCESS_EDIT_PROJECT,
      payload: action.payload.project
    } as ISuccessEditProjectAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível editar a iniciativa chave.';
    yield put({ type: Types.FAILURE_ACCEPT_METAS, message });
  };
};

function* delProject(action: IRequestGetProjectsByIdAction) {
  try {
    yield call(apiCallDelProject, action.payload);

    yield put({
      type: Types.SUCCESS_DEL_PROJECT,
      idCicloColaboradorProjeto: action.payload.idCicloColaboradorProjeto
    } as ISuccessDelProjectAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível excluir a iniciativa chave.';
    yield put({ type: Types.FAILURE_ACCEPT_METAS, message });
  };
};

function* reqAprovacao(action: IRequestAprovacaoAction) {
  try {
    yield call(apiCallReqAprovacao, action.payload);

    yield put({
      type: Types.SUCCESS_APROVACAO,
    } as ISuccessAprovacaoAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível aprovar a solicitação.';
    yield put({ type: Types.FAILURE_APROVACAO, message });
  };
};

export default function* positionsRoot() {
  yield all([
    yield takeLatest(Types.REQUEST_PROFILE_LOAD, loadProfileData),
    yield takeLatest(Types.REQUEST_PROFILE_METAS_LOAD, loadProfileMetas),
    yield takeLatest(Types.REQUEST_ACCEPT_META, acceptMeta),
    yield takeLatest(Types.REQUEST_GET_PROJECTS, getProjects),
    yield takeLatest(Types.REQUEST_GET_PROJECT_BY_ID, getProjectsById),
    yield takeLatest(Types.REQUEST_ADD_PROJECT, addProject),
    yield takeLatest(Types.REQUEST_EDIT_PROJECT, editProject),
    yield takeLatest(Types.REQUEST_DEL_PROJECT, delProject),
    yield takeLatest(Types.REQUEST_APROVACAO, reqAprovacao),
  ]);
};
