import {
  Types,
  DataTypes,
  CycleActionTypes,
  ObjectiveCycleType,
  Cycle,
  PillarsType,
} from "./types";

import customToast from "~/components/Toast/index"
import { PillarType } from "../pillars/types";

/**
 * Reducers
 */
const initialCycle: Cycle = {
  cycle: {
    ano: null,
    idCiclo: null,
    prazoPlanejamento: null,
    responsavel: {
      idFuncionario: null,
      nomeCompleto: ''
    },
    status: '',
  },
  pillars: []
};

const INITIAL_STATE: DataTypes = {
  data: initialCycle,
  loading: false,
  error: false,
  loadingValidation: false,
  successValidation: false,
  successData: false,
};


/**
 * Actions
 */

export const Creators = {
  getCycle: (id: number): CycleActionTypes => ({
    type: Types.REQUEST_CYCLE_LOAD,
    id
  }),

  updateCycleStatus: (idCiclo: number): CycleActionTypes => ({
    type: Types.UPDATE_CYCLE_STATUS_TO_VALIDATION,
    idCiclo
  }),

  getPillarsCycle: (id: number): CycleActionTypes => ({
    type: Types.REQUEST_CYCLE_PILLARS_LOAD,
    id
  }),

  addCyclePillar: (idCiclo: number, pillar: PillarType): CycleActionTypes => ({
    type: Types.REQUEST_CYCLES_ADD_PILLAR,
    payload: {
      idCiclo,
      pillar
    }
  }),

  addCycleObjective: (idCiclo: number, idCicloPilar: number, objetivo: ObjectiveCycleType): CycleActionTypes => ({
    type: Types.REQUEST_CYCLES_ADD_OBJECTIVE,
    payload: {
      idCiclo,
      idCicloPilar,
      objetivo
    }
  }),

  deleteCycleObjective: (idCiclo: number, idCicloObjetivo: number): CycleActionTypes => ({
    type: Types.REQUEST_CYCLES_DELETE_OBJECTIVE,
    payload: {
      idCiclo,
      idCicloObjetivo
    }
  }),

  deleteCyclePillar: (idCiclo: number, idCicloPilar: number): CycleActionTypes => ({
    type: Types.REQUEST_CYCLES_DELETE_PILLAR,
    payload: {
      idCiclo,
      idCicloPilar
    }
  }),

  pillarNext: (idCiclo: number, idCicloPilar: number): CycleActionTypes => ({
    type: Types.REQUEST_CYCLES_PILLAR_NEXT,
    payload: {
      idCiclo,
      idCicloPilar
    }
  }),

  pillarPrior: (idCiclo: number, idCicloPilar: number): CycleActionTypes => ({
    type: Types.REQUEST_CYCLES_PILLAR_PRIOR,
    payload: {
      idCiclo,
      idCicloPilar
    }
  }),

  startValidationCycle: (idCiclo: number): CycleActionTypes => {
      return ({
          type: Types.REQUEST_VALIDATION_CYCLE,
          idCiclo
      })
  }
};

export default function cycleReducer(state = INITIAL_STATE, action: CycleActionTypes): DataTypes {
  switch (action.type) {

    case Types.REQUEST_CYCLE_LOAD:
      return { ...state, data: { ...state.data }, loading: true, error: false, successData: false };
    case Types.SUCCESS_CYCLE_LOAD:
      return { ...state, data: { ...state.data, cycle: action.payload }, loading: false, error: false, successData: true };
    case Types.FAILURE_CYCLE_LOAD:
      customToast.error(action.message);
      return { ...state, data: { ...state.data }, loading: false, error: true, successData: false };

    case Types.UPDATE_CYCLE_STATUS_TO_VALIDATION:
      return {
        ...state, data: {
          ...state.data, cycle: {
            ...state.data.cycle,
            status: "V"
          }
        }
      }

    case Types.REQUEST_CYCLE_PILLARS_LOAD:
      return { ...state, data: { ...state.data }, loading: true, error: false };
    case Types.SUCCESS_CYCLE_PILLARS_LOAD:
      return { ...state, data: { ...state.data, pillars: action.payload }, loading: false, error: false };
    case Types.FAILURE_CYCLE_PILLARS_LOAD:
      customToast.error(action.message);
      return { ...state, data: { ...state.data }, loading: false, error: true };

    case Types.REQUEST_CYCLES_ADD_PILLAR:
      return { ...state, error: false };
    case Types.SUCCESS_CYCLES_ADD_PILLAR:
      customToast.success("Pilar cadastrado com sucesso");
      return {
        ...state,
        error: false,
        data: {
          ...state.data,
          pillars: [
            ...state.data.pillars,
            action.pillar
          ]
        }
      };
    case Types.FAILURE_CYCLES_ADD_PILLAR:
      customToast.error(action.message);
      return { ...state, error: true };

    case Types.REQUEST_CYCLES_ADD_OBJECTIVE:
      return { ...state, error: false };
    case Types.SUCCESS_CYCLES_ADD_OBJECTIVE:
      customToast.success("Objetivo cadastrado com sucesso");
      return {
        ...state,
        error: false,
        data: {
          ...state.data,
          pillars: handleAddObjective(state.data.pillars, action.payload.objective, action.payload.idCicloPilar)
        }
      };
    case Types.FAILURE_CYCLES_ADD_OBJECTIVE:
      customToast.error(action.message);
      return { ...state, error: true };

    case Types.REQUEST_CYCLES_DELETE_OBJECTIVE:
      return { ...state, error: false };
    case Types.SUCCESS_CYCLES_DELETE_OBJECTIVE:
      customToast.success("Objetivo removido com sucesso");
      return {
        ...state,
        error: false,
        data: {
          ...state.data,
          pillars: handleRemoveObjective(state.data.pillars, action.idCicloObjetivo)
        }
      };
    case Types.FAILURE_CYCLES_DELETE_OBJECTIVE:
      customToast.error(action.message);
      return { ...state, error: true };

    case Types.REQUEST_CYCLES_DELETE_PILLAR:
      return { ...state, error: false };
    case Types.SUCCESS_CYCLES_DELETE_PILLAR:
      customToast.success("Pilar removido com sucesso");
      return {
        ...state,
        error: false,
        data: {
          ...state.data,
          pillars: state.data.pillars.filter(pilarCiclo => pilarCiclo.idCicloPilar !== action.idCicloPilar)
        }
      };
    case Types.FAILURE_CYCLES_DELETE_PILLAR:
      customToast.error(action.message);
      return { ...state, error: true };

    case Types.REQUEST_CYCLES_PILLAR_NEXT:
      return state;
    case Types.SUCCESS_CYCLES_PILLAR_NEXT:
      return {
        ...state, data:
        {
          ...state.data,
          pillars: movePillar(state.data.pillars, action.idCicloPilar, 'N')
        }
      };
    case Types.FAILURE_CYCLES_PILLAR_NEXT:
      customToast.error(action.message);
      return state;

    case Types.REQUEST_CYCLES_PILLAR_PRIOR:
      return state;
    case Types.SUCCESS_CYCLES_PILLAR_PRIOR:
      return {
        ...state, data:
        {
          ...state.data,
          pillars: movePillar(state.data.pillars, action.idCicloPilar, 'P')
        }
      };
    case Types.FAILURE_CYCLES_PILLAR_PRIOR:
      customToast.error(action.message);
      return state;

    case Types.REQUEST_VALIDATION_CYCLE:
      return { ...state, loadingValidation: true, error: false, successValidation: false }
    case Types.SUCCESS_VALIDATION_CYCLE:
      return {
        ...state,
        loadingValidation: false,
        error: false,
        successValidation: true,
        data: {
          ...state.data,
          cycle:{
            ...state.data.cycle,
            status: action.status
          }
        }
      }
    case Types.FAILURE_VALIDATION_CYCLE:
      customToast.error(action.message)
      return { ...state, loadingValidation: false, error: true, successValidation: false }


    default:
      return state;
  }
}

const handleAddObjective = (pillars: PillarsType[], objective: ObjectiveCycleType, idCicloPilar: number): PillarsType[] => {
  const result = pillars.map(pillar => {
    if (pillar.idCicloPilar === idCicloPilar) {
      pillar.objetivos.push(objective);
      return pillar;
    } else {
      return pillar;
    }
  });

  return result;
};

const handleRemoveObjective = (pillars: PillarsType[], idCicloObjetivo: number): PillarsType[] => {
  const result = pillars.map(pillar => {
    return pillar = {
      ...pillar,
      objetivos: pillar.objetivos.filter(objetivo => objetivo.idCicloObjetivo !== idCicloObjetivo)
    }
  })

  return result;
}

const movePillar = (pillars: PillarsType[], idCicloPilar: number, direction: string): PillarsType[] => {
  const pillarAtual = pillars.find(item => item.idCicloPilar === idCicloPilar);
  const indexPilarAtual = pillars.findIndex(item => item.idCicloPilar === idCicloPilar);
  if (direction === 'N') {
    const pillarNext = pillars[indexPilarAtual + 1];
    return pillars.map((pilar, i) => {
      if (indexPilarAtual === i) {
        return pillarNext;
      } if (indexPilarAtual + 1 === i) {
        return pillarAtual ?? pilar;
      } else {
        return pilar;
      }
    })
  } else {
    const pillarPrior = pillars[indexPilarAtual - 1]
    return pillars.map((pilar, i) => {
      if (indexPilarAtual - 1 === i) {
        return pillarAtual ?? pilar;
      } else if (indexPilarAtual === i) {
        return pillarPrior;
      } else {
        return pilar;
      }
    })
  }
}
