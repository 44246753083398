/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_COMPANIES_LOAD = "companies/REQUEST_COMPANIES_LOAD",
    SUCCESS_COMPANIES_LOAD = "companies/SUCCESS_COMPANIES_LOAD",
    FAILURE_COMPANIES_LOAD = "companies/FAILURE_COMPANIES_LOAD",
    //ADD
    REQUEST_COMPANY_ADD = "companies/REQUEST_COMPANY_ADD",
    SUCCESS_COMPANY_ADD = "companies/SUCCESS_COMPANY_ADD",
    FAILURE_COMPANY_ADD = "companies/FAILURE_COMPANY_ADD",
    //PUT
    REQUEST_COMPANY_EDIT = "companies/REQUEST_COMPANY_EDIT",
    SUCCESS_COMPANY_EDIT = "companies/SUCCESS_COMPANY_EDIT",
    FAILURE_COMPANY_EDIT = "companies/FAILURE_COMPANY_EDIT",
    //DELETE
    REQUEST_COMPANY_DELETE = "companies/REQUEST_COMPANY_DELETE",
    SUCCESS_COMPANY_DELETE = "companies/SUCCESS_COMPANY_DELETE",
    FAILURE_COMPANY_DELETE = "companies/FAILURE_COMPANY_DELETE",
};
/**
 * Data types
 */
export interface ICompany {
    idEmpresa: number | null;
    codEmpresa: string;
    nomeEmpresa: string;
    flagAtiva: boolean;
};

interface ICompaniesState {
    readonly data: ICompany[];
    readonly loadingData: boolean;
    readonly loading: boolean;
    readonly error: boolean;
    readonly success: boolean;
};

export type DataTypes = ICompaniesState;

/**
 * Actions
 */

//GET
export interface IRequestCompaniesLoadAction {
    type: typeof Types.REQUEST_COMPANIES_LOAD;
    search: object;
};

export interface ISuccessCompaniesLoadAction {
    type: typeof Types.SUCCESS_COMPANIES_LOAD;
    payload: ICompany[];
};

interface IFailureCompaniesLoadAction {
    type: typeof Types.FAILURE_COMPANIES_LOAD;
    message: string;
};

type CompaniesLoadAction = IRequestCompaniesLoadAction | ISuccessCompaniesLoadAction | IFailureCompaniesLoadAction;

//ADD
export interface IRequestCompanyAddAction {
    type: typeof Types.REQUEST_COMPANY_ADD;
    company: ICompany;
};

interface ISuccessCompanyAddAction {
    type: typeof Types.SUCCESS_COMPANY_ADD;
    company: ICompany;
};

interface IFailureCompanyAddAction {
    type: typeof Types.FAILURE_COMPANY_ADD;
    message: string;
};

type CompaniesAddAction = IRequestCompanyAddAction | ISuccessCompanyAddAction | IFailureCompanyAddAction;

//PUT
export interface IRequestCompanyEditAction {
    type: typeof Types.REQUEST_COMPANY_EDIT;
    company: ICompany;
};

export interface ISuccessCompanyEditAction {
    type: typeof Types.SUCCESS_COMPANY_EDIT;
    company: ICompany;
};

export interface IFailureCompanyEditAction {
    type: typeof Types.FAILURE_COMPANY_EDIT;
    message: string;
};

type CompaniesEditAction = IRequestCompanyEditAction | ISuccessCompanyEditAction | IFailureCompanyEditAction;


//DELETE
export interface IRequestCompanyDeleteAction {
    type: typeof Types.REQUEST_COMPANY_DELETE;
    id: number;
};

export interface ISuccessCompanyDeleteAction {
    type: typeof Types.SUCCESS_COMPANY_DELETE;
    id: number;
};

export interface IFailureCompanyDeleteAction {
    type: typeof Types.FAILURE_COMPANY_DELETE;
    message: string;
};

type CompaniesDeleteAction = IRequestCompanyDeleteAction | ISuccessCompanyDeleteAction | IFailureCompanyDeleteAction;


export type CompaniesActionTypes = CompaniesLoadAction | CompaniesAddAction | CompaniesEditAction | CompaniesDeleteAction;