// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from "formik";
import * as yup from 'yup';

//FluentUI
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
    DetailsListLayoutMode,
    SelectionMode,
    Selection,
    IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBarButton, IconButton, ICommandBarStyles } from 'office-ui-fabric-react';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { IDialogContentProps } from 'office-ui-fabric-react/lib/Dialog';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
//MaterialUI
// import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
//Componentes
import Panel from "~/components/layout/Panel";
import Status from "~/components/Status";
import CustomDialog from "~/components/CustomDialogFluentUI";
// import Dropdown from "~/components/layout/Dropdown"
import colors from "~/assets/js/colors";
import { CustomDrawer as Drawer } from "~/components/layout/Drawer";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { InputText, InputCheckbox, InputDate } from '~/components/Forms';

import { DeleteButton } from '~/components/Buttons';
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
//Estilos
import {
    Wrapper,
    ListContainer,
    PrimaryButton,
    DefaultButton,
    ContainerContent,
} from "./styles";

import { Creators as getAttitudes } from "~/store/ducks/attitudes";
import { Creators as getAttitudeById } from "~/store/ducks/attitudes";
import { Creators as addAttitude } from "~/store/ducks/attitudes";
import { Creators as editAttitude } from "~/store/ducks/attitudes";
import { Creators as delAttitude } from "~/store/ducks/attitudes";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { AttitudeType, DataTypes } from "~/store/ducks/attitudes/types";
import { RootState } from "~/store/ducks";
//#endregion

const btnStyle: Partial<ICommandBarStyles> = {
    root: {
        height: 44
    }
}

/**
 * Validação do formulário
 */
const validationAttitudesSchema = yup.object().shape({
    nomeAtitude: yup.string()
        .min(2, "O nome da atitude deve conter pelo menos 2 caracteres")
        .max(100, "O nome da atitude não pode ter mais do que 100 caracteres")
        .required("Campo obrigatório"),
    descAtitude:
        yup.string()
            .required("Campo obrigatório"),

});

type Filter = {
    isOpen?: boolean;
    ativadas: string;
    filterStatus: boolean | null;
};

interface IAttitudesState {
    columns: IColumn[];
    items: AttitudeType[];
    isPanelOpen: boolean;
    isDialogOpen: boolean;
    inicialValues: AttitudeType;
    isFiltering: boolean;
    selectionDetails: string;
    selectionCount: number;
    isDialogExcludeOpen: boolean;
    filter: Filter;
    search: string;
    flagAtivo: boolean;
};

interface IPropsAttitudes {
    attitudes: DataTypes;
    setCurrentPage: (page: Page) => void;
    getAttitudes: (search?: string) => void;
    getAttitudeById: (id: number) => void;
    addAttitude: (attitude: AttitudeType) => void;
    editAttitude: (attitude: AttitudeType) => void;
    delAttitude: (id: number) => void;
}

const itemsBreadCrumb: BreadcrumbItems[] = [
    { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
    { text: "Atitudes", isCurrentItem: true },
];

const initialPosition: AttitudeType = {
    idAtitude: null,
    nomeAtitude: '',
    descAtitude: ''
};

class Attitudes extends Component<IPropsAttitudes, IAttitudesState> {
    private formRef: any;
    private inputSearch: any;
    private _selection: Selection;

    constructor(props: IPropsAttitudes) {
        super(props);
        //#region Colunas
        const columns: IColumn[] = [
            {
                key: 'column1',
                name: 'Código',
                ariaLabel: 'Código da atitude',
                fieldName: 'idAtitude',
                isRowHeader: true,
                minWidth: 75,
                maxWidth: 100,
                isResizable: true,
                isSortedDescending: false,
                onColumnClick: this._onColumnClick
            },
            {
                key: 'column2',
                name: 'Nome',
                fieldName: 'nomeAtitude',
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
            }
        ];
        //#endregion

        this.state = {
            columns: columns,
            items: [],
            isDialogOpen: false,
            isPanelOpen: false,
            selectionDetails: "",
            inicialValues: initialPosition,
            isFiltering: false,
            selectionCount: 0,
            isDialogExcludeOpen: false,
            search: "",
            filter: {
                isOpen: false,
                ativadas: 'todos',
                filterStatus: null,
            },
            flagAtivo: false
        }

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails(),
                });
            },
        });

        this.formRef = React.createRef();
        this.inputSearch = React.createRef();
    };

    componentDidMount() {
        const page: Page = {
            key: 'autoavaliacao',
            pages: itemsBreadCrumb
        };
        this.props.setCurrentPage(page);
        this.props.getAttitudes();
    };

    componentDidUpdate(prevProps: IPropsAttitudes, _: any) {
        if (this.state.isPanelOpen) {
            if (prevProps.attitudes.success !== this.props.attitudes.success) {
                if (this.props.attitudes.success) {
                    this.setState({ isPanelOpen: false })
                }
            }
        }

        if (this.props.attitudes.itemSelected.success !== prevProps.attitudes.itemSelected.success) {
            if (this.props.attitudes.itemSelected.success) {
                this.setState({ inicialValues: this.props.attitudes.itemSelected.item })
            }
        }
    }

    // Submit do formulário Formik
    handleSubmit = () => {
        if (this.formRef.current) {
            this.formRef.current.handleSubmit();
        };
    };

    _onItemInvoked = (item: AttitudeType): void => {
        this.props.getAttitudeById(item.idAtitude!)
        this.setState({
            isPanelOpen: true,
        });
    };

    _getSelectionDetails(): any {
        const selectionCount = this._selection.getSelectedCount();
        this.setState({ selectionCount });
        this._selection.getSelection();
    };

    openEdit = () => {
        const selectItem: number = (this._selection.getSelection()[0] as AttitudeType).idAtitude!;
        this.props.getAttitudeById(selectItem)
        this.setState({ isPanelOpen: true });
    };

    cancelPanel = () => {
        this._selection.setAllSelected(false);
        this.setState({ isPanelOpen: false });
    };

    excludePosition = () => {
        this.props.delAttitude((this._selection.getSelection()[0] as AttitudeType).idAtitude!);
        this.setState({ isDialogExcludeOpen: false });
    };

    //#region  Funções do filtro

    toggleFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                isOpen: !this.state.filter.isOpen,
            }
        });
    };

    toggleParamsFilter = (param: string) => {
        const { filter, search } = this.state;

        // switch (param) {
        //   case 'todos':
        //     this.setState({ filter: { ...filter, filterStatus: null, ativadas: param } },
        //       () => this.props.getPositions(search)
        //     );
        //     break;
        //   case 'ativadas':
        //     this.setState({ filter: { ...filter, filterStatus: true, ativadas: param } },
        //       () => this.props.getPositions(search, true)
        //     );
        //     break;
        //   case 'desativas':
        //     this.setState({ filter: { ...filter, filterStatus: false, ativadas: param } },
        //       () => this.props.getPositions(search, false)
        //     );
        //     break;
        // }

        // if (this.inputSearch.current) {
        //   this.filter(this.inputSearch.current.state.value);
        // };

    };

    handleSearch = (e: any) => {
        // const search: string = e?.target.value ? e!.target.value : "";
        // this.setState({ search: search });
        // if (search === "") {
        //   this.props.getPositions("", this.state.filter.filterStatus);
        // }
    };

    filter = (key: any) => {
        const { search, filter } = this.state;

        // if (key === 'Enter') {
        //   if (filter.filterStatus !== null) {
        //     this.props.getPositions(search, filter.filterStatus);
        //   } else {
        //     this.props.getPositions(search);
        //   }
        //   if (this.inputSearch.current) {
        //     this.inputSearch.current.focus();
        //   }
        // };

        // if (search === "") {
        //   this.props.getPositions("", filter.filterStatus);
        // }
    };
    //#endregion

    commandBarRender = () => {
        const { selectionCount } = this.state;
        if (selectionCount === 1) {
            return (
                <>
                    <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Edit' }} text="Editar" onClick={() => this.openEdit()} />
                    <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
                </>
            );
        } else if (selectionCount > 1) {
            return <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
        };
    };

    render() {
        const { isPanelOpen, isDialogExcludeOpen, selectionCount, inicialValues, isFiltering, items, columns, filter, search } = this.state;
        const { attitudes, getAttitudes, addAttitude, editAttitude } = this.props;

        const dialogContentProps: IDialogContentProps = {
            title: 'Excluir?',
            closeButtonAriaLabel: 'Close',
            subText: selectionCount <= 1 ? 'Tem certeza de que deseja excluir este item?' : 'Tem certeza de que deseja excluir estes items?',
        };

        return (
            <>
                <Panel
                    title={!inicialValues.idAtitude ? "Nova atitude" : inicialValues.nomeAtitude}
                    open={isPanelOpen}
                    onClose={() => this.cancelPanel()}
                    footer={
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <DefaultButton onClick={() => this.cancelPanel()}>Cancelar</DefaultButton>
                            {
                                !attitudes.loadingAction ?
                                    <PrimaryButton onClick={this.handleSubmit}>Salvar</PrimaryButton>
                                    :
                                    <Spinner styles={{ root: { width: 110 } }} size={SpinnerSize.small} />
                            }
                        </Stack>
                    }
                >
                    {
                        attitudes.itemSelected.loading ?
                            <Spinner size={SpinnerSize.medium} />
                            :
                            <Formik
                                innerRef={this.formRef}
                                initialValues={inicialValues}
                                validationSchema={validationAttitudesSchema}
                                validateOnChange={false}
                                validateOnBlur={true}
                                enableReinitialize
                                onSubmit={(values: AttitudeType) => {
                                    if (inicialValues.idAtitude === null) {
                                        addAttitude(values);
                                    } else {
                                        editAttitude(values);
                                    }
                                }}
                            >
                                {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => (
                                    <form onSubmit={handleSubmit}>
                                        <InputText
                                            value={values.nomeAtitude}
                                            onChange={(e) => { handleChange(e); setFieldError("nomeAtitude", "") }}
                                            id="nomeAtitude"
                                            error={errors.nomeAtitude ? true : false}
                                            name="nomeAtitude"
                                            type="text"
                                            label="Nome"
                                            helperText={errors.nomeAtitude}
                                            inputProps={{ maxLength: 100 }}
                                            className="mt-2"
                                            autoFocus
                                        />
                                        <InputText
                                            value={values.descAtitude}
                                            onChange={(e) => { handleChange(e); setFieldError("descAtitude", "") }}
                                            id="descAtitude"
                                            error={errors.descAtitude ? true : false}
                                            name="descAtitude"
                                            type="text"
                                            label="Descrição"
                                            helperText={errors.descAtitude}
                                            className="mt-2"
                                            multiline
                                            rows={3}
                                            rowsMax={15}
                                        />
                                    </form>
                                )}
                            </Formik>
                    }
                </Panel>

                <CustomDialog
                    hidden={!isDialogExcludeOpen}
                    onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
                    dialogContentProps={dialogContentProps}
                >
                    <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
                    <DeleteButton onClick={() => this.excludePosition()} text="Excluir" />
                </CustomDialog>
                <Wrapper>
                    <Drawer
                        isOpen={filter.isOpen}
                        content={
                            <ContainerContent>
                                <HeaderPage
                                    title="Atitudes"
                                    leftItems={
                                        <>
                                            <CommandBarButton
                                                styles={btnStyle}
                                                iconProps={{ iconName: 'Add' }}
                                                disabled={attitudes.loadingData}
                                                text="Adicionar Atitude"
                                                onClick={() => this.setState(
                                                    {
                                                        isPanelOpen: true,
                                                        inicialValues: initialPosition
                                                    }
                                                )}
                                            />
                                            {this.commandBarRender()}
                                        </>
                                    }
                                    rightItems={
                                        <>
                                            <SearchBox
                                                placeholder="Pesquisar"
                                                value={search}
                                                onChange={(e) => this.handleSearch(e)}
                                                onKeyUp={(e) => this.filter(e.key)}
                                                componentRef={this.inputSearch}
                                                styles={{
                                                    root:
                                                        { border: 'none', width: 200, marginRight: 1 }
                                                }}
                                            />
                                            <CommandBarButton
                                                styles={btnStyle}
                                                iconProps={{ iconName: 'Refresh' }}
                                                text="Atualizar"
                                                onClick={() => getAttitudes()}
                                            />
                                            {/* <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: 'filter' }}
                        text="Filtrar"
                        onClick={() => this.toggleFilter()}
                      /> */}
                                        </>
                                    }
                                />
                                {!attitudes.loadingData && attitudes.data.length === 0 ?
                                    <NoItems
                                        error={attitudes.error}
                                        text="Não há atitudes cadastradas"
                                        icon="ReminderPerson"
                                    />
                                    :
                                    <ListContainer>
                                        <ShimmeredDetailsList
                                            items={isFiltering ? items : attitudes.data}
                                            enableShimmer={attitudes.loadingData}
                                            columns={columns}
                                            selectionMode={SelectionMode.single}
                                            selection={this._selection}
                                            getKey={this._getKey}
                                            selectionPreservedOnEmptyClick={true}
                                            setKey="single"
                                            layoutMode={DetailsListLayoutMode.justified}
                                            isHeaderVisible={true}
                                            onItemInvoked={this._onItemInvoked}
                                        />
                                    </ListContainer>
                                }
                            </ContainerContent>
                        }>
                        <>
                            <Stack horizontal horizontalAlign="space-between">
                                <Text variant="xLarge">Filtros</Text>
                                <Stack horizontal>
                                    <IconButton
                                        iconProps={{ iconName: 'ChromeClose' }}
                                        styles={{ root: { color: colors.darkGray } }}
                                        title="Fechar"
                                        ariaLabel="Fechar"
                                        onClick={() => this.setState({ filter: { ...filter, isOpen: false } })}
                                    />
                                </Stack>
                            </Stack>
                            <RadioGroup aria-label="gender" name="gender1" style={{ marginTop: 10 }} value={filter.ativadas} onChange={(e: any) => this.toggleParamsFilter(e.target.value)}>
                                <Text variant="mediumPlus">Status</Text>
                                <FormControlLabel value="todos" control={<Radio color="primary" />} label="Todos" />
                                <FormControlLabel value="ativadas" control={<Radio color="primary" />} label="Ativadas" />
                                <FormControlLabel value="desativas" control={<Radio color="primary" />} label="Desativadas" />
                            </RadioGroup>
                        </>
                    </Drawer>
                </Wrapper>
            </>
        );
    };

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns } = this.state;
        const newColumns: IColumn[] = columns.slice();
        let items: AttitudeType[] = [];
        if (this.state.isFiltering) {
            items = this.state.items;
        } else {
            items = this.props.attitudes.data;
        };
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            };
        });
        const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems,
        });
    };

    private _getKey(item: any, index?: number): any {
        if (item !== undefined)
            return item.key;
    };

    private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
        return itemsSorted;
    };

}
const mapStateToProps = (state: RootState) => ({
    attitudes: state.attitudesReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...getAttitudes,
    ...getAttitudeById,
    ...addAttitude,
    ...editAttitude,
    ...delAttitude,
    ...setCurrentPage
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Attitudes);
