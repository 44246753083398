import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from "formik";
import * as yup from 'yup';
import moment from 'moment';

import InputAdornment from '@material-ui/core/InputAdornment';

import {
  Wrapper,
  PrimaryButton,
  DefaultButton,
  ListContainer,
  ContainerContent,
  WrapperCommands,
  LeftCommands,
  RightCommands
} from "./styles";

//FluentUI
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
  DetailsRow,
  IDetailsFooterProps,
  IDetailsRowBaseProps
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { CommandBarButton, ICommandBarStyles } from 'office-ui-fabric-react';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import CustomDialog from "~/components/CustomDialogFluentUI";
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Dialog, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import MenuItem from '@material-ui/core/MenuItem';
import Dropdown from '~/components/layout/Dropdown';
//MaterialUI
//Componentes
import { InputText, InputAutocomplete, InputDate } from '~/components/Forms';
import { DialogZ } from '~/components/CustomDialogFluentUI';
import NoItems from '~/components/layout/NoItems';
import { DeleteButton } from '~/components/Buttons';
import { Creators as getObjectives } from "~/store/ducks/objectives";

import { Creators as getProjects } from "~/store/ducks/profile";
import { Creators as getProjectsById } from "~/store/ducks/profile";
import { Creators as addProject } from "~/store/ducks/profile";
import { Creators as editProject } from "~/store/ducks/profile";
import { Creators as delProject } from "~/store/ducks/profile";
import { Creators as getDismemberment } from "~/store/ducks/cycle/dismemberment";
import { DataTypes, IProjectType, IObjetivosPesosAreas, IProjectSelectedState } from "~/store/ducks/profile/types";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { ObjectivesType } from "~/store/ducks/objectives/types"
import { ISolicitacaoMetaProjetoType } from "~/store/ducks/solicitation/types"
// import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
// import { DataTypes as DataTypesEmployees, EmployeeType } from "~/store/ducks/employees/types";
import { RootState } from "~/store/ducks";

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44
  }
};

interface IProjectState {
  columns: IColumn[];
  items: IProjectType[] | ISolicitacaoMetaProjetoType[];
  isDialogOpen: boolean;
  selectionDetails: string;
  inicialValues: IProjectType;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
  // employee: Partial<EmployeeType>;
  itemSelected: IProjectType;
  objSelected: Partial<ObjectivesType>;
};


const validadtionProjectSchema = yup.object().shape({
  idCicloPesoPai: yup.string().nullable().required("Campo obrigatório"),
  descProjeto: yup.string(),
  peso: yup.string().required("Campo obrigatório"),
  nomeProjeto: yup.string().required("Campo obrigatório"),
  prazoEntrega:
    yup.date()
      .nullable()
      .typeError('Data inválida')
      .required("Campo obrigatório"),
});


// const inicialValueCycle: IProjectType = {
//     descProjeto: "",
//     idCiclo: 5,
//     idCicloMetaProjeto: null,
//     idCicloPesoPai: null,
//     idFuncionario: null,
//     peso: '',
//     prazoEntrega: null
// };

const initialValues: IProjectType = {
  idCicloColaboradorProjeto: null,
  descProjeto: "",
  idCiclo: null,
  peso: "",
  idCicloPesoPai: null,
  prazoEntrega: null,
  nomeProjeto: ''
}

const dialogContentProps = {
  title: 'Adicionar Iniciativa Chave',
  closeButtonAriaLabel: 'Close',
  subText: "",
};


interface ICyclesProps {
  idCiclo: number;
  idCicloColaborador: number | null;
  // projects: DataTypes;
  allProjects: IProjectType[] | ISolicitacaoMetaProjetoType[];
  isLoadingProjects: boolean;
  successProjects: boolean;
  errorProjects: boolean;
  selectedProject?: IProjectSelectedState;
  objetivosPesoAreas?: IObjetivosPesosAreas[];
  // cycles: DataTypes;
  // employees: DataTypesEmployees;
  allObjectives: ObjectivesType[];
  // setCurrentPage: (page: Page) => void;
  getProjects: (idCiclo: number, idCicloColaborador: number, idFuncionario?: number) => void;
  getProjectsById: (idCicloColaborador: number, idCicloColaboradorProjeto: number, idFuncionario?: number) => void;
  addProject: (project: Partial<IProjectType>, idCicloColaborador: number) => void;
  editProject: (project: IProjectType, idCicloColaborador: number) => void;
  delProject: (idCicloColaborador: number, idCicloColaboradorProjeto: number, idFuncionario?: number) => void;
  getDismemberment: (idCiclo: number, idArea: number, nomeLocal?: string) => void;
  getObjectives: (search?: string, treeview?: boolean, filterAtivo?: boolean, filterPai?: number) => void;
  readOnly?: boolean;
}

class KeyProjects extends Component<ICyclesProps, IProjectState>{
  private formRef: any;
  private inputSearch: any;
  private _selection: Selection;
  private timeout: number;

  constructor(props: ICyclesProps) {
    super(props);

    const columns: IColumn[] = [
      {
        key: 'column1',
        name: 'ID',
        ariaLabel: 'Id',
        fieldName: 'idCicloColaboradorProjeto',
        isRowHeader: true,
        minWidth: 50,
        maxWidth: 75,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column2',
        name: 'Nome',
        fieldName: 'nomeProjeto',
        minWidth: 150,
        isRowHeader: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true
      },
      {
        key: 'column3',
        name: 'Peso',
        fieldName: 'peso',
        minWidth: 60,
        isRowHeader: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: IProjectType) => {
          return <div style={{ textAlign: 'right', paddingRight: 15 }}>{`${Number(item.peso)}%`}</div>
        }
      },
      {
        key: 'column4',
        name: 'Prazo',
        fieldName: 'prazoEntrega',
        minWidth: 100,
        maxWidth: 150,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'date',
        isPadded: true,
        onRender: (item: IProjectType) => moment(item.prazoEntrega).format("DD/MM/YYYY")
      },
    ];

    this.state = {
      items: [],
      columns: columns,
      isDialogOpen: false,
      isDialogExcludeOpen: false,
      selectionDetails: "",
      inicialValues: initialValues,
      selectionCount: 0,
      itemSelected: initialValues,
      objSelected: {}
    };
    this.formRef = React.createRef();
    this.inputSearch = React.createRef();
    this.timeout = 0;

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });
  };

  componentDidMount() {
    this.setState({ items: this.props.allProjects })
  }

  componentDidUpdate(prevProps: ICyclesProps, prevState: IProjectState) {
    const prevSuccess = prevProps.successProjects;
    const nextSuccess = this.props.successProjects;
    const prevData = prevProps.selectedProject;
    const nextData = this.props.selectedProject;

    if (prevProps.allProjects !== this.props.allProjects) {
      this.setState({ items: this.props.allProjects })
    }
    if(!this.props.readOnly){
      if (prevData?.item !== nextData?.item) {
        if (nextData?.success) {
          this.setState({
            inicialValues: nextData?.item
          });
        };
      };
    }

    if (prevSuccess !== nextSuccess) {
      if (nextSuccess) {
        this.setState({ isDialogOpen: false });
      }
    }
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    };
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    // this.setState({ isPanelOpen: false });
  };

  openAdd = () => {
    this.setState({
      isDialogOpen: true,
      inicialValues: initialValues
    })
  }

  openEdit = () => {
    this.setState({ isDialogOpen: true });
    const idSelected = (this._selection.getSelection()[0] as IProjectType).idCicloColaboradorProjeto!;
    this.props.getProjectsById(this.props.idCicloColaborador!, idSelected);
    // this.props.getCycleById(idSelected);
  };

  handleGetProjects = () => {
    const { idCiclo, idCicloColaborador } = this.props;
    this.props.getProjects(idCiclo, idCicloColaborador!);
  }

  excludeProject = () => {
    const idCicloColaboradorProjeto = (this._selection.getSelection()[0] as IProjectType).idCicloColaboradorProjeto!;
    this.props.delProject(this.props.idCicloColaborador!, idCicloColaboradorProjeto);
    this.setState({ isDialogExcludeOpen: false });
  };

  search = (text: string, type?: string) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.props.getObjectives(text, false)
    }, 700);
  };

  _onItemInvoked = (item: IProjectType) => {
    this.openEdit();
  }

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Edit' }} text="Editar" onClick={this.openEdit} />
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
        </>
      );
    } else if (selectionCount > 1) {
      return <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />;
    };
  };


  _onRenderDetailsFooter = (detailsFooterProps: IDetailsFooterProps): JSX.Element => (
    <DetailsRow
      {...detailsFooterProps}
      columns={detailsFooterProps.columns.map(col => {
        if (col.fieldName === 'nomeProjeto') {
          return { ...col, onRender: () => 'TOTAL' };
        } else if (col.fieldName !== 'peso') {
          return { ...col, onRender: () => '' };
        } else {
          return { ...col, onRender: () => `${(this.state.items as any[]).reduce((total, item) => total + Number(item.peso), 0)}%` };
        }
      })}
      item={{}}
      itemIndex={-1}
      groupNestingDepth={detailsFooterProps.groupNestingDepth}
      selectionMode={SelectionMode.none}
      onRenderItemColumn={_renderDetailsFooterItemColumn}
      styles={{
        root:
        {
          paddingLeft: this.props.readOnly ? 0 : 48,
          borderBottom: 'none',
          borderTop: '1px solid rgb(237, 235, 233)',
        },
        fields: {
          fontWeight: 'bold',
          selectors: {
            'div[data-item-index="-1"]': {
              padding: 0
            },
            'div[aria-colindex="2"]': {
              textAlign: 'right'
            },
            'div[aria-colindex="3"]': {
              textAlign: 'right',
              paddingRight: 47
            }
          }
        }
      }}
    // onRenderCheck={_onRenderCheckForFooterRow}
    />
  );

  render() {
    const { inicialValues, selectionCount, columns, isDialogExcludeOpen, isDialogOpen, objSelected, items } = this.state;
    const { readOnly, allObjectives, objetivosPesoAreas, selectedProject, errorProjects, isLoadingProjects, allProjects, idCiclo, addProject, editProject, getProjects } = this.props;
    const dialogContentExcludeProps = {
      title: 'Excluir?',
      closeButtonAriaLabel: 'Close',
      subText: selectionCount <= 1 ? 'Tem certeza de que deseja excluir este item?' : 'Tem certeza de que deseja excluir estes items?',
    };


    return (
      <>
        <Wrapper >
          <ContainerContent>
            {
              !readOnly &&
              <WrapperCommands>
                <LeftCommands>
                  <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: 'Add' }}
                    text="Adicionar Iniciativa"
                    onClick={this.openAdd}
                    disabled={isLoadingProjects}
                  />
                  {this.commandBarRender()}
                </LeftCommands>
                <RightCommands>
                  {/* <SearchBox
                                        placeholder="Pesquisar"
                                        onChange={(e) => this.filter(e?.target.value)}
                                        componentRef={this.inputSearch}
                                        styles={{ root: { border: 'none', width: 200, marginRight: 1 } }}
                                        disabled={cycles.data.length === 0}
                                    /> */}
                  <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Refresh' }} text="Atualizar" onClick={this.handleGetProjects} />
                </RightCommands>
              </WrapperCommands>
            }
            {!isLoadingProjects && allProjects.length === 0 ?
              <NoItems
                error={errorProjects}
                text="Não há iniciativas cadastradas"
                // img="/static/icons/cycle.svg"
                alt="Ciclos"
                icon="OpenEnrollment"
              />
              :
              <ListContainer>
                <ShimmeredDetailsList
                  items={items}
                  enableShimmer={isLoadingProjects}
                  columns={columns}
                  selectionMode={!readOnly ? SelectionMode.single : SelectionMode.none}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={!readOnly ? this._onItemInvoked : () => false}
                  onRenderDetailsFooter={this._onRenderDetailsFooter as any}
                />
              </ListContainer>
            }
          </ContainerContent>
        </Wrapper>

        {
          !this.props.readOnly &&
          <>
            <DialogZ
              hidden={!isDialogOpen}
              onDismiss={() => this.setState({ isDialogOpen: false })}
              dialogContentProps={dialogContentProps}
              minWidth={640}
              maxWidth={640}
            >
              {
                selectedProject?.loading ?
                  <Spinner size={SpinnerSize.medium} />
                  :
                  <Formik
                    initialValues={inicialValues}
                    validationSchema={validadtionProjectSchema}
                    enableReinitialize
                    validateOnChange={false}
                    validateOnBlur={true}
                    onSubmit={(values: IProjectType) => {
                      values.idCiclo = idCiclo;
                      values.idCicloPesoPai = Number(values.idCicloPesoPai);
                      if (!values.idCicloColaboradorProjeto) {
                        addProject(values, this.props.idCicloColaborador!);
                      } else {
                        editProject(values, this.props.idCicloColaborador!)
                      }
                    }}
                  >
                    {({ handleSubmit, handleChange, values, errors, setFieldValue, setFieldError }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="ms-Grid" dir="ltr">
                          <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12">
                              {/* <InputAutocomplete
                                                        value={(objSelected as ObjectivesType)}
                                                        onChange={(_, newValue) => {
                                                            this.setState({ objSelected: newValue! })
                                                            setFieldValue("objetivo", { idObjetivo: newValue?.idObjetivo! });
                                                        }}
                                                        onInputChange={(_, newInputValue) => {
                                                            setFieldError("objetivo", "");
                                                            this.search(newInputValue);
                                                        }}
                                                        getOptionLabel={(objective: ObjectivesType) => {
                                                            if (objective.descObjetivo) {
                                                                return objective.descObjetivo
                                                            } else {
                                                                return ""
                                                            }
                                                        }}
                                                        getOptionSelected={(option, value) => {
                                                            return option.idObjetivo === value.idObjetivo;
                                                        }}
                                                        options={allObjectives}
                                                        input={{
                                                            idInput: "objetivo",
                                                            labelInput: "Objetivo",
                                                            helperTextInput: errors.idCicloPesoPai ? "Campo obrigatório" : "",
                                                            errorInput: errors.idCicloPesoPai ? true : false,
                                                            autoFocus: true
                                                        }}
                                                    /> */}
                              <Dropdown
                                errors={errors.idCicloPesoPai}
                                label="Objetivo"
                                name="idCicloPesoPai"
                                values={String(values.idCicloPesoPai!) ?? ""}
                                handleChange={(e: any) => { handleChange(e); setFieldError("idCicloPesoPai", ""); }}
                                errorText={errors.idCicloPesoPai}
                              >
                                {
                                  objetivosPesoAreas?.map((obj, i) => (
                                    <MenuItem key={i} value={String(obj.idCicloPeso)}>{obj.objetivo.descObjetivo}</MenuItem>
                                  ))
                                }
                              </Dropdown>
                            </div>
                            <div className="ms-Grid-col ms-sm12">
                              <InputText
                                value={values.nomeProjeto}
                                onChange={handleChange}
                                id="nomeProjeto"
                                error={errors.nomeProjeto ? true : false}
                                name="nomeProjeto"
                                type="text"
                                label="Nome da iniciativa"
                                helperText={errors.nomeProjeto}
                              />
                            </div>
                            <div className="ms-Grid-col ms-sm12">
                              <InputText
                                value={values.descProjeto}
                                onChange={handleChange}
                                id="descProjeto"
                                error={errors.descProjeto ? true : false}
                                name="descProjeto"
                                type="text"
                                label="Descreva como a iniciativa será avaliado"
                                helperText={errors.descProjeto}
                                multiline
                                rows={3}
                                styles={{ marginBottom: 40 }}
                              />
                            </div>
                            <div className="ms-Grid-col ms-sm4">
                              <InputText
                                value={values.peso}
                                onChange={handleChange}
                                id="peso"
                                error={errors.peso ? true : false}
                                name="peso"
                                type="text"
                                label="Peso"
                                helperText={errors.peso}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                              />
                            </div>
                            <div className="ms-Grid-col ms-sm4">
                              <InputDate
                                id="prazoEntrega"
                                error={errors.prazoEntrega ? true : false}
                                name="prazoEntrega"
                                label="Prazo de Entrega"
                                value={values.prazoEntrega}
                                onChange={value => setFieldValue('prazoEntrega', value)}
                                helperText={errors.prazoEntrega}
                              />
                            </div>
                          </div>
                        </div>
                        <div style={{ paddingRight: 5 }}>
                          <DialogFooter>
                            <DefaultButton styles={{ root: { width: 110 } }} onClick={() => this.setState({ isDialogOpen: false })} text="Cancelar" />
                            <PrimaryButton styles={{ root: { width: 110 } }} type="submit" text="Salvar" />
                          </DialogFooter>
                        </div>
                      </form>
                    )
                    }
                  </Formik>
              }
            </DialogZ>

            <CustomDialog
              hidden={!isDialogExcludeOpen}
              onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
              dialogContentProps={dialogContentExcludeProps}
            >
              <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
              <DeleteButton onClick={this.excludeProject} text="Excluir" />
            </CustomDialog>
          </>
        }

      </>
    );

  };






  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: IProjectType[] | ISolicitacaoMetaProjetoType[] = [];
    items = this.props.allProjects;
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      };
    });
    const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems as IProjectType[] | ISolicitacaoMetaProjetoType[],
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };

  private _sort<T>(items: any[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    return itemsSorted;
  };
};

const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] = (item, index, column) => {
  if (column) {
    return (
      <div>
        <b>TOTAL</b>
      </div>
    );
  }
  return undefined;
};

const mapStateToProps = (state: RootState) => ({
  cycles: state.cyclesReducer,
  employees: state.employeesReducer,
  allObjectives: state.objectivesReducer.data
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...getProjects,
  ...getProjectsById,
  ...addProject,
  ...editProject,
  ...delProject,
  ...getDismemberment,
  ...getObjectives,
  ...setCurrentPage
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(KeyProjects);
