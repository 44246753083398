import { takeLatest, put, call, all } from "redux-saga/effects";
import {
  Types,
  IRequestCyclesLoadAction,
  IRequestCyclesLoadByIdAction,
  IRequestCyclesAddAction,
  IRequestCyclesDeleteAction,
  IRequestCyclesEditAction,
  CycleType
} from "../ducks/cycles/types";
import { Types as TypesCycle, IUpdateCycleStatusAction } from "../ducks/cycle/types";
import api from "~/services/api";

const apiCallGet = (search: string) => {
  return api.get(`/ciclos?SearchYear=${search}`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiCallGetById = (id: number) => {
  return api.get(`/ciclos/${id}`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiCallPost = (item: CycleType) => {
  return api.post('/ciclos', {
    ...item,
    idFuncionarioResponsavel: item.responsavel.idFuncionario,
    status: "P"
  })
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiCallPut = (item: CycleType) => {
  return api.put(`/ciclos/${item.idCiclo}`, {
    ...item,
    idFuncionarioResponsavel: item.responsavel.idFuncionario
  })
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiCallDel = (id: number) => {
  return api.delete(`/ciclos/${id}`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};



function* load(action: IRequestCyclesLoadAction) {
  try {
    const items = yield call(apiCallGet, action.search)
    yield put({ type: Types.SUCCESS_CYCLES_LOAD, payload: items });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar os ciclos.';
    yield put({ type: Types.FAILURE_CYCLES_LOAD, message });
  };
};

function* loadById(action: IRequestCyclesLoadByIdAction) {
  try {
    const item = yield call(apiCallGetById, action.id)
    yield put({ type: Types.SUCCESS_CYCLES_LOAD_ID, payload: item });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar o ciclo.';
    yield put({ type: Types.FAILURE_CYCLES_LOAD_ID, message });
  };
};

function* add(action: IRequestCyclesAddAction) {
  try {
    const idCiclo = yield call(apiCallPost, action.cycle);
    yield put({ type: Types.SUCCESS_CYCLES_ADD, cycle: { ...action.cycle, ...idCiclo, status: 'P' } });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível adicionar ciclo.';
    yield put({ type: Types.FAILURE_CYCLES_ADD, message });
  };
};

function* edit(action: IRequestCyclesEditAction) {
  try {
    yield call(apiCallPut, action.cycle);
    yield put({ type: Types.SUCCESS_CYCLES_EDIT, cycle: action.cycle });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível editar o ciclo.';
    yield put({ type: Types.FAILURE_CYCLES_EDIT, message });
  };
};

function* del(action: IRequestCyclesDeleteAction) {
  try {
    yield call(apiCallDel, action.id);
    yield put({ type: Types.SUCCESS_CYCLES_DELETE, id: action.id });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível editar o ciclo.';
    yield put({ type: Types.FAILURE_CYCLES_DELETE, message });
  };
};

export default function* companiesRoot() {
  yield all([
    yield takeLatest(Types.REQUEST_CYCLES_LOAD, load),
    yield takeLatest(Types.REQUEST_CYCLES_LOAD_ID, loadById),
    yield takeLatest(Types.REQUEST_CYCLES_ADD, add),
    yield takeLatest(Types.REQUEST_CYCLES_EDIT, edit),
    yield takeLatest(Types.REQUEST_CYCLES_DELETE, del),
  ]);
};
