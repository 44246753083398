import { Types, DataTypes, EvaluationActionTypes, EvaluationType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  error: false,
  loading: false
};

/**
 * Actions
 */

export const Creators = {
  getEvaluations: (): EvaluationActionTypes => ({
    type: Types.REQUEST_EVALUATIONS_LOAD
  }),

  addEvaluation: (evaluation: EvaluationType): EvaluationActionTypes => ({
    type: Types.REQUEST_EVALUATIONS_ADD,
    evaluation
  }),

  editEvaluation: (evaluation: EvaluationType): EvaluationActionTypes => ({
    type: Types.REQUEST_EVALUATIONS_EDIT,
    evaluation
  }),

  delEvaluation: (id: number): EvaluationActionTypes => ({
    type: Types.REQUEST_EVALUATIONS_DELETE,
    id
  })
};

export default function evaluationsReducer(state = INITIAL_STATE, action: EvaluationActionTypes): DataTypes {

  switch (action.type) {
    case Types.REQUEST_EVALUATIONS_LOAD:
      return { ...state, loading: true, error: false };
    case Types.SUCCESS_EVALUATIONS_LOAD:
      return { ...state, data: action.payload, loading: false, error: false };
    case Types.FAILURE_EVALUATIONS_LOAD:
      customToast.error(action.message);
      return { ...state, data: [], loading: false, error: true };

    case Types.REQUEST_EVALUATIONS_ADD:
      return { ...state, loading: true, error: false };
    case Types.SUCCESS_EVALUATIONS_ADD:
      customToast.success("Avaliação cadastrada com sucesso");
      return { ...state, loading: false, error: false };
    case Types.FAILURE_EVALUATIONS_ADD:
      customToast.error(action.message);
      return { ...state, loading: false, error: true };

    case Types.REQUEST_EVALUATIONS_EDIT:
      return { ...state, loading: true, error: false };
    case Types.SUCCESS_EVALUATIONS_EDIT:
      customToast.success("Avaliação editada com sucesso");
      return { ...state, loading: false, error: false };
    case Types.FAILURE_EVALUATIONS_EDIT:
      customToast.success(action.message);
      return { ...state, loading: false, error: true };

    case Types.REQUEST_EVALUATIONS_DELETE:
      return { ...state, loading: true, error: false };
    case Types.SUCCESS_EVALUATIONS_DELETE:
      customToast.success("Pilar removido com sucesso");
      return { ...state, loading: false, error: false };
    case Types.FAILURE_EVALUATIONS_DELETE:
      customToast.success(action.message);
      return { ...state, loading: false, error: true };

    default:
      return state;
  };
};