import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 654px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  height: 65px;
  width: 654px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px;
  box-sizing: border-box;
  border-top: 1px solid #E8E8E8;
`;

export const FormPanelCommand = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 8px;
    border-bottom: 1px solid #E8E8E8;
    flex-shrink: 0;
    box-sizing: border-box;
`
export const FormPanelHeader = styled.div`
    padding: 14px 32px 32px;
`
export const FormPanelContentInner = styled.div`
    padding: 0 32px 32px;
    height: calc(100% - 138px);
    overflow: auto;
    flex-grow: 1;
`