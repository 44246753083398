import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Formik } from "formik";

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { History } from 'history';

import * as yup from 'yup';
import moment from 'moment';

import {
    Wrapper,
    PrimaryButton,
    DefaultButton,
    ListContainer,
    GroupCheckbox,
    ContainerContent
} from "./styles";

//FluentUI
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
    DetailsListLayoutMode,
    SelectionMode,
    Selection,
    IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBarButton, IconButton, ICommandBarStyles } from 'office-ui-fabric-react';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import CustomDialog from "~/components/CustomDialogFluentUI";
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
//MaterialUI
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
//Componentes
import Panel from "~/components/layout/Panel";
import { RootState } from "~/store/ducks";
import { CustomDrawer as Drawer } from "~/components/layout/Drawer";
import { InputText, InputDate } from '~/components/Forms';
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
import { DeleteButton } from '~/components/Buttons';
import Status from '~/components/Status';
import Dropdown from "~/components/layout/Dropdown";
import Autocomplete from '@material-ui/lab/Autocomplete';
import colors from "~/assets/js/colors";
import { Creators as getSolicitations } from "~/store/ducks/solicitation";
import { ISolicitationType, DataTypes } from "~/store/ducks/solicitation/types";

import { Creators as setCurrentTab } from "~/store/ducks/profile";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import Colors from "~/assets/js/colors";

const btnStyle: Partial<ICommandBarStyles> = {
    root: {
        height: 40
    }
};

type GroupFilter = {
    a: boolean;
    f: boolean;
    c: boolean;
    e: boolean;
    k: boolean;
    n: boolean;
};

type Filter = {
    isOpen?: boolean;
    filteredBy?: string[];
    group: GroupFilter;
};

interface ICycleState {
    columns: IColumn[];
    items: ISolicitationType[];
    isPanelOpen: boolean;
    isDialogOpen: boolean;
    selectionDetails: string;
    // inicialValues: TaskType;
    selectionCount: number;
    isFiltering: boolean;
    isDialogExcludeOpen: boolean;
    filter: Filter;
    // itemSelected: TaskType;
    searchText: string;
};

interface ICyclesProps extends RouteComponentProps<any> {
    approvals: DataTypes;
    getSolicitations: (searchText?: string, status?: string[]) => void;
    // setCurrentPage: (page: Page) => void;
    setCurrentTab: (indexTab: string) => void;
    history: History;
}

class Solicitations extends Component<ICyclesProps, ICycleState>{
    private formRef: any;
    private inputSearch: any;
    private _selection: Selection;

    constructor(props: ICyclesProps) {
        super(props);

        const columns: IColumn[] = [
            {
                key: 'column1',
                name: '',
                ariaLabel: 'Status da solicitação',
                fieldName: 'status',
                minWidth: 15,
                maxWidth: 20,
                isResizable: true,
                isSortedDescending: false,
                onRender: (item: ISolicitationType) =>
                    <Status
                        color={convertStatusSolicitationColor(item.status)}
                        statusLetter={item.status.toLowerCase()}
                    />
            },
            {
                key: 'column2',
                name: 'ID',
                ariaLabel: 'ID',
                fieldName: 'idSolicitacao',
                isRowHeader: true,
                minWidth: 75,
                maxWidth: 100,
                isSortedDescending: false,
                onColumnClick: this._onColumnClick,
            },
            {
                key: 'column3',
                name: 'Descrição',
                fieldName: 'tituloSolicitacao',
                minWidth: 150,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Tipo',
                fieldName: 'tipoSolicitacao',
                minWidth: 150,
                maxWidth: 210,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Prazo',
                fieldName: 'dataExpiracao',
                minWidth: 150,
                maxWidth: 210,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                isPadded: true,
                onRender: (item: ISolicitationType) => moment(item.dataExpiracao).format("DD/MM/YYYY")
            },
        ];

        this.state = {
            columns: columns,
            items: [],
            isPanelOpen: false,
            isDialogOpen: false,
            isDialogExcludeOpen: false,
            selectionDetails: "",
            isFiltering: false,
            // inicialValues: inicialValueCycle,
            selectionCount: 0,
            filter: {
                isOpen: false,
                filteredBy: ['a'],
                group: {
                    a: true,
                    f: false,
                    e: false,
                    c: false,
                    k: false,
                    n: false,
                }
            },
            // itemSelected: inicialValueCycle,
            searchText: ""
        };
        this.formRef = React.createRef();
        this.inputSearch = React.createRef();

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails(),
                });
            },
        });
    };

    componentDidMount() {
        this.props.getSolicitations();
    };

    _getSelectionDetails(): any {
        const selectionCount = this._selection.getSelectedCount();
        this.setState({ selectionCount });
        this._selection.getSelection();
    };

    // Submit do formulário Formik
    handleSubmit = () => {
        if (this.formRef.current) {
            this.formRef.current.handleSubmit();
        };
    };

    cancelPanel = () => {
        this._selection.setAllSelected(false);
        this.setState({ isPanelOpen: false });
    };

    openAdd = () => {
        // this.setState({
        //     isPanelOpen: true,
        //     inicialValues: inicialValueCycle,
        //     employee: {
        //         idFuncionario: null,
        //         nomeCompleto: ""
        //     }
        // })
    }

    openEdit = () => {
        this.setState({ isPanelOpen: true });
        const idSelected = (this._selection.getSelection()[0] as ISolicitationType).idSolicitacao!;
        // this.props.getCycleById(idSelected);
    };

    handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
        const search = e.target.value;
        this.setState({ searchText: search })
    }

    handleEnterSearch = (e: React.KeyboardEvent<Element>) => {
        const { searchText, filter } = this.state;
        if (e.keyCode === 13) {
            this.props.getSolicitations(searchText, filter.filteredBy)
        }
    }

    handleFilterStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            filter: {
                ...this.state.filter,
                group: {
                    ...this.state.filter.group,
                    [e.target.name]: e.target.checked
                }
            }
        }, () => {
            const status = Object.entries(this.state.filter.group).filter(item => {
                if (item[1]) {
                    return item;
                }
            }).map(item => item[0])

            this.setState({
                filter: {
                    ...this.state.filter,
                    filteredBy: status
                }
            })

            this.props.getSolicitations(this.state.searchText, status)
        })
    }

    toggleFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                isOpen: !this.state.filter.isOpen,
            }
        });
    };

    // toggleParamsFilter = (param: string) => {
    //     const filter = this.state.filter;
    //     const params = filter.filteredBy;
    //     const stmarche = filter.group?.stmarche;
    //     const emporio = filter.group?.emporio;

    //     const index = params!.indexOf(param);
    //     if (index > -1) {
    //         params!.splice(index, 1);
    //     } else {
    //         params!.push(param);
    //     };

    //     if (param === "ST MARCHE") {
    //         this.setState({ filter: { ...this.state.filter, filteredBy: params, group: { ...this.state.filter.group, stmarche: !stmarche } } });
    //     } else {
    //         this.setState({ filter: { ...this.state.filter, filteredBy: params, group: { ...this.state.filter.group, emporio: !emporio! } } });
    //     };

    //     if (this.inputSearch.current) {
    //         this.filter(this.inputSearch.current.state.value);
    //     };

    // };


    clearFilters = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                filteredBy: [],
                group:
                {
                    a: false,
                    f: false,
                    e: false,
                    c: false,
                    k: false,
                    n: false
                }
            }
        }, () => this.props.getSolicitations(this.state.searchText));
    };

    _onItemInvoked = (item: ISolicitationType) => {
        const { idSolicitacao, tipoSolicitacao } = item;
        if (tipoSolicitacao === 'ME') {
            this.props.history.push({
                pathname: `/workflow/solicitacao/${idSolicitacao}`,
                state: { stateLocation: { disabled: true } }
            })
            // this.setState({ redirectPath: `/tarefas/${idSolicitacao}`, redirect: true, stateLocation: { disabled: true } })
        } else if (tipoSolicitacao === 'TV') {
            this.props.history.push({
                pathname: `/workflow/timeavaliacao`,
            })
            // this.setState({ redirectPath: "/workflow/timeavaliacao", redirect: true })
        } else if (tipoSolicitacao === 'AV') {
            this.props.history.push({
                pathname: `/workflow/autoavaliacao`,
            })
            // this.setState({ redirectPath: "/workflow/autoavaliacao", redirect: true })
        }
    }


    commandBarRender = () => {
        const { selectionCount } = this.state;
        if (selectionCount === 1) {
            return (
                <>
                    <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Edit' }} text="Editar" onClick={() => this.openEdit()} />
                    <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
                    {/* <Link to={`/ciclos/${(this._selection.getSelection()[0] as CycleType).idCiclo}`}
                    >
                        <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Settings' }} text="Definições" />
                    </Link> */}
                </>
            );
        } else if (selectionCount > 1) {
            return <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />;
        };
    };

    render() {
        const {
            isPanelOpen,
            // inicialValues,
            selectionCount,
            columns,
            isFiltering,
            isDialogExcludeOpen,
            items,
            filter,
            searchText
        } = this.state;
        const { approvals, getSolicitations } = this.props;
        // const { itemSelected } = cycles;

        const dialogContentProps = {
            title: 'Excluir?',
            closeButtonAriaLabel: 'Close',
            subText: selectionCount <= 1 ? 'Tem certeza de que deseja excluir este item?' : 'Tem certeza de que deseja excluir estes items?',
        };

        return (
            <>
                {/* <Panel
                                title={!inicialValues.idCiclo ? "Novo Ciclo" : inicialValues.ano!.toString()}
                                open={isPanelOpen}
                                loading={itemSelected.loading}
                                onClose={() => this.cancelPanel()}
                                footer={
                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <DefaultButton onClick={() => this.cancelPanel()}>Cancelar</DefaultButton>
                                        {
                                            !cycles.loadingAction ?
                                                <PrimaryButton onClick={this.handleSubmit}>Salvar</PrimaryButton>
                                                :
                                                <Spinner styles={{ root: { width: 110 } }} size={SpinnerSize.small} />
                                        }
                                    </Stack>
                                }
                            >
                                {
                                    cycles.itemSelected.loading ?
                                        <Spinner size={SpinnerSize.large} styles={{ root: { height: 'calc(100% - 50px)' } }} />
                                        :
                                        <Formik
                                            innerRef={this.formRef}
                                            initialValues={inicialValues}
                                            validationSchema={validationCyclesSchema}
                                            enableReinitialize
                                            validateOnChange={false}
                                            validateOnBlur={true}
                                            onSubmit={(values: any) => {
                                                console.log(values)
                                                if (inicialValues.idCiclo === null) {
                                                    addCycle(values);
                                                } else {
                                                    values.id = inicialValues.idCiclo
                                                    editCycle(values);
                                                };
                                            }}
                                        >
                                            {({ handleSubmit, handleChange, values, errors, setFieldValue, setFieldError }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <InputText
                                                        value={values.ano !== null ? values.ano : ""}
                                                        onChange={e => { handleChange(e); setFieldError('ano', '') }}
                                                        id="ano"
                                                        error={errors.ano ? true : false}
                                                        name="ano"
                                                        type="number"
                                                        label="Ano"
                                                        helperText={errors.ano}
                                                        className="mt-2"
                                                        autoFocus
                                                    />

                                                    <Autocomplete
                                                        value={(employee as EmployeeType)}
                                                        onChange={(_, newValue) => {
                                                            this.setState({ employee: newValue! })
                                                            setFieldValue("responsavel", { idFuncionario: newValue?.idFuncionario! });
                                                        }}
                                                        onInputChange={(_, newInputValue) => {
                                                            setFieldError("responsavel", "");
                                                            this.search(newInputValue, "responsavel");
                                                        }}
                                                        getOptionLabel={(employee: EmployeeType) => employee.nomeCompleto}
                                                        getOptionSelected={(option, value) => option.idFuncionario === value.idFuncionario}
                                                        id="responsavel"
                                                        noOptionsText="Não há opções"
                                                        options={employees.data}
                                                        renderInput={(params) => <InputText
                                                            {...params}
                                                            label="Funcionário Responsável"
                                                            helperText={errors.responsavel?.idFuncionario}
                                                            type="text"
                                                            error={errors.responsavel?.idFuncionario ? true : false}
                                                            name="funcionarioResponsavel"
                                                        />}
                                                    />

                                                    <InputDate
                                                        value={values.prazoPlanejamento}
                                                        onChange={value => setFieldValue('prazoPlanejamento', value)}
                                                        onKeyDown={() => setFieldError('prazoPlanejamento', '')}
                                                        id="prazoPlanejamento"
                                                        error={errors.prazoPlanejamento ? true : false}
                                                        name="prazoPlanejamento"
                                                        label="Prazo do planejamento"
                                                        helperText={errors.prazoPlanejamento}
                                                    />
                                                    <Dropdown
                                                        errors={errors.status}
                                                        label="Status"
                                                        name="status"
                                                        values={values.status}
                                                        handleChange={(e: any) => { handleChange(e); setFieldError("status", "") }}
                                                        errorText={errors.status}
                                                    >
                                                        <MenuItem value={"P"}>Em Planejamento</MenuItem>
                                                        <MenuItem value={"F"}>Finalizado</MenuItem>
                                                    </Dropdown>
                                                </form>
                                            )}
                                        </Formik>
                                }
                            </Panel> */}
                {/* <CustomDialog
                                hidden={!isDialogExcludeOpen}
                                onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
                                dialogContentProps={dialogContentProps}
                            >
                                <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
                                <DeleteButton onClick={() => this.excludeCompanies()} text="Excluir" />
                            </CustomDialog> */}
                <Wrapper >
                    <Drawer
                        diffHeight={105}
                        isOpen={filter.isOpen}
                        content={
                            <ContainerContent>
                                <Stack horizontal verticalAlign="center">
                                    <InputText
                                        label="Entre com a descrição para a busca"
                                        value={searchText}
                                        onChange={this.handleChangeText}
                                        onKeyUp={this.handleEnterSearch}
                                        variant="outlined"
                                        height_container={40}
                                        fullWidth
                                        size="small"
                                        smaller="small"
                                    />
                                    <CommandBarButton
                                        styles={{ root: { height: 40, marginLeft: '15px !important', padding: '0 5px' } }}
                                        iconProps={{ iconName: 'Refresh' }}
                                        text="Atualizar"
                                        onClick={() => getSolicitations(searchText, filter.filteredBy)}
                                    />
                                    <CommandBarButton
                                        styles={{ root: { height: 40, marginLeft: '15px !important', padding: '0 5px' } }}
                                        iconProps={{ iconName: 'filter' }}
                                        text="Filtrar"
                                        onClick={() => this.toggleFilter()}
                                    />
                                </Stack>
                                {!approvals.loading && approvals.data.length === 0 ?
                                    <NoItems
                                        error={approvals.error}
                                        text="Não há solicitações"
                                        icon="IssueTracking"
                                        alt="Solicitações"
                                    />
                                    :
                                    <ListContainer>
                                        <ShimmeredDetailsList
                                            items={isFiltering ? items : approvals.data}
                                            enableShimmer={approvals.loading}
                                            columns={columns}
                                            selectionMode={SelectionMode.none}
                                            selection={this._selection}
                                            getKey={this._getKey}
                                            selectionPreservedOnEmptyClick={true}
                                            setKey="single"
                                            layoutMode={DetailsListLayoutMode.justified}
                                            isHeaderVisible={true}
                                            onItemInvoked={this._onItemInvoked}
                                        />
                                    </ListContainer>
                                }
                            </ContainerContent>
                        }>
                        <>
                            <Stack horizontal horizontalAlign="space-between">
                                <Text variant="xLarge">Filtros</Text>
                                <Stack horizontal>
                                    <IconButton iconProps={{ iconName: 'ClearFilter' }} title="Limpar Filtros" disabled={filter.filteredBy!.length > 0 ? false : true} onClick={() => this.clearFilters()} ariaLabel="Limpar Filtros" />
                                    <IconButton iconProps={{ iconName: 'ChromeClose' }} styles={{ root: { color: colors.darkGray } }} title="Fechar" ariaLabel="Fechar" onClick={() => this.setState({ filter: { ...filter, isOpen: false } })} />
                                </Stack>
                            </Stack>
                            <GroupCheckbox>
                                <Text variant="mediumPlus" styles={{ root: { marginBottom: 10 } }}>Status</Text>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={filter.group?.a}
                                            onChange={this.handleFilterStatus}
                                            name="a"
                                            color="primary"
                                        />
                                    }
                                    label={<span style={{ fontSize: 14 }}>Aberta</span>}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={filter.group?.k}
                                            onChange={this.handleFilterStatus}
                                            name="k"
                                            color="primary"
                                        />
                                    }
                                    label={<span style={{ fontSize: 14 }}>Aprovadas</span>}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={filter.group?.c}
                                            onChange={this.handleFilterStatus}
                                            name="c"
                                            color="primary"
                                        />
                                    }
                                    label={<span style={{ fontSize: 14 }}>Cancelada</span>}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={filter.group?.e}
                                            onChange={this.handleFilterStatus}
                                            name="e"
                                            color="primary"
                                        />
                                    }
                                    label={<span style={{ fontSize: 14 }}>Expirada</span>}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={filter.group?.f}
                                            onChange={this.handleFilterStatus}
                                            name="f"
                                            color="primary"
                                        />
                                    }
                                    label={<span style={{ fontSize: 14 }}>Finalizada</span>}
                                />


                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={filter.group?.n}
                                            onChange={this.handleFilterStatus}
                                            name="n"
                                            color="primary"
                                        />
                                    }
                                    label={<span style={{ fontSize: 14 }}>Negadas</span>}
                                />
                            </GroupCheckbox >
                        </>
                    </Drawer>
                </Wrapper>
            </>
        );
    };

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns } = this.state;
        const newColumns: IColumn[] = columns.slice();
        let items: ISolicitationType[] = [];
        if (this.state.isFiltering) {
            items = this.state.items;
        } else {
            items = this.props.approvals.data;
        };
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            };
        });
        const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems,
        });
    };

    private _getKey(item: any, index?: number): any {
        if (item !== undefined)
            return item.key;
    };

    private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
        return itemsSorted;
    };
};

const convertTipoTarefa = (tipo: string): string => {
    switch (tipo) {
        case 'A':
            return 'Aberto';
        case 'E':
            return 'Expirada';
        case 'F':
            return 'Finalizada';
        case 'C':
            return 'Cancelada';
        default:
            return tipo;
    }
}
const convertStatusSolicitationColor = (tipo: string): string => {
    switch (tipo) {
        case 'a':
            return "#1FB964";
        case 'e':
            return Colors.darkGray;
        case 'f':
            return Colors.darkGray;
        case 'c':
            return Colors.error;
        case 'n':
            return Colors.error;
        case 'k':
            return "#1FB964";
        default:
            return tipo;
    }
}

const mapStateToProps = (state: RootState) => ({
    approvals: state.solicitationReducer,
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...setCurrentPage,
    ...getSolicitations,
    ...setCurrentTab
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Solicitations));