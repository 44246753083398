import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from "formik";
import * as yup from 'yup';

import { Dialog, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

import { InputText } from '~/components/Forms/index';

import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { PesoType } from '~/store/ducks/cycle/painel/types';
import { PesoDismembermentType } from '~/store/ducks/cycle/dismemberment/types';


const dialogPesoContentProps = {
    title: 'Definir Peso',
    closeButtonAriaLabel: 'Close',
    subText: "",
};

interface IFormPesosProps {
    isOpen: boolean;
    setIsOpen: () => void;
    descObjetivo: string;
    isLoadingPeso: boolean;
    initialValues: PesoType | PesoDismembermentType;
    onAddPeso: (peso: PesoType | Partial<PesoType>) => void;
    onEditPeso: (peso: PesoType | Partial<PesoType>) => void;
    onDeletePeso: (peso?: Partial<PesoType>) => void;
}

const validadtionDefinePesoSchema = yup.object().shape({
    definirPeso: yup.bool(),
    definirResponsabilidade: yup.bool(),
    peso: yup.string().when("definirPeso", {
        is: true,
        then: yup.string().required("Campo obrigatório")
    })
})

const validadtionDefinePesoDismembermentSchema = yup.object().shape({
    definirPeso: yup.bool(),
    peso: yup.object().shape({
        peso: yup.string().when("definirPeso", {
            is: true,
            then: yup.string().required("Campo obrigatório")
        })
    })
})


const FormPesos: React.FC<IFormPesosProps> = (props) => {
    const {
        isOpen,
        setIsOpen,
        descObjetivo,
        isLoadingPeso,
        initialValues,
        onAddPeso,
        onEditPeso,
        onDeletePeso
    } = props;
    const dispatch = useDispatch();

    return (
        <Dialog
            hidden={!isOpen}
            // onDismiss={() => setIsDialogPesoOpen(false)}
            dialogContentProps={dialogPesoContentProps}
            minWidth={640}
        >
            {
                isLoadingPeso ?
                    <Stack styles={{ root: { height: 323 } }} verticalAlign="center">
                        <Spinner size={SpinnerSize.large} />
                    </Stack>
                    :
                    <Formik
                        initialValues={{ ...initialValues, definirPeso: true }}
                        validationSchema={"area" in initialValues ? validadtionDefinePesoSchema : validadtionDefinePesoDismembermentSchema}
                        enableReinitialize
                        validateOnChange={false}
                        validateOnBlur={true}
                        onSubmit={(values: any) => {
                            const peso: PesoType = {
                                ...initialValues as PesoType,
                                peso: values.peso,
                                definirResponsabilidade: values.definirResponsabilidade,
                                idCicloPeso: values.idCicloPeso === null ? 0 : values.idCicloPeso,
                            };

                            const pesoDismemberment: Partial<PesoType> = {
                                peso: values.peso.peso,
                                definirResponsabilidade: false,
                                idCicloPeso: values.peso.idCicloPeso,
                                idArea: values.peso.idArea,
                                idCicloPesoPai: values.peso.idCicloPesoPai,
                                idObjetivo: values.peso.idObjetivo !== 0 ? values.peso.idObjetivo : (initialValues as PesoType).idObjetivo // Se o item não tiver idObjetivo, pega do Pai 
                            };

                            if ('area' in initialValues) {
                                if (values.idCicloPeso === null) {
                                    dispatch(onAddPeso(peso));
                                } else {
                                    if (values.definirPeso) {
                                        dispatch(onEditPeso(peso));
                                    } else {
                                        dispatch(onDeletePeso());
                                    };
                                };
                            } else {
                                if (values.peso.idCicloPeso === 0) {
                                    dispatch(onAddPeso(pesoDismemberment));
                                } else {
                                    if (values.definirPeso) {
                                        dispatch(onEditPeso(pesoDismemberment));
                                    } else {
                                        dispatch(onDeletePeso(pesoDismemberment));
                                    };
                                };

                            }
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => {
                            let hasIdCicloPeso: boolean;
                            let nomeLocal: string;
                            let peso: string;
                            if ('area' in initialValues) {
                                const value = (values as PesoType);
                                hasIdCicloPeso = value.idCicloPeso !== null && value.idCicloPeso !== 0;
                                nomeLocal = value.area.nomeLocal;
                                peso = String(value.peso);
                            } else {
                                const value = (values as PesoDismembermentType);
                                hasIdCicloPeso = value.peso.idCicloPeso !== null && value.peso.idCicloPeso !== 0;
                                nomeLocal = value.nomeLocal;
                                peso = value.peso.peso;
                            }

                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12">
                                                <InputText
                                                    value={descObjetivo}
                                                    onChange={handleChange}
                                                    label="Objetivo"
                                                    disabled={true}
                                                    style={{ width: 380 }}
                                                    className="mt-2" />
                                            </div>
                                            <div className="ms-Grid-col ms-sm12">
                                                <InputText
                                                    value={nomeLocal}
                                                    label="Local"
                                                    style={{ width: 380 }}
                                                    disabled
                                                    className="mt-2" />
                                            </div>
                                            {
                                                hasIdCicloPeso &&
                                                <FormControlLabel style={{ padding: 8 }}
                                                    control={<Switch
                                                        size="small"
                                                        type="checkbox"
                                                        checked={values.definirPeso}
                                                        onChange={handleChange}
                                                        name="definirPeso"
                                                        id="definirPeso"
                                                        color="primary"
                                                    />}
                                                    label="Definir Peso"
                                                />
                                            }

                                            {
                                                ("area" in initialValues) && hasIdCicloPeso &&
                                                <FormControlLabel style={{ padding: 8 }}
                                                    control={<Switch
                                                        size="small"
                                                        type="checkbox"
                                                        checked={(values as PesoType).definirResponsabilidade}
                                                        onChange={handleChange}
                                                        name="definirResponsabilidade"
                                                        id="definirResponsabilidade"
                                                        color="primary"
                                                        disabled={!values.definirPeso}
                                                    />}
                                                    label="Definir Responsabilidade"
                                                />
                                            }
                                            <div className="ms-Grid-col ms-sm12">
                                                <InputText
                                                    value={peso}
                                                    onChange={(e) => setFieldValue("peso", ("area" in initialValues) ? e.target.value : { ...initialValues.peso, peso: e.target.value })}
                                                    id="peso"
                                                    error={errors.peso ? true : false}
                                                    name="peso"
                                                    type="text"
                                                    label="Peso"
                                                    disabled={!values.definirPeso}
                                                    helperText={errors.peso}
                                                    style={{ width: 380 }}
                                                    autoFocus
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end" style={{ paddingRight: 20 }}>%</InputAdornment>,
                                                    }}
                                                    className="mt-2" />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ paddingRight: 5 }}>
                                        <DialogFooter>
                                            <DefaultButton styles={{ root: { width: 110 } }} onClick={setIsOpen} text="Cancelar" />
                                            <PrimaryButton styles={{ root: { width: 110 } }} type="submit" text="Salvar" />
                                        </DialogFooter>
                                    </div>
                                </form>
                            )
                        }}
                    </Formik>
            }
        </Dialog >
    );
}

export default FormPesos;