// #region Imports
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from 'moment';

//FluentUI
import { Persona, PersonaSize } from 'office-ui-fabric-react/lib/Persona';
import { Text } from "office-ui-fabric-react/lib/Text";
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
    DetailsListLayoutMode,
    SelectionMode,
    Selection,
    IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { CommandBarButton, ICommandBarStyles, FontIcon } from "office-ui-fabric-react";
import { MarqueeSelection } from "office-ui-fabric-react/lib/MarqueeSelection";
import { Label, ILabelStyles } from 'office-ui-fabric-react/lib/Label';
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { IStyleSet } from 'office-ui-fabric-react/lib/Styling';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { IDialogContentProps } from 'office-ui-fabric-react/lib/Dialog';
import InputAdornment from '@material-ui/core/InputAdornment';
//MaterialUI
//Componentes
import CustomDialog from "~/components/CustomDialogFluentUI";
import PanelDisplay from "~/components/PanelDisplay";
import PanelWizard from "~/components/PanelWizard";
import {
    TitleField,
    GroupItems,
    GroupItemUmTerco,
    GroupItemDoisTerco,
} from '~/components/FormGroup';
import Status from "~/components/Status";
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
import { DeleteButton } from '~/components/Buttons';

import FormIdentification from "./FormIdentification";
import FormCadastral from './FormCadastral';
import FormContentInfoBasic, { InfoBasicType } from "./FormBasicInfo";
import FormContentInfoAdmission, { InfoAdmissionType } from "./FormInfoAdmission";
import InfoAcess from "./InfoAccess";


import { getAbbreviatedName } from "~/utils/abbreviatedName";
import passwordGenerator from '~/utils/passwordGenerator';
import colors from "~/assets/js/colors";
import { InputText } from "~/components/Forms";
//Estilos
import {
    Wrapper,
    ListContainer,
    PrimaryButton,
    DefaultButton,
    PrimaryButtonDisplay
    // ImgPerfil,
} from "./styles";

import { Creators as getEmployees } from "~/store/ducks/employees";
import { Creators as getEmployeeById } from "~/store/ducks/employees";
import { Creators as addEmployee } from "~/store/ducks/employees";
import { Creators as editEmployee } from "~/store/ducks/employees";
import { Creators as editEmployeePassword } from "~/store/ducks/employees";
import { Creators as editEmployeeAccess } from "~/store/ducks/employees";
import { Creators as delEmployee } from "~/store/ducks/employees";
import { Creators as resetEmployees } from "~/store/ducks/employees";
import { EmployeeType, DataTypes } from "~/store/ducks/employees/types";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { BreadcrumbItems, Page } from "~/store/ducks/home/types";
import { RootState } from "~/store/ducks";

//#endregion
//#region Styles
const btnStyle: Partial<ICommandBarStyles> = {
    root: {
        height: 44
    }
};

const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: { marginTop: 10, paddingBottom: 0 },
};
//#endregion
//#region Interfaces

type Filter = {
    isOpen?: boolean;
    filterStatus: boolean | null;
};

enum TypeContentPanel {
    identification = "ID",
    cadastral = "CADASTRAL"
};

interface IEmployeesState {
    columns: IColumn[];
    items: EmployeeType[];
    isPanelDisplayOpen: boolean;
    isPanelOpen: boolean;
    isPanelEditOpen: boolean;
    panelContent: TypeContentPanel | null;
    isDialogOpen: boolean;
    isDialogAccessOpen: boolean;
    isDialogResetPasswordOpen: boolean;
    isLoadData: boolean;
    initialValuesItem: EmployeeType;
    initialValuesBasic: InfoBasicType;
    initialValuesAdmission: InfoAdmissionType;
    partialItemSelected: EmployeeType;
    isFiltering: boolean;
    selectionDetails: string;
    selectionCount: number;
    isDialogExcludeOpen: boolean;
    filter: Filter;
    search: string;
    activeStep: number;
    grantedAccess: boolean;
};


const initialEmployee: EmployeeType = {
    idFuncionario: null,
    nomeCompleto: "",
    dataNascimento: null,
    email: "",
    cpf: "",
    matricula: "",
    sexo: "",
    dataAdmissao: null,
    dataDemissao: null,
    status: 'A',
    unidade: {
        idUnidade: null,
        nomeUnidade: "",
    },
    flagAcessoRHR: true,
    imagem: '',
    // cargo: {
    //     idCargo: null,
    //     titulo: ""
    // },
    // local: {
    //     idArea: null,
    //     nomeLocal: ""
    // },
    password: "",
};

const initialEmployeeBasicInfo: InfoBasicType = {
    nomeCompleto: "",
    dataNascimento: null,
    email: "",
    cpf: "",
    sexo: "",
};

const initialEmployeeInfoAdmission: InfoAdmissionType = {
    matricula: "",
    dataAdmissao: null,
    unidade: {
        idUnidade: null,
        nomeUnidade: ""
    },
    // cargo: {
    //     idCargo: null,
    //     titulo: ""
    // },
    // local: {
    //     idArea: null,
    //     nomeLocal: ""
    // }
};

interface IProps {
    employees: DataTypes;
    location?: any;
    getEmployees: (search?: string, filter?: boolean | null) => void;
    getEmployeeById: (id: number) => void;
    addEmployee: (employee: EmployeeType) => void;
    editEmployee: (employee: EmployeeType) => void;
    editEmployeePassword: (id: number, password: string) => void;
    editEmployeeAccess: (id: number, access: boolean) => void;
    delEmployee: (id: number) => void;
    resetEmployees: () => void;
    setCurrentPage: (page: Page) => void;
};

//#endregion
//#region InitialValues
const itemsBreadCrumb: BreadcrumbItems[] = [
    { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
    { text: "Funcionários", isCurrentItem: true },
];

const stepsTitles = [
    {
        title: "Configurar identificação",
        subText: "Para começar, preenche as informações sobre quem você está adicionando como funcionário."
    },
    {
        title: "Configurar admissão",
        subText: "É importante preencher corretamente as informações da admissão"
    },
    {
        title: "Configurar acesso ao Portal RHR",
        subText: "Para acessar o portal o usuário deverá se identificar informando o e-mail e senha."
    }
];

const page: Page = {
    key: "funcionarios",
    pages: itemsBreadCrumb,
};

//#endregion

class Employees extends Component<IProps, IEmployeesState> {
    private formRefIdentification: any;
    private formRefCadastral: any;
    private formRefInfoBasic: any;
    private formRefInfoAdmission: any;

    private inputSearch: any;
    private _selection: Selection;
    private steps: string[];
    private password: string;

    constructor(props: IProps) {
        super(props);
        //#region Colunas
        const columns: IColumn[] = [
            {
                key: 'column1',
                name: '',
                ariaLabel: 'Status da empresa',
                fieldName: 'status',
                minWidth: 15,
                maxWidth: 20,
                isResizable: true,
                isSortedDescending: false,
                onRender: (item: EmployeeType) => <Status status={item.status === 'A'} />
            },
            {
                key: "column2",
                name: "Matrícula",
                ariaLabel: "Matrícula",
                fieldName: "matricula",
                isRowHeader: true,
                minWidth: 75,
                maxWidth: 100,
                isSortedDescending: false,
                onColumnClick: this._onColumnClick
            },
            {
                key: "column3",
                name: "Nome Completo",
                fieldName: "nomeCompleto",
                ariaLabel: "Nome Completo",
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: "Sorted A to Z",
                sortDescendingAriaLabel: "Sorted Z to A",
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
            },
            {
                key: "column4",
                name: "Unidade",
                fieldName: "unidade",
                minWidth: 70,
                maxWidth: 90,
                isRowHeader: true,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: "string",
                isPadded: true,
                onRender: (item: EmployeeType) => item.unidade.nomeUnidade,
            },
        ];
        //#endregion

        this.state = {
            columns: columns,
            items: [],
            isDialogOpen: false,
            isPanelDisplayOpen: false,
            isPanelOpen: false,
            isPanelEditOpen: false,
            isDialogAccessOpen: false,
            isDialogResetPasswordOpen: false,
            panelContent: null,
            isLoadData: true,
            selectionDetails: "",
            initialValuesItem: initialEmployee,
            initialValuesBasic: initialEmployeeBasicInfo,
            initialValuesAdmission: initialEmployeeInfoAdmission,
            partialItemSelected: initialEmployee,
            isFiltering: false,
            selectionCount: 0,
            isDialogExcludeOpen: false,
            filter: {
                isOpen: false,
                filterStatus: null
            },
            search: '',
            activeStep: 0,
            grantedAccess: true
        };

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails(),
                });
            },
        });

        this.steps = ["Informações Básicas", "Informações de Admissão", "Configurações de Acesso"];

        this.formRefIdentification = createRef();
        this.formRefCadastral = createRef();
        this.formRefInfoBasic = createRef();
        this.formRefInfoAdmission = createRef();

        this.inputSearch = createRef();
        this.password = '';
    }

    componentDidMount() {
        const { setCurrentPage, resetEmployees } = this.props;
        resetEmployees();
        if (this.props.location.state) {
            const employeeName = this.props.location.state.employeeName ?? "";
            this.setState({ search: employeeName, isPanelOpen: this.props.location.state.isPanelOpen ?? false })
        }

        setCurrentPage(page);
    };

    componentDidUpdate(prevProps: IProps, prevState: IEmployeesState) {
        const { successEdit: prevSuccessEdit, success: prevSuccess } = prevProps.employees;
        const { successEdit: nextSuccessEdit, success: nextSuccess } = this.props.employees;
        if (prevSuccessEdit !== nextSuccessEdit) {
            if (nextSuccessEdit) {
                this.setState({ isPanelEditOpen: false });
            };
        };

        if (prevSuccess !== nextSuccess) {
            if (nextSuccess) {
                this.setState({ isPanelOpen: false });
            };
        };
    };

    _onItemInvoked = (item: EmployeeType): void => {
        const value = this._selection.getSelection()[0] as EmployeeType;
        this.props.getEmployeeById(value.idFuncionario!);
        this.setState({ isPanelDisplayOpen: true, partialItemSelected: value });
    };

    _getSelectionDetails(): any {
        const selectionCount = this._selection.getSelectedCount();
        this.setState({ selectionCount });
        this._selection.getSelection();
    };

    openEdit = () => {
        const value = this._selection.getSelection()[0] as EmployeeType;
        this.props.getEmployeeById(value.idFuncionario!);
        this.setState({ isPanelDisplayOpen: true, partialItemSelected: value });
    };

    cancelPanel = () => {
        this._selection.setAllSelected(false);
        this.setState({ isPanelOpen: false, initialValuesItem: initialEmployee });
    };

    handleSave = () => {
        const formRefIdentification = this.formRefIdentification.current;
        const formRefCadastral = this.formRefCadastral.current;

        if (formRefIdentification) {
            formRefIdentification.handleSubmit();
        };

        if (formRefCadastral) {
            formRefCadastral.handleSubmit();
        };

    };

    handleSaveAddEmployee = () => {
        this.props.addEmployee(this.state.initialValuesItem);
    };

    handleDelEmployee = () => {
        const id = (this._selection.getSelection()[0] as EmployeeType).idFuncionario;
        this.props.delEmployee(id!);
        this.setState({ isDialogExcludeOpen: false });
    };

    //#region  Funções do filtro

    // toggleFilter = () => {
    //     this.setState({
    //         filter: {
    //             ...this.state.filter,
    //             isOpen: !this.state.filter.isOpen,
    //         }
    //     })
    // }

    // toggleParamsFilter = (param: string) => {
    //     const filter = this.state.filter
    //     const params = filter.filteredBy
    //     const stmarche = filter.group?.stmarche
    //     const emporio = filter.group?.emporio

    //     const index = params!.indexOf(param);
    //     if (index > -1) {
    //         params!.splice(index, 1);
    //     } else {
    //         params!.push(param)
    //     }

    //     if (param === "ST MARCHE") {
    //         this.setState({ filter: { ...this.state.filter, filteredBy: params, group: { ...this.state.filter.group, stmarche: !stmarche } } })
    //     } else {
    //         this.setState({ filter: { ...this.state.filter, filteredBy: params, group: { ...this.state.filter.group, emporio: !emporio! } } })
    //     }

    //     if (this.inputSearch.current) {
    //         this.filter(this.inputSearch.current.state.value)
    //     }

    // }


    filter = (key: any) => {
        const { search, filter } = this.state;
        if (search.trim().length > 0) {

            if (key === 'Enter') {
                if (filter.filterStatus !== null) {
                    this.props.getEmployees(search, filter.filterStatus);
                } else {
                    this.props.getEmployees(search);
                }
                if (this.inputSearch.current) {
                    this.inputSearch.current.focus();
                }
            };
        }

        // if (search === "") {
        //     this.props.getEmployees("", filter.filterStatus);
        // }
    };

    handleSearch = (e: any) => {
        const search: string = e?.target.value ? e!.target.value : "";
        this.setState({ search: search });
        // if (search === "") {
        //     this.props.getEmployees("", this.state.filter.filterStatus);
        // }
    };

    // clearFilters = () => {
    //     this.setState({
    //         filter: {
    //             ...this.state.filter,
    //             filteredBy: [],
    //             group:
    //             {
    //                 emporio: false,
    //                 stmarche: false
    //             }
    //         }
    //     }, () => this.filter(""))
    // }

    //#endregion

    handleBack = () => {
        this.setState(prevState => ({
            activeStep: prevState.activeStep - 1
        }));
    }

    handleNext = () => {
        const formRefInfoBasic = this.formRefInfoBasic.current
        const formRefInfoAdmission = this.formRefInfoAdmission.current

        switch (this.state.activeStep) {
            case 0:
                if (formRefInfoBasic) {
                    formRefInfoBasic.handleSubmit();
                };
                break;
            case 1:
                if (formRefInfoAdmission) {
                    formRefInfoAdmission.handleSubmit();
                };
                break;
            case 2:
                console.log(this.state.initialValuesItem)
                break;

        }
        // this.setState(prevState => ({
        //     activeStep: prevState.activeStep + 1
        // }));
    };

    handleReset = () => {
        this.setState({ activeStep: 0 });
    };

    renderForms = () => {
        const { initialValuesBasic, initialValuesAdmission, initialValuesItem, grantedAccess } = this.state;

        switch (this.state.activeStep) {
            case 0:
                return (
                    <FormContentInfoBasic
                        formRef={this.formRefInfoBasic}
                        initialValues={initialValuesBasic}
                        success={(values: InfoBasicType) => {
                            this.setState(prevState => ({
                                initialValuesBasic: values,
                                activeStep: prevState.activeStep + 1,
                                initialValuesItem: {
                                    ...initialValuesItem,
                                    ...values
                                }
                            }), () => console.log(initialValuesItem))
                        }}
                    />
                );
            case 1:
                return (
                    <FormContentInfoAdmission
                        formRef={this.formRefInfoAdmission}
                        initialValues={initialValuesAdmission}
                        success={(values: InfoAdmissionType) => {
                            this.setState(prevState => ({
                                initialValuesAdmission: values,
                                activeStep: prevState.activeStep + 1,
                                initialValuesItem: {
                                    ...initialValuesItem,
                                    ...values,
                                    password: passwordGenerator()
                                }
                            }), () => console.log(this.state.initialValuesItem));
                        }}
                    />);
            case 2:
                return <InfoAcess
                    email={initialValuesItem.email}
                    password={initialValuesItem.password}
                    grantedAccess={grantedAccess}
                    handleGrantedAccess={() => this.setState({ grantedAccess: !grantedAccess })}
                />;
        }
    }


    commandBarRender = () => {
        const { selectionCount } = this.state;
        if (selectionCount === 1) {
            return (
                <>
                    <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: "Edit" }}
                        text="Editar"
                        onClick={this.openEdit}
                    />
                    <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: "Delete" }}
                        text="Excluir"
                        onClick={() => this.setState({ isDialogExcludeOpen: true })}
                    />
                </>
            );
        } else if (selectionCount > 1) {
            return (
                <CommandBarButton
                    styles={btnStyle}
                    iconProps={{ iconName: "Delete" }}
                    text="Excluir"
                    onClick={() => this.setState({ isDialogExcludeOpen: true })}
                />
            );
        }
    };

    render() {
        const {
            isPanelDisplayOpen,
            isPanelOpen,
            isPanelEditOpen,
            isDialogExcludeOpen,
            isDialogResetPasswordOpen,
            isDialogAccessOpen,
            selectionCount,
            partialItemSelected,
            isFiltering,
            items,
            columns,
            panelContent,
            search,
            activeStep
        } = this.state;
        const { employees, getEmployees, editEmployeePassword, editEmployeeAccess } = this.props;
        const { itemSelected: { item: itemSelected } } = employees;
        // const dialogProps: IDialog = {
        //     title: !itemSelected.idFuncionario ? "Cancelar cadastro?" : "Cancelar edição?",
        //     subText: !itemSelected.idFuncionario
        //         ? "Todos os dados inseridos serão perdidos. Tem certeza que quer cancelar o cadastro da empresa? "
        //         : "As mudanças serão descardas. Tem certeza que quer cancelar a edição da empresa?",
        //     closeDialog: () => this.setState({ isDialogOpen: false }),
        //     isOpenDialog: this.state.isDialogOpen,
        //     openDialog: () => this.setState({ isDialogOpen: true }),
        // };

        const dialogContentProps: IDialogContentProps = {
            title: "Excluir?",
            closeButtonAriaLabel: "Close",
            subText:
                selectionCount <= 1
                    ? "Tem certeza de que deseja excluir este item?"
                    : "Tem certeza de que deseja excluir estes items?",
        };

        const dialogConfigContentProps: IDialogContentProps = {
            title: itemSelected.flagAcessoRHR ? "Bloquear entrada?" : "Conceder acesso?",
            closeButtonAriaLabel: "Close",
            subText: `Tem certeza que quer ${itemSelected.flagAcessoRHR ? 'bloquear' : 'conceder'} a entrada ao RHR ?`
        };

        const dialogResetPasswordContentProps: IDialogContentProps = {
            title: "Redefinir senha",
            closeButtonAriaLabel: "Close",
            subText: `Tem certeza que quer redefinir a senha do acesso ao RHR ? Nova senha: ${this.password}`
        };

        return (
            <>
                <PanelDisplay
                    open={isPanelDisplayOpen}
                    onClose={() => this.setState({ isPanelDisplayOpen: false })}
                    isLoading={employees.itemSelected.loading}
                    panelHeader={
                        <Stack horizontal verticalAlign="center">
                            {/* {
                                itemSelected.img ?
                                    <ImgPerfil src="/static/img/user.png" alt="Nome" />
                                    :
                                    <AbbreviatedPefil>
                                        <Text variant="xxLargePlus" styles={{ root: { color: colors.white } }}>
                                            {getAbbreviatedName(itemSelected.nomeCompleto)}
                                        </Text>
                                    </AbbreviatedPefil>
                                } */}
                            <Persona
                                size={PersonaSize.size100}
                                initialsColor={colors.primary}
                                imageInitials={getAbbreviatedName(partialItemSelected.nomeCompleto)}
                                imageUrl={''}
                                styles={{ root: { width: 100, marginRight: 32 } }}
                            />
                            <Stack>
                                <Text variant="xxLarge" styles={{ root: { fontWeight: 'bold', textTransform: 'uppercase' } }}>{partialItemSelected.nomeCompleto}</Text>
                                <Text variant="medium">{!employees.itemSelected.loading ? itemSelected!.email : ''}</Text>
                                <Stack horizontal>
                                    <CommandBarButton
                                        styles={btnStyle}
                                        iconProps={{ iconName: "Permissions" }}
                                        text="Redefinir senha"
                                        onClick={() => {
                                            this.password = passwordGenerator();
                                            this.setState({ isDialogResetPasswordOpen: true });
                                        }}
                                    />
                                    {
                                        itemSelected.flagAcessoRHR ?
                                            <CommandBarButton
                                                styles={btnStyle}
                                                iconProps={{ iconName: "Blocked" }}
                                                text="Bloquear entrada"
                                                onClick={() => this.setState({ isDialogAccessOpen: true })}
                                            />
                                            :
                                            <CommandBarButton
                                                styles={btnStyle}
                                                iconProps={{ iconName: "Signin" }}
                                                text="Conceder entrada"
                                                onClick={() => this.setState({ isDialogAccessOpen: true })}
                                            />
                                    }
                                </Stack>
                            </Stack>
                        </Stack>
                    }
                >
                    <Pivot aria-label="Basic Pivot Example" styles={{ root: { marginBottom: 32 } }}>
                        <PivotItem headerText="Dados Principais">
                            <GroupItems title="Identificação">
                                <GroupItemUmTerco>
                                    <TitleField text="Matrícula" />
                                    <TitleField text={itemSelected!.matricula} />
                                    <Link styles={{ root: { fontSize: 14 } }} onClick={() => this.setState({ isPanelEditOpen: true, panelContent: TypeContentPanel.identification })}>
                                        Gerenciar idendificação
                                    </Link>
                                </GroupItemUmTerco>
                                <GroupItemDoisTerco>
                                    <TitleField text="Nome Completo" />
                                    <TitleField text={itemSelected!.nomeCompleto} />
                                </GroupItemDoisTerco>
                            </GroupItems>

                            <GroupItems title="Situação Cadastral" hasMultiItems>
                                <GroupItems>
                                    <GroupItemUmTerco>
                                        <TitleField text="Data de Admissão" />
                                        <TitleField text={moment(new Date(itemSelected!.dataAdmissao!)).utc().format('DD/MM/YYYY')} />
                                    </GroupItemUmTerco>
                                    <GroupItemUmTerco>
                                        <TitleField text="Status" />
                                        <TitleField text={itemSelected!.status === 'A' ? 'Ativo' : "Desligado"} />
                                    </GroupItemUmTerco>
                                    {itemSelected!.status === 'D' &&
                                        <GroupItemUmTerco>
                                            <TitleField text="Data de Desligamento" />
                                            <TitleField text={moment(new Date(itemSelected!.dataDemissao!)).utc().format('DD/MM/YYYY')} />
                                        </GroupItemUmTerco>
                                    }
                                </GroupItems>
                                <GroupItems>
                                    <GroupItemUmTerco>
                                        <TitleField text="Unidade" />
                                        <TitleField text={itemSelected!.unidade.nomeUnidade!} />
                                        <Link styles={{ root: { fontSize: 14 } }} onClick={() => this.setState({ isPanelEditOpen: true, panelContent: TypeContentPanel.cadastral })}>
                                            Gerenciar situação cadastral
                                            </Link>
                                    </GroupItemUmTerco>
                                </GroupItems>
                            </GroupItems>

                            {/* <GroupItems title="Cargo">
                                <GroupItemUmTerco>
                                    <TitleField text={itemSelected.cargo.titulo!} />
                                </GroupItemUmTerco>
                            </GroupItems>

                            <GroupItems title="Local">
                                <GroupItemUmTerco>
                                    <TitleField text={itemSelected.local.nomeLocal!} />
                                </GroupItemUmTerco>
                            </GroupItems> */}

                        </PivotItem>
                        <PivotItem headerText="Dispositivos">
                            <Label styles={labelStyles}>Pivot #2</Label>
                        </PivotItem>
                    </Pivot>
                </PanelDisplay>

                {/* --------------------------- EDIT PANEL --------------------------------------- */}

                <PanelDisplay
                    open={isPanelEditOpen}
                    onClose={() => this.setState({ isPanelEditOpen: false })}
                    isLoading={false}
                    footer={
                        <>
                            <DefaultButton styles={{ root: { marginRight: 10 } }} onClick={() => this.setState({ isPanelEditOpen: false })}>Cancelar</DefaultButton>
                            <PrimaryButton onClick={this.handleSave}>Salvar</PrimaryButton>
                        </>
                    }
                >
                    {panelContent === TypeContentPanel.identification &&
                        <FormIdentification initialValues={itemSelected!} formRef={this.formRefIdentification} success={(values) => this.props.editEmployee(values)} />}
                    {panelContent === TypeContentPanel.cadastral &&
                        <FormCadastral initialValues={itemSelected!} formRef={this.formRefCadastral} success={(values) => this.props.editEmployee(values)} />
                    }
                </PanelDisplay>

                {/* -----------------------------------  PANEL CADASTRADA FUNCIONÁRIO ------------------------------------- */}

                <PanelWizard
                    open={isPanelOpen}
                    onClose={() => this.setState({ isPanelOpen: false })}
                    title="Adicionar Funcionário"
                    isLoading={false}
                    steps={this.steps}
                    titleSteps={stepsTitles}
                    activeStep={activeStep}
                    handleBack={this.handleBack}
                    handleNext={this.handleNext}
                    footer={
                        <>
                            {this.state.activeStep === this.steps.length - 1 &&
                                <>
                                    {/* <DefaultButton styles={{ root: { marginRight: 10 } }} onClick={() => this.setState({ isPanelOpen: false })}>Cancelar</DefaultButton> */}
                                    <PrimaryButtonDisplay onClick={this.handleSaveAddEmployee}>Salvar</PrimaryButtonDisplay>
                                </>
                            }
                        </>
                    }
                >
                    {
                        this.renderForms()
                    }
                </PanelWizard>

                <CustomDialog
                    hidden={!isDialogExcludeOpen}
                    onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
                    dialogContentProps={dialogContentProps}
                >
                    <DefaultButton
                        onClick={() => this.setState({ isDialogExcludeOpen: false })}
                        text="Cancelar"
                    />
                    <DeleteButton
                        onClick={this.handleDelEmployee}
                        text="Excluir"
                    />
                </CustomDialog>

                {/* ----------------------------------------- LISTA FUNCIONARIOS ----------------------------------------------- */}

                <Wrapper>
                    <HeaderPage
                        title="Funcionários"
                        leftItems={
                            <>
                                <CommandBarButton
                                    styles={btnStyle}
                                    iconProps={{ iconName: "Add" }}
                                    disabled={employees.loadingData}
                                    text="Adicionar Funcionário"
                                    onClick={() =>
                                        this.setState({
                                            isPanelOpen: true,
                                            activeStep: 0,
                                            initialValuesBasic: initialEmployeeBasicInfo,
                                            initialValuesAdmission: initialEmployeeInfoAdmission
                                        })
                                    }
                                />
                                {this.commandBarRender()}
                            </>
                        }
                    // rightItems={
                    //     <>
                    //         <SearchBox
                    //             placeholder="Pesquisar"
                    //             value={search}
                    //             onChange={(e) => this.handleSearch(e)}
                    //             onKeyUp={(e) => this.filter(e.key)}
                    //             componentRef={this.inputSearch}
                    //             // onClear={() => this.clearFilters()}
                    //             styles={{
                    //                 root:
                    //                     { border: 'none', width: 200, marginRight: 1 }
                    //             }}
                    //         />
                    //         <CommandBarButton
                    //             styles={btnStyle}
                    //             iconProps={{ iconName: "Refresh" }}
                    //             text="Atualizar"
                    //             onClick={() => getEmployees()}
                    //         />
                    //         {/* <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'filter' }} text="Filtrar" onClick={() => this.toggleFilter()} /> */}
                    //     </>
                    // }
                    />
                    <Stack horizontal styles={{ root: { height: 44, marginTop: 5 } }}>
                        {/* <SearchBox
                            autoFocus
                            placeholder="Pesquisar"
                            value={search}
                            onChange={(e) => this.handleSearch(e)}
                            onKeyUp={(e) => this.filter(e.key)}
                            componentRef={this.inputSearch}
                            // onClear={() => this.clearFilters()}
                            styles={{
                                root:
                                    { width: 200, marginRight: 1, flex: 1 }
                            }}
                        /> */}
                        <InputText
                            autoFocus
                            variant="outlined"
                            label="Pesquisar"
                            value={search}
                            onChange={(e) => this.handleSearch(e)}
                            onKeyUp={(e) => this.filter(e.key)}
                            innerRef={this.inputSearch}
                            size="small"
                            style={{ maxWidth: '100%', paddingRight: 15, height: 40 }}
                            height_container={40}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <FontIcon iconName="Search" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <CommandBarButton
                            styles={{ root: { marginLeft: '15px !important', padding: 10, height: 40 } }}
                            iconProps={{ iconName: "Refresh" }}
                            text="Atualizar"
                            disabled={search.trim().length === 0}
                            onClick={() => getEmployees(search)}
                        />

                    </Stack>

                    {
                        !employees.loadingData && employees.data.length === 0 ?
                            employees.success &&
                            <NoItems
                                error={employees.error}
                                text="Não foi encontrado nenhum funcionário"
                                icon="IDBadge"
                            />
                            :
                            <ListContainer>
                                <ShimmeredDetailsList
                                    items={isFiltering ? items : employees.data}
                                    enableShimmer={employees.loadingData}
                                    columns={columns}
                                    selectionMode={SelectionMode.single}
                                    selection={this._selection}
                                    getKey={this._getKey}
                                    selectionPreservedOnEmptyClick={true}
                                    setKey="single"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    isHeaderVisible={true}
                                    onItemInvoked={this._onItemInvoked}
                                />
                            </ListContainer>
                    }
                </Wrapper>

                <CustomDialog
                    hidden={!isDialogAccessOpen}
                    onDismiss={() => this.setState({ isDialogAccessOpen: false })}
                    dialogContentProps={dialogConfigContentProps}
                >
                    <DefaultButton
                        onClick={() => this.setState({ isDialogAccessOpen: false })}
                        text="Cancelar"
                    />
                    <PrimaryButton
                        onClick={() => {
                            editEmployeeAccess(itemSelected.idFuncionario!, itemSelected.flagAcessoRHR ? false : true);
                            this.setState({ isDialogAccessOpen: false });
                        }}
                        text={itemSelected.flagAcessoRHR ? "Bloquear" : "Conceder"}
                    />
                </CustomDialog>

                <CustomDialog
                    hidden={!isDialogResetPasswordOpen}
                    onDismiss={() => this.setState({ isDialogResetPasswordOpen: false })}
                    dialogContentProps={dialogResetPasswordContentProps}
                >
                    <>
                        <DefaultButton
                            onClick={() => this.setState({ isDialogResetPasswordOpen: false })}
                            text="Cancelar"
                        />
                        <PrimaryButton
                            styles={{ root: { marginLeft: 8 } }}
                            onClick={() => {
                                this.setState({ isDialogResetPasswordOpen: false }, () =>
                                    editEmployeePassword(itemSelected.idFuncionario!, this.password)
                                )
                            }}
                            text="Refinir"
                        />
                    </>
                </CustomDialog>
            </>
        );
    };
    //#region  Table Functions
    private _onColumnClick = (
        ev: React.MouseEvent<HTMLElement>,
        column: IColumn
    ): void => {
        const { columns, isFiltering, items: itemsState } = this.state;
        const { employees } = this.props;

        const newColumns: IColumn[] = columns.slice();
        let items: EmployeeType[] = [];
        if (isFiltering) {
            items = itemsState;
        } else {
            items = employees.data;
        }
        const currColumn: IColumn = newColumns.filter(
            (currCol) => column.key === currCol.key
        )[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = this._sort(
            items,
            currColumn.fieldName!,
            currColumn.isSortedDescending
        );
        this.setState({
            columns: newColumns,
            items: newItems,
        });
    };

    private _getKey(item: any, index?: number): any {
        if (item !== undefined) return item.key;
    };

    private _sort<T>(
        items: T[],
        columnKey: string,
        isSortedDescending?: boolean
    ): T[] {
        const key = columnKey as keyof T;
        const itemsSorted = items.sort((a: T, b: T) =>
            (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
        );
        return itemsSorted;
    };

    //#endregion
};


const mapStateToProps = (state: RootState) => ({
    employees: state.employeesReducer,
});

const mapDispatchToProps = (dispatch: any) =>
    bindActionCreators(
        {
            ...getEmployees,
            ...getEmployeeById,
            ...addEmployee,
            ...editEmployee,
            ...editEmployeePassword,
            ...editEmployeeAccess,
            ...delEmployee,
            ...resetEmployees,
            ...setCurrentPage,
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(Employees);
