import styled from 'styled-components';

export const Wrapper = styled.div`
  height: calc(100vh - 231px);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  top: 50px;
  overflow: auto;

  @media screen and (min-width: 700px) and (max-width: 960px){
    height: calc(100vh - 228px);
  }

  @media screen and (max-width: 700px){
    height: calc(100vh - 294px);
  }
`;

export const ContainerContent = styled.div`
  padding: 20px;
  height: calc(100% - 50px);
`
export const WrapperStatus = styled.div`
  display: flex;
  align-items: center;
`
