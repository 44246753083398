import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';

export const Modal = styled(Dialog) <{ width: number, height?: number }>`
  .MuiPaper-rounded{
    border-radius: 2px;
    padding: 0px;
    min-width: ${props => props.width}px;
    ${({ height }) => height ? `height: ${height}px` : ''};

  }

  .MuiTypography-root{
      line-height: normal;
  }

  .MuiDialogTitle-root{
      padding: 16px 46px 20px 24px;
      font-size: 20px;
  }

  .MuiDialogContent-root{
      padding: 0px 24px;
      color: #605E5C;
      margin-bottom: 24px;
  }

  .MuiDialogActions-root{
      padding: 0 24px 24px;
  }
`;
