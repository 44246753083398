import styled from "styled-components";
import { TextField, Container as MaterialContainer } from "@material-ui/core";

import colors from "~/assets/js/colors";

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-color: ${colors.white};
  background-image: url("/static/img/bg-st-marche.jpg");
  overflow: hidden;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  width: 550px;
  height: auto;
  overflow: hidden;
  border-radius: 2px;
  background-color: ${colors.white};
  margin: 10px;
  padding: 20px 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.16);
`;

export const CardContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const RomasoftLogo = styled.img`
  width: 160px;
  height: auto;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Title = styled.span<{ marginTop?: string }>`
  font-size: 24px;
  font-weight: 600;
  margin-top: ${props => props.marginTop};
  margin-left: 22px;
  margin-bottom: 20px;
  color: ${colors.black};
  @media (max-width: 960px) {
    margin-left: 23px;
  }
  @media (max-width: 600px) {
    margin-left: 16px;
  }
  @media (max-width: 420px) {
    font-size: 1.2rem;
  }
`;

export const InputContainer = styled(MaterialContainer)`
  margin-left: 0px;
  height: 70px;
`;

export const Input = styled(TextField)`
  width: 100%;
`;

export const ForgotLogin = styled.span`
  margin-top: 10px;
  font-size: 13px;
  display: block;
  cursor: pointer;
  color: ${colors.linkColor};
  margin-left: 24px;
  @media (max-width: 960px) {
    margin-left: 23px;
  }
  @media (max-width: 600px) {
    margin-left: 16px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: calc(100% - 24px);
  @media (max-width: 960px) {
    width: calc(100% - 23px);
  }
  @media (max-width: 600px) {
    width: calc(100% - 16px);
  }
`;

export const ErrorMessage = styled.span`
  font-size: 0.875rem;
  margin: 0px 23px 20px 23px;
  color: ${colors.red};
`;
