// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from "formik";
import * as yup from 'yup';

//FluentUI
import { Text, ITextStyles } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
    DetailsListLayoutMode,
    SelectionMode,
    Selection,
    IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList, IShimmeredDetailsListStyles } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBarButton, IconButton, ICommandBarStyles } from 'office-ui-fabric-react';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { Rating, RatingSize } from 'office-ui-fabric-react/lib/Rating';

//MaterialUI
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

//Componentes
import PainelHeader from '~/components/layout/PainelHeader';
import CustomDialog from "~/components/CustomDialogFluentUI";
import Panel from "~/components/layout/Panel";
import Dropdown from "~/components/layout/Dropdown";
import colors from "~/assets/js/colors";
import { CustomDrawer as Drawer } from "~/components/layout/Drawer";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { InputText } from '~/components/Forms';
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
import { DeleteButton } from '~/components/Buttons';
//Estilos
import {
    Wrapper,
    ListContainer,
    ContainerContent,
    ContainerTitle,
    Status
} from "./styles";


import evaluationsReducer, { Creators as getEvaluations } from "~/store/ducks/evaluations";
import { Creators as addEvaluation } from "~/store/ducks/evaluations";
import { Creators as editEvaluation } from "~/store/ducks/evaluations";
import { Creators as delEvaluation } from "~/store/ducks/evaluations";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { EvaluationType, DataTypes } from "~/store/ducks/evaluations/types";
import { RootState } from "~/store/ducks";
import Colors from "~/assets/js/colors";
//#endregion


/**
 * Validação do formulário
 */
// const validationEvaluationsSchema = yup.object().shape({
//   code: yup.string().required("Campo obrigatório"),
//   companyName: yup.string().required("Campo obrigatório"),
//   group: yup.string().required("Campo obrigatório"),
//   // inactivate: yup.boolean().required("Campo obrigatório")
// })

//#region Types e Interfaces

const btnStyle: Partial<ICommandBarStyles> = {
    root: {
        height: 44
    }
};

type Filter = {
    isOpen?: boolean;
    filteredBy?: string[];
    departamento?: any;
};

interface IEvaluationsState {
    columns: IColumn[];
    items: EvaluationType[];
    isPanelOpen: boolean;
    isDialogOpen: boolean;
    openPanel: boolean;
    isLoadData: boolean;
    inicialValues: EvaluationType;
    isFiltering: boolean;
    selectionDetails: string;
    selectionCount: number;
    isDialogExcludeOpen: boolean;
    filter: Filter;
};

interface IProps {
    getEvaluations: () => void;
    addEvaluation: (evaluation: EvaluationType) => void;
    editEvaluation: (evaluation: EvaluationType) => void;
    delEvaluation: (id: number) => void;
    setCurrentPage: (page: Page) => void;
    evaluations: DataTypes;
};
//#endregion

const initial: EvaluationType = {
    idAvaliacao: null,
    colaborador: "",
    departamento: "",
    matricula: "",
    avaliacao: null
};


const itemsBreadCrumb: BreadcrumbItems[] = [
    { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
    { text: "Painel Workflow", isCurrentItem: false, url: "/workflow" },
    { text: "Avaliação do Time", isCurrentItem: true },
];

const page: Page = {
    key: 'timeavaliacao',
    pages: itemsBreadCrumb
};

const data: any = [
    {
        matricula: 2342,
        nome: "VALTERI BOTTAS",
        autoAvaliacao: "D",
        avaliacaoGestor: true,
        performance: 4,
        potencial: true
    },
    {
        matricula: 4654,
        nome: "SEBASTIAN VETTEL",
        autoAvaliacao: "D",
        avaliacaoGestor: true,
        performance: 3,
        potencial: true
    },
    {
        matricula: 15256,
        nome: "KIMI RAIKKONEN",
        autoAvaliacao: "D",
        avaliacaoGestor: true,
        performance: 4,
        potencial: true
    },
    {
        matricula: 12342,
        nome: "MAX VERSTAPPEN",
        autoAvaliacao: "D",
        avaliacaoGestor: true,
        performance: 5,
        potencial: true
    },
]

class Evaluations extends Component<IProps, IEvaluationsState> {
    private formRef: any;
    private inputSearch: any;
    private _selection: Selection;

    constructor(props: IProps) {
        super(props);
        //#region Colunas
        const columns: IColumn[] = [
            {
                key: 'column1',
                name: 'Matrícula',
                ariaLabel: 'Matrícula do Colaborador',
                fieldName: 'matricula',
                isRowHeader: true,
                minWidth: 75,
                maxWidth: 100,
                isSortedDescending: false,
                onColumnClick: this._onColumnClick,
            },
            {
                key: 'column2',
                name: 'Nome Completo',
                fieldName: 'nome',
                ariaLabel: 'Nome Completo',
                isRowHeader: true,
                minWidth: 200,
                maxWidth: 400,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Auto Avaliação',
                fieldName: 'cargo',
                ariaLabel: 'Auto Avaliação',
                isRowHeader: true,
                minWidth: 150,
                maxWidth: 200,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: EvaluationType) => {
                    return (
                        <Stack horizontal verticalAlign="center">
                            <Status bgColor={Colors.primary} />
                            <Text variant="medium">Dentro da Expectativa</Text>
                        </Stack>
                    )
                }
            },
            {
                key: 'column4',
                name: 'Avaliação Gestor',
                fieldName: 'colaborador',
                ariaLabel: 'Avaliação Gestor',
                isRowHeader: true,
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: EvaluationType) => {
                    return (
                        <Stack horizontal horizontalAlign="center">
                            <Status bgColor={Colors.primary} />
                        </Stack>
                    )
                }
            },
            {
                key: 'column5',
                name: 'Performance',
                fieldName: 'performance',
                ariaLabel: 'Performance',
                isRowHeader: true,
                minWidth: 120,
                maxWidth: 150,
                isResizable: true,
                onColumnClick: this._onColumnClick,
                isPadded: true,
                onRender: (item: any) => {
                    return (
                        <Rating
                            id="teste"
                            min={1}
                            max={5}
                            styles={{
                                ratingStarFront:{
                                    color: Colors.primary
                                },
                                ratingStarBack:{
                                    color: Colors.primary
                                },
                            }}
                            rating={item.performance}
                            onChange={this.onSmallStarChange}
                            ariaLabelFormat={'Select {0} of {1} stars'}
                        />
                    )
                }
                // onRender: (item: any) => {
                //     if (item.performance !== null && item.performance !== undefined) {
                //         return (
                //             <Stack horizontal verticalAlign="center">
                //                 {
                //                     [1, 2, 3, 4, 5].map(num => {
                //                         if (item.performance! <= num) {
                //                             return (
                //                                 <FontIcon key={num} style={{ fontSize: 16, marginRight: 5, color: "#CCCCCC" }} iconName={"FavoriteStarFill"} />
                //                             )
                //                         } else {
                //                             return (
                //                                 <FontIcon key={num} style={{ fontSize: 16, marginRight: 5, color: Colors.primary }} iconName={"FavoriteStarFill"} />
                //                             )
                //                         }
                //                     })
                //                 }
                //             </Stack>
                //         )
                //     }
                // }
            },
            {
                key: 'column6',
                name: 'Potencial',
                fieldName: 'colaborador',
                ariaLabel: 'Potencial',
                isRowHeader: true,
                minWidth: 75,
                maxWidth: 100,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: EvaluationType) => {
                    return (
                        <Stack horizontal horizontalAlign="center">
                            <Status bgColor={Colors.primary} />
                        </Stack>
                    )
                }
            },
        ];
        //#endregion

        this.state = {
            columns: columns,
            items: [],
            isDialogOpen: false,
            isPanelOpen: false,
            openPanel: false,
            isLoadData: true,
            selectionDetails: "",
            inicialValues: initial,
            isFiltering: false,
            selectionCount: 0,
            isDialogExcludeOpen: false,
            filter: {
                isOpen: false,
                filteredBy: [],
                departamento: {}
            }
        };

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails(),
                });
            },
        });

        this.formRef = React.createRef();
        this.inputSearch = React.createRef();
    };

    componentDidMount() {
        this.props.setCurrentPage(page);
        // this.props.getEvaluations();

        const evaluations = this.props.evaluations.data;
        const departamentos = evaluations.map(item => item.departamento)
            .filter((item, pos, self) => self.indexOf(item) === pos)
            .map(item => [item, false]);


        const filters = Object.fromEntries(departamentos);

        this.setState({
            filter: {
                departamento: {
                    ...filters
                }
            }
        });
    };

    onSmallStarChange = (ev: React.FocusEvent<HTMLElement>, rating?: number): void => {
        console.log(ev.target.parentNode?.parentElement);
        console.log(rating);
    };

    // Submit do formulário Formik
    handleSubmit = () => {
        if (this.formRef.current) {
            this.formRef.current.handleSubmit();
        };
    };

    _onItemInvoked = (item: EvaluationType): void => {
        this.setState({
            isPanelOpen: true,
            inicialValues: {
                ...item
            }
        });
    };

    _getSelectionDetails(): any {
        const selectionCount = this._selection.getSelectedCount();
        this.setState({ selectionCount });
        this._selection.getSelection();
    };

    openEdit = () => {
        this.setState({ isPanelOpen: true, inicialValues: (this._selection.getSelection()[0] as EvaluationType) });
    };

    cancelPanel = () => {
        this._selection.setAllSelected(false);
        this.setState({ isPanelOpen: false });
    };

    excludeEvaluation = () => {
        this.props.delEvaluation((this._selection.getSelection()[0] as EvaluationType).idAvaliacao!);
        this.setState({ isDialogExcludeOpen: false });
    };

    //#endregion

    commandBarRender = () => {
        const { selectionCount } = this.state;
        if (selectionCount === 1) {
            return (
                <>
                    <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Edit' }} text="Editar" onClick={() => this.openEdit()} />
                    <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
                </>
            );
        } else if (selectionCount > 1) {
            return <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />;
        };
    };

    render() {
        const { selectionCount, isFiltering, items, columns } = this.state;
        const { evaluations } = this.props;

        return (
            <>
                <Wrapper>
                    <PainelHeader
                        title="Avaliação do Time"
                        icon={{ icon: "Equalizer" }}
                        spaceBetween={200}
                        labelGroups={[
                            {
                                label: "Ciclo",
                                value: "2020",
                                icon: "HomeGroup"
                            },
                            {
                                label: "Grupo",
                                value: "Gerentes",
                                icon: "Contact"
                            },
                            {
                                label: "Líder",
                                value: "André Prearo",
                                icon: "Flag"
                            },
                        ]}
                    />
                    <Stack horizontal styles={{ root: { padding: '10px 20px 0px' } }}>
                        <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Save' }} text="Salvar" onClick={() => console.log("Salvar")} />
                        <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Send' }} text="Enviar Avaliação" onClick={() => console.log("Enviado")} />
                    </Stack>
                    <ContainerContent>
                        <ContainerTitle>
                            <Text variant="mediumPlus" styles={textStyles}>Colaboradores</Text>
                        </ContainerTitle>
                        {false ?
                            // {!evaluations.loading && evaluations.data.length === 0 ?
                            <NoItems
                                error={evaluations.error}
                                text="Não há avaliações cadastradas"
                                icon="AccountActivity"
                            />
                            :
                            <ListContainer>
                                <ShimmeredDetailsList
                                    // items={isFiltering ? items : evaluations.data}
                                    items={data}
                                    enableShimmer={evaluations.loading}
                                    columns={columns}
                                    selectionMode={SelectionMode.single}
                                    selection={this._selection}
                                    getKey={this._getKey}
                                    selectionPreservedOnEmptyClick={true}
                                    setKey="single"
                                    layoutMode={DetailsListLayoutMode.justified}
                                    onItemInvoked={this._onItemInvoked}
                                    styles={listStyles}
                                />
                            </ListContainer>
                        }
                    </ContainerContent>
                </Wrapper>
            </>
        );
    }

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns } = this.state;
        const newColumns: IColumn[] = columns.slice();
        let items: EvaluationType[] = [];
        if (this.state.isFiltering) {
            items = this.state.items;
        } else {
            items = this.props.evaluations.data;
        };
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            };
        });
        const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems,
        });
    };

    private _getKey(item: any, index?: number): any {
        if (item !== undefined)
            return item.key;
    };

    private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
        return itemsSorted;
    };

}

const textStyles: ITextStyles = {
    root: {
        fontWeight: 500,
        marginTop: 10,
        display: 'block'
    }
}

const listStyles: IShimmeredDetailsListStyles = {
    root: {
        selectors: {
            ".root-237": {
                backgroundColor: '#FF0000'
            }
        }
    }
}

const mapStateToProps = (state: RootState) => ({
    evaluations: state.evaluationsReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ ...getEvaluations, ...addEvaluation, ...delEvaluation, ...editEvaluation, ...setCurrentPage }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Evaluations);
