import { Types, DataTypes, CompaniesActionTypes, ICompany } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    data: [],
    loadingData: false,
    loading: false,
    error: false,
    success: false
};


/**
 * Actions
 */

export const Creators = {
    getCompanies: (search?: string, filter?: boolean | null): CompaniesActionTypes => {
        const pesquisa = search !== "" && search !== undefined ? search : "";
        const filtro = filter !== null && filter !== undefined ? filter : null;
        return ({
            type: Types.REQUEST_COMPANIES_LOAD,
            search: {
                searchText: pesquisa,
                filterAtivo: filtro
            }
        })
    },
    addCompany: (company: ICompany): CompaniesActionTypes => ({
        type: Types.REQUEST_COMPANY_ADD,
        company
    }),

    editCompany: (company: ICompany): CompaniesActionTypes => ({
        type: Types.REQUEST_COMPANY_EDIT,
        company
    }),

    delCompany: (id: number): CompaniesActionTypes => ({
        type: Types.REQUEST_COMPANY_DELETE,
        id
    })
};

export default function companiesReducer(state = INITIAL_STATE, action: CompaniesActionTypes): DataTypes {
    switch (action.type) {

        case Types.REQUEST_COMPANIES_LOAD:
            return { ...state, data: [], loadingData: true, error: false, success: false };
        case Types.SUCCESS_COMPANIES_LOAD:
            return { ...state, data: action.payload, loadingData: false, error: false, success: true };
        case Types.FAILURE_COMPANIES_LOAD:
            customToast.error(action.message);
            return { ...state, data: [], loadingData: false, error: true, success: false };

        case Types.REQUEST_COMPANY_ADD:
            return { ...state, error: false, success: false, loading: true };
        case Types.SUCCESS_COMPANY_ADD:
            customToast.success("Empresa cadastrada com sucesso");
            return { ...state, error: false, success: true, data: [...state.data, action.company], loading: false };
        case Types.FAILURE_COMPANY_ADD:
            customToast.error(action.message);
            return { ...state, error: true, success: false, loading: false };

        case Types.REQUEST_COMPANY_EDIT:
            return { ...state, error: false, success: false, loading: true };
        case Types.SUCCESS_COMPANY_EDIT:
            customToast.success("Empresa editada com sucesso");
            return {
                ...state,
                error: false,
                success: true,
                loading: false,
                data: state.data.map(item => {
                    if (item.idEmpresa !== action.company.idEmpresa) {
                        return item;
                    } else {
                        return action.company;
                    }
                })
            };
        case Types.FAILURE_COMPANY_EDIT:
            customToast.error(action.message);
            return { ...state, error: true, success: false, loading: false };
            
        case Types.REQUEST_COMPANY_DELETE:
            return { ...state, error: false, success: false, loadingData: true };
        case Types.SUCCESS_COMPANY_DELETE:
            customToast.success("Item excluído com sucesso");
            return { ...state, error: false, success: true, data: state.data.filter(item => item.idEmpresa !== action.id), loadingData: false };
        case Types.FAILURE_COMPANY_DELETE:
            customToast.error(action.message);
            return { ...state, error: true, success: false, loadingData: false };
        default:
            return state;
    };
};