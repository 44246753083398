import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { Formik } from 'formik';
import { EmployeeType } from '~/store/ducks/employees/types';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { FormHeaderStyle } from '~/components/FormGroup';
import { InputDate, InputAutocomplete } from '~/components/Forms';

import { IUnit, DataTypes as DataTypesUnit } from "~/store/ducks/units/types";
import { Creators as CreatorsUnit } from "~/store/ducks/units";

import { RootState } from "~/store/ducks";

interface IFormIdentification {
    initialValues: EmployeeType;
    formRef: any;
    success: (values: EmployeeType) => void;
};


const validationFormCadastralSchema = yup.object().shape({
    unidade: yup.object().shape({
        idUnidade: yup.number()
            .nullable()
            .required("Campo obrigatório"),
    }),
    dataAdmissao: yup.date().typeError('Data de nascimento inválida').required("Campo obrigatório"),
    dataDemissao:
        yup.date()
            .nullable()
            .when("status", {
                is: false,
                then: yup.date()
                    .typeError('Data de nascimento inválida')
                    .required("Campo obrigatório")
            }),
});

const FormCadastral: React.FC<IFormIdentification> = (props) => {
    const { formRef, initialValues } = props;
    const isActive: boolean = initialValues.status === 'A';
    const [status, setStatus] = useState(!isActive);

    const [valueUnit, setValueUnit] = useState<Partial<IUnit>>({
        idUnidade: initialValues.unidade.idUnidade,
        nomeUnidade: initialValues.unidade.nomeUnidade
    });


    const unitsState = useSelector<RootState, DataTypesUnit>(state => state.unitsReducer);

    const dispatch = useDispatch();
    let timeout: number = 0;

    // useEffect(() => {
    //     setValueUnit(props.initialValues.unidade);
    // }, []);

    const search = (text: string, type: string) => {
        if (text.trim()) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                dispatch(CreatorsUnit.getUnits(text));
            }, 700);
        }
    };

    return (
        <>
            <FormHeaderStyle text="Gerenciar situação cadastral" />
            <Formik
                enableReinitialize={true}
                innerRef={formRef}
                initialValues={initialValues}
                validationSchema={validationFormCadastralSchema}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={(values: EmployeeType) => {
                    values.unidade = {
                        idUnidade: valueUnit.idUnidade,
                        nomeUnidade: valueUnit.nomeUnidade
                    };
                    values.status = status ? 'D' : 'A';
                    values.dataDemissao = status ? values.dataDemissao : null;
                    props.success(values)
                }}
            >
                {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <InputAutocomplete
                                value={(valueUnit as IUnit)}
                                onChange={(_, newValue) => {
                                    setValueUnit(newValue!)
                                    setFieldValue("unidade", { idUnidade: newValue?.idUnidade! });
                                }}
                                onInputChange={(_, newInputValue) => {
                                    setFieldError("unidade", "");
                                    search(newInputValue, "unidade");
                                }}
                                getOptionLabel={(unit: IUnit) => unit.nomeUnidade}
                                getOptionSelected={(option, value) => option.idUnidade === value.idUnidade}
                                options={unitsState.data}
                                input={{
                                    idInput: "unidade",
                                    labelInput: "Unidade",
                                    helperTextInput: errors.unidade?.idUnidade as string,
                                    errorInput: errors.unidade?.idUnidade ? true : false,
                                    autoFocus: true
                                }}
                            />
                            <InputDate
                                id="dataAdmissao"
                                error={errors.dataAdmissao ? true : false}
                                name="dataAdmissao"
                                label="Data de Admissão"
                                value={moment(values.dataAdmissao)}
                                onChange={value => setFieldValue('dataAdmissao', value)}
                                helperText={errors.dataAdmissao}
                            />

                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={status}
                                            onChange={(e) => {
                                                setStatus(e.target.checked);
                                                setFieldValue("status", !e.target.checked);
                                            }}
                                            name="status"
                                            color="primary"
                                        />
                                    }
                                    label="Inativar Funcionário"
                                />
                                {
                                    status &&
                                    <InputDate
                                        style={{ marginTop: 15 }}
                                        id="dataDemissao"
                                        error={errors.dataDemissao ? true : false}
                                        name="dataDemissao"
                                        label="Data de Desligamento"
                                        value={values.dataDemissao ? moment(values.dataDemissao) : null}
                                        onChange={value => setFieldValue('dataDemissao', value)}
                                        className="mt-2"
                                        helperText={errors.dataDemissao}
                                    />
                                }
                            </>
                        </form>
                    )
                }}
            </Formik>
        </>
    );
};

export default FormCadastral;