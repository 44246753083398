import { Types, DataTypes, Page, HomeActionTypes } from "./types"

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    page: {
        key: 'home',
        pages: [{ text: 'Página Inicial', isCurrentItem: true }]
    },
    isMenuExpanded: true
};


/**
 * Actions
 */

export const Creators = {
    setCurrentPage: (page: Page): HomeActionTypes => ({
        type: Types.SET_CURRENT_PAGE,
        payload: page
    }),

    setMenuExpanded: (isExpanded: boolean): HomeActionTypes => ({
        type: Types.SET_MENU_EXPANDED,
        isExpanded
    })
};

export default function cycleReducer(state = INITIAL_STATE, action: HomeActionTypes): DataTypes {
    switch (action.type) {

        case Types.SET_CURRENT_PAGE:
            return { ...state, page: action.payload }

        case Types.SET_MENU_EXPANDED:
            return {...state, isMenuExpanded: action.isExpanded}

        default:
            return state;
    }
}