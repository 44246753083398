import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import {
  Wrapper,
  WrapperLink,
  WrapperLinkText
} from "./styles";
import colors from "~/assets/js/colors"

import { Text } from 'office-ui-fabric-react/lib/Text';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { LoginType } from "~/store/ducks/login/types"
import { RootState } from "~/store/ducks"

function Dashboard() {
  const { name } = useSelector<RootState, LoginType>(state => state.loginReducer.data)
  const items: BreadcrumbItems[] = [
    { text: 'Página Inicial', isCurrentItem: true },
  ];

  const page: Page = {
    key: 'home',
    pages: items
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setCurrentPage.setCurrentPage(page))
  })

  return (
    <Wrapper>
      <Text variant={'xxLarge'}>Olá, {name}</Text>
      <Text variant={'medium'}>Seja bem vindo ao Romasoft HR</Text>
      <div className="ms-Grid" dir="ltr" style={{ marginTop: 15 }}>
        <div className="ms-Grid-row">
          <div className="ms-Grid-col paddingZero">
            <ButtonLink iconName="BIDashboard" text="Dashboard" href={{ pathname: '/' }} />
          </div>
          <div className="ms-Grid-col paddingZero">
            <ButtonLink iconName="StackedLineChart" text="Avaliações de Desempenho" href={{ pathname: '/' }} />
          </div>
          <div className="ms-Grid-col paddingZero">
            <ButtonLink iconName="AddFriend" text="Novo Colaborador" href={{ pathname: '/funcionarios', state: { isPanelOpen: true } }} />
          </div>
          <div className="ms-Grid-col paddingZero">
            <ButtonLink iconName="Calendar" text="Agenda" href={{ pathname: '/' }} />
          </div>
          <div className="ms-Grid-col paddingZero">
            <ButtonLink iconName="DocumentApproval" text="Aprovações Pendentes" href={{ pathname: '/workflow', state: { indexTab: '1' } }} />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

interface IProps {
  iconName: string;
  text: string;
  href?: any;
  onClick?: () => any;
}

const ButtonLink: React.FC<IProps> = (props) => {
  return (
    <Link to={props.href}>
      <WrapperLink onClick={props.onClick}>
        <FontIcon iconName={props.iconName} style={{ fontSize: 32, color: colors.greenDarker, marginBottom: 10 }} />
        <WrapperLinkText>
          <Text variant={'medium'} styles={{ root: { textAlign: 'center' } }}>{props.text}</Text>
        </WrapperLinkText>
      </WrapperLink>
    </Link>
  )
}


export default Dashboard;
