import React from 'react';
import colors from '~/assets/js/colors';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';

import { Text } from 'office-ui-fabric-react/lib/Text';

import { Container, WrapperBoxCheck, WrapperBox, TrFooter, Tr, TdFull, Table, TdFooter, Div } from './styles';

const Perfomance: React.FC = () => {
    return (
        <Container>
            <Table>
                <tbody>
                    <Tr>
                        <td>
                            <Box
                                bgColor={colors.primary}
                                title="Excepcional"
                                value="5"
                                porcent="14%" />
                        </td>
                        <TdFull>
                            Pessoas
                        </TdFull>
                        <td>
                            <Box
                                bgColor="#FDF1F2"
                                borderColor="#E81123"
                                color="#FF3B30"
                                thinFont
                                value="+1" />
                        </td>
                        <td>
                            <Box
                                bgColor={colors.white}
                                borderColor={colors.primary}
                                color={colors.black}
                                title="Excepcional"
                                value="4"
                                porcent="10%" />
                        </td>
                    </Tr>
                    <Tr>
                        <td>
                            <Box
                                bgColor="#73AF8E"
                                title="Muito Bom"
                                value="11"
                                porcent="31%" />
                        </td>
                        <TdFull>
                            Pessoas
                        </TdFull>
                        <td>
                            <Box
                                bgColor="#FDF1F2"
                                borderColor="#E81123"
                                color="#FF3B30"
                                thinFont
                                value="+4" />
                        </td>
                        <td>
                            <Box
                                bgColor={colors.white}
                                borderColor="#73AF8E"
                                color={colors.black}
                                title="Muito Bom"
                                value="7"
                                porcent="20%" />
                        </td>
                    </Tr>
                    <Tr>
                        <td>
                            <Box
                                bgColor="#88C8A5"
                                title="Bom"
                                value="12"
                                porcent="31%" />
                        </td>
                        <TdFull>
                            Pessoas
                        </TdFull>
                        <td>
                            <Box
                                bgColor="#FDF1F2"
                                borderColor="#E81123"
                                color="#FF3B30"
                                thinFont
                                value="-7" />
                        </td>
                        <td>
                            <Box
                                bgColor={colors.white}
                                borderColor="#88C8A5"
                                color={colors.black}
                                title="Bom"
                                value="19"
                                porcent="20%" />
                        </td>
                    </Tr>
                    <Tr>
                        <td>
                            <Box
                                bgColor="#D4A31D"
                                title="Regular"
                                value="5"
                                porcent="14%" />
                        </td>
                        <TdFull>
                            Pessoas
                        </TdFull>
                        <td>
                            <Box
                                bgColor="#FDF1F2"
                                borderColor="#E81123"
                                color="#FF3B30"
                                thinFont
                                value="+1" />
                        </td>
                        <td>
                            <Box
                                bgColor={colors.white}
                                borderColor="#D4A31D"
                                color={colors.black}
                                title="Regular"
                                value="4"
                                porcent="10%" />
                        </td>
                    </Tr>
                    <Tr>
                        <td>
                            <Box
                                bgColor="#B30000"
                                title="Insatisfatório"
                                value="2"
                                porcent="6%" />
                        </td>
                        <TdFull>
                            Pessoas
                        </TdFull>
                        <td>
                            <BoxCheck />
                        </td>
                        <td>
                            <Box
                                bgColor={colors.white}
                                borderColor="#B30000"
                                color={colors.black}
                                title="Insatisfatório"
                                value="4"
                                porcent="10%" />
                        </td>
                    </Tr>
                    <TrFooter>
                        <TdFooter>
                            <Div>
                                <img src="/static/icons/finish-flag.svg" alt="Ícone"/>
                            </Div>
                        </TdFooter>
                        <TdFooter bgColor="#FFFFFF">
                            <Div>
                                Colaboradores
                            </Div>
                        </TdFooter>
                        <TdFooter bgColor="#FFFFFF">
                            <Div>
                                Diferença
                            </Div>
                        </TdFooter>
                        <TdFooter>
                            <Div>
                                <img src="/static/icons/dot-and-circle.svg" alt="Ícone diferença"/>
                            </Div>
                        </TdFooter>
                    </TrFooter>
                </tbody>
            </Table>
        </Container>
    );
}

interface IBoxProps {
    bgColor: string;
    borderColor?: string;
    thinFont?: boolean;
    color?: string;
    title?: string;
    value: string;
    porcent?: string;
}

const Box: React.FC<IBoxProps> = (props) => {
    let { bgColor, borderColor, color, title, value, porcent, thinFont } = props;
    if (thinFont) {
        console.log("entrou")
    }
    return (
        <WrapperBox bgColor={bgColor} borderColor={borderColor} color={color}>
            {title &&
                <Text variant="smallPlus" styles={{ root: { fontWeight: 'bold', textTransform: 'uppercase' } }}>{title}</Text>
            }
            <Text style={{ fontSize: 50, fontWeight: thinFont ? 400 : 'bold' }}>{value}</Text>
            {porcent &&
                <Text variant="medium">{porcent}</Text>
            }
        </WrapperBox>
    )
}

const BoxCheck: React.FC = () => {
    return (
        <WrapperBoxCheck>
            <FontIcon iconName="VerifiedBrandSolid" style={{ fontSize: 50, color: colors.primary }} />
        </WrapperBoxCheck>
    )
}

export default Perfomance;