import styled from "styled-components";

import {
  DefaultButton as DefaultBtn,
  PrimaryButton as PrimaryBtn,
} from "office-ui-fabric-react";

export const WrapperTreeview = styled.div`
    margin-top: 5px;
    height: calc(100% - 70px);
    overflow-y: auto;
`

export const PrimaryButton = styled(PrimaryBtn)`
  width: 110px;
`

export const DefaultButton = styled(DefaultBtn)`
  width: 110px;
`

export const Wrapper = styled.div`
  height: calc(100vh - 67px);
  width: calc(100% - 250px);
  flex-basis: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;


export const ContainerContent = styled.div`
  padding: 20px;
  height: calc(100% - 35px);
`