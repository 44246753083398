import React, { ReactNode } from 'react';
import Drawer from '@material-ui/core/Drawer';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const drawerWidth = 320;



interface IProps {
    isOpen: boolean | undefined;
    children: JSX.Element;
    content: JSX.Element | ReactNode;
    height?: number;
    diffHeight?: number;
    backgroundColor?: string;
    widthContent?: string;
    positionStyle?: string;
}

export const HeaderDrawer: React.FC<IProps> = props => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                height: '100%',
                width: '100%'
            },
            drawer: {
                width: drawerWidth,
                flexShrink: 0,
            },
            drawerContent: {
                padding: 20
            },
            drawerPaper: {
                width: drawerWidth,
                height: `calc(100vh - 50px)`,
                top: props.height ? props.height : 50,
                backgroundColor: props.backgroundColor || "#FAFAFA"
            },
            content: {
                flexGrow: 1,
                height: '100%',
                width: '100%',
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                marginRight: -drawerWidth,
            },
            contentShift: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginRight: 0,
                width: `calc(100% - ${drawerWidth}px)`,
            },
        }),
    );
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <main className={clsx(classes.content, { [classes.contentShift]: props.isOpen, })}>
                {props.content}
            </main>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={props.isOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerContent}>
                    {props.children}
                </div>
            </Drawer>
        </div>
    )
}

export const CustomDrawer: React.FC<IProps> = props => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                height: '100%',
                width: '100%',
                display: 'flex',
            },
            drawer: {
                width: drawerWidth,
                flexShrink: 0,
            },
            drawerContent: {
                padding: 20
            },
            drawerPaper: {
                width: drawerWidth,
                position: 'relative',
                display: props.isOpen? 'flex' : 'none',
                height: `calc(100vh - ${props.diffHeight ? props.diffHeight : 50}px)`,
                top: 0,
                backgroundColor: props.backgroundColor || "#FAFAFA",
            },
            content: {
                flexGrow: 1,
                height: '100%',
                width: '100%',
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                marginRight: -drawerWidth,
                
            },
            contentShift: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginRight: 0,
                width: `calc(100% - ${drawerWidth}px)`,
            },
        }),
    );
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <main className={clsx(classes.content, { [classes.contentShift]: props.isOpen, })}>
                {props.content}
            </main>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={props.isOpen}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerContent}>
                    {props.children}
                </div>
            </Drawer>
        </div>
    )
}
