const Colors = {
  white: "#FFFFFF",
  black: "#1d1d1d",
  red: "#FF3B30",
  error: "#f44336",
  orange: "#FF9500",
  yellow: "#FFCC00",
  warning: "#BE880A",
  green: "#4CD964",
  greenDarker: "#26A44C",
  teal: "#5AC8FA",
  blue: "#007AFF",
  pink: "#FF2D55",
  romasoftBlue: "#29C5F4",
  linkColor: "#0067B8",
  midGray: "#C7C7CC",
  gray: "#8E8E93",
  purple: "#673AB7",
  darkGray: "#535357",
  primary: "#027636",
};

const luminanace = (r: number, g: number, b: number) => {
  var a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928
      ? v / 12.92
      : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

const contrast = (rgb1: any, rgb2: any) => {
  return (luminanace(rgb1[0], rgb1[1], rgb1[2]) + 0.05) / (luminanace(rgb2[0], rgb2[1], rgb2[2]) + 0.05);
}

const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

export const calcConstrast = (hex: string) => {
  return contrast([255,255,255], [hexToRgb(hex)?.r, hexToRgb(hex)?.g, hexToRgb(hex)?.b]) > 2 ? true : false
}


export default Colors;
