import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from 'yup';

import ListObjectives from './ListObjectives';

import { Text, FontIcon, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { IconButton, IButtonStyles } from 'office-ui-fabric-react';
import { TooltipHost, ITooltipHostStyles } from 'office-ui-fabric-react/lib/Tooltip';

import InputAdornment from '@material-ui/core/InputAdornment';


import TreeView from '~/components/TreeView';
import { InputText, InputAutocomplete } from '~/components/Forms';
import { OutlinedButton } from '~/components/Buttons';
import CustomDialog from '~/components/CustomDialog';
import NoItems from '~/components/layout/NoItems';


import { RootState } from '~/store/ducks';
import { DataTypes, ChartType } from "~/store/ducks/chart/types";
import { DataTypes as DataTypesObjectives, ObjectivesType } from "~/store/ducks/objectives/types";
import { Creators as CreatorsObjectives } from "~/store/ducks/objectives";
import { Creators as CreatorsChart } from '~/store/ducks/chart';
import { Creators as CreatorObjectives } from "~/store/ducks/objectives";

import {
    PesoType,
    MetaType,
} from "~/store/ducks/cycle/painel/types";

import {
    DismembermentType,
    DataTypes as DataTypesDismemberment,
    DismembermentItemType,
    Peso,
    ObjectiveDismemberment,
    MetaDismembermentType,
    PesoDismembermentType
} from "~/store/ducks/cycle/dismemberment/types";
import { Creators as CreatorsDismemberment } from "~/store/ducks/cycle/dismemberment";

import FormMetas from '../FormMetas';
import FormPesos from '../FormPesos';

import {
    Container,
    WrapperInput,
    WrapperTreeview,
    Table,
    THead,
    TBody,
    Gap,
    TdParentIcon,
    TdArea,
    TdMetaHeader,
    TdPesoHeader,
    TdMetaHeaderTitle,
    TrHeader,
    TrParent,
    TableHeader,
    TdMeta,
    TdPeso,
    TdAreaHeader,
    TrChild,
    TdIndication,
    BiggerGap,
    TdAreaChild,
    TBodyChild,
    TdActionBtns,
    TableChild,
    TdIcon,
    WrapperAutoComplete,
    DescArea,
    TableTotal,
    GapTotal,
    TdTotal,
    Tr,
    WrapperDelBtn,
    WrapperAnchorIcon,
    WrapperSpinner,
    WrapperSearchInput,
    WrapperOutlineButton,
    WrapperDismemberment
} from './styles';
import Colors from '~/assets/js/colors';

interface IDismembermentProps {
    idCiclo: number | null;
}

const validationNewObjectiveSchema = yup.object().shape({
    codObjetivo: yup.string()
        .min(2, "O código do objetivo deve conter pelo menos 2 caracteres")
        .max(10, "O código do objetivo não pode ter mais do que 10 caracteres")
        .required("Campo obrigatório"),
    descObjetivo: yup.string()
        .min(2, "A descrição do objetivo deve conter pelo menos 2 caracteres")
        .max(80, "A descrição do objetivo não pode ter mais do que 80 caracteres")
        .required("Campo obrigatório"),
    descIngles: yup.string().notRequired(),
    descricaoMecanica: yup.string().required("Campo obrigatório"),
});

const initialValuesPeso: PesoDismembermentType = {
    descObjetivo: "",
    nomeLocal: "",
    peso: {
        idArea: null,
        idCicloPeso: 0,
        idCicloPesoPai: 0,
        idObjetivo: 0,
        peso: ""
    }
}

const valuesMeta: MetaType = {
    idCicloMeta: null,
    objetivo: {
        descObjetivo: '',
        mecanicaCalculo: '',
        unidade: ''
    },
    minimo: '',
    target: '',
    maximo: '',
    minimoRef: '',
    maximoRef: '',
    targetRef: '',
    habilitarReferencia: false
}

const valueObjective: Partial<ObjectivesType> = {
    descIngles: "",
    descObjetivo: "",
    codObjetivo: "",
    descricaoMecanica: ""
}

const Dismemberment: React.FC<IDismembermentProps> = (props) => {
    const dismembermentState = useSelector<RootState, DataTypesDismemberment>(state => state.cycleDismembermentReducer);

    const [isDialogTreeViewOpen, setIsDialogTreeViewOpen] = useState<boolean>(false);
    const [isDialogObjectiveOpen, setIsDialogObjectiveOpen] = useState<boolean>(false);
    const [isDialogAddNewObjectiveOpen, setIsDialogAddNewObjectiveOpen] = useState<boolean>(false);
    const [isDialogPesoOpen, setIsDialogPesoOpen] = useState<boolean>(false);
    const [isDialogMetaOpen, setIsDialogMetaOpen] = useState<boolean>(false);

    const [itemAreaTreeViewSelected, setItemAreaTreeViewSelected] = useState<Partial<ChartType> | null>(null);
    const [idAreaTreeViewSelected, setIdAreaTreeViewSelected] = useState<number | null>(null);
    const [valueAreaSelected, setValueAreaSelected] = useState<Partial<ChartType>>({});
    const [itemParentSelected, setItemParentSelected] = useState<DismembermentItemType | null>(null);
    const [itemObjectiveListSelected, setItemObjectiveListSelected] = useState<ObjectivesType | undefined>(undefined);
    const [textSearchObjective, setTextSearchObjective] = useState<string>('');
    const [objAdded, setObjAdded] = useState<(number | undefined)[]>([]);

    const [initialValuesMeta, setInitialValuesMeta] = useState(valuesMeta);
    const [initialPeso, setInitialPeso] = useState(initialValuesPeso);

    const [flagObjectiveAdded, setFlagObjectiveAdded] = useState(false);

    const [pesoSelected, setPesoSelected] = useState<Partial<PesoType>>({
        idCicloPeso: 0,
        idArea: 0,
        idCicloPesoPai: 0
    })

    const [errorInput, setErrorInput] = useState<boolean>(false);

    const allAreas = useSelector<RootState, DataTypes>(state => state.chartReducer);
    let allAreasFiltered = allAreas.data;
    const allObjectives = useSelector<RootState, DataTypesObjectives>(state => state.objectivesReducer);

    const dispatch = useDispatch();
    const idCiclo = props.idCiclo;

    const dismemberment: DismembermentType = dismembermentState.data;

    let timeout: number = 0;

    const {
        handleSubmit,
        handleChange,
        values,
        errors,
        setFieldError,
        resetForm
    } = useFormik({
        initialValues: valueObjective,
        validationSchema: validationNewObjectiveSchema,
        enableReinitialize: true,
        validateOnBlur: true,
        validateOnChange: false,
        onSubmit(values) {
            const { codObjetivo, descObjetivo, descIngles, descricaoMecanica, nivel, abrangencia } = values;
            const { idObjetivo: idObjetivoPai = 0, objetivo } = pesoSelected || {};
            const newObjective: ObjectivesType = {
                flagAtivo: true,
                flagMostrarTodos: false,
                idObjetivoPai: idObjetivoPai!,
                mecanicaCalculo: objetivo!.mecanicaCalculo,
                objPai: {
                    descObjetivo: objetivo!.descObjetivo
                },
                unidade: objetivo!.unidade!,
                idObjetivo: null,
                children: [],
                codObjetivo: codObjetivo!,
                descObjetivo: descObjetivo!,
                descIngles: descIngles ?? "",
                descricaoMecanica: descricaoMecanica!,
            }

            dispatch(CreatorObjectives.addObjectives(newObjective, true));
            setFlagObjectiveAdded(true);
        }
    });


    useEffect(() => {
        if (dismembermentState.success) {
            setValueAreaSelected(dismembermentState.areaSelected ?? {});
        }
        if (dismembermentState.successAction) {
            setIsDialogPesoOpen(false);
            setIsDialogMetaOpen(false);
        }
    }, [dismembermentState]);

    useEffect(() => {
        dispatch(CreatorsChart.getChart("", true, false));
    }, [])

    useEffect(() => {
        if (allObjectives.success && flagObjectiveAdded) {
            dispatch(CreatorsDismemberment.addInheritedArea(idCiclo!, pesoSelected.idArea!, itemParentSelected!, allObjectives.itemSelected.item!))
            setIsDialogAddNewObjectiveOpen(false);
            setFlagObjectiveAdded(false)
        }
    }, [allObjectives.success, flagObjectiveAdded])

    const search = (text: string) => {
        if (text.trim()) {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                allAreasFiltered = allAreas.data.filter(item => item.nomeLocal === text);
                // dispatch(CreatorsChart.getChart(text, true, false));
            }, 700);
        };
    };

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
        if (valueAreaSelected && valueAreaSelected.idArea) {
            dispatch(CreatorsDismemberment.getDismemberment(props.idCiclo!, valueAreaSelected.idArea!, valueAreaSelected.nomeLocal!));
        } else {
            setErrorInput(true);
        };
    };

    const handleConfirmTreeViewArea = () => {
        dispatch(CreatorsDismemberment.getDismemberment(props.idCiclo!, idAreaTreeViewSelected!, itemAreaTreeViewSelected?.nomeLocal))
        setIsDialogTreeViewOpen(false);
    };

    const handleConfirmObjectiveArea = () => {
        dispatch(CreatorsDismemberment.addInheritedArea(idCiclo!, pesoSelected.idArea!, itemParentSelected!, itemObjectiveListSelected!));
        setIsDialogObjectiveOpen(false);
    };

    const handleClickParentArea = () => {
        const { idAreaPai, areaPai } = dismemberment.area;
        if (idAreaPai !== 0) {
            dispatch(CreatorsDismemberment.getDismemberment(props.idCiclo!, idAreaPai!, areaPai?.nomeLocal));
        };
    };

    const handleClickParentAreaChild = (idArea: number, nomeArea: string) => {
        dispatch(CreatorsDismemberment.getDismemberment(idCiclo!, idArea, nomeArea));
    };

    const handleGetChildrenArea = (idArea: number, idCicloPeso: number) => {
        dispatch(CreatorsDismemberment.getDismembermentChildren(idCiclo!, idArea, idCicloPeso))
    };

    const handleAnchor = (parentArea: DismembermentItemType, idArea: number, objetivo: Partial<ObjectivesType>) => {
        dispatch(CreatorsDismemberment.addInheritedArea(idCiclo!, idArea, parentArea, objetivo));
    }

    const handleAddObjetive = (parentArea: DismembermentItemType, idArea: number, objAdded: (ObjectiveDismemberment | undefined)[]) => {
        setObjAdded(objAdded.map(obj => obj?.idObjetivo))

        dispatch(CreatorsObjectives.getObjectives("", false, true, parentArea.idObjetivo));
        setTextSearchObjective("")

        setIsDialogObjectiveOpen(true);
        resetForm()
        setPesoSelected({
            idCicloPeso: parentArea.idCicloPeso,
            idArea: idArea,
            idCicloPesoPai: parentArea.idCicloPeso,
            idObjetivo: parentArea.idObjetivo,
            objetivo: parentArea.objetivo
        })
        setItemParentSelected(parentArea);
    }

    const openDialogMeta = (idArea: number, goal: MetaDismembermentType, idCicloPeso: number, idCicloPesoPai: number, objetivo?: ObjectiveDismemberment) => {
        let meta: MetaDismembermentType = goal;
        if (meta === undefined || meta.idCicloMeta === 0) {
            meta = {
                idCicloMeta: 0,
                minimo: '',
                target: '',
                maximo: ''
            }
        } else if (meta?.idCicloMeta === -1) {
            meta.idCicloMeta = 0;
        }
        setInitialValuesMeta({
            ...initialValuesMeta,
            ...meta,
            objetivo: {
                descObjetivo: objetivo?.descObjetivo ?? "",
                mecanicaCalculo: objetivo?.mecanicaCalculo ?? "",
                unidade: objetivo?.unidade ?? ""
            }
        })
        setIsDialogMetaOpen(true);
        setPesoSelected({
            idArea: idArea,
            idObjetivo: objetivo?.idObjetivo!,
            idCicloPeso: idCicloPeso,
            idCicloPesoPai: idCicloPesoPai
        })

    }

    const openDialogPeso = (descObjetivo: string, nomeLocal: string, peso: Peso, idArea: number, idCicloPeso: number, idCicloPesoPai: number, idObjetivo: number) => {
        setPesoSelected({
            idCicloPeso: idCicloPeso,
            idArea: idArea,
            idCicloPesoPai: idCicloPesoPai,
            idObjetivo: idObjetivo
        })

        setInitialPeso({
            descObjetivo: descObjetivo,
            nomeLocal: nomeLocal,
            peso: {
                idArea: idArea,
                idCicloPeso: peso.idCicloPeso,
                idCicloPesoPai: idCicloPesoPai,
                idObjetivo: peso.idObjetivo,
                peso: String(peso.peso),
            }
        })
        setIsDialogPesoOpen(true);
    };

    const handleDeleteItem = (idArea: number, idCicloPeso: number, idCicloPesoPai: number, idObjetivo: number) => {
        dispatch(CreatorsDismemberment.delInheritedArea(idCicloPesoPai, idArea, idObjetivo));
        if (idCicloPeso) {
            const peso: Partial<PesoType> = {
                idArea,
                idCicloPesoPai,
                idObjetivo,
                idCicloPeso
            }
            dispatch(CreatorsDismemberment.deletePesoDismemberment(idCiclo!, peso));
        }
    }

    const handleDuplicateArea = (idCicloPai: number, idArea: number) => {
        dispatch(CreatorsDismemberment.addDuplicateArea(idCicloPai, idArea));
    }

    const handleOnItemInvoked = () => {
        // setIsDialogPesoOpen(true);
    };

    const handleOnItemInvokedObjective = (item: ObjectivesType) => {
        // setIsDialogPesoOpen(true);
    };

    const handleAddNewObjectiveToList = () => {
        setIsDialogObjectiveOpen(false);
        setIsDialogAddNewObjectiveOpen(true);
    }

    const renderParenteTable = () => {
        return dismemberment.respPesos.map((item, i) => {
            const { meta, objetivo, peso: pesoValue, idArea, idCicloPeso, corPilar, idObjetivo } = item;

            const formattedObj: ObjectiveDismemberment = {
                ...objetivo,
                idObjetivo,
                codObjetivo: ''
            }
            const peso: Peso = {
                idCicloPeso: idCicloPeso,
                idObjetivo: idObjetivo,
                peso: pesoValue
            }

            const resultParent = (
                <Table key={idArea}>
                    <TBody>
                        <ParentArea
                            idTableChild={`table-child-${item.idCicloPeso}`}
                            corPilar={corPilar}
                            objetivo={objetivo}
                            position={i}
                            isLoading={dismembermentState.idCicloPesoChildrenLoading === idCicloPeso}
                            isError={dismembermentState.idCicloPesoChildrenError === idCicloPeso}
                            peso={peso.peso}
                            hasChildren={item.vFilhos ? true : false}
                            handleGetChildrenArea={() => handleGetChildrenArea(idArea, idCicloPeso)}
                            onClickMeta={() => openDialogMeta(idArea, meta[0], idCicloPeso, idCicloPeso, formattedObj)}
                            openDialogPeso={() => openDialogPeso(item.objetivo.descObjetivo, dismemberment.area.nomeLocal!, peso, idArea, idCicloPeso, idCicloPeso, item.idObjetivo)}
                            meta={meta}
                        />
                    </TBody>
                </Table>
            )
            let resultChildren = null;

            if (item.vFilhos) {
                if (item.vFilhos.length > 0) {
                    let x = 0;
                    resultChildren = (
                        <TableChildren color={corPilar} key={i} id={`table-child-${item.idCicloPeso}`} isHidden={false}>
                            {
                                item.vFilhos.map((itemChild, j, arr) => {
                                    const { apelido, nomeLocal, idArea, meta: metaChild, peso: pesoChild, objetivo: objetivoChild } = itemChild;
                                    const { idCicloPeso: idCicloPesoChild } = pesoChild;
                                    const isInherited = itemChild.peso.idObjetivo === item.idObjetivo;

                                    const itemsAdded = arr.filter((it, i) => it.idArea === itemChild.idArea);
                                    const hadInherited = itemsAdded.some(item => item.objetivo?.idObjetivo === idObjetivo);
                                    const objAdded = itemsAdded.map(item => item.objetivo);

                                    let isLastItemArea;

                                    if (itemsAdded.length === 1) {
                                        isLastItemArea = true;
                                    } else if (itemsAdded[itemsAdded.length - 1].objetivo?.idObjetivo === itemChild.objetivo?.idObjetivo) {
                                        isLastItemArea = true;
                                    } else {
                                        isLastItemArea = false;
                                    };


                                    if (itemChild.objetivo?.idObjetivo === 0 || !itemChild.objetivo) {
                                        const resultBlankArea = (
                                            <BlankArea key={j}
                                                apelido={apelido}
                                                nomeLocal={nomeLocal}
                                                hadInherited={hadInherited}
                                                onClickApelido={() => handleClickParentAreaChild(idArea, nomeLocal)}
                                                handleAnchorClick={() => handleAnchor(item, idArea, { ...objetivoChild, idObjetivo: itemChild.peso.idObjetivo === 0 ? idObjetivo : itemChild.peso.idObjetivo })}
                                                handleChildrenClick={() => handleAddObjetive(item, idArea, objAdded)}
                                                color={corPilar}
                                            />
                                        )
                                        x = idArea;

                                        return resultBlankArea;
                                    } else {
                                        const resultAnchoredArea = (
                                            <AnchoredArea
                                                key={j}
                                                apelido={apelido}
                                                nomeLocal={nomeLocal}
                                                descObjetivo={objetivoChild!.descObjetivo}
                                                descricaoMecanica={!isInherited ? objetivoChild?.descricaoMecanica : objetivo.descricaoMecanica}
                                                meta={metaChild}
                                                unit={objetivoChild?.unidade}
                                                peso={itemChild.peso.peso}
                                                onClickDuplicateArea={() => handleDuplicateArea(idCicloPeso, idArea)}
                                                onClickDelete={() => handleDeleteItem(idArea, idCicloPesoChild, idCicloPeso, objetivoChild?.idObjetivo!)}
                                                onClickApelido={() => handleClickParentAreaChild(idArea, nomeLocal)}
                                                onClickPeso={() => openDialogPeso(objetivoChild!.descObjetivo, nomeLocal, itemChild.peso, idArea, idCicloPesoChild, idCicloPeso, item.idObjetivo)}
                                                onClickMeta={() => openDialogMeta(idArea, metaChild, idCicloPesoChild, idCicloPeso, objetivoChild)}
                                                color={corPilar}
                                                unidade={objetivo.unidade}
                                                isInherited={isInherited}
                                                hasChildren={!isLastItemArea}
                                            />
                                        )
                                        x = idArea;

                                        return resultAnchoredArea;
                                    }
                                })
                            }
                        </TableChildren>
                    )
                }
            }

            const result = [resultParent, resultChildren];

            return result;
        })

    }

    const renderParenteTableMemo = useMemo(() => {
        return renderParenteTable();
    }, [dismembermentState])

    return (
        <>
            <Container>
                <WrapperInput>
                    <Text variant="medium" styles={{ root: { marginRight: 20 } }}>Área</Text>
                    <WrapperAutoComplete>
                        <InputAutocomplete
                            value={(valueAreaSelected as ChartType)}
                            onChange={(_, newValue) => {
                                setValueAreaSelected(newValue!);
                            }}
                            onInputChange={(_, newInputValue) => {
                                setErrorInput(false);
                                search(newInputValue);
                            }}
                            getOptionLabel={(area: ChartType) => {
                                if (area.nomeLocal) {
                                    return area.nomeLocal;
                                } else {
                                    return "";
                                };
                            }}
                            getOptionSelected={(option, value) => {
                                return option.idArea === value.idArea;
                            }}
                            options={allAreasFiltered}
                            style={{ width: '100%', height: 32 }}
                            input={{
                                idInput: "objetivo",
                                labelInput: "",
                                autoFocus: true,
                                variant: "outlined",
                                styles: { height: 32 },
                                smaller: "small",
                                errorInput: errorInput,
                                helperTextInput: errorInput ? "Campo obrigatório" : "",
                            }}
                        />
                        <IconButton
                            iconProps={{ iconName: "Search" }}
                            styles={IconButtonStyle}
                            onClick={() => setIsDialogTreeViewOpen(true)}
                        />
                    </WrapperAutoComplete>
                    <OutlinedButton style={{ marginLeft: 20, minWidth: 205 }} onClick={handleClick}>Abrir Desmembramento</OutlinedButton>
                </WrapperInput>
                <WrapperDismemberment>
                    {
                        dismembermentState.loadingData ?
                            <WrapperSpinner>
                                <Spinner label="Carregando desmembramento" size={SpinnerSize.large} />
                            </WrapperSpinner>
                            :
                            dismembermentState.success &&
                            <>
                                <TableHeader>
                                    <TBody>
                                        <TrHeader>
                                            <TdParentIcon rowSpan={2} onClick={handleClickParentArea} disabled={dismemberment.area.idAreaPai === 0}>
                                                <TooltipHost
                                                    content={dismemberment.area.areaPai?.nomeLocal}
                                                    id="1"
                                                    styles={TooltipAreaStyle}
                                                >
                                                    <FontIcon iconName="GroupedAscending" style={{ fontSize: 20 }} />
                                                </TooltipHost>
                                            </TdParentIcon>
                                            <Gap rowSpan={2} />
                                            <TdAreaHeader rowSpan={2}>
                                                {dismemberment.area.nomeLocal}
                                            </TdAreaHeader>
                                            {
                                                dismemberment.respPesos.length !== 0 &&
                                                <>
                                                    <Gap rowSpan={2} />
                                                    <TdMetaHeaderTitle colSpan={3}>
                                                        METAS
                                                </TdMetaHeaderTitle>
                                                    <Gap rowSpan={2} />
                                                    <TdPesoHeader rowSpan={2}>
                                                        PESO
                                                </TdPesoHeader>
                                                </>
                                            }
                                        </TrHeader>
                                        {dismemberment.respPesos.length !== 0 &&
                                            <TrHeader>
                                                <TdMetaHeader>
                                                    Min
                                            </TdMetaHeader>
                                                <TdMetaHeader>
                                                    Target
                                            </TdMetaHeader>
                                                <TdMetaHeader>
                                                    Max
                                            </TdMetaHeader>
                                            </TrHeader>
                                        }
                                    </TBody>
                                </TableHeader>
                                {renderParenteTableMemo}
                                {
                                    dismemberment.respPesos.length !== 0 ?
                                        <TableTotal>
                                            <TBody>
                                                <Tr>
                                                    <GapTotal />
                                                    <TdTotal>Total</TdTotal>
                                                    <Gap />
                                                    <TdPeso black>
                                                        {dismemberment.respPesos.reduce((total, item) => total += Number(item.peso), 0)}%
                                                    </TdPeso>
                                                </Tr>
                                            </TBody>
                                        </TableTotal>
                                        :
                                        <NoItems
                                            error={false}
                                            text="Não há pesos cadastrados"
                                            img="/static/icons/supermarket.svg"
                                            alt="Não há pesos cadastrados"
                                        />

                                }
                            </>
                    }
                </WrapperDismemberment>

            </Container>

            <FormMetas
                isLoading={false}
                isLoadingAction={dismembermentState.loadingAction}
                isOpen={isDialogMetaOpen}
                setIsOpen={() => setIsDialogMetaOpen(false)}
                valuesInitialMeta={initialValuesMeta}
                onAddMeta={(metas: MetaType) => CreatorsDismemberment.addMetaDismemberment(idCiclo!, pesoSelected, metas)}
                onEditMeta={(metas: MetaType) => CreatorsDismemberment.editMetaDismemberment(idCiclo!, initialValuesMeta.idCicloMeta!, pesoSelected, metas)}
                onDeleteMeta={() => CreatorsDismemberment.deleteMetaDismemberment(idCiclo!, pesoSelected, initialValuesMeta.idCicloMeta!)}
            />

            <FormPesos
                isOpen={isDialogPesoOpen}
                setIsOpen={() => setIsDialogPesoOpen(false)}
                initialValues={initialPeso}
                descObjetivo={initialPeso.descObjetivo}
                isLoadingPeso={false}
                onAddPeso={peso => CreatorsDismemberment.addPesoDismemberment(idCiclo!, peso.idObjetivo!, peso, peso.idCicloPesoPai!)}
                onEditPeso={peso => CreatorsDismemberment.editPesoDismemberment(idCiclo!, peso.idObjetivo!, peso.idCicloPeso!, peso)}
                onDeletePeso={(peso) => CreatorsDismemberment.deletePesoDismemberment(idCiclo!, peso!)}
            />

            <CustomDialog
                open={isDialogTreeViewOpen}
                title="Escolher área"
                width={850}
                btnConfirmText="Definir"
                onClickCancel={() => setIsDialogTreeViewOpen(false)}
                onClickConfirm={handleConfirmTreeViewArea}
                disabledBtnConfirm={idAreaTreeViewSelected === null}
                height={500}
            >
                <TreeView
                    columns={[{
                      name: 'Área',
                      fieldName: 'nomeLocal'
                    }]}
                    flatten={{
                        hasToFlatten: true,
                        getItemSelected: (item) => setItemAreaTreeViewSelected(item),
                        idFieldParent: 'idAreaPai'
                    }}
                    state={allAreas}
                    fieldId="idArea"
                    idItemSelected={idAreaTreeViewSelected}
                    handleIdItemSelected={id => setIdAreaTreeViewSelected(id)}
                    handleOnItemInvoked={handleOnItemInvoked}
                />
            </CustomDialog>

            <CustomDialog
                open={isDialogObjectiveOpen}
                title="Escolher objetivo"
                width={850}
                btnConfirmText="Definir"
                onClickCancel={() => setIsDialogObjectiveOpen(false)}
                onClickConfirm={handleConfirmObjectiveArea}
                disabledBtnConfirm={itemObjectiveListSelected === undefined}
                height={500}
            >
                <WrapperSearchInput>
                    <InputText
                        autoFocus
                        variant="outlined"
                        label="Pesquisar"
                        value={textSearchObjective}
                        onChange={(e) => setTextSearchObjective(e.target.value)}
                        size="small"
                        style={{ maxWidth: '100%', paddingRight: 15, height: 40 }}
                        height_container={40}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <FontIcon iconName="Search" />
                                </InputAdornment>
                            ),
                        }}
                    />
                </WrapperSearchInput>
                <ListObjectives
                    items={allObjectives}
                    objsAdded={objAdded}
                    textSearch={textSearchObjective}
                    onItemInvoked={handleOnItemInvokedObjective}
                    getItemSelected={(obj: ObjectivesType) => setItemObjectiveListSelected(obj)}
                />
                <WrapperOutlineButton>
                    <OutlinedButton
                        colorBtn={Colors.primary}
                        style={{ width: 135 }}
                        onClick={handleAddNewObjectiveToList}
                    >
                        Novo Objetivo
                    </OutlinedButton>
                </WrapperOutlineButton>
            </CustomDialog>

            {/* -------- ADICIONAR OBJETIVO ------------ */}

            <CustomDialog
                open={isDialogAddNewObjectiveOpen}
                title="Novo objetivo"
                width={850}
                btnConfirmText="Salvar"
                onClickCancel={() => setIsDialogAddNewObjectiveOpen(false)}
                onClickConfirm={(e) => handleSubmit(e)}
                height={500}
            >
                <form onSubmit={handleSubmit}>
                    <InputText
                        value={pesoSelected.objetivo?.descObjetivo ?? ""}
                        id="descObjetivoPai"
                        name="descObjetivoPai"
                        type="text"
                        label="Objetivo Pai"
                        disabled
                        className="mt-2"
                    />
                    <InputText
                        value={values.codObjetivo}
                        onChange={(e: any) => { handleChange(e); setFieldError("codObjetivo", "") }}
                        id="codObjetivo"
                        error={errors.codObjetivo ? true : false}
                        name="codObjetivo"
                        type="text"
                        label="Código do objetivo"
                        helperText={errors.codObjetivo}
                        className="mt-2"
                        inputProps={{ maxLength: 10 }}
                        autoFocus
                    />
                    <InputText
                        value={values.descObjetivo}
                        onChange={(e: any) => { handleChange(e); setFieldError("descObjetivo", "") }}
                        id="descObjetivo"
                        error={errors.descObjetivo ? true : false}
                        name="descObjetivo"
                        type="text"
                        label="Descrição"
                        helperText={errors.descObjetivo}
                        inputProps={{ maxLength: 80 }}
                        className="mt-2"
                    />
                    <InputText
                        value={values.descIngles}
                        onChange={(e: any) => { handleChange(e); setFieldError("descIngles", "") }}
                        id="descIngles"
                        error={errors.descIngles ? true : false}
                        name="descIngles"
                        type="text"
                        label="Descrição em Inglês"
                        helperText={errors.descIngles}
                        inputProps={{ maxLength: 80 }}
                        className="mt-2"
                    />
                    <InputText
                        value={values.descricaoMecanica ?? ""}
                        onChange={(e: any) => { handleChange(e); setFieldError("descricaoMecanica", "") }}
                        id="descricaoMecanica"
                        error={errors.descricaoMecanica ? true : false}
                        name="descricaoMecanica"
                        type="text"
                        label="Descrição mecânica"
                        helperText={errors.descricaoMecanica}
                        className="mt-2"
                    />
                </form>
            </CustomDialog>
        </>
    );
}

const InheritIconStyle: React.CSSProperties = {
    fontSize: 16,
    position: 'absolute',
    right: 7,
    top: 7
}

const TooltipAreaStyle: Partial<ITooltipHostStyles> = {
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
};

const TooltipAreaDescStyle: Partial<ITooltipHostStyles> = {
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    }
};

const IconButtonStyle: Partial<IButtonStyles> = {
    root: {
        position: 'absolute',
        top: 4,
        right: 8,
        width: 24,
        height: 24,
        padding: 8
    },
    icon: {
        fontSize: 12,
    }
};

interface ITableChildren {
    id: string;
    color: string;
    isHidden: boolean;
}

const TableChildren: React.FC<ITableChildren> = (props) => {
    return (
        <TableChild hidden={props.isHidden} id={props.id}>
            <TBodyChild color={props.color}>
                {props.children}
            </TBodyChild>
        </TableChild>
    )
}

interface IParentArea {
    idTableChild: string;
    corPilar: string;
    handleGetChildrenArea: () => void;
    openDialogPeso: () => void;
    onClickMeta: () => void;
    hasChildren: boolean;
    position: number;
    isLoading: boolean;
    isError: boolean;
    // descObjetivo: string;
    objetivo: DismembermentItemType["objetivo"];
    // unit?: string;
    meta: {
        idCicloMeta: number;
        minimo: string;
        target: string;
        maximo: string;
    }[];
    // unidade: string;
    peso: string | number;
    // descricaoMecanica: string;
}

const ParentArea: React.FC<IParentArea> = (props) => {
    const {
        idTableChild,
        corPilar,
        handleGetChildrenArea,
        position,
        objetivo,
        meta,
        peso,
        openDialogPeso,
        isLoading,
        isError,
        onClickMeta,
        hasChildren
    } = props;

    const { minimo = "", target = "", maximo = "" } = meta[0] || {};
    const { descObjetivo, unidade, descricaoMecanica } = objetivo;

    const [isExpanded, setIsExpanded] = useState(hasChildren);

    const handleCollapsed = () => {
        setIsExpanded(false)
        const tableChild = document.getElementById(idTableChild);
        tableChild?.classList.add("tbl-collapsed");
        tableChild?.classList.remove("tbl-expanded");
    }

    const handleExpanded = () => {
        setIsExpanded(true)
        if (!hasChildren) {
            handleGetChildrenArea();
        }
        const tableChild = document.getElementById(idTableChild);
        tableChild?.classList.remove("tbl-collapsed");
        tableChild?.classList.add("tbl-expanded");
    }

    return (
        <TrParent color={corPilar}>
            {
                isLoading ?
                    <TdIcon>
                        <Spinner size={SpinnerSize.medium} />
                    </TdIcon>
                    :
                    isError ?
                        <TdIcon onClick={handleExpanded}>
                            <FontIcon iconName="CirclePlus" style={{ fontSize: 20 }} />
                        </TdIcon>
                        :
                        !isExpanded ?
                            <TdIcon onClick={handleExpanded}>
                                <FontIcon iconName="CirclePlus" style={{ fontSize: 20 }} />
                            </TdIcon>
                            :
                            <TdIcon onClick={handleCollapsed}>
                                <FontIcon iconName="SkypeCircleMinus" style={{ fontSize: 20 }} />
                            </TdIcon>

            }
            <Gap />
            <TdIndication rmvHover>
                {position + 1}
            </TdIndication>
            <Gap />
            <TdArea>
                <TooltipHost
                    content={descricaoMecanica}
                    id="1"
                    styles={TooltipAreaDescStyle}
                >
                    {descObjetivo}
                </TooltipHost>
            </TdArea>
            <Gap />
            {
                unidade !== "A" &&
                <>
                    <TdMeta onClick={onClickMeta}>
                        {formattedMeta(minimo, unidade)}
                    </TdMeta>
                    <TdMeta onClick={onClickMeta}>
                        {formattedMeta(target, unidade)}
                    </TdMeta>
                    <TdMeta dark onClick={onClickMeta}>
                        {formattedMeta(maximo, unidade)}
                    </TdMeta>
                    <Gap />
                </>
            }
            <TdPeso onClick={openDialogPeso}>
                {formattedPeso(Number(peso))}%
            </TdPeso>
        </TrParent>
    )
}

interface IBlankArea {
    apelido: string;
    nomeLocal: string;
    color: string;
    hadInherited: boolean;
    handleAnchorClick: () => void;
    handleChildrenClick: () => void;
    onClickApelido: () => void;
}

const BlankArea: React.FC<IBlankArea> = (props) => {
    const {
        apelido,
        nomeLocal,
        handleAnchorClick,
        handleChildrenClick,
        color,
        onClickApelido,
        hadInherited
    } = props;

    return (
        <TrChild color={color}>
            <BiggerGap />
            <TdIndication onClick={onClickApelido}>
                <TooltipHost
                    content={nomeLocal}
                    id="1"
                    styles={TooltipAreaStyle}
                >
                    {apelido}
                </TooltipHost>
            </TdIndication>
            <Gap />
            <TdActionBtns>
                {
                    !hadInherited &&
                    <TooltipHost
                        content="Delegar meta"
                        id="1"
                    >
                        <IconButton iconProps={{ iconName: "AnchorLock" }} ariaLabel="Herdar" onClick={handleAnchorClick} />
                    </TooltipHost>
                }
                <TooltipHost
                    content="Desmembrar"
                    id="1"
                >
                    <IconButton iconProps={{ iconName: "Childof" }} ariaLabel="Herdar" onClick={handleChildrenClick} />
                </TooltipHost>
            </TdActionBtns>
        </TrChild>
    )
}

interface IAnchoredArea {
    apelido: string;
    nomeLocal: string;
    descObjetivo: string;
    descricaoMecanica?: string;
    unidade: string;
    isInherited?: boolean;
    unit?: string;
    meta: {
        idCicloMeta: number;
        minimo: string;
        target: string;
        maximo: string;
    };
    peso: string | number;
    color: string;
    hasChildren: boolean;
    onClickDuplicateArea: () => void;
    onClickApelido: () => void;
    onClickPeso: () => void;
    onClickMeta: () => void;
    onClickDelete: () => void;
}

const AnchoredArea: React.FC<IAnchoredArea> = (props) => {
    const {
        apelido,
        nomeLocal,
        descObjetivo,
        meta,
        peso,
        onClickPeso,
        color,
        unidade,
        isInherited,
        unit,
        onClickMeta,
        onClickApelido,
        descricaoMecanica,
        onClickDuplicateArea,
        onClickDelete,
        hasChildren
    } = props;
    const { minimo, target, maximo } = meta;

    const handleAddDuplicate = () => {
        onClickDuplicateArea();
    }

    return (
        <TrChild color={color} hadAddDuplicate={hasChildren}>
            <BiggerGap />
            <TdIndication onClick={onClickApelido}>
                <TooltipHost
                    content={nomeLocal}
                    id="1"
                    styles={TooltipAreaStyle}
                >
                    {apelido}
                </TooltipHost>
            </TdIndication>
            <Gap />
            <TdAreaChild colSpan={hasChildren ? 3 : 1}>
                <TooltipHost
                    content={descricaoMecanica}
                    id="1"
                    styles={TooltipAreaDescStyle}
                >
                    <DescArea>{descObjetivo}</DescArea>
                    {
                        isInherited &&
                        <WrapperAnchorIcon>
                            <FontIcon iconName="AnchorLock" style={{ ...InheritIconStyle, color: color }} />
                        </WrapperAnchorIcon>
                    }
                    <WrapperDelBtn onClick={onClickDelete}>
                        <IconButton iconProps={{ iconName: "Delete" }} styles={{ root: { color: color, fontSize: 32 } }} />
                    </WrapperDelBtn>
                </TooltipHost>
            </TdAreaChild>
            <Gap />
            {
                !hasChildren &&
                <>
                    <TdIndication noBorder onClick={handleAddDuplicate}>
                        <TooltipHost
                            content={"Duplicar área"}
                            id="1"
                            styles={TooltipAreaStyle}
                        >
                            <FontIcon iconName="DependencyAdd" style={{ fontSize: 16 }} />
                        </TooltipHost>
                    </TdIndication>
                    <Gap />
                </>
            }
            {
                isInherited ?
                    <>
                        <TdMeta anchored>
                            {formattedMeta(minimo, unidade)}
                        </TdMeta>
                        <TdMeta anchored>
                            {formattedMeta(target, unidade)}
                        </TdMeta>
                        <TdMeta anchored>
                            {formattedMeta(maximo, unidade)}
                        </TdMeta>
                    </>
                    :
                    <>
                        <TdMeta onClick={onClickMeta}>
                            {formattedMeta(minimo, unidade)}
                        </TdMeta>
                        <TdMeta onClick={onClickMeta}>
                            {formattedMeta(target, unidade)}
                        </TdMeta>
                        <TdMeta onClick={onClickMeta} dark>
                            {formattedMeta(maximo, unidade)}
                        </TdMeta>
                    </>
            }
            <Gap />
            <TdPeso onClick={onClickPeso}>
                {Number(peso) !== 0 ? `${formattedPeso(Number(peso))}%` : ''}
            </TdPeso>
        </TrChild>
    )
}

const formattedMeta = (value: string, unidade: string): string => {

    const valueFormatted: number = parseFloat(value);
    if (valueFormatted === 0) {
        return '';
    };
    if (!Number.isNaN(valueFormatted)) {
        switch (unidade) {
            case 'P':
                return `${valueFormatted}%`;
            case 'M':
                const moeda: string = parseFloat(value).toLocaleString('pt-br', { minimumFractionDigits: 2 });
                return moeda;
            case 'L':
                const million: string = (decimal(parseFloat(value) / 1000000)).toLocaleString('pt-br');
                return `${million}M`;
            case 'N':
                return `${valueFormatted}`;
            case 'A':
                return "";
            default:
                return value;
        };
    } else {
        return '';
    }
};

const decimal = (num: number) => {
    const og = Math.pow(10, 2)
    return Math.floor(num * og) / og;
}

const getPeso = (item: any): string => {
    const value = parseFloat(item.peso);
    if (value === 0) {
        return "";
    } else {
        return `${value}%`
    }
}

const formattedPeso = (peso: number): string => {
    return `${parseFloat(`${peso}`)}`;
}

export default Dismemberment;
