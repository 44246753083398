//#region Imports
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from '~/store/ducks/home';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { CommandBarButton, IconButton, ICommandBarStyles, IButtonStyles, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

import {
  PrimaryButton,
  DefaultButton,
  Wrapper,
  ContainerContent,
  WrapperTreeview,
} from "./styles";

import MenuItem from '@material-ui/core/MenuItem';

import CustomDialog from "~/components/CustomDialogFluentUI";
import Panel from "~/components/layout/Panel";
import Dropdown from "~/components/layout/Dropdown";
import { InputText, InputCheckbox } from '~/components/Forms';
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
import TreeView from '~/components/TreeView';
import { DeleteButton } from '~/components/Buttons';

import { Page } from "~/store/ducks/home/types";
import { RootState } from '~/store/ducks';
import { ObjectivesType, DataTypes } from "~/store/ducks/objectives/types";
import { Creators as CreatorObjectives } from "~/store/ducks/objectives";

import colors from "~/assets/js/colors";

const validationSchema = yup.object().shape({
  descObjetivoPai: yup.number().nullable(),
  codObjetivo: yup.string()
    .min(2, "O código do objetivo deve conter pelo menos 2 caracteres")
    .max(10, "O código do objetivo não pode ter mais do que 10 caracteres")
    .required("Campo obrigatório"),
  descObjetivo: yup.string()
    .min(2, "A descrição do objetivo deve conter pelo menos 2 caracteres")
    .max(80, "A descrição do objetivo não pode ter mais do que 80 caracteres")
    .required("Campo obrigatório"),
  descIngles: yup.string().notRequired(),
  nivel: yup.string().required("Campo obrigatório"),
  unidade: yup.string().required("Campo obrigatório"),
  abrangencia: yup.string().required("Campo obrigatório"),
  descricaoMecanica: yup.string().required("Campo obrigatório"),
  mecanicaCalculo: yup.string().required("Campo obrigatório")
});

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44
  }
};

const btnPanelStyles: Partial<IButtonStyles> = {
  root: {
    color: colors.darkGray
  }
};

let initial: ObjectivesType = {
  idObjetivo: null,
  descIngles: "",
  codObjetivo: "",
  descObjetivo: "",
  nivel: "",
  unidade: "",
  abrangencia: "",
  descricaoMecanica: "",
  flagMostrarTodos: false,
  idObjetivoPai: 0,
  children: [],
  objPai: null,
  mecanicaCalculo: '',
  flagAtivo: true
};

const Objectives: React.FC = () => {
  const dispatch = useDispatch();

  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const [idSelected, setIdSelected] = useState<number | null>(null);
  const [isAddingChild, setIsAddingChild] = useState<boolean>(false);
  const [isDialogExcludeOpen, setIsDialogExcludeOpen] = useState(false);

  const objectivesState = useSelector<RootState, DataTypes>(state => state.objectivesReducer);

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setValues,
    setFieldError
  } = useFormik({
    initialValues: initial,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit(values) {
      if (!values.idObjetivo) {
        dispatch(CreatorObjectives.addObjectives(values));
      } else {
        values.idObjetivoPai = idSelected;
        dispatch(CreatorObjectives.editObjective({ ...values, flagAtivo: !values.flagAtivo }));
      };
    }
  });

  useEffect(() => {
    const page: Page = {
      key: 'objetivos',
      pages: [
        { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
        { text: 'Catálogo de Objetivos', isCurrentItem: true }]
    };

    dispatch(Creators.setCurrentPage(page));
    dispatch(CreatorObjectives.resetObjectives());
    dispatch(CreatorObjectives.getObjectives());
  }, [dispatch]);

  useEffect(() => {
    if (objectivesState.itemSelected.success) {
      if (isAddingChild) {
        const { descObjetivo, idObjetivo } = objectivesState.itemSelected.item;
        initial.objPai = { descObjetivo };
        initial.idObjetivoPai = idObjetivo;
        setValues(initial);
      } else {
        setValues({ ...objectivesState.itemSelected.item, flagAtivo: !objectivesState.itemSelected.item.flagAtivo });
      }
    }
  }, [objectivesState.itemSelected, isAddingChild, setValues])

  useEffect(() => {
    if (objectivesState.success) {
      setIsPanelOpen(false);
    }
  }, [objectivesState.success])

  const excludeCompanies = () => {
    setIsDialogExcludeOpen(false);
    setIsPanelOpen(false);
    dispatch(CreatorObjectives.delObjective(idSelected!));
    setIdSelected(0);
  };

  const cancelPanel = () => {
    setIsAddingChild(false);
    setIsPanelOpen(false);
  };

  const handleOnItemInvoked = () => {
    setIsPanelOpen(true);
    setIsAddingChild(false);
    dispatch(CreatorObjectives.getObjectiveById(idSelected!));
  }

  const commandBarRender = () => {
    if (idSelected) {
      return (
        <>
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Edit' }} text="Editar" onClick={() => {
            setIsPanelOpen(true);
            setIsAddingChild(false);
            dispatch(CreatorObjectives.getObjectiveById(idSelected));
          }} />
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'RowsChild' }} text="Adicionar subitem" onClick={() => {
            setIsPanelOpen(true);
            setIsAddingChild(true);
            dispatch(CreatorObjectives.getObjectiveById(idSelected));
          }} />
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => setIsDialogExcludeOpen(true)} />
        </>
      );
    }
  };

  const dialogContentProps = {
    title: 'Excluir?',
    closeButtonAriaLabel: 'Close',
    subText: 0 <= 1 ? 'Tem certeza de que deseja excluir este item?' : 'Tem certeza de que deseja excluir estes items?',
  };

  return (
    <>
      <Panel
        title={!values.idObjetivo ? "Novo Objetivo" : objectivesState.itemSelected.item.descObjetivo}
        loading={objectivesState.itemSelected.loading}
        open={isPanelOpen}
        onClose={() => cancelPanel}
        hideClose={true}
        itemsHeader={<IconButton
          iconProps={{ iconName: 'ChromeClose' }}
          styles={btnPanelStyles}
          title="Fechar"
          ariaLabel="Fechar"
          onClick={() => setIsPanelOpen(false)}
        />}
        footer={
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <DefaultButton onClick={() => cancelPanel()}>Cancelar</DefaultButton>
            <PrimaryButton onClick={(e: any) => handleSubmit(e)}>Salvar</PrimaryButton>
          </Stack>
        }
      >
        {
          objectivesState.itemSelected.loading ?
            <Spinner size={SpinnerSize.large} styles={{ root: { height: 'calc(100% - 50px)' } }} />
            :
            <form onSubmit={handleSubmit}>
              {
                values.objPai !== null &&
                <InputText
                  value={values.objPai?.descObjetivo ?? ""}
                  id="descObjetivoPai"
                  name="descObjetivoPai"
                  type="text"
                  label="Objetivo Pai"
                  disabled
                  className="mt-2"
                />
              }
              <InputText
                value={values.codObjetivo}
                onChange={(e: any) => { handleChange(e); setFieldError("codObjetivo", "") }}
                id="codObjetivo"
                error={errors.codObjetivo ? true : false}
                name="codObjetivo"
                type="text"
                label="Código do objetivo"
                helperText={errors.codObjetivo}
                className="mt-2"
                inputProps={{ maxLength: 10 }}
                autoFocus
              />
              <InputText
                value={values.descObjetivo}
                onChange={(e: any) => { handleChange(e); setFieldError("descObjetivo", "") }}
                id="descObjetivo"
                error={errors.descObjetivo ? true : false}
                name="descObjetivo"
                type="text"
                label="Descrição"
                helperText={errors.descObjetivo}
                inputProps={{ maxLength: 80 }}
                className="mt-2"
              />
              <InputText
                value={values.descIngles}
                onChange={(e: any) => { handleChange(e); setFieldError("descIngles", "") }}
                id="descIngles"
                error={errors.descIngles ? true : false}
                name="descIngles"
                type="text"
                label="Descrição em Inglês"
                helperText={errors.descIngles}
                inputProps={{ maxLength: 80 }}
                className="mt-2"
              />
              <Dropdown
                errors={errors.nivel}
                label="Nível do objetivo"
                name="nivel"
                values={values.nivel ?? ""}
                handleChange={(e: any) => { handleChange(e); setFieldError("nivel", "") }}
                errorText={errors.nivel}
              >
                <MenuItem value={"E"}>Estratégico</MenuItem>
                <MenuItem value={"T"}>Tático</MenuItem>
                <MenuItem value={"O"}>Operacional</MenuItem>
                <MenuItem value={"X"}>Todos</MenuItem>
              </Dropdown>
              <Dropdown
                errors={errors.unidade}
                label="Unidade"
                name="unidade"
                values={values.unidade}
                handleChange={(e: any) => { handleChange(e); setFieldError("unidade", "") }}
                errorText={errors.unidade}
              >
                <MenuItem value={"P"}>Percentual</MenuItem>
                <MenuItem value={"M"}>Moeda</MenuItem>
                <MenuItem value={"L"}>Milhão</MenuItem>
                <MenuItem value={"N"}>Número</MenuItem>
                <MenuItem value={"A"}>Nenhuma</MenuItem>
              </Dropdown>
              <Dropdown
                errors={errors.abrangencia}
                label="Abrangência"
                name="abrangencia"
                values={values.abrangencia ?? ""}
                handleChange={(e: any) => { handleChange(e); setFieldError("abrangencia", "") }}
                errorText={errors.abrangencia}
              >
                <MenuItem value={"L"}>Locais</MenuItem>
                <MenuItem value={"F"}>Funcionários</MenuItem>
              </Dropdown>
              <InputText
                value={values.descricaoMecanica ?? ""}
                onChange={(e: any) => { handleChange(e); setFieldError("descricaoMecanica", "") }}
                id="descricaoMecanica"
                error={errors.descricaoMecanica ? true : false}
                name="descricaoMecanica"
                type="text"
                label="Descrição mecânica"
                helperText={errors.descricaoMecanica}
                className="mt-2"
              />
              <Dropdown
                errors={errors.mecanicaCalculo}
                label="Mecânica do cálculo"
                name="mecanicaCalculo"
                values={values.mecanicaCalculo}
                handleChange={(e: any) => { handleChange(e); setFieldError("mecanicaCalculo", "") }}
                errorText={errors.mecanicaCalculo}
              >
                <MenuItem value={"+"}>Quanto maior melhor</MenuItem>
                <MenuItem value={"-"}>Quanto menor melhor</MenuItem>
              </Dropdown>
              {
                values.idObjetivo &&
                <InputCheckbox
                  checked={values.flagAtivo}
                  onChange={handleChange}
                  name="flagAtivo"
                  color="primary"
                  label="Inativar Objetivo"
                />
              }
            </form>
        }

      </Panel>

      <CustomDialog
        hidden={!isDialogExcludeOpen}
        onDismiss={() => setIsDialogExcludeOpen(false)}
        dialogContentProps={dialogContentProps}
      >
        <DefaultButton onClick={() => setIsDialogExcludeOpen(false)} text="Cancelar" />
        <DeleteButton onClick={excludeCompanies} text="Excluir" />
      </CustomDialog>

      <Wrapper>
        <ContainerContent>
          <HeaderPage
            title="Catálogo de Objetivos"
            leftItems={
              <>
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: 'Add' }}
                  text="Adicionar Objetivos"
                  onClick={() => {
                    setValues({ ...initial, objPai: null });
                    setIsPanelOpen(true);
                  }}
                  disabled={objectivesState.loadingData}
                />
                {commandBarRender()}
              </>
            }
            rightItems={
              <>
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: 'Refresh' }}
                  text="Atualizar"
                  onClick={() => dispatch(CreatorObjectives.getObjectives())}
                />
              </>
            }
          />
          <WrapperTreeview>
            {
              objectivesState.data.length === 0 && !objectivesState.loadingData ?
                <NoItems
                  error={objectivesState.error}
                  text="Não há objetivos cadastrados"
                  icon="Bullseye"
                />
                :
                <TreeView
                  columns={[
                    {
                      name: 'Descrição',
                      fieldName: 'descObjetivo'
                    },
                    {
                      name: 'Nível do Objetivo',
                      fieldName: 'nivel',
                      onRender: convertNivel
                    }
                  ]}
                  state={objectivesState}
                  fieldId="idObjetivo"
                  idItemSelected={idSelected}
                  handleIdItemSelected={id => setIdSelected(id)}
                  handleOnItemInvoked={handleOnItemInvoked}
                  status={{
                    hasStatus: true,
                    fieldName: 'flagAtivo'
                  }}
                />
            }
          </WrapperTreeview>
        </ContainerContent>
      </Wrapper>
    </>
  );
}

const convertNivel = (nivel: string): string => {
  switch (nivel) {
    case 'E':
      return "Estratégico";
    case 'T':
      return "Tático";
    case 'O':
      return "Operacional";
    case 'X':
      return "Todos";
    default:
      return "";
  }
}

export default Objectives;
