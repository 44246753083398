
/**
 * Types
 */
export enum Types {
  REQUEST_CYCLE_VALIDATION = "cycleValidation/REQUEST_CYCLE_VALIDATION",
  SUCCESS_CYCLE_VALIDATION = "cycleValidation/SUCCESS_CYCLE_VALIDATION",
  FAILURE_CYCLE_VALIDATION = "cycleValidation/FAILURE_CYCLE_VALIDATION",
  //REFRESH
  CAN_REFRESH_VALIDATION = "cycleValidation/CAN_REFRESH_VALIDATION"
};

export type ValidationsType = {
  idCiclo: number;
  idArea: number;
  idAreaPai: number;
  qtdePesos: number;
  somaPesos: number;
  statusQtde: string;
  statusSoma: string;
  Area:{
    nomeLocal: string;
  };

  idCicloValidacao: number;
  // tipoValidacao: {
  //   descValidacao: string;
  // };
  // status: string;
  children: ValidationsType[];
}

export type ValidationType = {
  status: 'V' | 'P' | 'F' | '';
  validacoes: ValidationsType[]
}

interface ICycleValidationState {
  readonly data: ValidationType;
  readonly loadingData: boolean;
  readonly success: boolean;
  readonly error: boolean;
  readonly flagStartValidation: boolean;
};

export type DataTypes = ICycleValidationState;


//GET
export interface IRequestValidationCycleAction {
  type: typeof Types.REQUEST_CYCLE_VALIDATION;
  idCiclo: number;
};

export interface ISuccessValidationCycleAction {
  type: typeof Types.SUCCESS_CYCLE_VALIDATION;
  payload: {
    data: ValidationType;
    flagStartValidation: boolean;
  }
};

interface IFailureValidationCycleAction {
  type: typeof Types.FAILURE_CYCLE_VALIDATION;
  message: string;
};

type ValidationCycleAction = IRequestValidationCycleAction | ISuccessValidationCycleAction | IFailureValidationCycleAction;

interface ICanRefreshValidationAction{
  type: typeof Types.CAN_REFRESH_VALIDATION
}

type CanRefreshValidationAction = ICanRefreshValidationAction;

export type ValidationCycleActionTypes = ValidationCycleAction | CanRefreshValidationAction;
