import { Types, DataTypes, CycleAttitudesActionTypes, CycloAttitudeType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  loading: false,
  success: false,
  error: false
};

/**
 * Actions
 */

export const Creators = {
  getCycleAttitudes: (idCiclo: number): CycleAttitudesActionTypes => ({
    type: Types.REQUEST_CYCLE_ATTITUDES_LOAD,
    idCiclo
  }),

  addCycleAttitude: (idCiclo: number, idAtitude: number, ordem: number | null, nomeAtitude: string): CycleAttitudesActionTypes => ({
    type: Types.REQUEST_CYCLE_ATTITUDES_ADD,
    payload: {
      idCiclo,
      idAtitude,
      ordem,
      atitude: {
        nomeAtitude
      }
    }
  }),

  delCycleAttitude: (idCiclo: number, idCicloAtitude: number): CycleAttitudesActionTypes => ({
    type: Types.REQUEST_CYCLE_ATTITUDES_DEL,
    payload: {
      idCiclo,
      idCicloAtitude
    }
  })
};


export default function cycleAttitudesReducer(state = INITIAL_STATE, action: CycleAttitudesActionTypes): DataTypes {

  switch (action.type) {
    case Types.REQUEST_CYCLE_ATTITUDES_LOAD:
      return { ...state, loading: true, error: false, success: false };
    case Types.SUCCESS_CYCLE_ATTITUDES_LOAD:
      return { ...state, data: action.payload, loading: false, error: false, success: true };
    case Types.FAILURE_CYCLE_ATTITUDES_LOAD:
      customToast.error(action.message);
      return { ...state, data: [], loading: false, error: true, success: false };

    case Types.REQUEST_CYCLE_ATTITUDES_ADD:
      return { ...state, success: false };
    case Types.SUCCESS_CYCLE_ATTITUDES_ADD:
      return {
        ...state, data: addCycleAttitude(state.data, action.payload), success: true
      };
    case Types.FAILURE_CYCLE_ATTITUDES_ADD:
      customToast.error(action.message);
      return { ...state, loading: false, success: false };

    case Types.REQUEST_CYCLE_ATTITUDES_DEL:
      return { ...state, success: false };
    case Types.SUCCESS_CYCLE_ATTITUDES_DEL:
      return { ...state, data: delCycleAttitude(state.data, action.idCicloAtitude), success: true };
    case Types.FAILURE_CYCLE_ATTITUDES_DEL:
      customToast.error(action.message);
      return { ...state, loading: false, success: false };

    default:
      return state;
  }
}

const addCycleAttitude = (allAttitudes: CycloAttitudeType[], attitude: CycloAttitudeType): CycloAttitudeType[] => {
  const { ordem } = attitude;
  if (ordem !== null && ordem <= allAttitudes.length) {
    for (let i = ordem - 1; i < allAttitudes.length; i++) {
      allAttitudes[i].ordem = allAttitudes[i].ordem + 1;
    }

    return [...allAttitudes, attitude].sort((a, b) => a.ordem - b.ordem);

  } else {
    return [...allAttitudes, { ...attitude, ordem: allAttitudes.length + 1 }]
  }
}

const delCycleAttitude = (allAttitudes: CycloAttitudeType[], idCicloAtitude: number): CycloAttitudeType[] => {
  const ordem = allAttitudes.find(item => item.idCicloAtitude === idCicloAtitude)!.ordem;

  if (ordem <= allAttitudes.length) {
    for (let i = ordem - 1; i < allAttitudes.length; i++) {
      allAttitudes[i].ordem = allAttitudes[i].ordem - 1;
    }

    return [...allAttitudes].filter(item => item.idCicloAtitude !== idCicloAtitude).sort((a, b) => a.ordem - b.ordem);

  } else {
    return [...allAttitudes].filter(item => item.idCicloAtitude !== idCicloAtitude);
  }
}
