import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Task from './Tasks';
import Approvals from './Approvals';
import Solicitations from './Solicitations';

import { Label, ILabelStyles } from 'office-ui-fabric-react/lib/Label';
import { Pivot, PivotItem, IPivotStyles } from 'office-ui-fabric-react/lib/Pivot';
import { IStyleSet } from 'office-ui-fabric-react/lib/Styling';

import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { Creators } from "~/store/ducks/home";

import { Container } from './styles';

const pivotStyles: Partial<IPivotStyles> = {
    root: {
        borderBottom: '1px solid #CFD3DA',
        backgroundColor: "#FFFFFF",
        width: '100%',
        zIndex: 999,
    },
    itemContainer: {
        height: 'calc(100vh - 181px);'
    },
    linkContent: {
        width: 130
    }
}

const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: { marginTop: 10 },
};

const WorkFlow: React.FC = () => {

    const location: any = useLocation()
    const dispatch = useDispatch();

    const [currentPivot, setCurrentPivot] = useState<string>(location.state ? location.state.indexTab : '0');


    useEffect(() => {
        const items: BreadcrumbItems[] = [
            { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
            { text: 'Painel WorkFlow', url: "/workflow", isCurrentItem: true }
        ];

        const page: Page = {
            key: 'workflow',
            pages: items
        };

        dispatch(Creators.setCurrentPage(page));
    }, [dispatch])

    const handlePivot = (idPivot: string) => {
        setCurrentPivot(idPivot);
    }


    return (
        <Container>
            <Pivot
                aria-label="Basic Pivot Example"
                styles={pivotStyles}
                selectedKey={String(currentPivot)}
                onLinkClick={(e) => handlePivot(e?.props.itemKey!)}
            >
                <PivotItem
                    headerText="Tarefas"
                    itemKey="0"
                    key="0"
                >
                    <Task />
                </PivotItem>
                <PivotItem
                    headerText="Aprovações"
                    itemKey="1"
                    key="1"
                >
                    <Approvals fielName="idFuncionario" />
                </PivotItem>
                <PivotItem
                    headerText="Solicitações"
                    itemKey="2"
                    key="2"
                >
                    <Solicitations />
                </PivotItem>
            </Pivot>
        </Container>
    );
}

export default WorkFlow;