// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from "formik";
import * as yup from 'yup';

//FluentUI
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBarButton, IconButton, ICommandBarStyles } from 'office-ui-fabric-react';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { IDialogContentProps } from 'office-ui-fabric-react/lib/Dialog';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { ActionButton, IButtonStyles } from 'office-ui-fabric-react';

//MaterialUI
// import MenuItem from '@material-ui/core/MenuItem';
import MenuItem from '@material-ui/core/MenuItem';
import Dropdown from "~/components/layout/Dropdown"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
//Componentes
import Panel from "~/components/layout/Panel";
import Status from "~/components/Status";
import CustomDialog from "~/components/CustomDialogFluentUI";

import Dialog from '~/components/CustomDialog';
// import Dropdown from "~/components/layout/Dropdown"
import colors from "~/assets/js/colors";
import { CustomDrawer as Drawer } from "~/components/layout/Drawer";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { InputText, InputCheckbox, InputDate } from '~/components/Forms';

import { DeleteButton } from '~/components/Buttons';
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
//Estilos
import {
  Wrapper,
  ListContainer,
  PrimaryButton,
  DefaultButton,
  ContainerContent,
} from "./styles";

import { Creators as getCycleAttitudes } from "~/store/ducks/cycle/attitude";
import { Creators as addCycleAttitude } from "~/store/ducks/cycle/attitude";
import { Creators as delCycleAttitude } from "~/store/ducks/cycle/attitude";
import { DataTypes, CycloAttitudeType } from "~/store/ducks/cycle/attitude/types";

import { AttitudeType } from "~/store/ducks/attitudes/types";
import { RootState } from "~/store/ducks";
//#endregion


/**
 * Validação do formulário
 */

const validationAttitudeSchema = yup.object().shape({
  idAtitude: yup.string().required("Campo obrigatório"),
  // ordem: yup.string().required("Campo obrigatório"),
})

type Filter = {
  isOpen?: boolean;
  ativadas: string;
  filterStatus: boolean | null;
};

interface IAttitudesState {
  columns: IColumn[];
  items: CycloAttitudeType[];
  isDialogAttitudeOpen: boolean;
  isDialogOpen: boolean;
  inicialValues: AttitudeType;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
  filter: Filter;
  search: string;
  flagAtivo: boolean;
};

interface IPropsAttitudes {
  idCycle: number | null;
  attitudes: AttitudeType[];
  cycleAttitudes: DataTypes;
  getCycleAttitudes: (idCiclo: number) => void;
  addCycleAttitude: (idCiclo: number, idAtitude: number, ordem: number | null, nomeAtitude: string) => void;
  delCycleAttitude: (idCiclo: number, idCicloAtitude: number) => void;
}

const initialPosition: AttitudeType = {
  idAtitude: null,
  nomeAtitude: '',
  descAtitude: ''
};

class CycleAttitudes extends Component<IPropsAttitudes, IAttitudesState> {
  private formRef: any;
  private inputSearch: any;
  private _selection: Selection;

  constructor(props: IPropsAttitudes) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [

      {
        key: 'column1',
        name: 'Ordem',
        fieldName: 'ordem',
        minWidth: 30,
        maxWidth: 50,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column2',
        name: 'Nome',
        fieldName: 'nomeAtitude',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
        onRender: (item: CycloAttitudeType) => item.atitude.nomeAtitude
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isDialogOpen: false,
      isDialogAttitudeOpen: false,
      selectionDetails: "",
      inicialValues: initialPosition,
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      search: "",
      filter: {
        isOpen: false,
        ativadas: 'todos',
        filterStatus: null,
      },
      flagAtivo: false
    }

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.inputSearch = React.createRef();
  };

  // componentDidMount() {
  //     this.props.getAttitudes();
  // };

  componentDidUpdate(prevProps: IPropsAttitudes, _: any) {
    if (prevProps.idCycle !== this.props.idCycle) {
      if (this.props.idCycle) {
        this.props.getCycleAttitudes(this.props.idCycle!);
      }
    }

    if (this.state.isDialogAttitudeOpen) {
      if (prevProps.cycleAttitudes.success !== this.props.cycleAttitudes.success) {
        if (this.props.cycleAttitudes.success) {
          this.setState({ isDialogAttitudeOpen: false })
        }
      }
    }

    // if (this.props.attitudes.itemSelected.success !== prevProps.attitudes.itemSelected.success) {
    //     if (this.props.attitudes.itemSelected.success) {
    //         this.setState({ inicialValues: this.props.attitudes.itemSelected.item })
    //     }
    // }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    };
  };

  _onItemInvoked = (item: AttitudeType): void => {
    // this.props.getAttitudeById(item.idAtitude!)
    // this.setState({
    //     isPanelOpen: true,
    // });
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };

  openEdit = () => {
    // const selectItem: number = (this._selection.getSelection()[0] as AttitudeType).idAtitude!;
    // this.props.getAttitudeById(selectItem)
    // this.setState({ isPanelOpen: true });
  };

  excludeCycleAttitude = () => {
    const id = ((this._selection.getSelection()[0] as CycloAttitudeType).idCicloAtitude!);
    this.props.delCycleAttitude(this.props.idCycle!, id)
    this.setState({ isDialogExcludeOpen: false });
  };

  //#region  Funções do filtro

  toggleFilter = () => {
    this.setState({
      filter: {
        ...this.state.filter,
        isOpen: !this.state.filter.isOpen,
      }
    });
  };

  toggleParamsFilter = (param: string) => {
    const { filter, search } = this.state;

    // switch (param) {
    //   case 'todos':
    //     this.setState({ filter: { ...filter, filterStatus: null, ativadas: param } },
    //       () => this.props.getPositions(search)
    //     );
    //     break;
    //   case 'ativadas':
    //     this.setState({ filter: { ...filter, filterStatus: true, ativadas: param } },
    //       () => this.props.getPositions(search, true)
    //     );
    //     break;
    //   case 'desativas':
    //     this.setState({ filter: { ...filter, filterStatus: false, ativadas: param } },
    //       () => this.props.getPositions(search, false)
    //     );
    //     break;
    // }

    // if (this.inputSearch.current) {
    //   this.filter(this.inputSearch.current.state.value);
    // };

  };

  handleSearch = (e: any) => {
    // const search: string = e?.target.value ? e!.target.value : "";
    // this.setState({ search: search });
    // if (search === "") {
    //   this.props.getPositions("", this.state.filter.filterStatus);
    // }
  };

  filter = (key: any) => {
    const { search, filter } = this.state;

    // if (key === 'Enter') {
    //   if (filter.filterStatus !== null) {
    //     this.props.getPositions(search, filter.filterStatus);
    //   } else {
    //     this.props.getPositions(search);
    //   }
    //   if (this.inputSearch.current) {
    //     this.inputSearch.current.focus();
    //   }
    // };

    // if (search === "") {
    //   this.props.getPositions("", filter.filterStatus);
    // }
  };
  //#endregion

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          {/* <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Edit' }} text="Editar" onClick={() => this.openEdit()} /> */}
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
        </>
      );
    }
  };

  render() {
    const { isDialogAttitudeOpen, isDialogExcludeOpen, selectionCount, inicialValues, isFiltering, items, columns, filter, search } = this.state;
    const { attitudes, cycleAttitudes } = this.props;

    const dialogContentProps: IDialogContentProps = {
      title: 'Excluir?',
      closeButtonAriaLabel: 'Close',
      subText: selectionCount <= 1 ? 'Tem certeza de que deseja excluir este item?' : 'Tem certeza de que deseja excluir estes items?',
    };

    return (
      <>
        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
          <DeleteButton onClick={() => this.excludeCycleAttitude()} text="Excluir" />
        </CustomDialog>
        <Wrapper>
          {/* <Drawer
                        isOpen={filter.isOpen}
                        content={ */}
          <ContainerContent>
            <Stack horizontal horizontalAlign="space-between">
              <Stack horizontal verticalAlign="center">
                <ActionButton iconProps={{ iconName: "Add" }} styles={actionButtonStyle} onClick={() => this.setState({ isDialogAttitudeOpen: true })}>
                  Adicionar Atitude
                </ActionButton>
                {this.commandBarRender()}
              </Stack>
              <ActionButton iconProps={{ iconName: "Refresh" }} styles={actionButtonStyle} onClick={() => this.props.getCycleAttitudes(this.props.idCycle!)}>
                Atualizar
            </ActionButton>
            </Stack>
            {/* <HeaderPage
                                    title="Atitudes"
                                    leftItems={
                                        <>
                                            <CommandBarButton
                                                styles={btnStyle}
                                                iconProps={{ iconName: 'Add' }}
                                                disabled={attitudes.loadingData}
                                                text="Adicionar Atitude"
                                                onClick={() => this.setState(
                                                    {
                                                        isPanelOpen: true,
                                                        inicialValues: initialPosition
                                                    }
                                                )}
                                            />
                                            {this.commandBarRender()}
                                        </>
                                    }
                                    rightItems={
                                        <>
                                            <SearchBox
                                                placeholder="Pesquisar"
                                                value={search}
                                                onChange={(e) => this.handleSearch(e)}
                                                onKeyUp={(e) => this.filter(e.key)}
                                                componentRef={this.inputSearch}
                                                styles={{
                                                    root:
                                                        { border: 'none', width: 200, marginRight: 1 }
                                                }}
                                            />
                                            <CommandBarButton
                                                styles={btnStyle}
                                                iconProps={{ iconName: 'Refresh' }}
                                                text="Atualizar"
                                                onClick={() => getAttitudes()}
                                            />
                                        </>
                                    }
                                /> */}
            {!cycleAttitudes.loading && cycleAttitudes.data.length === 0 ?
              <NoItems
                error={cycleAttitudes.error}
                text="Não há atitudes cadastradas"
                icon="ReminderPerson"
              />
              :
              <ListContainer>
                <ShimmeredDetailsList
                  items={isFiltering ? items : cycleAttitudes.data}
                  enableShimmer={cycleAttitudes.loading}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={this._onItemInvoked}
                />
              </ListContainer>
            }
          </ContainerContent>
          {/* }>
                        <>
                            <Stack horizontal horizontalAlign="space-between">
                                <Text variant="xLarge">Filtros</Text>
                                <Stack horizontal>
                                    <IconButton
                                        iconProps={{ iconName: 'ChromeClose' }}
                                        styles={{ root: { color: colors.darkGray } }}
                                        title="Fechar"
                                        ariaLabel="Fechar"
                                        onClick={() => this.setState({ filter: { ...filter, isOpen: false } })}
                                    />
                                </Stack>
                            </Stack>
                            <RadioGroup aria-label="gender" name="gender1" style={{ marginTop: 10 }} value={filter.ativadas} onChange={(e: any) => this.toggleParamsFilter(e.target.value)}>
                                <Text variant="mediumPlus">Status</Text>
                                <FormControlLabel value="todos" control={<Radio color="primary" />} label="Todos" />
                                <FormControlLabel value="ativadas" control={<Radio color="primary" />} label="Ativadas" />
                                <FormControlLabel value="desativas" control={<Radio color="primary" />} label="Desativadas" />
                            </RadioGroup>
                        </>
                    </Drawer> */}
        </Wrapper>

        <Dialog
          title="Definir Atitude"
          open={isDialogAttitudeOpen}
          onClickCancel={() => this.setState({ isDialogAttitudeOpen: false })}
          onClickConfirm={this.handleSubmit}
          btnConfirmText="Adicionar"
        >
          <Formik
            innerRef={this.formRef}
            initialValues={{ idAtitude: "", ordem: "default" }}
            validationSchema={validationAttitudeSchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values: any) => {
              const attitude = this.props.attitudes.find(att => att.idAtitude == values.idAtitude)
              const ordem = values.ordem !== "default" ? Number(values.ordem) : null;
              this.props.addCycleAttitude(Number(this.props.idCycle!), attitude?.idAtitude!, ordem, attitude!.nomeAtitude)
              // values.pilar.nomePilar = valuePillar.nomePilar;
              // dispatch(Creators.getPillarsCycle(idCycle))
              // const pilar: PillarT = allPilares.find(pilar => pilar.idPilar === parseInt(values.pilar))!;

              // dispatch(Creators.addCyclePillar(idCycle!, pilar))
              // setIsDialogPillarsOpen(false)
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <form onSubmit={handleSubmit}>
                <Dropdown
                  errors={errors.idAtitude}
                  errorText={errors.idAtitude}
                  label="Atitude"
                  name="idAtitude"
                  values={values.idAtitude}
                  handleChange={handleChange}
                  autofocus={true}
                >
                  {
                    attitudes
                      .map((attitude, i) =>
                        <MenuItem key={i} value={`${attitude.idAtitude}`}>
                          {attitude.nomeAtitude}
                        </MenuItem>
                      )
                  }
                  {/* {
                    attitudes
                      .filter((att, i) =>
                        !cycleAttitudes.data.map(ca => ca?.idAtitude)
                          .includes(att?.idAtitude!))
                      .map((attitude, i) =>
                        <MenuItem key={i} value={`${attitude.idAtitude}`}>
                          {attitude.nomeAtitude}
                        </MenuItem>
                      )
                  } */}
                </Dropdown>
                <Dropdown
                  errors={errors.ordem}
                  errorText={errors.ordem}
                  label="Ordem"
                  name="ordem"
                  values={values.ordem}
                  handleChange={handleChange}
                >
                  {
                    cycleAttitudes.data.map((ca, i) => <MenuItem key={i} value={`${ca.ordem}`}>{ca.ordem}º</MenuItem>)
                  }
                  {/* <MenuItem value={`${cycleAttitudes.data.length + 1}`}>{cycleAttitudes.data.length + 1}º</MenuItem> */}
                  <MenuItem value={"default"}>Último</MenuItem>
                </Dropdown>
              </form>
            )}
          </Formik>
        </Dialog>
      </>
    );
  };

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: CycloAttitudeType[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.cycleAttitudes.data;
    };
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      };
    });
    const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };

  private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    return itemsSorted;
  };

}

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44,
    marginTop: 15,
  }
}


const actionButtonStyle: Partial<IButtonStyles> = {
  icon: {
    color:
      colors.primary
  },
  root: {
    marginTop: 15,
    height: 44,
    width: 'fit-content'
  }
}
const mapStateToProps = (state: RootState) => ({
  attitudes: state.attitudesReducer.data,
  cycleAttitudes: state.cycleAttitudeReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...getCycleAttitudes,
  ...addCycleAttitude,
  ...delCycleAttitude
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CycleAttitudes);
