import styled from 'styled-components';
import { calcConstrast } from "~/assets/js/colors";
import { Container as MaterialContainer } from "@material-ui/core";

export const Div = styled.div`
    width: calc(100% - 290px);
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
`;

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: row;
`;

export const Wrapper = styled.div`
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: auto;
  z-index: 1;
  padding: 0 20px 20px;
`;

export const ContainerCycle = styled.div`
    background-color: #EFF1F3;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 20px;

`

export const Title = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
`

export const DetailsWrapper = styled.div`
    margin-top: 18px;
    margin-left: 52px;
    display: flex;
    flex-direction: row;
    @media (max-width: 960px) {
        flex-direction: column;
        margin-top: 0px;
    }
`

export const Full = styled.div`
    width: 100%;
`