import { takeLatest, put, call, all } from "redux-saga/effects";
import {
  Types,
  EmployeeType,
  IRequestEmployeesLoadAction,
  IRequestEmployeesLoadIDAction,
  IRequestEmployeeAddAction,
  IRequestEmployeeEditAction,
  IRequestEmployeeEditPasswordAction,
  IRequestEmployeeEditAccessAction,
  IRequestEmployeeDeleteAction,
} from "../ducks/employees/types";
import api from "~/services/api";

const apiCallGet = (search: any) => {
  const searchFilter = search.searchText !== "" ? `?SearchText=${search.searchText}` : "?";
  const filter = search.status !== null ? `&Status=${search.status}` : "";

  return api
    .get(`/funcionarios${searchFilter}${filter}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallGetById = (id: number) => {
  return api
    .get(`/funcionarios/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPost = (item: EmployeeType) => {
  return api
    .post("/funcionarios", {
      ...item,
      idUnidade: item.unidade.idUnidade,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPut = (item: EmployeeType) => {
  return api
    .put(`/funcionarios/${item.idFuncionario}`, {
      ...item,
      idUnidade: item.unidade.idUnidade,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPutPassword = (payload: any) => {
  return api
    .put(`/funcionarios/password/${payload.id}`, {
      password: payload.password
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPutAccess = (payload: any) => {
  return api
    .put(`/funcionarios/acesso/${payload.id}`, {
      flagAcessoRHR: payload.access
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (id: number) => {
  return api
    .delete(`/funcionarios/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load(action: IRequestEmployeesLoadAction) {
  try {
    let items: EmployeeType = yield call(apiCallGet, action.search);
    yield put({ type: Types.SUCCESS_EMPLOYEES_LOAD, payload: items });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar'
    yield put({
      type: Types.FAILURE_EMPLOYEES_LOAD,
      message
    });
  }
}

function* getById(action: IRequestEmployeesLoadIDAction) {
  try {
    let item: EmployeeType = yield call(apiCallGetById, action.id);
    yield put({ type: Types.SUCCESS_EMPLOYEES_LOAD_ID, payload: item });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar'
    yield put({
      type: Types.FAILURE_EMPLOYEES_LOAD_ID,
      message
    });
  }
}

function* add(action: IRequestEmployeeAddAction) {
  try {
    const idFuncionario = yield call(apiCallPost, action.employee);
    yield put({ type: Types.SUCCESS_EMPLOYEES_ADD, employee: { ...action.employee, ...idFuncionario, flagAtiva: true } });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível adicionar funcionário'
    yield put({
      type: Types.FAILURE_EMPLOYEES_ADD,
      message
    });
  }
}

function* edit(action: IRequestEmployeeEditAction) {
  try {
    yield call(apiCallPut, action.employee);
    yield put({ type: Types.SUCCESS_EMPLOYEES_EDIT, employee: action.employee });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível editar funcionário';
    yield put({ type: Types.FAILURE_EMPLOYEES_EDIT, message });
  }
}

function* editPassword(action: IRequestEmployeeEditPasswordAction) {
  try {
    yield call(apiCallPutPassword, action.payload);
    yield put({ type: Types.SUCCESS_EMPLOYEES_EDIT_PASSWORD });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível alterar a senha do funcionário';
    yield put({ type: Types.FAILURE_EMPLOYEES_EDIT_PASSWORD, message });
  }
}

function* editAccess(action: IRequestEmployeeEditAccessAction) {
  try {
    yield call(apiCallPutAccess, action.payload);
    yield put({ type: Types.SUCCESS_EMPLOYEES_EDIT_ACCESS, access: action.payload.access });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível alterar o acesso ao RHR';
    yield put({ type: Types.FAILURE_EMPLOYEES_EDIT_ACCESS, message });
  }
}

function* del(action: IRequestEmployeeDeleteAction) {
  try {
    yield call(apiCallDel, action.id);
    yield put({ type: Types.SUCCESS_EMPLOYEES_DELETE, id: action.id });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível excluír funcionário';
    yield put({ type: Types.FAILURE_EMPLOYEES_DELETE, message });
  }
}

export default function* employeesRoot() {
  yield all([
    yield takeLatest(Types.REQUEST_EMPLOYEES_LOAD, load),
    yield takeLatest(Types.REQUEST_EMPLOYEES_LOAD_ID, getById),
    yield takeLatest(Types.REQUEST_EMPLOYEES_ADD, add),
    yield takeLatest(Types.REQUEST_EMPLOYEES_EDIT, edit),
    yield takeLatest(Types.REQUEST_EMPLOYEES_EDIT_PASSWORD, editPassword),
    yield takeLatest(Types.REQUEST_EMPLOYEES_EDIT_ACCESS, editAccess),
    yield takeLatest(Types.REQUEST_EMPLOYEES_DELETE, del),
  ]);
}
