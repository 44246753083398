import React from 'react';

import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

import {
    TitleContainer,
    WrapperCommandBar
} from './styles';

interface IProps {
    title: string;
    leftItems: JSX.Element;
    rightItems?: JSX.Element;
}

const HeaderPage: React.FC<IProps> = (props) => {
    return (
        <>
            <TitleContainer>
                <Text variant="xxLarge" styles={{ root: { marginBottom: "15px" } }}>{props.title}</Text>
            </TitleContainer>
            <WrapperCommandBar>
                <Stack horizontal horizontalAlign="space-between" styles={{ root: { margin: "5px 0" } }} >
                    <Stack horizontal>
                        {props.leftItems}
                    </Stack>
                    <Stack horizontal verticalAlign="center">
                        {props.rightItems}
                    </Stack>
                </Stack>
            </WrapperCommandBar>
        </>
    );
}

export default HeaderPage;