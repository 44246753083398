import styled from 'styled-components';

export const DetailsWrapperItem = styled.div<{spaceBetween?: number, noTop?: boolean}>`
    display: flex;
    flex-direction: row;
    margin-right: ${({spaceBetween}) => spaceBetween ? spaceBetween : 96}px;
    margin-top: 15px;
    @media (max-width: 960px) {
    }
`

export const DetailsWrapperText = styled.div`
    display: flex;
    flex-direction: column;
    width: max-content;
`
