import api from "~/services/api";
import { takeLatest, put, call, all } from "redux-saga/effects";
import {
    Types,
    IRequestTasksLoadAction,
    IRequestTasksLoadByIdAction,
    ISuccessTasksLoadAction,
    ISuccessTasksLoadByIdAction
} from "../ducks/tasks/types";

const apiCallGet = (payload: IRequestTasksLoadAction["payload"]) => {
    const { searchText, filterStatus } = payload;

    const searchFilter = searchText !== "" ? `?SearchText=${searchText}`: "?";
    const filter = filterStatus ? `&FilterStatus=${filterStatus}`: "";

    // return api.get(`/tarefas${searchFilter}`)
    return api.get(`/tarefas${searchFilter}${filter}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallGetById = (idTarefa: number) => {
    return api.get(`/tarefas/${idTarefa}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};


function* load(action: IRequestTasksLoadAction) {
    try {
        const items = yield call(apiCallGet, action.payload);
        yield put({
            type: Types.SUCCESS_TASKS_LOAD,
            payload: items
        } as ISuccessTasksLoadAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar as tarefas.';
        yield put({ type: Types.FAILURE_TASKS_LOAD, message });
    };
};

function* loadById(action: IRequestTasksLoadByIdAction) {
    try {
        const item = yield call(apiCallGetById, action.idTarefa);
        yield put({
            type: Types.SUCCESS_TASKS_LOAD_ID,
            payload: item
        } as ISuccessTasksLoadByIdAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar a tarefa.';
        yield put({ type: Types.FAILURE_TASKS_LOAD, message });
    };
};


export default function* positionsRoot() {
    yield all([
        yield takeLatest(Types.REQUEST_TASKS_LOAD, load),
        yield takeLatest(Types.REQUEST_TASKS_LOAD_ID, loadById),
    ]);
};