import { Types, DataTypes, PositionTypes, PositionActionTypes } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    data: [],
    loadingData: false,
    loadingAction: false,
    error: false,
    success: false
};


/**
 * Actions
 */

export const Creators = {
    getPositions: (search?: string, filter?: boolean | null): PositionActionTypes => {
        const pesquisa = search !== "" && search !== undefined ? search : "";
        const filtro = filter !== null && filter !== undefined ? filter : null;
        return ({
            type: Types.REQUEST_POSITIONS_LOAD,
            search: {
                searchText: pesquisa,
                filterAtivo: filtro
            }
        })
    },

    addPosition: (position: PositionTypes): PositionActionTypes => ({
        type: Types.REQUEST_POSITION_ADD,
        position
    }),

    editPosition: (position: PositionTypes): PositionActionTypes => ({
        type: Types.REQUEST_POSITION_EDIT,
        position
    }),

    delPosition: (id: number): PositionActionTypes => ({
        type: Types.REQUEST_POSITION_DELETE,
        id
    })
};

export default function positionsReducer(state = INITIAL_STATE, action: PositionActionTypes): DataTypes {
    switch (action.type) {

        case Types.REQUEST_POSITIONS_LOAD:
            return { ...state, data: [], loadingData: true, error: false, success: false };
        case Types.SUCCESS_POSITIONS_LOAD:
            return { ...state, data: action.payload, loadingData: false, error: false, success: true };
        case Types.FAILURE_POSITIONS_LOAD:
            customToast.error(action.message);
            return { ...state, data: [], loadingData: false, error: true, success: false };

        case Types.REQUEST_POSITION_ADD:
            return { ...state, error: false, success: false, loadingAction: true };
        case Types.SUCCESS_POSITION_ADD:
            customToast.success("Cargo cadastrado com sucesso");
            console.log(action.position)
            return { ...state, error: false, success: true, data: [...state.data, action.position], loadingAction: false };
        case Types.FAILURE_POSITION_ADD:
            customToast.error(action.message);
            return { ...state, error: true, success: false, loadingAction: false };

        case Types.REQUEST_POSITION_EDIT:
            return { ...state, error: false, success: false, loadingAction: true };
        case Types.SUCCESS_POSITION_EDIT:
            customToast.success("Cargo editado com sucesso");
            return {
                ...state,
                error: false,
                success: true,
                loadingAction: false,
                data: state.data.map(item => {
                    if (item.idCargo !== action.position.idCargo) {
                        return item;
                    } else {
                        return action.position;
                    }
                })
            };
        case Types.FAILURE_POSITION_EDIT:
            customToast.error(action.message);
            return { ...state, error: true, success: false, loadingAction: false };

        case Types.REQUEST_POSITION_DELETE:
            return { ...state, error: false, success: false, loadingData: true };
        case Types.SUCCESS_POSITION_DELETE:
            customToast.success("Item excluído com sucesso");
            return { ...state, error: false, success: true, data: state.data.filter(item => item.idCargo !== action.id), loadingData: false };
        case Types.FAILURE_POSITION_DELETE:
            customToast.error(action.message);
            return { ...state, error: true, success: false, loadingData: false };

        default:
            return state;
    };
};