/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_POSITIONS_LOAD = "positions/REQUEST_POSITIONS_LOAD",
    SUCCESS_POSITIONS_LOAD = "positions/SUCCESS_POSITIONS_LOAD",
    FAILURE_POSITIONS_LOAD = "positions/FAILURE_POSITIONS_LOAD",
    //ADD
    REQUEST_POSITION_ADD = "positions/REQUEST_POSITION_ADD",
    SUCCESS_POSITION_ADD = "positions/SUCCESS_POSITION_ADD",
    FAILURE_POSITION_ADD = "positions/FAILURE_POSITION_ADD",
    //PUT
    REQUEST_POSITION_EDIT = "positions/REQUEST_POSITION_EDIT",
    SUCCESS_POSITION_EDIT = "positions/SUCCESS_POSITION_EDIT",
    FAILURE_POSITION_EDIT = "positions/FAILURE_POSITION_EDIT",
    //DELETE
    REQUEST_POSITION_DELETE = "positions/REQUEST_POSITION_DELETE",
    SUCCESS_POSITION_DELETE = "positions/SUCCESS_POSITION_DELETE",
    FAILURE_POSITION_DELETE = "positions/FAILURE_POSITION_DELETE",
};
/**
 * Data types
 */
export interface PositionTypes {
    idCargo: number | null;
    codCargo: string;
    titulo: string;
    cbo: string;
    dataExtinto: Date | null;
    flagAtivo: boolean;
};

interface IPositionsState {
    readonly data: PositionTypes[];
    readonly loadingData: boolean;
    readonly loadingAction: boolean;
    readonly error: boolean;
    readonly success: boolean;
};

export type DataTypes = IPositionsState;

/**
 * Actions
 */

//GET
export interface IRequestPositionsLoadAction {
    type: typeof Types.REQUEST_POSITIONS_LOAD;
    search: object;
};

export interface ISuccessPositionsLoadAction {
    type: typeof Types.SUCCESS_POSITIONS_LOAD;
    payload: PositionTypes[];
};

interface IFailurePositionsLoadAction {
    type: typeof Types.FAILURE_POSITIONS_LOAD;
    message: string;
};

type PositionsLoadAction = IRequestPositionsLoadAction | ISuccessPositionsLoadAction | IFailurePositionsLoadAction;

//ADD
export interface IRequestPositionAddAction {
    type: typeof Types.REQUEST_POSITION_ADD;
    position: PositionTypes;
};

interface ISuccessPositionAddAction {
    type: typeof Types.SUCCESS_POSITION_ADD;
    position: PositionTypes;
};

interface IFailurePositionAddAction {
    type: typeof Types.FAILURE_POSITION_ADD;
    message: string;
};

type PositionAddAction = IRequestPositionAddAction | ISuccessPositionAddAction | IFailurePositionAddAction;

//PUT
export interface IRequestPositionEditAction {
    type: typeof Types.REQUEST_POSITION_EDIT;
    position: PositionTypes;
};

export interface ISuccessPositionEditAction {
    type: typeof Types.SUCCESS_POSITION_EDIT;
    position: PositionTypes;
};

export interface IFailurePositionEditAction {
    type: typeof Types.FAILURE_POSITION_EDIT;
    message: string;
};

type PositionEditAction = IRequestPositionEditAction | ISuccessPositionEditAction | IFailurePositionEditAction;


//DELETE
export interface IRequestPositionDeleteAction {
    type: typeof Types.REQUEST_POSITION_DELETE;
    id: number;
};

export interface ISuccessPositionDeleteAction {
    type: typeof Types.SUCCESS_POSITION_DELETE;
    id: number;
};

export interface IFailurePositionDeleteAction {
    type: typeof Types.FAILURE_POSITION_DELETE;
    message: string;
};

type PositionDeleteAction = IRequestPositionDeleteAction | ISuccessPositionDeleteAction | IFailurePositionDeleteAction;


export type PositionActionTypes = PositionsLoadAction | PositionAddAction | PositionEditAction | PositionDeleteAction;