import { Types, DataTypes, TaskActionTypes, TaskType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    data: [],
    selected: null,
    loadingData: false,
    loadingAction: false,
    error: false,
    success: false
};


/**
 * Actions
 */

export const Creators = {
    getTasks: (search: string = "", filter?: string[]): TaskActionTypes => {
        return ({
            type: Types.REQUEST_TASKS_LOAD,
            payload: {
                searchText: search,
                filterStatus: filter
            }
        })
    },

    getTasksById: (idTarefa: number): TaskActionTypes => {
        return ({
            type: Types.REQUEST_TASKS_LOAD_ID,
            idTarefa
        })
    },
};


export default function positionsReducer(state = INITIAL_STATE, action: TaskActionTypes): DataTypes {
    switch (action.type) {

        case Types.REQUEST_TASKS_LOAD:
            return { ...state, data: [], loadingData: true, error: false };
        case Types.SUCCESS_TASKS_LOAD:
            return { ...state, data: action.payload, loadingData: false, error: false };
        case Types.FAILURE_TASKS_LOAD:
            customToast.error(action.message);
            return { ...state, data: [], loadingData: false, error: true };

        case Types.REQUEST_TASKS_LOAD_ID:
            return { ...state, success: false, error: false }
        case Types.SUCCESS_TASKS_LOAD_ID:
            return { ...state, selected: action.payload, success: true, error: false }
        case Types.FAILURE_TASKS_LOAD_ID:
            customToast.error(action.message);
            return { ...state, success: false, error: true }
        default:
            return state;
    }
}

