import styled from 'styled-components';
import colors from "~/assets/js/colors";
import { IconButton } from 'office-ui-fabric-react';

export const Container = styled.div<{ isLoading: boolean }>`
  padding: 0px 20px;
`;



export const IconButtonDelete = styled(IconButton)`
  visibility: hidden;
  width: 0px;
  transition: width 0.3s;
  &:hover{
    opacity: 0.8;
    background-color: rgba(0,0,0,0.2);
  }
`

export const HoverPillar = styled.div`
  opacity: 0;
`
export const Pillar = styled.div<{ color: string, colorText: string }>`
    text-align: center;
    width: 195px;
    min-height: 70px;
    display: flex;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 5px;
    overflow: hidden;
    margin-top: 10px;
    color: ${props => props.colorText};
    background-color: ${props => props.color};
    border: 1px solid ${props => props.color};
    &:hover{
      ${IconButtonDelete}{
        visibility: visible;
        width: 32px;
        margin-left: 5px;
        transition: width 0.3s;
        padding-right: 5px;
        display: flex;
      }

      ${HoverPillar}{
        opacity: 1;
        transition: opacity 0.3s;
      }
    }
`
export const ContainerPillarItem = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5px 0px 15px;
    padding-right: 20px;
`;

export const ContainerPillar = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

 ${ContainerPillarItem}:first-child{
  ${Pillar} {
      i[data-icon-name="DecreaseIndentArrow"]{
      display: none;
      }
    }
  }
 
 ${ContainerPillarItem}:last-child{
  ${Pillar} {
      i[data-icon-name="IncreaseIndentArrow"]{
      display: none;
      }
    }
  }
`;

export const PillarOutline = styled.div<{ color: string }>`
    text-align: center;
    margin-top: 10px;
    width: 195px;
    min-height: 64px;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 5px;
    background-color: ${colors.white};
    border: 1px solid ${props => props.color};
    display: flex;
    &:hover{
      ${IconButtonDelete}{
        visibility: visible;
        width: 32px;
        transition: width 0.2s;
      }
    }
`