//#region Imports
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Creators } from '~/store/ducks/home';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { CommandBarButton, IconButton, ICommandBarStyles, IButtonStyles, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { IDialogContentProps } from 'office-ui-fabric-react/lib/Dialog';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';

import {
  PrimaryButton,
  DefaultButton,
  Wrapper,
  ContainerContent,
  WrapperTreeview,
} from "./styles";

import MenuItem from '@material-ui/core/MenuItem';

import CustomDialog from "~/components/CustomDialogFluentUI";
import Panel from "~/components/layout/Panel";
import Dropdown from "~/components/layout/Dropdown";
import { InputText, InputCheckbox } from '~/components/Forms';
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
import TreeView from '~/components/TreeView';
import { DeleteButton } from '~/components/Buttons';

import { Page } from "~/store/ducks/home/types";
import { RootState } from '~/store/ducks';
import { ChartType, DataTypes } from "~/store/ducks/chart/types";
import { Creators as CreatorCharts } from "~/store/ducks/chart";

import colors from "~/assets/js/colors";

const validationSchema = yup.object().shape({
  nomeAreaPai: yup.number().nullable(),
  codArea: yup.string()
    .max(30, "O código do local não pode ter mais do que 30 caracteres")
    .required("Campo obrigatório"),
  nomeLocal: yup.string()
    .min(2, "A descrição do objetivo deve conter pelo menos 2 caracteres")
    .max(60, "A descrição do objetivo não pode ter mais do que 60 caracteres")
    .required("Campo obrigatório"),
  nivel: yup.string().required("Campo obrigatório"),
  apelido: yup.string().nullable().max(4, "O apelido não pode ter mais do que 4 caracteres").required("Campo obrigatório"),
  tipoLocal: yup.string().required("Campo obrigatório")
});

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44
  }
};

const btnPanelStyles: Partial<IButtonStyles> = {
  root: {
    color: colors.darkGray
  }
};

let initial: ChartType = {
  idArea: null,
  codArea: "",
  nomeLocal: "",
  nivel: "",
  apelido: "",
  areaPai: null,
  idAreaPai: null,
  tipoLocal: "",
  children: [],
  flagAtiva: true
};

const Chart: React.FC = () => {
  const dispatch = useDispatch();

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [isDialogClosePanel, setIsDialogClosePanel] = useState(false);

  const [idItemSelected, setIdItemSelected] = useState<number | null>(null);
  const [isAddingChild, setIsAddingChild] = useState<boolean>(false);
  const [isDialogExcludeOpen, setIsDialogExcludeOpen] = useState(false);
  const [isToggleActive, setIsToggleActive] = useState(false);

  const chartState = useSelector<RootState, DataTypes>(state => state.chartReducer);

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    resetForm,
    setValues,
    setFieldError,
  } = useFormik({
    initialValues: initial,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit(values) {
      if (!values.idArea) {
        dispatch(CreatorCharts.addChart(values));
      } else {
        dispatch(CreatorCharts.editChart({ ...values, flagAtiva: !values.flagAtiva }));
      };
      setIsAddingChild(false);
    }
  });

  useEffect(() => {
    const page: Page = {
      key: 'organograma',
      pages: [
        { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
        { text: 'Organograma', isCurrentItem: true }]
    };

    dispatch(CreatorCharts.getChart("", true));
    dispatch(Creators.setCurrentPage(page));
  }, [dispatch]);

  useEffect(() => {
    if (chartState.itemSelected.success) {
      if (isAddingChild) {
        const { nomeLocal, idArea } = chartState.itemSelected.item;
        initial.areaPai = { nomeLocal };
        initial.idAreaPai = idArea;
        setValues(initial);
      } else {
        setValues({ ...chartState.itemSelected.item, flagAtiva: !chartState.itemSelected.item.flagAtiva });
      };
    };
  }, [chartState.itemSelected, setValues, isAddingChild]);

  useEffect(() => {
    if (chartState.success) {
      setIsPanelOpen(false);
    };
  }, [chartState.success]);

  const handleClosePanel = () => {
    setIsPanelOpen(false);
    setTimeout(() => {
      resetForm();
    }, 500);
  };

  const excludeCompanies = () => {
    setIsDialogExcludeOpen(false);
    setIsPanelOpen(false);
    dispatch(CreatorCharts.delChart(idItemSelected!));
    setIdItemSelected(null);
  };

  const cancelPanel = () => {
    setIsAddingChild(false);
    setIsPanelOpen(false);
  };

  const openEdit = () => {
    setIsPanelOpen(true);
    setIsAddingChild(false);
    dispatch(CreatorCharts.getChartById(idItemSelected!));
  };

  const handleOnItemInvoked = () => {
    openEdit();
  };

  const handleToggle = () => {
    setIsToggleActive(!isToggleActive);
    if (isToggleActive) {
      dispatch(CreatorCharts.getChart("", true));
    } else {
      dispatch(CreatorCharts.getChart());
    };
  };

  const commandBarRender = () => {
    if (idItemSelected !== null) {
      return (
        <>
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Edit' }} text="Editar" onClick={() => openEdit()} />
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'RowsChild' }} text="Adicionar subitem" onClick={() => {
            setIsPanelOpen(true);
            setIsAddingChild(true);
            dispatch(CreatorCharts.getChartById(idItemSelected!));
          }} />
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => setIsDialogExcludeOpen(true)} />
        </>
      );
    };
  };

  const dialogProps: IDialogContentProps = {
    title: !values.idArea ? "Cancelar cadastro?" : "Cancelar edição?",
    subText: !values.idArea ?
      "Todos os dados inseridos serão perdidos. Tem certeza que quer cancelar o cadastro da área? "
      :
      "As mudanças serão descardas. Tem certeza que quer cancelar a edição da área?",
  };


  const dialogContentProps = {
    title: 'Excluir?',
    closeButtonAriaLabel: 'Close',
    subText: 0 <= 1 ? 'Tem certeza de que deseja excluir este item?' : 'Tem certeza de que deseja excluir estes items?',
  };

  return (
    <>
      <Panel
        title={!values.idArea ? "Nova Área" : chartState.itemSelected.item.nomeLocal}
        loading={chartState.itemSelected.loading}
        open={isPanelOpen}
        onClose={() => cancelPanel}
        hideClose={true}
        itemsHeader={
          <IconButton
            iconProps={{ iconName: 'ChromeClose' }}
            styles={btnPanelStyles}
            title="Fechar"
            ariaLabel="Fechar"
            onClick={() => setIsPanelOpen(false)}
          />
        }
        footer={
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <DefaultButton onClick={() => cancelPanel()}>Cancelar</DefaultButton>
            {
              !chartState.loadingAction ?
                <PrimaryButton onClick={(e: any) => handleSubmit(e)}>Salvar</PrimaryButton>
                :
                <Spinner styles={{ root: { width: 110 } }} size={SpinnerSize.small} />
            }
          </Stack>
        }
      >
        {
          chartState.itemSelected.loading ?
            <Spinner size={SpinnerSize.large} styles={{ root: { height: 'calc(100% - 50px)' } }} />
            :
            <form onSubmit={handleSubmit}>
              {
                values.areaPai !== null &&
                <InputText
                  value={values.areaPai?.nomeLocal ?? ""}
                  id="nomeAreaPai"
                  name="nomeAreaPai"
                  type="text"
                  label="Área Pai"
                  disabled
                  className="mt-2"
                />
              }
              <InputText
                value={values.codArea}
                onChange={(e: any) => { handleChange(e); setFieldError("codArea", "") }}
                id="codArea"
                error={errors.codArea ? true : false}
                name="codArea"
                type="text"
                label="Código da área"
                helperText={errors.codArea}
                className="mt-2"
                inputProps={{ maxLength: 30 }}
                autoFocus
              />
              <InputText
                value={values.nomeLocal}
                onChange={(e: any) => { handleChange(e); setFieldError("nomeLocal", "") }}
                id="nomeLocal"
                error={errors.nomeLocal ? true : false}
                name="nomeLocal"
                type="text"
                label="Nome da área"
                helperText={errors.nomeLocal}
                inputProps={{ maxLength: 60 }}
                className="mt-2"
              />
              <InputText
                value={values.apelido ?? ""}
                onChange={(e: any) => { handleChange(e); setFieldError("apelido", "") }}
                id="apelido"
                error={errors.apelido ? true : false}
                name="apelido"
                type="text"
                label="Apelido"
                helperText={errors.apelido}
                inputProps={{ maxLength: 4 }}
                className="mt-2"
              />
              <Dropdown
                errors={errors.nivel}
                label="Nível do objetivo"
                name="nivel"
                values={values.nivel}
                handleChange={(e: any) => { handleChange(e); setFieldError("nivel", "") }}
                errorText={errors.nivel}
              >
                <MenuItem value={"T"}>Tático</MenuItem>
                <MenuItem value={"E"}>Estratégico</MenuItem>
                <MenuItem value={"O"}>Operacional</MenuItem>
                <MenuItem value={"C"}>Coletivo</MenuItem>
              </Dropdown>
              <Dropdown
                errors={errors.tipoLocal}
                label="Nível do local"
                name="tipoLocal"
                values={values.tipoLocal}
                handleChange={(e: any) => { handleChange(e); setFieldError("tipoLocal", "") }}
                errorText={errors.tipoLocal}
              >
                <MenuItem value={"P"}>Presidência</MenuItem>
                <MenuItem value={"V"}>Vice Presidência</MenuItem>
                <MenuItem value={"D"}>Diretoria</MenuItem>
                <MenuItem value={"G"}>Gerência</MenuItem>
                <MenuItem value={"L"}>Local</MenuItem>
              </Dropdown>
              {
                values.idArea &&
                <InputCheckbox
                  checked={values.flagAtiva}
                  onChange={handleChange}
                  name="flagAtiva"
                  color="primary"
                  label="Inativar Área"
                />
              }
            </form>
        }

      </Panel>

      <CustomDialog
        hidden={!isDialogExcludeOpen}
        onDismiss={() => setIsDialogExcludeOpen(false)}
        dialogContentProps={dialogContentProps}
      >
        <DefaultButton onClick={() => setIsDialogExcludeOpen(false)} text="Cancelar" />
        <DeleteButton onClick={excludeCompanies} text="Excluir" />
      </CustomDialog>

      <CustomDialog
        hidden={!isDialogClosePanel}
        onDismiss={() => setIsDialogClosePanel(false)}
        dialogContentProps={dialogProps}
      >
        <DefaultButton onClick={() => setIsDialogClosePanel(false)} text="Cancelar" />
        <PrimaryButton onClick={() => { setIsDialogClosePanel(false); handleClosePanel(); }} text="Fechar" />
      </CustomDialog>

      <Wrapper>
        <ContainerContent>
          <HeaderPage
            title="Organograma"
            leftItems={
              <>
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: 'Add' }}
                  text="Adicionar Área"
                  onClick={() => {
                    setValues({ ...initial, areaPai: null })
                    setIsPanelOpen(true);
                  }}
                  disabled={chartState.loadingData}
                />
                {commandBarRender()}
              </>
            }
            rightItems={
              <Stack horizontal verticalAlign="center">
                <Toggle checked={isToggleActive} onText="Todos items" offText="Items ativos" onChange={handleToggle} styles={{ root: { marginBottom: 0 } }} />
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: 'Refresh' }}
                  text="Atualizar"
                  onClick={() => dispatch(CreatorCharts.getChart("", !isToggleActive))}
                />
              </Stack>
            }
          />
          <WrapperTreeview>
            {
              chartState.data.length === 0 && !chartState.loadingData ?
                <NoItems
                  error={chartState.error}
                  text="Não há áreas cadastradas"
                  img="/static/icons/supermarket.svg"
                  alt="Locais"
                />
                :
                <TreeView
                  columns={[
                    {
                      name: 'Área',
                      fieldName: 'nomeLocal',
                    },
                    {
                      name: 'Nível',
                      fieldName: 'tipoLocal',
                      onRender: convertTipo
                    },
                    {
                      name: 'Tipo',
                      fieldName: 'nivel',
                      onRender: convertNivel
                    }
                  ]}
                  status={{
                    hasStatus: true,
                    fieldName: 'flagAtiva'
                  }}
                  state={chartState}
                  fieldId="idArea"
                  idItemSelected={idItemSelected}
                  handleIdItemSelected={id => setIdItemSelected(id)}
                  handleOnItemInvoked={handleOnItemInvoked}
                />
            }
          </WrapperTreeview>
        </ContainerContent>
      </Wrapper>
    </>
  );
};

const convertNivel = (nivel: string): string => {
  switch (nivel.toUpperCase()) {
    case 'E':
      return "Estratégico";
    case 'T':
      return "Tático";
    case 'O':
      return "Operacional";
    case 'C':
      return "Coletivo";
    default:
      return "";
  };
};

const convertTipo = (tipo: string): string => {
  switch (tipo.toUpperCase()) {
    case 'P':
      return "Presidência";
    case 'V':
      return "Vice Presidência";
    case 'D':
      return "Diretoria";
    case 'G':
      return "Gerência";
    case 'L':
      return "Local";
    default:
      return "";
  };
};

export default Chart;
