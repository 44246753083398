import React from 'react';
import { Dialog, DialogFooter, IDialogProps } from 'office-ui-fabric-react/lib/Dialog';

interface ICustomDialogProps {
    zIndex?: number;
}

const CustomDialog: React.FC<IDialogProps> = (props) => {

    return (
        <Dialog
            {...props}
            minWidth={420}
            styles={{ main: { minHeight: 165 } }}
        >
            <DialogFooter>
                {props.children}
            </DialogFooter>
        </Dialog>
    );
}

export const DialogZ: React.FC<IDialogProps & ICustomDialogProps> = (props) => {

    return (
        <Dialog
            {...props}
            minWidth={420}
            modalProps={{ styles: { layer: { zIndex: 1000 } } }}
        >
            {props.children}
        </Dialog>
    );
}

export default CustomDialog;