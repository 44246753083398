import styled from 'styled-components';
import colors from '~/assets/js/colors';

export const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 5px;
    height: 50px;
    border-left: 3px solid ${colors.primary};
    margin-top: 5px;
    &:hover{
        background-color: #FAFAFA;
        box-shadow: 0px 1px 2px rgba(0,0,0,0.5);
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
`

export const WrapperDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  height: 100%;
  width: 50px;
  padding-left: 5px;
`;

export const WrapperIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center ;
  justify-content: center;
  height: 100%;
  width: 50px;
  margin-right: 5px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  height: 100%;
  width: 195px;
`;
