// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from "formik";
import * as yup from 'yup';

//FluentUI
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBarButton, IconButton, ICommandBarStyles } from 'office-ui-fabric-react';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { IDialogContentProps } from 'office-ui-fabric-react/lib/Dialog';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
//MaterialUI
// import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
//Componentes
import Panel from "~/components/layout/Panel";
import Status from "~/components/Status";
import CustomDialog from "~/components/CustomDialogFluentUI";
// import Dropdown from "~/components/layout/Dropdown"
import colors from "~/assets/js/colors";
import { CustomDrawer as Drawer } from "~/components/layout/Drawer";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { InputText, InputCheckbox, InputDate } from '~/components/Forms';

import { DeleteButton } from '~/components/Buttons';
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
//Estilos
import {
  Wrapper,
  ListContainer,
  PrimaryButton,
  DefaultButton,
  ContainerContent,
} from "./styles";

import { Creators as getPositions } from "~/store/ducks/positions";
import { Creators as addPosition } from "~/store/ducks/positions";
import { Creators as editPosition } from "~/store/ducks/positions";
import { Creators as delPosition } from "~/store/ducks/positions";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { PositionTypes, DataTypes } from "~/store/ducks/positions/types";
import { RootState } from "~/store/ducks";
//#endregion

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44
  }
}

/**
 * Validação do formulário
 */
const validationPositionSchema = yup.object().shape({
  codCargo: yup.string()
    .min(2, "O código do cargo deve conter pelo menos 2 caracteres")
    .max(5, "O código da cargo não pode ter mais do que 5 caracteres")
    .required("Campo obrigatório"),
  titulo:
    yup.string()
      .min(3, "O titulo do cargo deve conter pelo menos 3 caracteres")
      .max(80, "O titulo do cargo não pode ter mais do que 80 caracteres")
      .required("Campo obrigatório"),
  cbo:
    yup.string()
      .max(6, "O cbo do cargo não pode ter mais do que 80 caracteres")
      .required("Campo obrigatório"),
  dataExtinto:
    yup.date()
      .nullable()
      .when("flagAtivo", {
        is: true,
        then: yup.date()
          .typeError('Data de nascimento inválida')
          .required("Campo obrigatório")
      }),
  flagAtivo:
    yup.bool()

});

type Filter = {
  isOpen?: boolean;
  ativadas: string;
  filterStatus: boolean | null;
};

interface IPositionsState {
  columns: IColumn[];
  items: PositionTypes[];
  isPanelOpen: boolean;
  isDialogOpen: boolean;
  inicialValues: PositionTypes;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
  filter: Filter;
  search: string;
  flagAtivo: boolean;
};

interface IPropsPosition {
  positions: DataTypes;
  setCurrentPage: (page: Page) => void;
  getPositions: (search?: string, filter?: boolean | null) => void;
  addPosition: (position: PositionTypes) => void;
  editPosition: (position: PositionTypes) => void;
  delPosition: (id: number) => void;
}

const itemsBreadCrumb: BreadcrumbItems[] = [
  { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
  { text: "Cargos", isCurrentItem: true },
];

const initialPosition: PositionTypes = {
  idCargo: null,
  codCargo: "",
  titulo: "",
  cbo: "",
  dataExtinto: null,
  flagAtivo: true
};

class Positions extends Component<IPropsPosition, IPositionsState> {
  private formRef: any;
  private inputSearch: any;
  private _selection: Selection;

  constructor(props: IPropsPosition) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: 'column1',
        name: '',
        ariaLabel: 'Status do cargo',
        fieldName: 'flagAtivo',
        minWidth: 15,
        maxWidth: 20,
        isResizable: true,
        isSortedDescending: false,
        onRender: (item: PositionTypes) => <Status status={item.flagAtivo!} />
      },
      {
        key: 'column2',
        name: 'Código',
        ariaLabel: 'Código do cargo',
        fieldName: 'codCargo',
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick
      },
      {
        key: 'column3',
        name: 'Título',
        fieldName: 'titulo',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      }
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isDialogOpen: false,
      isPanelOpen: false,
      selectionDetails: "",
      inicialValues: initialPosition,
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      search: "",
      filter: {
        isOpen: false,
        ativadas: 'todos',
        filterStatus: null,
      },
      flagAtivo: false
    }

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.inputSearch = React.createRef();
  };

  componentDidMount() {
    const page: Page = {
      key: 'cargos',
      pages: itemsBreadCrumb
    };
    this.props.setCurrentPage(page);
    this.props.getPositions();
  };

  componentDidUpdate(prevProps: any, _: any) {
    if (this.state.isPanelOpen) {
      if (prevProps.positions.success !== this.props.positions.success) {
        if (this.props.positions.success) {
          this.setState({ isPanelOpen: false })
        }
      }
    }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    };
  };

  _onItemInvoked = (item: PositionTypes): void => {
    this.setState({
      isPanelOpen: true,
      inicialValues: {
        ...item
      }
    });
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };

  openEdit = () => {
    const selectItem: PositionTypes = (this._selection.getSelection()[0] as PositionTypes)
    this.setState({ isPanelOpen: true, inicialValues: selectItem });
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  excludePosition = () => {
    this.props.delPosition((this._selection.getSelection()[0] as PositionTypes).idCargo!);
    this.setState({ isDialogExcludeOpen: false });
  };

  //#region  Funções do filtro

  toggleFilter = () => {
    this.setState({
      filter: {
        ...this.state.filter,
        isOpen: !this.state.filter.isOpen,
      }
    });
  };

  toggleParamsFilter = (param: string) => {
    const { filter, search } = this.state;

    switch (param) {
      case 'todos':
        this.setState({ filter: { ...filter, filterStatus: null, ativadas: param } },
          () => this.props.getPositions(search)
        );
        break;
      case 'ativadas':
        this.setState({ filter: { ...filter, filterStatus: true, ativadas: param } },
          () => this.props.getPositions(search, true)
        );
        break;
      case 'desativas':
        this.setState({ filter: { ...filter, filterStatus: false, ativadas: param } },
          () => this.props.getPositions(search, false)
        );
        break;
    }

    if (this.inputSearch.current) {
      this.filter(this.inputSearch.current.state.value);
    };

  };

  handleSearch = (e: any) => {
    const search: string = e?.target.value ? e!.target.value : "";
    this.setState({ search: search });
    if (search === "") {
      this.props.getPositions("", this.state.filter.filterStatus);
    }
  };

  filter = (key: any) => {
    const { search, filter } = this.state;

    if (key === 'Enter') {
      if (filter.filterStatus !== null) {
        this.props.getPositions(search, filter.filterStatus);
      } else {
        this.props.getPositions(search);
      }
      if (this.inputSearch.current) {
        this.inputSearch.current.focus();
      }
    };

    if (search === "") {
      this.props.getPositions("", filter.filterStatus);
    }
  };
  //#endregion

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Edit' }} text="Editar" onClick={() => this.openEdit()} />
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
        </>
      );
    } else if (selectionCount > 1) {
      return <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
    };
  };

  render() {
    const { isPanelOpen, isDialogExcludeOpen, selectionCount, inicialValues, isFiltering, items, columns, filter, search } = this.state;
    const { positions, getPositions, addPosition, editPosition } = this.props;

    const dialogContentProps: IDialogContentProps = {
      title: 'Excluir?',
      closeButtonAriaLabel: 'Close',
      subText: selectionCount <= 1 ? 'Tem certeza de que deseja excluir este item?' : 'Tem certeza de que deseja excluir estes items?',
    };

    return (
      <>
        <Panel
          title={!inicialValues.idCargo ? "Novo cargo" : inicialValues.titulo}
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>Cancelar</DefaultButton>
              {
                !positions.loadingAction ?
                  <PrimaryButton onClick={this.handleSubmit}>Salvar</PrimaryButton>
                  :
                  <Spinner styles={{ root: { width: 110 } }} size={SpinnerSize.small} />
              }
            </Stack>
          }
        >
          <Formik
            innerRef={this.formRef}
            initialValues={{ ...inicialValues, flagAtivo: !inicialValues.flagAtivo }}
            validationSchema={validationPositionSchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values: PositionTypes) => {
              if (inicialValues.idCargo === null) {
                addPosition(values);
              } else {
                values.idCargo = inicialValues.idCargo;
                if (!values.flagAtivo) {
                  values.dataExtinto = null;
                };
                editPosition({ ...values, flagAtivo: !values.flagAtivo });
              };

            }}
          >
            {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <InputText
                  value={values.codCargo}
                  onChange={(e) => { handleChange(e); setFieldError("codCargo", "") }}
                  id="codCargo"
                  error={errors.codCargo ? true : false}
                  name="codCargo"
                  type="text"
                  label="Código"
                  helperText={errors.codCargo}
                  inputProps={{ maxLength: 5 }}
                  className="mt-2"
                  autoFocus
                />
                <InputText
                  value={values.titulo}
                  onChange={(e) => { handleChange(e); setFieldError("titulo", "") }}
                  id="titulo"
                  error={errors.titulo ? true : false}
                  name="titulo"
                  type="text"
                  label="Título"
                  helperText={errors.titulo}
                  inputProps={{ maxLength: 80 }}
                  className="mt-2"
                />
                <InputText
                  value={values.cbo}
                  onChange={(e) => { handleChange(e); setFieldError("cbo", "") }}
                  id="cbo"
                  error={errors.cbo ? true : false}
                  name="cbo"
                  type="text"
                  label="CBO"
                  helperText={errors.cbo}
                  inputProps={{ maxLength: 6 }}
                  className="mt-2"
                />

                {
                  inicialValues.idCargo &&
                  <>
                    <InputCheckbox
                      checked={values.flagAtivo}
                      onChange={handleChange}
                      name="flagAtivo"
                      color="primary"
                      label="Inativar Cargo"
                    />
                    {
                      values.flagAtivo &&
                      <InputDate
                        value={values.dataExtinto}
                        onChange={date => setFieldValue("dataExtinto", date)}
                        id="dataExtinto"
                        error={errors.dataExtinto ? true : false}
                        name="dataExtinto"
                        label="Data de extinção"
                        className="mt-2"
                        helperText={errors.dataExtinto}
                      />
                    }

                  </>
                }
              </form>
            )}
          </Formik>
        </Panel>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
          <DeleteButton onClick={() => this.excludePosition()} text="Excluir" />
        </CustomDialog>
        <Wrapper>
          <Drawer
            isOpen={filter.isOpen}
            content={
              <ContainerContent>
                <HeaderPage
                  title="Cargos"
                  leftItems={
                    <>
                      <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: 'Add' }}
                        disabled={positions.loadingData}
                        text="Adicionar Cargo"
                        onClick={() => this.setState(
                          {
                            isPanelOpen: true,
                            inicialValues: initialPosition
                          }
                        )}
                      />
                      {this.commandBarRender()}
                    </>
                  }
                  rightItems={
                    <>
                      <SearchBox
                        placeholder="Pesquisar"
                        value={search}
                        onChange={(e) => this.handleSearch(e)}
                        onKeyUp={(e) => this.filter(e.key)}
                        componentRef={this.inputSearch}
                        styles={{
                          root:
                            { border: 'none', width: 200, marginRight: 1 }
                        }}
                      />
                      <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: 'Refresh' }}
                        text="Atualizar"
                        onClick={() => getPositions()}
                      />
                      {/* <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: 'filter' }}
                        text="Filtrar"
                        onClick={() => this.toggleFilter()}
                      /> */}
                    </>
                  }
                />
                {!positions.loadingData && positions.data.length === 0 ?
                  <NoItems
                    error={positions.error}
                    text="Não há cargos cadastrados"
                    icon="Work"
                  />
                  :
                  <ListContainer>
                    <ShimmeredDetailsList
                      items={isFiltering ? items : positions.data}
                      enableShimmer={positions.loadingData}
                      columns={columns}
                      selectionMode={SelectionMode.single}
                      selection={this._selection}
                      getKey={this._getKey}
                      selectionPreservedOnEmptyClick={true}
                      setKey="single"
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible={true}
                      onItemInvoked={this._onItemInvoked}
                    />
                  </ListContainer>
                }
              </ContainerContent>
            }>
            <>
              <Stack horizontal horizontalAlign="space-between">
                <Text variant="xLarge">Filtros</Text>
                <Stack horizontal>
                  <IconButton
                    iconProps={{ iconName: 'ChromeClose' }}
                    styles={{ root: { color: colors.darkGray } }}
                    title="Fechar"
                    ariaLabel="Fechar"
                    onClick={() => this.setState({ filter: { ...filter, isOpen: false } })}
                  />
                </Stack>
              </Stack>
              <RadioGroup aria-label="gender" name="gender1" style={{ marginTop: 10 }} value={filter.ativadas} onChange={(e: any) => this.toggleParamsFilter(e.target.value)}>
                <Text variant="mediumPlus">Status</Text>
                <FormControlLabel value="todos" control={<Radio color="primary" />} label="Todos" />
                <FormControlLabel value="ativadas" control={<Radio color="primary" />} label="Ativadas" />
                <FormControlLabel value="desativas" control={<Radio color="primary" />} label="Desativadas" />
              </RadioGroup>
            </>
          </Drawer>
        </Wrapper>
      </>
    );
  };

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: PositionTypes[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.positions.data;
    };
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      };
    });
    const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };

  private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    return itemsSorted;
  };

}
const mapStateToProps = (state: RootState) => ({
  positions: state.positionsReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...getPositions,
  ...addPosition,
  ...delPosition,
  ...editPosition,
  ...setCurrentPage
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Positions);
