import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 1170px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const Footer = styled.div`
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px;
  box-sizing: border-box;
  border-top: 1px solid #E8E8E8;
  justify-content: flex-start;
  flex-shrink: 0;
`;

export const FormPanelCommand = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 13px;
    border-bottom: 1px solid #E8E8E8;
    flex-shrink: 0;
    box-sizing: border-box;
`
export const FormPanelHeader = styled.div`
    padding: 14px 32px 32px;
`
export const FormPanelContentInner = styled.div`
    height: 100%;
    overflow: auto;
    flex-grow: 1;
`
export const Loading = styled.div<{ isLoading: boolean }>`
    display: ${props => props.isLoading ? 'block' : 'none'};
`

export const ContainerContent = styled.div`
    display: flex;
    height: 100%;
`

export const FormPanelContentLeft = styled.div`
    width: 300px;
    padding: 48px;
    padding-right: 0px;
    border-right: 1px solid #E8E8E8;
    flex-shrink: 0;
    box-sizing: border-box;
`

export const FormPanelContentRight = styled.div`
    width: 100%;
    padding: 48px;
    overflow: auto;
`

export const WrapperTitleStepper = styled.div`
    margin-bottom: 36px;
`

export const WrapperButtons = styled.div`
    position: relative;
    left: 196px;
`