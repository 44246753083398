import api from "~/services/api";
import { takeLatest, put, call, all } from "redux-saga/effects";
import { 
    Types, 
    IRequestPositionsLoadAction, 
    PositionTypes, 
    IRequestPositionDeleteAction,
    IRequestPositionAddAction,
    IRequestPositionEditAction,
 } from "../ducks/positions/types";

const apiCallGet = (search: any) => {
    const searchFilter = search.searchText !== "" ? `?SearchText=${search.searchText}`: "?";
    const filter = search.filterAtivo !== null ? `&FilterAtivo=${search.filterAtivo}`: "";

    return api.get(`/cargos${searchFilter}${filter}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallPost = (item: PositionTypes) => {
    return api.post('/cargos', {
        ...item
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallPut = (item: PositionTypes) => {
    return api.put(`/cargos/${item.idCargo}`, {
        ...item
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallDel = (id: number) => {
    return api.delete(`/cargos/${id}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

function* load(action: IRequestPositionsLoadAction) {
    try {
        const items = yield call(apiCallGet, action.search);
        yield put({ type: Types.SUCCESS_POSITIONS_LOAD, payload: items });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar os cargos.';
        yield put({ type: Types.FAILURE_POSITIONS_LOAD, message });
    };
};

function* add(action: IRequestPositionAddAction) {
    try {
        const idCargo = yield call(apiCallPost, action.position);
        yield put({ type: Types.SUCCESS_POSITION_ADD, position: { ...action.position, ...idCargo, flagAtivo: true } });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar cargo';
        yield put({ type: Types.FAILURE_POSITION_ADD, message });
    };
};

function* edit(action: IRequestPositionEditAction) {
    try {
        yield call(apiCallPut, action.position);
        yield put({ type: Types.SUCCESS_POSITION_EDIT, position: action.position });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível editar cargo';
        yield put({ type: Types.FAILURE_POSITION_EDIT, message });
    };
};

function* del(action: IRequestPositionDeleteAction) {
    try {
        yield call(apiCallDel, action.id);
        yield put({ type: Types.SUCCESS_POSITION_DELETE, id: action.id });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluir cargo';
        yield put({ type: Types.FAILURE_POSITION_DELETE, message });
    };
};

export default function* positionsRoot() {
    yield all([
        yield takeLatest(Types.REQUEST_POSITIONS_LOAD, load),
        yield takeLatest(Types.REQUEST_POSITION_ADD, add),
        yield takeLatest(Types.REQUEST_POSITION_EDIT, edit),
        yield takeLatest(Types.REQUEST_POSITION_DELETE, del),
    ]);
};