import { takeLatest, put, call, all } from "redux-saga/effects";
import api from "~/services/api";

import {
    Types,
    ValidationType,
    IRequestValidationCycleAction,
    ISuccessValidationCycleAction
} from "../ducks/cycle/validation/types";

const apiCallValidation = (idCiclo: number) => {
    return api.get(`/ciclos/${idCiclo}/validar`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

function* validation(action: IRequestValidationCycleAction) {
    try {
        const data: ValidationType = yield call(apiCallValidation, action.idCiclo);
        // data.validacoes.map(item => item.children = [])
        yield put({
            type: Types.SUCCESS_CYCLE_VALIDATION,
            payload: {
              data:{
                status: data.status,
                validacoes: data.validacoes ?? []
              },
              flagStartValidation: false
            }
        } as ISuccessValidationCycleAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar a validação do ciclo.';
        yield put({ type: Types.FAILURE_CYCLE_VALIDATION, message });
    };
};

export default function* companiesRoot() {
    yield all([
        yield takeLatest(Types.REQUEST_CYCLE_VALIDATION, validation),
    ]);
};
