import styled from 'styled-components';

export const Container = styled.div`
    flex-basis: 100%;
    width: calc(100% - 250px);
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
`;

export const Content = styled.div`
    padding: 20px;
    overflow-y: scroll;
`
export const ContainerTitle = styled.div`
    border-bottom: 1px solid #CFD3DA;
    padding-bottom: 10px;
`

export const WrapperResults = styled.div`
    margin-top: 10px;
`

export const WrapperForm = styled.div`
    padding: 10px 0;
    box-sizing: border-box;
`
export const WrapperDropdown = styled.div`
    width: 200px;
    margin-right: 30px;
    min-width: 200px;
`

export const ContainerForm = styled.div`
    padding-left: 14px;
    margin-top: 4px;
`

export const Status = styled.div<{ bgColor: string }>`
    width: 17px;
    height: 17px;
    background-color: ${({ bgColor }) => bgColor};
    border-radius: 50%;
    margin-right: 12px;
`