import styled from 'styled-components';
import colors from '~/assets/js/colors';

export const Container = styled.div`
    padding: 30px;
    position: relative;
    top: 45px;
`

export const ContainerBox = styled.div`
    display: flex;
`

export const WrapperBox = styled.div<{ bgColor: string, borderColor?: string, color?: string }>`
    width: 110px;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: ${props => props.color ? props.color : '#FFFFFF'} ;
    border-radius: 2px;
    border: 1px solid ${props => props.borderColor ? props.borderColor : props.bgColor};
    background-color: ${props => props.bgColor};
`;

export const WrapperBoxCheck = styled.div`
    width: 110px;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 2px;
    border: 1px solid ${colors.primary};
    background-color: #FFFFFF;
`;

export const Div = styled.div`
    display: flex;
    align-items: center;
    justify-content: center
`
export const Tr = styled.tr`
    min-height: 120px;
`

export const TdFull = styled.td`
    width: 100%;
    background-color: #FBFBFB;
    border-radius: 2px;
`

export const Table = styled.table`
    border-collapse: separate;
    border-spacing: 10px;
`

export const TrFooter = styled.tr`
    min-height: 40px;
`

export const TdFooter = styled.td<{bgColor?: string}>`
    height: 40px;
    background-color: ${props => props.bgColor ? props.bgColor : '#201F1E'};
    border: 1px solid #201F1E;
    color: #201F1E;
`