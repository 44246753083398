import styled from "styled-components";
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

export const SelectInput = styled(Select)<{custompadding?: string}>`
    width: 100%;
    .MuiSelect-select:focus{
        padding: ${props => props.custompadding ? props.custompadding : ''};
    }
`
export const WrapperField = styled(FormControl) <{ height?: number }>`
    width: 100%;
    height: ${props => props.height ? props.height : 70}px;
`