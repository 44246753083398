import styled from 'styled-components';

export const Div = styled.div`
    flex-basis: 100%;
    width: calc(100% - 250px);
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
`;

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: row;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 1;
  overflow: auto;
`;

export const ContainerCycle = styled.div`
    background-color: #EFF1F3;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 20px;

`

export const Title = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
`

export const IconCycle = styled.img`
    padding-right: 20px;
`

export const DetailsWrapper = styled.div`
    margin-top: 18px;
    margin-left: 52px;
    display: flex;
    flex-direction: row;
    @media (max-width: 960px) {
        flex-direction: column;
        margin-top: 0px;
    }
`

export const WrapperButton = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    @media (max-width: 700px) {
        width: max-content;
        margin-top: 15px;
    }
`
