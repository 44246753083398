import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import api from '~/services/api';
import customToast from "~/components/Toast/index";
import AvatarEditor from 'react-avatar-editor';

import {
  Container,
  Content,
  WrapperTasks,
  ContainerTitleWrapper,
  ImgUser,
  WrapperInfo,
  FlexRow,
  HoverImg,
  Version,
  UploadImg,
  InputUpload
} from './styles';
import { IBreadcrumbItem } from 'office-ui-fabric-react/lib/Breadcrumb';
import { Text, ITextStyles } from 'office-ui-fabric-react/lib/Text';
import { ActionButton, IIconProps, IconButton, MessageBarButton, MessageBar, MessageBarType, Spinner, SpinnerSize } from 'office-ui-fabric-react';
import { Slider } from 'office-ui-fabric-react/lib/Slider';

import Header from "~/components/layout/Header";
import { HeaderDrawer as Drawer } from "~/components/layout/Drawer"
import { Calendar } from "~/components/Calendar"
import { DateRangeType } from 'office-ui-fabric-react/lib/Calendar';
import { TooltipHost } from 'office-ui-fabric-react/lib/Tooltip';
import { useId } from '@uifabric/react-hooks';
import { IPersonaSharedProps, Persona, PersonaSize, IPersonaStyles } from 'office-ui-fabric-react/lib/Persona';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { Link } from 'office-ui-fabric-react/lib/Link';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';

import { ReminderCalendar, ReminderTask } from '~/components/Reminder'
import { DeleteButton } from '~/components/Buttons'
import colors from '~/assets/js/colors';

import { Creators as LoginActions } from "~/store/ducks/login";
import { DataTypes } from "~/store/ducks/login/types"
import { RootState } from "~/store/ducks"

import { version } from "~/services/api";

const styleName: Partial<ITextStyles> = {
  root: {
    display: 'block',
    height: 20,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    marginBottom: 2
  }
}

const ContainerPrivateRouter: React.FC = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [isDrawerCloseBtn, setIsDrawerCloseBtn] = useState<boolean>(false)
  const [drawerContent, setDrawerContent] = useState({
    isRingerDrawerOpen: false,
    isHelpDrawerOpen: false,
    isConfigDrawerOpen: false,
    isUserDrawerOpen: false
  })

  const items: IBreadcrumbItem[] = [
    { text: 'Dashboard', key: 'dashboard', isCurrentItem: true },
  ];

  const handleClose = () => {
    setIsDrawerOpen(false)
    setDrawerContent({
      isRingerDrawerOpen: false,
      isConfigDrawerOpen: false,
      isHelpDrawerOpen: false,
      isUserDrawerOpen: false
    })
    setIsDrawerCloseBtn(true)
  }

  const handleDrawerContent = () => {
    if (drawerContent.isRingerDrawerOpen) {
      return <RingerDrawer handleClose={handleClose} />
    } else if (drawerContent.isConfigDrawerOpen) {
      return <ConfigDrawer handleClose={handleClose} />
    } else if (drawerContent.isHelpDrawerOpen) {
      return <HelpDrawer handleClose={handleClose} />
    } else if (drawerContent.isUserDrawerOpen) {
      return <UserDrawer handleClose={handleClose} />
    }
  }

  const handleRinger = () => {
    if (!drawerContent.isRingerDrawerOpen) {
      setIsDrawerOpen(true)
      setDrawerContent({
        isRingerDrawerOpen: true,
        isHelpDrawerOpen: false,
        isConfigDrawerOpen: false,
        isUserDrawerOpen: false
      })
      setIsDrawerCloseBtn(false)
    } else {
      setIsDrawerOpen(false)
      setDrawerContent({
        ...drawerContent,
        isRingerDrawerOpen: false
      })
    }
  }

  const handleHelp = () => {
    if (!drawerContent.isHelpDrawerOpen) {
      setIsDrawerOpen(true)
      setDrawerContent({
        isRingerDrawerOpen: false,
        isHelpDrawerOpen: true,
        isConfigDrawerOpen: false,
        isUserDrawerOpen: false
      })
      setIsDrawerCloseBtn(false)
    } else {
      setIsDrawerOpen(false)
      setDrawerContent({
        ...drawerContent,
        isHelpDrawerOpen: false
      })
    }
  }

  const handleConfig = () => {
    if (!drawerContent.isConfigDrawerOpen) {
      setIsDrawerOpen(true)
      setDrawerContent({
        isRingerDrawerOpen: false,
        isHelpDrawerOpen: false,
        isConfigDrawerOpen: true,
        isUserDrawerOpen: false
      })
      setIsDrawerCloseBtn(false)
    } else {
      setIsDrawerOpen(false)
      setDrawerContent({
        ...drawerContent,
        isConfigDrawerOpen: false
      })
    }
  }

  const handleUser = () => {
    if (!drawerContent.isUserDrawerOpen) {
      setIsDrawerOpen(true)
      setDrawerContent({
        isRingerDrawerOpen: false,
        isHelpDrawerOpen: false,
        isConfigDrawerOpen: false,
        isUserDrawerOpen: true
      })
      setIsDrawerCloseBtn(false)
    } else {
      setIsDrawerOpen(false)
      setDrawerContent({
        ...drawerContent,
        isUserDrawerOpen: false
      })
    }
  }


  return (
    <Container>
      <Header breadcrumb={items} onRinger={handleRinger} onConfig={handleConfig} onHelp={handleHelp} onUser={handleUser} closeDrawer={!isDrawerCloseBtn} />
      <Content>

        <Drawer
          isOpen={isDrawerOpen}
          content={props.children}
          backgroundColor="#E9EBEE">
          {handleDrawerContent()!}
        </Drawer>
      </Content>
    </Container>
  )
}

const RingerDrawer: React.FC<IPropsContentDrawer> = (props) => {
  return (
    <>
      <ContainerTitleWrapper>
        <Text variant={'xLarge'} styles={{ root: { fontWeight: '500' } }}>Notificações</Text>
        <IconButton iconProps={{ iconName: 'ChromeClose' }} styles={{ root: { color: colors.gray } }} title="Fechar" ariaLabel="Fechar" onClick={props.handleClose} />
      </ContainerTitleWrapper>
      <Calendar showGoToToday autoNavigateOnSelection dateRangeType={DateRangeType.Day} isMonthPickerVisible={false} showMonthPickerAsOverlay={true} />
      <WrapperTasks>
        <Text variant={'medium'} styles={{ root: { fontWeight: '500' } }}>Hoje</Text>
        <ReminderCalendar hour="19:00" duration="1 hora" reminder="Reunião de Calibração" moreInfo="Sala 203" />
        <ReminderCalendar hour="20:00" reminder="Prazo" />
      </WrapperTasks>

      <WrapperTasks>
        <Text variant={'medium'} styles={{ root: { fontWeight: '500' } }}>Tarefas</Text>
        <ReminderTask icon="Bullseye" reminder="Reunião de Calibração" moreInfo="Sala 203" />
        <ReminderTask reminder="Prazo" />
      </WrapperTasks>
    </>
  )
}

interface IPropsContentDrawer {
  handleClose: () => void;
}

const ConfigDrawer: React.FC<IPropsContentDrawer> = (props) => {
  return (
    <>
      <ContainerTitleWrapper>
        <Text variant={'xLarge'} styles={{ root: { fontWeight: '500' } }}>Configurações</Text>
        <IconButton iconProps={{ iconName: 'ChromeClose' }} styles={{ root: { color: colors.gray } }} title="Fechar" ariaLabel="Fechar" onClick={props.handleClose} />
      </ContainerTitleWrapper>
    </>
  )
}

const HelpDrawer: React.FC<IPropsContentDrawer> = (props) => {
  return (
    <>
      <ContainerTitleWrapper>
        <Text variant={'xLarge'} styles={{ root: { fontWeight: '500' } }}>Ajuda</Text>
        <IconButton iconProps={{ iconName: 'ChromeClose' }} styles={{ root: { color: colors.gray } }} title="Fechar" ariaLabel="Fechar" onClick={props.handleClose} />
      </ContainerTitleWrapper>
    </>
  )
}


const UserDrawer: React.FC<IPropsContentDrawer> = (props) => {
  const dispatch = useDispatch()
  const loginState = useSelector<RootState, DataTypes>(state => state.loginReducer)
  const [isDialogImageOpen, setIsDialogImageOpen] = useState<boolean>(false);
  const [isDialogDelImageOpen, setIsDialogDelImageOpen] = useState<boolean>(false);
  const [urlImg, setUrlImg] = useState<string>(loginState.data.imagem ?? '');
  const [changeImage, setChangeImage] = useState<boolean>(false);
  const [hasImage, setHasImage] = useState<boolean>(loginState.data.imagem ? true : false);
  // const [imgCropped, setImgCropped] = useState(loginState.data.imagem ?? '');
  const [sliderValue, setSliderValue] = React.useState(1.2);
  const [canShowControllersImage, setCanShowControllersImage] = useState<boolean>(false);
  const [editor, setEditor] = useState<any>(null);
  const [showWarningFileType, setShowWarningFileType] = useState<boolean>(false);
  const refInput: any = React.useRef(null);

  const tooltipId = useId('tooltip');
  const [isMouseOver, setIsMouseOver] = useState(false)
  const personaWithInitials: IPersonaSharedProps = {
    imageInitials: loginState.data.abbreviation,
  };

  useEffect(() => {
    if (loginState.successUpload) {
      // setUrlImg(imgCropped);
      setChangeImage(false)
      setIsDialogImageOpen(false);

      setIsDialogDelImageOpen(false);

      if (loginState.data.imagem === null) {
        setHasImage(false);
        setChangeImage(false);
      }
    }
  }, [loginState])

  const setEditorRef = (editor: any) => setEditor(editor);

  const sliderOnChange = (value: number) => setSliderValue(value);

  const handleScaleReduce = () => {
    const result = sliderValue - 1;
    if (result > 1) {
      setSliderValue(result)
    } else {
      setSliderValue(1)
    }
  }

  const handleOpenDialogImage = () => {
    setIsDialogImageOpen(true)
    setSliderValue(1.2)
    setCanShowControllersImage(false)
  }

  const handleMouseOver = () => {
    setIsMouseOver(true)
  }

  const handleMouseLeave = () => {
    setIsMouseOver(false)
  }

  const handleVersion = () => {
    api.get("/").then(resp => customToast.info(`${resp.data.title}: ${resp.data.version}`))
      .catch(e => console.log(e))
  }

  const handleClickUpload = () => {
    if (refInput.current) {
      refInput.current?.click();
    }
  }

  const handleCancelUpload = () => {
    setIsDialogImageOpen(false);
    setTimeout(() => {
      setChangeImage(false);
      if (!loginState.data.imagem) {
        setHasImage(false);
      }
    }, 500)
  }

  const handleDeleteImageDialog = () => {
    setIsDialogImageOpen(false);
    setIsDialogDelImageOpen(true);
  }

  const handleDelete = () => {
    dispatch(LoginActions.delAvatar());

  }

  const handleCancelDelete = () => {
    setIsDialogImageOpen(true);
    setIsDialogDelImageOpen(false);
  }

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const img = e.target.files![0];
    const url = URL.createObjectURL(img);
    const types = ['image/gif', 'image/jpeg', 'image/png']
    if (types.includes(img.type)) {
      setHasImage(true);
      setUrlImg(url);
      setChangeImage(true)
      setCanShowControllersImage(true)
    } else {
      setUrlImg('');
      setCanShowControllersImage(false);
      setShowWarningFileType(true);
    }
  }

  const handleSaveImage = async () => {
    if (editor) {
      // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
      // drawn on another canvas, or added to the DOM.
      // const canvas = editor.getImage()
      const img = editor.getImage();
      // If you want the image resized to the canvas size (also a HTMLCanvasElement)
      const canvasScaled = editor.getImageScaledToCanvas()
      // setImgCropped(canvasScaled.toDataURL());

      // console.log("img.toDataURL()")
      // console.log(img.toDataURL())
      // console.log("img")
      // console.log(canvasScaled.toDataURL())
      // const compressedFile = await imageCompression.getDataUrlFromFile(img.toDataURL());
      dispatch(LoginActions.uploadAvatar(canvasScaled.toDataURL()))
    }
  }

  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Alterar foto',
    closeButtonAriaLabel: 'Close',
  };

  const dialogDelContentProps = {
    type: DialogType.normal,
    title: 'Apagar foto',
    closeButtonAriaLabel: 'Close',
    subText: 'Tem certeza que quer apagar sua foto?'
  };

  return (
    <>
      <ContainerTitleWrapper>
        <Text variant={'xLarge'} styles={{ root: { fontWeight: '500' } }}>Minha Conta</Text>
        <IconButton iconProps={{ iconName: 'ChromeClose' }} styles={{ root: { color: colors.gray } }} title="Fechar" ariaLabel="Fechar" onClick={props.handleClose} />
      </ContainerTitleWrapper>
      <FlexRow>
        <div onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
          {/* {false ?
            <ImgUser src="./static/img/user.png" title="Trocar foto" />
            : */}
          <Persona {...personaWithInitials} imageUrl={loginState.data.imagem ?? ''} initialsColor={colors.primary} size={PersonaSize.size72} styles={{ root: { display: 'block' } }} />
          {/* } */}
          {isMouseOver &&
            <HoverImg title="Alterar foto" onClick={handleOpenDialogImage}>
              <FontIcon iconName="Camera" style={{ fontSize: 30, color: colors.white }} />
            </HoverImg>
          }
        </div>
        <WrapperInfo>
          <Text variant="mediumPlus" styles={styleName}>{loginState.data.name}</Text>
          <TooltipHost
            content={loginState.data.email}
            closeDelay={500}
            id={tooltipId}
            calloutProps={{ gapSpace: 0 }}
          >
            <Text variant="medium" aria-describedby={tooltipId}>{loginState.data.email}</Text>
          </TooltipHost>
          <Stack verticalAlign="start" tokens={{ childrenGap: 2 }} styles={{ root: { marginTop: 2 } }}>
            <Link href="" styles={{ root: { fontWeight: 'bold' } }}>Minha Conta</Link>
            <Link onClick={() => dispatch(LoginActions.signOut())} styles={{ root: { fontWeight: 'bold' } }}>Sair</Link>
          </Stack>
        </WrapperInfo>
      </FlexRow>
      <Version onClick={handleVersion}>Versão {version}</Version>

      <Dialog
        hidden={!isDialogImageOpen}
        // onDismiss={() => setIsDialogImageOpen(false)}
        dialogContentProps={dialogContentProps}
        minWidth={420}
      >
        <InputUpload ref={refInput} role="button" type="file" data-log-name="PhotoPickerFileInput" accept="image/*" onChange={handleUpload} />
        <ActionButton iconProps={{ iconName: 'Add' }} onClick={handleClickUpload}>
          Carregar uma nova foto
        </ActionButton>
        <ActionButton iconProps={{ iconName: 'Delete' }} onClick={handleDeleteImageDialog} disabled={loginState.data.imagem === null}>
          Apagar
        </ActionButton>
        {
          showWarningFileType &&
          <MessageBar
            messageBarType={MessageBarType.warning}
            onDismiss={() => setShowWarningFileType(false)}
            dismissButtonAriaLabel="Close"
          >
            Este formato de arquivo não funcionou. Tente usar um dos formatos de imagem mais comuns, como .jpg ou .png.
          </MessageBar>
        }
        <Stack horizontalAlign="center" styles={{ root: { marginTop: 15, height: 288 } }}>

          {
            !hasImage ?
              <Persona
                {...personaWithInitials}
                initialsColor={colors.primary}
                styles={PersonaStyle}
              />
              :
              <>
                {
                  !changeImage ?
                    <ImgUser src={loginState.data.imagem ?? ''} />
                    :
                    <AvatarEditor
                      image={urlImg}
                      width={256}
                      height={256}
                      border={0}
                      borderRadius={128}
                      color={[255, 255, 255, 0.6]} // RGBA
                      scale={sliderValue}
                      rotate={0}
                      style={{ width: 256, height: 256 }}
                      // onLoadSuccess={() => setCanShowControllersImage(true)}
                      onLoadFailure={() => setCanShowControllersImage(false)}
                      ref={setEditorRef}
                    />
                }
              </>
          }
          {
            canShowControllersImage &&
            <Stack
              horizontal
              verticalAlign="center"
              styles={{ root: { width: 256, marginTop: 10 } }}
            >
              <IconButton
                iconProps={{ iconName: 'CalculatorSubtract' }}
                title="Reduzir"
                ariaLabel="Reduzir"
                onClick={handleScaleReduce}
                disabled={sliderValue === 1}
              />
              <Slider
                min={1}
                max={10}
                step={0.1}
                showValue={false}
                value={sliderValue}
                onChange={sliderOnChange}
                styles={{ root: { flex: 1 } }}
              />
              <IconButton
                iconProps={{ iconName: 'CalculatorAddition' }}
                title="Ampliar"
                ariaLabel="Ampliar"
                onClick={() => setSliderValue((val) => val + 1)}
                disabled={sliderValue === 10}
              />
            </Stack>
          }
          {/* <img src={imgCropped} /> */}
        </Stack>
        <DialogFooter styles={{ actionsRight: { lineHeight: 0 } }}>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>

            <DefaultButton styles={{ root: { width: 110 } }} onClick={handleCancelUpload} text="Cancelar" />
            {
              loginState.isUploading ?
                <Spinner size={SpinnerSize.small} styles={{ root: { width: 120 } }} />
                :
                <PrimaryButton disabled={!hasImage || !changeImage} styles={{ root: { width: 110, marginLeft: 10 } }} onClick={handleSaveImage} text="Salvar" />
            }
          </div>
        </DialogFooter>
      </Dialog>

      <Dialog
        hidden={!isDialogDelImageOpen}
        dialogContentProps={dialogDelContentProps}
      // minWidth={420}
      >
        <DialogFooter styles={{ actionsRight: { lineHeight: 0 } }}>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>

            <DefaultButton styles={{ root: { width: 110 } }} onClick={handleCancelDelete} text="Cancelar" />
            {
              loginState.isUploading ?
                <Spinner size={SpinnerSize.small} styles={{ root: { width: 120 } }} />
                :
                <DeleteButton onClick={handleDelete} style={{ marginLeft: 10 }} text="Apagar" />
            }
          </div>
        </DialogFooter>
      </Dialog>
    </>
  )
}

const PersonaStyle: Partial<IPersonaStyles> = {
  root: {
    display: 'block',
    marginTop: 44,
    selectors: {
      '.ms-Persona-initials': {
        height: 200,
        width: 200,
        lineHeight: 200,
        fontSize: 60
      },
      '.ms-Persona-imageArea': {
        width: 'auto'
      }
    }
  }
}

export default ContainerPrivateRouter;
