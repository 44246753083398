import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { Formik } from "formik";

import { InputText, InputDate, InputAutocomplete } from '~/components/Forms';

import { IUnit, DataTypes as DataTypesUnit } from "~/store/ducks/units/types";
import { Creators as CreatorsUnit } from "~/store/ducks/units";

// import { PositionTypes, DataTypes as DataTypesPosition } from "~/store/ducks/positions/types";
// import { Creators as CreatorsPosition } from "~/store/ducks/positions";

// import { ChartType, DataTypes as DataTypesChart } from "~/store/ducks/chart/types";
// import { Creators as CreatorsChart } from "~/store/ducks/chart";

import { RootState } from "~/store/ducks";

export type InfoAdmissionType = {
    matricula: string;
    dataAdmissao: Date | null;
    unidade: Partial<IUnit>;
    // cargo: Partial<PositionTypes>;
    // local: Partial<ChartType>;
};

interface IFormInfoAdmissionProps {
    formRef: any;
    initialValues: InfoAdmissionType;
    success: (values: InfoAdmissionType) => any;
};

const validationSchemaInfoAdmission = yup.object().shape({
    matricula:
        yup.string()
            .max(10, "Matrícula não pode ter mais do que 10 caracteres")
            .required("Campo obrigatório"),
    dataAdmissao:
        yup.date()
            .min(new Date("01/01/1900"), "Data de nascimento inválida")
            .max(new Date(), "Data de nascimento inválida")
            .nullable()
            .typeError('Data de nascimento inválida')
            .required("Campo obrigatório"),
    unidade: yup.object().shape({
        idUnidade: yup.number()
            .nullable()
            .required("Campo obrigatório"),
    }),
    // cargo: yup.object().shape({
    //     idCargo:
    //         yup.number()
    //             .nullable()
    //             .required("Campo obrigatório"),
    // }),
    // local: yup.object().shape({
    //     idArea:
    //         yup.number()
    //             .nullable()
    //             .required("Campo obrigatório")
    // })

});

// const initialUnit: Partial<IUnit> = {
//     idUnidade: null,
//     nomeUnidade: "",
// };

// const initialPosition: Partial<PositionTypes> = {
//     idCargo: null,
//     titulo: "",
// };

// const initialChart: Partial<ChartType> = {
//     idArea: null,
//     nomeLocal: "",
// }

const FormContentInfoAdmission: React.FC<IFormInfoAdmissionProps> = (props) => {
    const [valueUnit, setValueUnit] = useState<Partial<IUnit>>(props.initialValues.unidade);
    // const [valuePosition, setValuePosition] = useState<Partial<PositionTypes>>(initialPosition);
    // const [valueChart, setValueChart] = useState<Partial<ChartType>>(initialChart);

    const dispatch = useDispatch();
    let timeout: number = 0;

    const unitsState = useSelector<RootState, DataTypesUnit>(state => state.unitsReducer);
    // const positionsState = useSelector<RootState, DataTypesPosition>(state => state.positionsReducer);
    // const chartState = useSelector<RootState, DataTypesChart>(state => state.chartReducer);
    // useEffect(() => {
    //     setValueUnit(props.initialValues.unidade);
    //     // setValuePosition(props.initialValues.cargo);
    //     // setValueChart(props.initialValues.local);
    //     // console.log(props.initialValues)
    // }, []);

    const search = (text: string, type: string) => {
        if(text.trim()){
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                switch (type) {
                    case 'unidade':
                        dispatch(CreatorsUnit.getUnits(text));
                        break;
                    // case 'cargo':
                    //     dispatch(CreatorsPosition.getPositions(text));
                    //     break;
                    // case 'local':
                    //     dispatch(CreatorsChart.getChart(text));
                    //     break;
                }
            }, 700);
        }
    };

    return (
        <>
            <Formik
                enableReinitialize={true}
                innerRef={props.formRef}
                initialValues={props.initialValues}
                validationSchema={validationSchemaInfoAdmission}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={(values: InfoAdmissionType) => {
                    values.unidade = {
                        idUnidade: valueUnit.idUnidade,
                        nomeUnidade: valueUnit.nomeUnidade
                    };
                    // values.cargo = {
                    //     idCargo: valuePosition.idCargo,
                    //     titulo: valuePosition.titulo
                    // };
                    // values.local = {
                    //     idArea: valueChart.idArea,
                    //     nomeLocal: valueChart.nomeLocal
                    // }

                    props.success(values);
                }}
            >
                {({ handleSubmit, handleChange, values, errors, setFieldError, setFieldValue }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md6" style={{ paddingLeft: 0, paddingRight: 8 }}>
                                        <InputText
                                            value={values.matricula}
                                            onChange={(e) => { handleChange(e); setFieldError("matricula", "") }}
                                            id="matricula"
                                            error={errors.matricula ? true : false}
                                            name="matricula"
                                            type="text"
                                            label="Matrícula"
                                            helperText={errors.matricula}
                                            className="mt-2"
                                            inputProps={{
                                                maxLength: 10
                                            }}
                                            autoFocus
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md6" style={{ paddingLeft: 8, paddingRight: 0 }}>
                                        <InputDate
                                            id="dataAdmissao"
                                            error={errors.dataAdmissao ? true : false}
                                            name="dataAdmissao"
                                            label="Data de Admissão"
                                            value={values.dataAdmissao}
                                            onChange={value => {
                                                setFieldError('dataAdmissao', '');
                                                setFieldValue('dataAdmissao', value);
                                            }}
                                            helperText={errors.dataAdmissao}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 paddingZero" style={{ height: 70 }}>
                                        <InputAutocomplete
                                            value={(valueUnit as IUnit)}
                                            onChange={(_, newValue) => {
                                                setValueUnit(newValue!)
                                                setFieldValue("unidade", { idUnidade: newValue?.idUnidade! });
                                            }}
                                            onInputChange={(_, newInputValue) => {
                                                setFieldError("unidade", "");
                                                search(newInputValue, "unidade");
                                            }}
                                            getOptionLabel={(unit: IUnit) => unit.nomeUnidade}
                                            getOptionSelected={(option, value) => option.idUnidade === value.idUnidade}
                                            options={unitsState.data}
                                            input={{
                                                idInput: "unidade",
                                                labelInput: "Unidade",
                                                helperTextInput: errors.unidade?.idUnidade as string,
                                                errorInput: errors.unidade?.idUnidade ? true : false
                                            }}
                                        />
                                    </div>
                                    {/* <div className="ms-Grid-col ms-sm12 paddingZero" style={{ height: 70 }}>
                                        <Autocomplete
                                            value={(valuePosition as PositionTypes)}
                                            onChange={(_, newValue) => {
                                                setValuePosition(newValue!)
                                                setFieldValue("cargo", { idCargo: newValue?.idCargo! });
                                            }}
                                            onInputChange={(_, newInputValue) => {
                                                setFieldError("cargo", "");
                                                search(newInputValue, "cargo");
                                            }}
                                            getOptionLabel={(position: PositionTypes) => position.titulo}
                                            getOptionSelected={(option, value) => option.idCargo === value.idCargo}
                                            id="cargo"
                                            noOptionsText="Não há opções"
                                            options={positionsState.data}
                                            renderInput={(params) => <InputText
                                                {...params}
                                                label="Cargo"
                                                helperText={errors.cargo?.idCargo}
                                                type="text"
                                                error={errors.cargo?.idCargo ? true : false}
                                                name="cargo"
                                            />}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 paddingZero" style={{ height: 70 }}>
                                        <Autocomplete
                                            value={(valueChart as ChartType)}
                                            onChange={(_, newValue) => {
                                                setValueChart(newValue!)
                                                setFieldValue("local", { idArea: newValue?.idArea! });
                                            }}
                                            onInputChange={(_, newInputValue) => {
                                                setFieldError("local", "");
                                                search(newInputValue, "local");
                                            }}
                                            getOptionLabel={(position: ChartType) => position.nomeLocal}
                                            getOptionSelected={(option, value) => option.idArea === value.idArea}
                                            id="local"
                                            noOptionsText="Não há opções"
                                            options={chartState.data}
                                            renderInput={(params) => <InputText
                                                {...params}
                                                label="Local"
                                                helperText={errors.local?.idArea}
                                                type="text"
                                                error={errors.local?.idArea ? true : false}
                                                name="local"
                                            />}
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </form>
                    )
                }}
            </Formik>
        </>
    );
};

export default FormContentInfoAdmission;