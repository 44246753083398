import { Types, DataTypes, LoginActionTypes } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: {
    email: "",
    name: "",
    abbreviation: "",
    imagem: null
  },
  isLogged: false,
  loading: false,
  error: "",
  isUploading: false,
  successUpload: false
};

/**
 * Actions
 */

export const Creators = {
  getEmailLogin: (email: string): LoginActionTypes => ({
    type: Types.REQUEST_USER_EMAIL,
    email,
  }),

  signIn: (email: string, password: string): LoginActionTypes => ({
    type: Types.REQUEST_USER_LOGIN,
    payload: { email, password }
  }),

  signOut: (): LoginActionTypes => ({
    type: Types.USER_SIGNOUT
  }),

  clearError: () => ({
    type: Types.CLEAR_ERROR_LOGIN
  }),

  uploadAvatar: (imagem: string): LoginActionTypes => ({
    type: Types.REQUEST_EMPLOYEE_UPLOAD,
    payload: {
      imagem
    }
  }),

  delAvatar: (): LoginActionTypes => ({
    type: Types.REQUEST_EMPLOYEE_DEL_UPLOAD
  })

};

export default function loginReducer(state = INITIAL_STATE, action: LoginActionTypes): DataTypes {

  switch (action.type) {
    //EMAIL
    // case Types.REQUEST_USER_EMAIL:
    //   return { ...state, email: { data: null, loading: true, error: false } }
    // case Types.SUCCESS_USER_EMAIL:
    //   return { ...state, email: { data: action.payload, loading: false, error: false } };
    // case Types.FAILURE_USER_EMAIL:
    //   return { ...state, email: { data: null, loading: false, error: true } }

    // LOGIN
    case Types.REQUEST_USER_LOGIN:
      return { ...state, loading: true, error: "" };
    case Types.SUCCESS_USER_LOGIN:
      return { ...state, loading: false, error: "", data: action.payload, isLogged: true };
    case Types.FAILURE_USER_LOGIN:
      return { ...state, loading: false, error: action.message, isLogged: false };
    case Types.CLEAR_ERROR_LOGIN:
      return { ...state, error: "", loading: false };
    case Types.USER_SIGNOUT:
      localStorage.clear();
      return { ...state, data: { email: "", name: "", abbreviation: "", imagem: null }, isLogged: false };

    case Types.REQUEST_EMPLOYEE_UPLOAD:
      return { ...state, isUploading: true, successUpload: false };
    case Types.SUCCESS_EMPLOYEE_UPLOAD:
      return { ...state, data: { ...state.data, imagem: action.payload.imagem }, isUploading: false, successUpload: true };
    case Types.FAILURE_EMPLOYEE_UPLOAD:
      customToast.error(action.message);
      return { ...state, isUploading: false, successUpload: false };

    case Types.REQUEST_EMPLOYEE_DEL_UPLOAD:
      return { ...state, isUploading: true, successUpload: false }
    case Types.SUCCESS_EMPLOYEE_DEL_UPLOAD:
      return { ...state, data: { ...state.data, imagem: null }, isUploading: false, successUpload: true };
    case Types.FAILURE_EMPLOYEE_DEL_UPLOAD:
      customToast.error(action.message);
      return { ...state, isUploading: false, successUpload: false };

    default:
      return state;
  };
};
