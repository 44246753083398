import React from 'react';
import { Text, ITextStyles } from 'office-ui-fabric-react/lib/Text';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';

import { Container, Wrapper, WrapperDate, WrapperIcon } from './styles';

const root: ITextStyles = {
    root: {
        textOverflow: 'ellipsis',
        width: '-webkit-fill-available',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    }
}

interface IProps {
    hour: string;
    duration?: string;
    reminder: string;
    moreInfo?: string;
}

export const ReminderCalendar: React.FC<IProps> = (props) => {
    return (
        <Container>
            <WrapperDate>
                <Text variant="smallPlus">{props.hour}</Text>
                <Text variant="smallPlus">{props.duration}</Text>
            </WrapperDate>
            <Wrapper>
                <Text variant="medium" styles={ root }>{props.reminder}</Text>
                <Text variant="smallPlus" styles={root}>{props.moreInfo}</Text>
            </Wrapper>
        </Container >
    );
}


interface IPropsTask {
    icon?: string;
    reminder: string;
    moreInfo?: string;
}

const rootTask: ITextStyles = {
    root: {
        textOverflow: 'ellipsis',
        width: '-webkit-fill-available',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontWeight: 'bold'
    }
}

export const ReminderTask: React.FC<IPropsTask> = (props) => {
    return (
        <Container>
            <WrapperIcon>
                <FontIcon iconName={props.icon} style={{fontSize: 30}}/>
            </WrapperIcon>
            <Wrapper>
                <Text variant="medium" styles={ rootTask }>{props.reminder}</Text>
                <Text variant="smallPlus" styles={root}>{props.moreInfo}</Text>
            </Wrapper>
        </Container >
    );
}

