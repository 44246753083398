import React, { useState, useEffect } from "react";
import * as yup from 'yup';
import { useHistory } from "react-router-dom";
import { Link } from 'office-ui-fabric-react/lib/Link';
import { Formik } from "formik"
import { RootState } from "~/store/ducks"
import { PrimaryButton } from "office-ui-fabric-react";
import { useSelector, useDispatch } from "react-redux";
import { ActionButton } from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import colors from "~/assets/js/colors";

import {
  Container,
  Content,
  Card,
  CardContent,
  RomasoftLogo,
  Title,
  ImageContainer,
  InputContainer,
  Input,
  ForgotLogin,
  ButtonContainer,
  ErrorMessage,
} from "./styles";

import { Creators as LoginActions } from "~/store/ducks/login";
import { DataTypes } from "~/store/ducks/login/types"

const emailSchema = yup.object().shape({
  email: yup
    .string()
    .email("E-mail inválido")
    .required("E-mail obrigatório"),
})

const passwordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Senha obrigatória")
})

function AcessarScreen() {
  const [validEmail, setValidEmail] = useState<boolean>(false)
  const [email, setEmail] = useState<string>("")
  // const errorMessage: string = "O usuário informado não foi encontrado. Verifique se você digitou corretamente. Caso contrário, entre em contato com o seu administrador."

  const loginState = useSelector<RootState, DataTypes>(state => state.loginReducer)
  const dispatch = useDispatch()
  const history = useHistory();

  useEffect(() => {
    dispatch(LoginActions.clearError());
  }, [dispatch])

  const login = () => {
    return (
      <CardContent>
        <Title>Entrar</Title>
        {/* {email.error && <ErrorMessage>{errorMessage}</ErrorMessage>} */}
        <Formik
          initialValues={{ email: "" }}
          initialErrors={{ email: "" }}
          validationSchema={emailSchema}
          validateOnChange={false}
          onSubmit={values => {
            dispatch(LoginActions.clearError())
            setEmail(values.email)
            setValidEmail(true)
          }}
        >
          {({ handleSubmit, handleChange, values, errors, setFieldError }) => (
            <form onSubmit={handleSubmit}>
              <InputContainer fixed>
                <Input
                  error={errors.email ? true : false}
                  id="email"
                  name="email"
                  label="Seu e-mail"
                  value={values.email}
                  className="mt-2"
                  autoFocus
                  helperText={errors.email ? errors.email : null}
                  onChange={(e) => { handleChange(e); setFieldError("email", "") }}
                  spellCheck="false"
                />
              </InputContainer>
              <ForgotLogin>
                <Link onClick={() => history.push("/recuperar/conta")}>
                  Esqueceu seu login?
                </Link>
              </ForgotLogin>
              <ButtonContainer>
                <PrimaryButton text="Próximo" type="submit" styles={{ root: { width: 100 } }} />
              </ButtonContainer>
            </form>
          )}
        </Formik>
      </CardContent>
    )
  }

  const passwordRender = () => (
    <CardContent>
      <ActionButton iconProps={{ iconName: 'Back' }} text={`${email}`} styles={{ label: { fontSize: "1rem", color: colors.darkGray }, icon: { color: colors.darkGray, marginLeft: 0 }, root: { paddingLeft: 23, width: 'fit-content' } }} onClick={() => setValidEmail(false)} />
      <Title marginTop={"20px"} >Insira a Senha</Title>
      {loginState.error ? <ErrorMessage>{loginState.error}</ErrorMessage> : null}
      <Formik
        initialValues={{ password: "" }}
        validationSchema={passwordSchema}
        onSubmit={(values) => {
          dispatch(LoginActions.signIn(email, values.password))
          // history.push('/dashboard')
        }}
      >
        {({ handleSubmit, handleChange, values, errors }) => (
          <form onSubmit={handleSubmit}>
            <InputContainer fixed>
              <Input
                autoFocus
                error={errors.password ? true : false}
                id="password"
                name="password"
                label="Senha"
                value={values.password}
                autoComplete="username new-password"
                type="password"
                className="mt-2"
                helperText={errors.password ? errors.password : null}
                onChange={handleChange}
              />
            </InputContainer>
            <ForgotLogin>
              <Link onClick={() => history.push("/recuperar/senha")}>
                Esqueci minha senha?
              </Link>
            </ForgotLogin>
            <ButtonContainer>
              {loginState.loading ?
                <Spinner size={SpinnerSize.medium} />
                :
                <PrimaryButton text="Entrar" type="submit" styles={{ root: { width: 100 } }} onClick={e => { e.preventDefault(); handleSubmit() }} />
              }
            </ButtonContainer>
          </form>
        )}
      </Formik>
    </CardContent>
  )
  return (
    <Container>
      <Content>
        <Card>
          <ImageContainer>
            <RomasoftLogo src="./static/img/st-marche.png" />
          </ImageContainer>
          {validEmail ? passwordRender() : login()}
        </Card>
      </Content>
    </Container>
  );
}

export default AcessarScreen;
