// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from "formik";
import * as yup from 'yup';

//FluentUI
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBarButton, IconButton, ICommandBarStyles } from 'office-ui-fabric-react';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { IDialogContentProps } from 'office-ui-fabric-react/lib/Dialog';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
//MaterialUI
// import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
//Componentes
import Panel from "~/components/layout/Panel";
import Status from "~/components/Status";
import CustomDialog from "~/components/CustomDialogFluentUI";
import { InputText, InputCheckbox } from '~/components/Forms';
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
import { DeleteButton } from '~/components/Buttons';
// import Dropdown from "~/components/layout/Dropdown"
import colors from "~/assets/js/colors";
import { CustomDrawer as Drawer } from "~/components/layout/Drawer";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
//Estilos
import {
  Wrapper,
  ListContainer,
  PrimaryButton,
  DefaultButton,
  ContainerContent,
} from "./styles";

import { Creators as getCompanies } from "~/store/ducks/companies";
import { Creators as addCompany } from "~/store/ducks/companies";
import { Creators as delCompany } from "~/store/ducks/companies";
import { Creators as editCompany } from "~/store/ducks/companies";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { ICompany, DataTypes } from "~/store/ducks/companies/types";
import { RootState } from "~/store/ducks";
//#endregion

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44
  }
}

/**
 * Validação do formulário
 */
const validationCompanySchema = yup.object().shape({
  codEmpresa: yup.string()
    .min(2, "O código da empresa deve conter pelo menos 2 caracteres")
    .max(5, "O código da empresa não pode ter mais do que 5 caracteres")
    .required("Campo obrigatório"),
  nomeEmpresa:
    yup.string()
      .min(3, "O nome da empresa deve conter pelo menos 3 caracteres")
      .max(80, "O nome da empresa não pode ter mais do que 80 caracteres")
      .required("Campo obrigatório"),
  flagAtiva:
    yup.bool()
  // group: yup.string().required("Campo obrigatório"),
  // inactivate: yup.boolean().required("Campo obrigatório")
});

type Filter = {
  isOpen?: boolean;
  ativadas: string;
  filterStatus: boolean | null;
};

interface ICompaniesState {
  columns: IColumn[];
  items: ICompany[];
  isPanelOpen: boolean;
  isDialogOpen: boolean;
  openPanel: boolean;
  isLoadData: boolean;
  inicialValues: ICompany;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
  filter: Filter;
  search: string;
};

interface IPropsCompany {
  companies: DataTypes;
  setCurrentPage: (page: Page) => void;
  getCompanies: (search?: string, filter?: boolean | null) => void;
  addCompany: (company: ICompany) => void;
  editCompany: (company: ICompany) => void;
  delCompany: (id: number) => void;
}

const itemsBreadCrumb: BreadcrumbItems[] = [
  { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
  { text: "Empresas", isCurrentItem: true },
];

const initialValue: ICompany = {
  idEmpresa: null,
  codEmpresa: "",
  nomeEmpresa: "",
  flagAtiva: true
}

class Companies extends Component<IPropsCompany, ICompaniesState> {
  private formRef: any;
  private inputSearch: any;
  private _selection: Selection;

  constructor(props: IPropsCompany) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: 'column1',
        name: '',
        ariaLabel: 'Status da empresa',
        fieldName: 'flagAtiva',
        minWidth: 15,
        maxWidth: 20,
        isResizable: true,
        isSortedDescending: false,
        onRender: (item: ICompany) => <Status status={item.flagAtiva} />
      },
      {
        key: 'column2',
        name: 'Código',
        ariaLabel: 'Código da empresa',
        fieldName: 'codEmpresa',
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 100,
        isResizable: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick
      },
      {
        key: 'column3',
        name: 'Razão Social',
        fieldName: 'nomeEmpresa',
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      }
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isDialogOpen: false,
      isPanelOpen: false,
      openPanel: false,
      isLoadData: true,
      selectionDetails: "",
      inicialValues: initialValue,
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      search: "",
      filter: {
        isOpen: false,
        ativadas: 'todos',
        filterStatus: null,
      }
    }

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.inputSearch = React.createRef();
  };

  componentDidMount() {
    const page: Page = {
      key: 'empresas',
      pages: itemsBreadCrumb
    };
    this.props.setCurrentPage(page);
    this.props.getCompanies();
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.isPanelOpen) {
      if (prevProps.companies.success !== this.props.companies.success) {
        if (this.props.companies.success) {
          this.setState({ isPanelOpen: false })
        }
      }
    }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    };
  };

  _onItemInvoked = (item: ICompany): void => {
    this.setState({
      isPanelOpen: true,
      inicialValues: {
        ...item
      }
    });
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };

  openEdit = () => {
    const selectItem: ICompany = (this._selection.getSelection()[0] as ICompany)
    this.setState({ isPanelOpen: true, inicialValues: selectItem });
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  excludeCompanies = () => {
    this.props.delCompany((this._selection.getSelection()[0] as ICompany).idEmpresa!);
    this.setState({ isDialogExcludeOpen: false });
  };

  //#region  Funções do filtro

  toggleFilter = () => {
    this.setState({
      filter: {
        ...this.state.filter,
        isOpen: !this.state.filter.isOpen,
      }
    });
  };

  toggleParamsFilter = (param: string) => {
    const { filter, search } = this.state;
    // // const params = filter.filteredBy;
    // const active = filter.active;
    // const desactive = filter.desactive;

    switch (param) {
      case 'todos':
        this.setState({ filter: { ...filter, filterStatus: null, ativadas: param } },
          () => this.props.getCompanies(search)
        );
        // this.props.getCompanies(search);
        break;
      case 'ativadas':
        this.setState({ filter: { ...filter, filterStatus: true, ativadas: param } },
          () => this.props.getCompanies(search, true)
        );
        // this.props.getCompanies(search, true);
        break;
      case 'desativas':
        this.setState({ filter: { ...filter, filterStatus: false, ativadas: param } },
          () => this.props.getCompanies(search, false)
        );
        // this.props.getCompanies(search, false);
        break;
    }



    // if (param === 'ACTIVE') {
    //   this.setState({ filter: { ...filter, active: !active } }, () => {
    //     if (this.state.filter.active) {
    //       this.props.getCompanies("", true);
    //     } else {
    //       this.props.getCompanies(this.state.search);
    //     }
    //   })
    // }

    // if (param === 'DESACTIVE') {
    //   this.setState({ filter: { ...filter, desactive: !desactive } }, () => {
    //     if (this.state.filter.desactive) {
    //       this.props.getCompanies("", false);
    //     } else {
    //       this.props.getCompanies(this.state.search);
    //     }
    //   })
    // }

    // // const index = params!.indexOf(param);
    // if (index > -1) {
    //   params!.splice(index, 1);
    // } else {
    //   params!.push(param);
    // };

    // if (param === "ST MARCHE") {
    //   this.setState({ filter: { ...this.state.filter, filteredBy: params, group: { ...this.state.filter.group, active: !active } } });
    // } else {
    //   this.setState({ filter: { ...this.state.filter, filteredBy: params, group: { ...this.state.filter.group, desactive: !desactive! } } });
    // };

    if (this.inputSearch.current) {
      this.filter(this.inputSearch.current.state.value);
    };

  };

  handleSearch = (e: any) => {
    const search: string = e?.target.value ? e!.target.value : "";
    this.setState({ search: search });
    if (search === "") {
      this.props.getCompanies("", this.state.filter.filterStatus);
    }
  };

  filter = (key: any) => {
    const { search, filter } = this.state;

    if (key === 'Enter') {
      if (filter.filterStatus !== null) {
        this.props.getCompanies(search, filter.filterStatus);
      } else {
        this.props.getCompanies(search);
      }
      if (this.inputSearch.current) {
        this.inputSearch.current.focus();
      }
    };

    if (search === "") {
      this.props.getCompanies("", filter.filterStatus);
    }

    // const params = this.state.filter.filteredBy;
    // const companies = this.props.companies.data;
    // let items = text ? companies.filter((i: ICompany) => i.nomeEmpresa.toLowerCase().indexOf(text.toLowerCase()) > -1) : companies;
    // if (params!.length > 0) {
    //   items = items.filter((item: ICompany) => {
    //     for (let i = 0; i < params!.length; i++) {
    //       if (item.grupo === params![i]) {
    //         return item;
    //       };
    //     };
    //   });
    // };
    // this.setState({ items, isFiltering: true });
  };

  // clearFilters = () => {
  //   const { filterStatus } = this.state;
  //   this.props.getCompanies("");
  //   // this.setState({
  //   //   filter: {
  //   //     ...this.state.filter,
  //   //     filteredBy: [],
  //   //     group:
  //   //     {
  //   //       active: false,
  //   //       desactive: false
  //   //     }
  //   //   }
  //   // }, () => this.filter(""))
  // };

  //#endregion

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Edit' }} text="Editar" onClick={() => this.openEdit()} />
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
        </>
      );
    } else if (selectionCount > 1) {
      return <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
    };
  };

  render() {
    const { isPanelOpen, isDialogExcludeOpen, selectionCount, inicialValues, isFiltering, items, columns, filter, search } = this.state;
    const { companies, addCompany, editCompany, getCompanies } = this.props;

    // const dialogProps: IDialog = {
    //   title: !inicialValues.idEmpresa ? "Cancelar cadastro?" : "Cancelar edição?",
    //   subText: !inicialValues.idEmpresa ?
    //     "Todos os dados inseridos serão perdidos. Tem certeza que quer cancelar o cadastro da empresa? "
    //     :
    //     "As mudanças serão descardas. Tem certeza que quer cancelar a edição da empresa?",
    //   closeDialog: () => this.setState({ isDialogOpen: false }),
    //   isOpenDialog: this.state.isDialogOpen,
    //   openDialog: () => this.setState({ isDialogOpen: true })
    // };

    const dialogContentProps: IDialogContentProps = {
      title: 'Excluir?',
      closeButtonAriaLabel: 'Close',
      subText: selectionCount <= 1 ? 'Tem certeza de que deseja excluir este item?' : 'Tem certeza de que deseja excluir estes items?',
    };

    return (
      <>
        <Panel
          title={!inicialValues.idEmpresa ? "Nova empresa" : inicialValues.nomeEmpresa}
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          // dialog={dialogProps}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>Cancelar</DefaultButton>
              {
                !companies.loading ?
                  <PrimaryButton onClick={this.handleSubmit}>Salvar</PrimaryButton>
                  :
                  <Spinner styles={{ root: { width: 110 } }} size={SpinnerSize.small} />
              }
            </Stack>
          }
        >
          <Formik
            innerRef={this.formRef}
            initialValues={{ ...inicialValues, flagAtiva: !inicialValues.flagAtiva }}
            validationSchema={validationCompanySchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values: ICompany) => {
              if (inicialValues.idEmpresa === null) {
                addCompany(values);
              } else {
                values.idEmpresa = inicialValues.idEmpresa;
                editCompany({ ...values, flagAtiva: !values.flagAtiva });
              };

            }}
          >
            {({ handleSubmit, handleChange, values, errors, setFieldError }) => (
              <form onSubmit={handleSubmit}>
                <InputText
                  value={values.codEmpresa}
                  onChange={(e) => { handleChange(e); setFieldError("codEmpresa", "") }}
                  id="codEmpresa"
                  error={errors.codEmpresa ? true : false}
                  name="codEmpresa"
                  type="text"
                  label="Código"
                  helperText={errors.codEmpresa}
                  inputProps={{ maxLength: 5 }}
                  className="mt-2"
                  autoFocus
                />
                <InputText
                  value={values.nomeEmpresa}
                  onChange={(e) => { handleChange(e); setFieldError("nomeEmpresa", "") }}
                  id="nomeEmpresa"
                  error={errors.nomeEmpresa ? true : false}
                  name="nomeEmpresa"
                  type="text"
                  label="Razão Social"
                  helperText={errors.nomeEmpresa}
                  inputProps={{ maxLength: 80 }}
                  className="mt-2"
                />
                {
                  inicialValues.idEmpresa &&
                  <InputCheckbox
                    checked={values.flagAtiva}
                    onChange={handleChange}
                    name="flagAtiva"
                    color="primary"
                    label="Inativar Empresa"
                  />
                }
              </form>
            )}
          </Formik>
        </Panel>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
          <DeleteButton onClick={() => this.excludeCompanies()} text="Excluir" />
        </CustomDialog>
        <Wrapper>
          <Drawer
            isOpen={filter.isOpen}
            content={
              <ContainerContent>
                <HeaderPage
                  title="Empresas"
                  leftItems={
                    <>
                      <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: 'Add' }}
                        disabled={companies.loadingData}
                        text="Adicionar Empresa"
                        onClick={() => this.setState(
                          {
                            isPanelOpen: true,
                            inicialValues: initialValue
                          }
                        )}
                      />
                      {this.commandBarRender()}
                    </>
                  }
                  rightItems={
                    <>
                      <SearchBox
                        placeholder="Pesquisar"
                        value={search}
                        onChange={(e) => this.handleSearch(e)}
                        onKeyUp={(e) => this.filter(e.key)}
                        componentRef={this.inputSearch}
                        // onClear={() => this.clearFilters()}
                        styles={{
                          root:
                            { border: 'none', width: 200, marginRight: 1 }
                        }}
                      />
                      <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: 'Refresh' }}
                        text="Atualizar"
                        onClick={() => getCompanies()}
                      />
                      <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: 'filter' }}
                        text="Filtrar"
                        onClick={() => this.toggleFilter()}
                      />
                    </>
                  }
                />
                {!companies.loadingData && companies.data.length === 0 ?
                  <NoItems
                    error={companies.error}
                    text="Não há empresas cadastradas"
                    img="/static/icons/supermarket.svg"
                    alt="Empresas"
                  />
                  :
                  <ListContainer>
                    <ShimmeredDetailsList
                      items={isFiltering ? items : companies.data}
                      enableShimmer={companies.loadingData}
                      columns={columns}
                      selectionMode={SelectionMode.single}
                      selection={this._selection}
                      getKey={this._getKey}
                      selectionPreservedOnEmptyClick={true}
                      setKey="single"
                      layoutMode={DetailsListLayoutMode.justified}
                      isHeaderVisible={true}
                      onItemInvoked={this._onItemInvoked}
                    />
                  </ListContainer>
                }
              </ContainerContent>
            }>
            <>
              <Stack horizontal horizontalAlign="space-between">
                <Text variant="xLarge">Filtros</Text>
                <Stack horizontal>
                  {/* <IconButton
                    iconProps={{ iconName: 'ClearFilter' }}
                    title="Limpar Filtros"
                    // disabled={filter.filteredBy!.length > 0 ? false : true}
                    onClick={() => this.clearFilters()}
                    ariaLabel="Limpar Filtros"
                  /> */}
                  <IconButton
                    iconProps={{ iconName: 'ChromeClose' }}
                    styles={{ root: { color: colors.darkGray } }}
                    title="Fechar"
                    ariaLabel="Fechar"
                    onClick={() => this.setState({ filter: { ...filter, isOpen: false } })}
                  />
                </Stack>
              </Stack>
              {/* <GroupCheckbox>
                <Text variant="mediumPlus" styles={{ root: { marginBottom: 10 } }}>Status</Text>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.active}
                      onChange={() => this.toggleParamsFilter("ACTIVE")}
                      name="inactivate"
                      color="primary"
                    />
                  }
                  label={<span style={{ fontSize: 14 }}>Ativas</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.desactive}
                      onChange={() => this.toggleParamsFilter("DESACTIVE")}
                      name="inactivate"
                      color="primary"
                    />
                  }
                  label={<span style={{ fontSize: 14 }}>Desativas</span>}
                />
              </GroupCheckbox > */}
              <RadioGroup aria-label="gender" name="gender1" style={{ marginTop: 10 }} value={filter.ativadas} onChange={(e: any) => this.toggleParamsFilter(e.target.value)}>
                <Text variant="mediumPlus">Status</Text>
                <FormControlLabel value="todos" control={<Radio color="primary" />} label="Todos" />
                <FormControlLabel value="ativadas" control={<Radio color="primary" />} label="Ativadas" />
                <FormControlLabel value="desativas" control={<Radio color="primary" />} label="Desativadas" />
              </RadioGroup>
            </>
          </Drawer>
        </Wrapper>
      </>
    );
  };

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: ICompany[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.companies.data;
    };
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      };
    });
    const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };

  private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    return itemsSorted;
  };

}
const mapStateToProps = (state: RootState) => ({
  companies: state.companiesReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ ...getCompanies, ...addCompany, ...delCompany, ...editCompany, ...setCurrentPage }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
