import { takeLatest, put, call, all } from "redux-saga/effects";
import {
    Types,
    ObjectiveCycleType,
    PainelType,
    PesoType,
    IRequestCyclesGetPainelAction,
    IRequestCyclesGetMetaIDAction,
    IRequestCyclesGetMetaIdObjectiveAction,
    IRequestCyclesAddMetaIDAction,
    IRequestCyclesEditMetaIDAction,
    IRequestCyclesDeleteMetaAction,
    IRequestCyclesLoadPesoByIdAction,
    IRequestCyclesAddPesoAction,
    IRequestCyclesEditPesoAction,
    IRequestCyclesDeletePesoAction,
    ISuccessCyclesGetMetaIDAction,
    ISuccessCyclesGetPainelAction,
    ISuccessCyclesGetMetaIdObjectiveAction,
    ISuccessCyclesAddMetaIDAction,
    ISuccessCyclesEditMetaIDAction,
    ISuccessCyclesDeleteMetaAction,
    ISuccessCyclesLoadPesoByIdAction,
    ISuccessCyclesAddPesoAction,
    ISuccessCyclesEditPesoAction,
    ISuccessCyclesDeletePesoAction,
    MetaType,
} from "../ducks/cycle/painel/types";

import {
    IRequestDismembermentAddMetaAction,
    IRequestDismembermentEditMetaAction,
    IRequestDismembermentDeleteMetaAction,
    IRequestDismembermentAddPesoAction,
    IRequestDismembermentEditPesoAction,
    IRequestDismembermentDeletePesoAction
} from "../ducks/cycle/dismemberment/types";

import api from "~/services/api";


const apiGetPainel = (payload: IRequestCyclesGetPainelAction["payload"]) => {
    return api.get(`/ciclos/${payload.idCiclo}/painel?Nivel=${payload.nivel}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

//#region API METAS

const apiGetMetaId = (payload: IRequestCyclesGetMetaIDAction["payload"]) => {
    return api.get(`/ciclos/${payload.idCiclo}/metas/${payload.idCicloMeta}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiGetMetaIdObjective = (payload: IRequestCyclesGetMetaIdObjectiveAction["payload"]) => {
    return api.get(`/ciclos/${payload.idCiclo}/objetivo/${payload.idObjective}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

export const apiAddMeta = (payload: IRequestCyclesAddMetaIDAction["payload"] | IRequestDismembermentAddMetaAction["payload"]) => {
    return api.post(`/ciclos/${payload.idCiclo}/metas`, {
        idObjetivo: payload.idObjetivo,
        ...payload.meta
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

export const apiEditMeta = (payload: IRequestCyclesEditMetaIDAction["payload"] | IRequestDismembermentEditMetaAction["payload"]) => {
    return api.put(`/ciclos/${payload.idCiclo}/metas/${payload.idCicloMeta}`, {
        idObjetivo: payload.idObjetivo,
        ...payload.meta
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

export const apiDelMeta = (payload: IRequestCyclesDeleteMetaAction["payload"] | IRequestDismembermentDeleteMetaAction["payload"]) => {
    return api.delete(`/ciclos/${payload.idCiclo}/metas/${payload.idCicloMeta}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};
//#endregion
//#region API PESOS

const apiGetPesoId = (payload: IRequestCyclesLoadPesoByIdAction["payload"]) => {
    return api.get(`/ciclos/${payload.idCiclo}/pesos/${payload.idCicloPeso}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

export const apiAddPeso = (payload: IRequestCyclesAddPesoAction["payload"] | IRequestDismembermentAddPesoAction["payload"]) => {
    const { idObjetivo, peso } = payload;
    return api.post(`/ciclos/${payload.idCiclo}/pesos`, {
        idObjetivo: idObjetivo,
        idArea: peso.idArea,
        definirResponsabilidade: peso.definirResponsabilidade,
        peso: peso.peso,
        idCicloPeso: peso.idCicloPeso,
        idCicloPesoPai: peso.idCicloPesoPai ?? 0
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

export const apiEditPeso = (payload: IRequestCyclesEditPesoAction["payload"] | IRequestDismembermentEditPesoAction["payload"]) => {
    return api.put(`/ciclos/${payload.idCiclo}/pesos/${payload.idCicloPeso}`, {
        ...payload.peso
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

export const apiDeletePeso = (payload: IRequestCyclesDeletePesoAction["payload"] | IRequestDismembermentDeletePesoAction["payload"]) => {
    return api.delete(`/ciclos/${payload.idCiclo}/pesos/${payload.idCicloPeso}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};


function* loadPainel(action: IRequestCyclesGetPainelAction) {
    try {
        const painel: PainelType = yield call(apiGetPainel, action.payload);
        yield put({ type: Types.SUCCESS_CYCLES_LOAD_PAINEL, payload: painel } as ISuccessCyclesGetPainelAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar as definições de metas';
        yield put({
            type: Types.FAILURE_CYCLES_LOAD_PAINEL,
            message
        });
    };
};

//#region METAS

function* loadMetaID(action: IRequestCyclesGetMetaIDAction) {
    try {
        const meta: MetaType = yield call(apiGetMetaId, action.payload);
        yield put({
            type: Types.SUCCESS_CYCLES_META_LOAD_BY_ID,
            payload: meta
        } as ISuccessCyclesGetMetaIDAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar a meta';
        yield put({
            type: Types.FAILURE_CYCLES_META_LOAD_BY_ID,
            message
        });
    };
};

function* loadMetaIdObjective(action: IRequestCyclesGetMetaIdObjectiveAction) {
    try {
        const meta: MetaType = yield call(apiGetMetaIdObjective, action.payload);
        console.log(meta)
        yield put({
            type: Types.SUCCESS_CYCLES_META_LOAD_BY_ID_OBJECTIVE,
            payload: meta
        } as ISuccessCyclesGetMetaIdObjectiveAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar a meta';
        yield put({
            type: Types.FAILURE_CYCLES_META_LOAD_BY_ID_OBJECTIVE,
            message
        });
    };
};

function* addMeta(action: IRequestCyclesAddMetaIDAction) {
    try {
        const idCicloMeta = yield call(apiAddMeta, action.payload);
        // yield call([ Types.REQUEST_CYCLES_LOAD_PAINEL, loadPainel])
        yield put({
            type: Types.SUCCESS_CYCLES_META_ADD_BY_ID,
            idObjetivo: action.payload.idObjetivo,
            idCicloPilar: action.payload.idCicloPilar,
            meta: { ...action.payload.meta, ...idCicloMeta }
        } as ISuccessCyclesAddMetaIDAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar a meta';
        yield put({
            type: Types.FAILURE_CYCLES_META_ADD_BY_ID,
            message
        });
    };
};

function* editMeta(action: IRequestCyclesEditMetaIDAction) {
    try {
        yield call(apiEditMeta, action.payload);
        yield put({
            type: Types.SUCCESS_CYCLES_META_EDIT_BY_ID,
            idObjetivo: action.payload.idObjetivo,
            idCicloPilar: action.payload.idCicloPilar,
            meta: action.payload.meta
        } as ISuccessCyclesEditMetaIDAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível editar a meta';
        yield put({
            type: Types.FAILURE_CYCLES_META_EDIT_BY_ID,
            message
        });
    };
};

function* delMeta(action: IRequestCyclesDeleteMetaAction) {
    try {
        yield call(apiDelMeta, action.payload);
        yield put({
            type: Types.SUCCESS_CYCLES_META_DELETE,
            idCicloPilar: action.payload.idCicloPilar,
            idObjetivo: action.payload.idObjetivo
        } as ISuccessCyclesDeleteMetaAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluír a meta';
        yield put({
            type: Types.FAILURE_CYCLES_META_DELETE,
            message
        });
    };
};
//#endregion
//#region PESOS
function* loadPesoById(action: IRequestCyclesLoadPesoByIdAction) {
    try {
        const peso: PesoType = yield call(apiGetPesoId, action.payload);
        yield put({
            type: Types.SUCCESS_CYCLES_LOAD_PESO_BY_ID,
            payload: peso
        } as ISuccessCyclesLoadPesoByIdAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar o peso';
        yield put({
            type: Types.FAILURE_CYCLES_META_LOAD_BY_ID,
            message
        });
    };
};


function* addPeso(action: IRequestCyclesAddPesoAction) {
    try {
        const idCicloPeso = yield call(apiAddPeso, action.payload);
        const { peso, idCicloPilar, idObjetivo } = action.payload;
        yield put({
            type: Types.SUCCESS_CYCLES_ADD_PESO,
            payload: {
                peso: {
                    idArea: peso.idArea,
                    peso: {
                        ...idCicloPeso,
                        peso: peso.peso,
                        definirResponsabilidade: peso.definirResponsabilidade
                    }
                },
                idCicloPilar: idCicloPilar,
                idObjetivo: idObjetivo
            }
        } as ISuccessCyclesAddPesoAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar o peso';
        yield put({
            type: Types.FAILURE_CYCLES_ADD_PESO,
            message
        });
    };
};

function* editPeso(action: IRequestCyclesEditPesoAction) {
    try {
        yield call(apiEditPeso, action.payload);
        const { peso, idCicloPilar, idObjetivo } = action.payload;
        yield put({
            type: Types.SUCCESS_CYCLES_EDIT_PESO,
            payload: {
                peso: {
                    peso: {
                        idCicloPeso: peso.idCicloPeso,
                        peso: peso.peso,
                        definirResponsabilidade: peso.definirResponsabilidade
                    },
                    idArea: peso.idArea,
                },
                idCicloPilar: idCicloPilar,
                idObjetivo: idObjetivo,
            }
        } as ISuccessCyclesEditPesoAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível editar o peso';
        yield put({
            type: Types.FAILURE_CYCLES_EDIT_PESO,
            message
        });
    };
};

function* deletePeso(action: IRequestCyclesDeletePesoAction) {
    try {
        yield call(apiDeletePeso, action.payload);
        const { idCicloPeso, idCicloPilar, idObjetivo } = action.payload;
        yield put({
            type: Types.SUCCESS_CYCLES_DELETE_PESO,
            payload: {
                idCicloPeso: idCicloPeso,
                idCicloPilar: idCicloPilar,
                idObjetivo: idObjetivo
            }
        } as ISuccessCyclesDeletePesoAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluír o peso';
        yield put({
            type: Types.FAILURE_CYCLES_DELETE_PESO,
            message
        });
    };
};


export default function* companiesRoot() {
    yield all([
        yield takeLatest(Types.REQUEST_CYCLES_LOAD_PAINEL, loadPainel),
        yield takeLatest(Types.REQUEST_CYCLES_META_LOAD_BY_ID, loadMetaID),
        yield takeLatest(Types.REQUEST_CYCLES_META_LOAD_BY_ID_OBJECTIVE, loadMetaIdObjective),
        yield takeLatest(Types.REQUEST_CYCLES_META_ADD_BY_ID, addMeta),
        yield takeLatest(Types.REQUEST_CYCLES_META_EDIT_BY_ID, editMeta),
        yield takeLatest(Types.REQUEST_CYCLES_META_DELETE, delMeta),
        yield takeLatest(Types.REQUEST_CYCLES_LOAD_PESO_BY_ID, loadPesoById),
        yield takeLatest(Types.REQUEST_CYCLES_ADD_PESO, addPeso),
        yield takeLatest(Types.REQUEST_CYCLES_EDIT_PESO, editPeso),
        yield takeLatest(Types.REQUEST_CYCLES_DELETE_PESO, deletePeso)
    ])
}
