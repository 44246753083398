/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_CYCLE_ATTITUDES_LOAD = "cycle_attitudes/REQUEST_CYCLE_ATTITUDES_LOAD",
    SUCCESS_CYCLE_ATTITUDES_LOAD = "cycle_attitudes/SUCCESS_CYCLE_ATTITUDES_LOAD",
    FAILURE_CYCLE_ATTITUDES_LOAD = "cycle_attitudes/FAILURE_CYCLE_ATTITUDES_LOAD",
    //POST
    REQUEST_CYCLE_ATTITUDES_ADD = "cycle_attitudes/REQUEST_CYCLE_ATTITUDES_ADD",
    SUCCESS_CYCLE_ATTITUDES_ADD = "cycle_attitudes/SUCCESS_CYCLE_ATTITUDES_ADD",
    FAILURE_CYCLE_ATTITUDES_ADD = "cycle_attitudes/FAILURE_CYCLE_ATTITUDES_ADD",
    //DELETE
    REQUEST_CYCLE_ATTITUDES_DEL = "cycle_attitudes/REQUEST_CYCLE_ATTITUDES_DEL",
    SUCCESS_CYCLE_ATTITUDES_DEL = "cycle_attitudes/SUCCESS_CYCLE_ATTITUDES_DEL",
    FAILURE_CYCLE_ATTITUDES_DEL = "cycle_attitudes/FAILURE_CYCLE_ATTITUDES_DEL",
};


/**
 * Data types
 */

export type CycloAttitudeType = {
    idCicloAtitude: number | null,
    idAtitude: number,
    idCiclo: number,
    ordem: number,
    atitude: {
        nomeAtitude: string;
    }
};

interface ICycleAttitudeState {
    readonly data: CycloAttitudeType[];
    readonly loading: boolean;
    readonly error: boolean;
    readonly success: boolean;
};

export type DataTypes = ICycleAttitudeState;


/**
 * Actions
 */

//GET
export interface IRequestCycleAttitudesLoadAction {
    type: typeof Types.REQUEST_CYCLE_ATTITUDES_LOAD;
    idCiclo: number;
};

export interface ISuccessCycleAttitudesLoadAction {
    type: typeof Types.SUCCESS_CYCLE_ATTITUDES_LOAD;
    payload: CycloAttitudeType[];
};

interface IFailureCycleAttitudesLoadAction {
    type: typeof Types.FAILURE_CYCLE_ATTITUDES_LOAD;
    message: string;
};

type CycleAttitudesLoadAction = IRequestCycleAttitudesLoadAction | ISuccessCycleAttitudesLoadAction | IFailureCycleAttitudesLoadAction;

//POST
export interface IRequestCycleAttitudesAddAction {
    type: typeof Types.REQUEST_CYCLE_ATTITUDES_ADD;
    payload: {
        idCiclo: number;
        idAtitude: number;
        ordem: number | null;
        atitude: {
            nomeAtitude: string;
        }
    }
};

export interface ISuccessCycleAttitudesAddAction {
    type: typeof Types.SUCCESS_CYCLE_ATTITUDES_ADD;
    payload: CycloAttitudeType;
};

interface IFailureCycleAttitudesAddAction {
    type: typeof Types.FAILURE_CYCLE_ATTITUDES_ADD;
    message: string;
};

type CycleAttitudesAddAction = IRequestCycleAttitudesAddAction | ISuccessCycleAttitudesAddAction | IFailureCycleAttitudesAddAction;

//DELETE
export interface IRequestCycleAttitudesDelAction {
    type: typeof Types.REQUEST_CYCLE_ATTITUDES_DEL;
    payload: {
        idCiclo: number;
        idCicloAtitude: number;
    }
};

export interface ISuccessCycleAttitudesDelAction {
    type: typeof Types.SUCCESS_CYCLE_ATTITUDES_DEL;
    idCicloAtitude: number;
};

interface IFailureCycleAttitudesDelAction {
    type: typeof Types.FAILURE_CYCLE_ATTITUDES_DEL;
    message: string;
};

type CycleAttitudesDelAction = IRequestCycleAttitudesDelAction | ISuccessCycleAttitudesDelAction | IFailureCycleAttitudesDelAction;

export type CycleAttitudesActionTypes = CycleAttitudesLoadAction | CycleAttitudesAddAction | CycleAttitudesDelAction;
