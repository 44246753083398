import styled from 'styled-components';
import {
  DefaultButton as DefaultBtn,
  PrimaryButton as PrimaryBtn,
} from "office-ui-fabric-react";


export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: row;
  `;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ContainerContent = styled.div`
`

export const ListContainer = styled.div`
  width: 100%;
  height: calc(100% - 82px);
  overflow-y: auto;
`;

export const PrimaryButton = styled(PrimaryBtn)`
  width: 110px;
`

export const DefaultButton = styled(DefaultBtn)`
  width: 110px;
`
export const WrapperCommands = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const LeftCommands = styled.div`
    display: flex;
    align-items: center;
`

export const RightCommands = styled.div`
    display: flex;
    align-items: center;
`