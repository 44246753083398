/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_EVALUATIONS_LOAD = "evaluations/REQUEST_EVALUATIONS_LOAD",
    SUCCESS_EVALUATIONS_LOAD = "evaluations/SUCCESS_EVALUATIONS_LOAD",
    FAILURE_EVALUATIONS_LOAD = "evaluations/FAILURE_EVALUATIONS_LOAD",
    //ADD
    REQUEST_EVALUATIONS_ADD = "evaluations/REQUEST_EVALUATIONS_EMAIL",
    SUCCESS_EVALUATIONS_ADD = "evaluations/SUCCESS_EVALUATIONS_ADD",
    FAILURE_EVALUATIONS_ADD = "evaluations/FAILURE_EVALUATIONS_ADD",
    //PUT
    REQUEST_EVALUATIONS_EDIT = "evaluations/REQUEST_EVALUATIONS_EDIT",
    SUCCESS_EVALUATIONS_EDIT = "evaluations/SUCCESS_EVALUATIONS_EDIT",
    FAILURE_EVALUATIONS_EDIT = "evaluations/FAILURE_EVALUATIONS_EDIT",
    //DELETE
    REQUEST_EVALUATIONS_DELETE = "evaluations/REQUEST_EVALUATIONS_DELETE",
    SUCCESS_EVALUATIONS_DELETE = "evaluations/SUCCESS_EVALUATIONS_DELETE",
    FAILURE_EVALUATIONS_DELETE = "evaluations/FAILURE_EVALUATIONS_DELETE",
};
/**
 * Data types
 */



export type EvaluationType = {
    idAvaliacao?: number | null;
    matricula: string;
    departamento: string;
    colaborador: string;
    avaliacao?: number | null;
};

interface IEvaluationsState {
    readonly data: EvaluationType[];
    readonly loading: boolean;
    readonly error: boolean;
};

export type DataTypes = IEvaluationsState;

/**
 * Actions
 */

//GET
interface IRequestEvaluationLoadAction {
    type: typeof Types.REQUEST_EVALUATIONS_LOAD;
};

export interface ISuccessEvaluationLoadAction {
    type: typeof Types.SUCCESS_EVALUATIONS_LOAD;
    payload: EvaluationType[];
};

interface IFailureEvaluationLoadAction {
    type: typeof Types.FAILURE_EVALUATIONS_LOAD;
    message: string;
};

type EvaluationLoadAction = IRequestEvaluationLoadAction | ISuccessEvaluationLoadAction | IFailureEvaluationLoadAction;

//ADD
export interface IRequestEvaluationAddAction {
    type: typeof Types.REQUEST_EVALUATIONS_ADD;
    evaluation: EvaluationType;
};

interface ISuccessEvaluationAddAction {
    type: typeof Types.SUCCESS_EVALUATIONS_ADD;
};

interface IFailureEvaluationAddAction {
    type: typeof Types.FAILURE_EVALUATIONS_ADD;
    message: string;
};

type EvaluationAddAction = IRequestEvaluationAddAction | ISuccessEvaluationAddAction | IFailureEvaluationAddAction;

//PUT
export interface IRequestEvaluationEditAction {
    type: typeof Types.REQUEST_EVALUATIONS_EDIT;
    evaluation: EvaluationType;
};

export interface ISuccessEvaluationEditAction {
    type: typeof Types.SUCCESS_EVALUATIONS_EDIT;
};

export interface IFailureEvaluationEditAction {
    type: typeof Types.FAILURE_EVALUATIONS_EDIT;
    message: string;
};

type EvaluationEditAction = IRequestEvaluationEditAction | ISuccessEvaluationEditAction | IFailureEvaluationEditAction;


//DELETE
export interface IRequestEvaluationDeleteAction {
    type: typeof Types.REQUEST_EVALUATIONS_DELETE;
    id: number;
};

export interface ISuccessEvaluationDeleteAction {
    type: typeof Types.SUCCESS_EVALUATIONS_DELETE;
};

export interface IFailureEvaluationDeleteAction {
    type: typeof Types.FAILURE_EVALUATIONS_DELETE;
    message: string;
};

type EvaluationDeleteAction = IRequestEvaluationDeleteAction | ISuccessEvaluationDeleteAction | IFailureEvaluationDeleteAction;

export type EvaluationActionTypes = EvaluationLoadAction | EvaluationAddAction | EvaluationEditAction | EvaluationDeleteAction;