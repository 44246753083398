import { Types, DataTypes, EmployeesActionTypes, EmployeeType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
  data: [],
  error: false,
  loadingData: false,
  loadingAction: false,
  success: false,
  successEdit: false,
  itemSelected: {
    item: {
      idFuncionario: null,
      cpf: '',
      email: '',
      matricula: '',
      nomeCompleto: '',
      sexo: '',
      password: '',
      flagAcessoRHR: false,
      dataAdmissao: null,
      status: '',
      unidade: {
        idUnidade: null,
        nomeUnidade: ''
      },
      dataNascimento: null,
      imagem: ''
    },
    error: false,
    loading: false,
    success: false
  }
};

/**
 * Actions
 */

export const Creators = {
  getEmployees: (search?: string, filter?: boolean | null): EmployeesActionTypes => {
    const pesquisa = search !== "" && search !== undefined ? search : "";
    const filtro = filter !== null && filter !== undefined ? filter : null;
    return ({
      type: Types.REQUEST_EMPLOYEES_LOAD,
      search: {
        searchText: pesquisa,
        status: filtro
      }
    })
  },

  getEmployeeById: (id: number): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_LOAD_ID,
    id
  }),

  addEmployee: (employee: EmployeeType): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_ADD,
    employee
  }),

  editEmployee: (employee: EmployeeType): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_EDIT,
    employee
  }),

  editEmployeePassword: (id: number, password: string): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_EDIT_PASSWORD,
    payload: {
      id,
      password
    }
  }),

  editEmployeeAccess: (id: number, access: boolean): EmployeesActionTypes => {
    return ({
      type: Types.REQUEST_EMPLOYEES_EDIT_ACCESS,
      payload: {
        id,
        access
      }
    })
  },

  delEmployee: (id: number): EmployeesActionTypes => ({
    type: Types.REQUEST_EMPLOYEES_DELETE,
    id
  }),

  resetEmployees: (): EmployeesActionTypes => ({
    type: Types.RESET_EMPLOYEES
  })
};

export default function employeesReducer(state = INITIAL_STATE, action: EmployeesActionTypes): DataTypes {

  switch (action.type) {
    case Types.REQUEST_EMPLOYEES_LOAD:
      return { ...state, loadingData: true, error: false, success: false };
    case Types.SUCCESS_EMPLOYEES_LOAD:
      return { ...state, data: action.payload, loadingData: false, error: false, success: true };
    case Types.FAILURE_EMPLOYEES_LOAD:
      customToast.error(action.message);
      return { ...state, data: [], loadingData: false, error: true, success: false };

    case Types.REQUEST_EMPLOYEES_LOAD_ID:
      return { ...state, itemSelected: { ...state.itemSelected, loading: true, success: false, error: false } };
    case Types.SUCCESS_EMPLOYEES_LOAD_ID:
      return { ...state, itemSelected: { item: action.payload, loading: false, success: true, error: false } };
    case Types.FAILURE_EMPLOYEES_LOAD_ID:
      customToast.error(action.message);
      return { ...state, itemSelected: { ...state.itemSelected, loading: false, success: false, error: true } };

    case Types.REQUEST_EMPLOYEES_ADD:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_EMPLOYEES_ADD:
      customToast.success("Funcionário cadastrado com sucesso");
      return { ...state, loadingAction: false, error: false, success: true, data: [...state.data, action.employee] };
    case Types.FAILURE_EMPLOYEES_ADD:
      customToast.error(action.message);
      return { ...state, loadingAction: false, error: true, success: false };

    case Types.REQUEST_EMPLOYEES_EDIT:
      return { ...state, loadingAction: true, error: false, successEdit: false };
    case Types.SUCCESS_EMPLOYEES_EDIT:
      customToast.success("Funcionário editado com sucesso");
      const { employee } = action;
      return {
        ...state,
        loadingAction: false,
        error: false,
        successEdit: true,
        data: state.data.map(item => {
          if (item.idFuncionario !== employee.idFuncionario) {
            return item;
          } else {

            return {
              ...item,
              ...employee
            };
          }
        }),
        itemSelected: {
          ...state.itemSelected,
          item: { ...state.itemSelected.item, ...employee }
        }
      };
    case Types.FAILURE_EMPLOYEES_EDIT:
      customToast.error(action.message);
      return { ...state, loadingAction: false, error: true, successEdit: false };

    case Types.REQUEST_EMPLOYEES_EDIT_PASSWORD:
      return { ...state };
    case Types.SUCCESS_EMPLOYEES_EDIT_PASSWORD:
      customToast.success("Senha do funcionário alterada com sucesso");
      return { ...state };
    case Types.FAILURE_EMPLOYEES_EDIT_PASSWORD:
      customToast.error(action.message);
      return { ...state };


    case Types.REQUEST_EMPLOYEES_EDIT_ACCESS:
      return {
        ...state, itemSelected: {
          ...state.itemSelected,
          error: false,
          loading: true,
          success: false
        }
      };
    case Types.SUCCESS_EMPLOYEES_EDIT_ACCESS:
      customToast.success("Acesso do funcionário ao RHR alterado com sucesso");
      return {
        ...state,
        loadingAction: false,
        error: false,
        successEdit: true,
        itemSelected: {
          item: { ...state.itemSelected.item, flagAcessoRHR: action.access },
          error: false,
          loading: false,
          success: true
        }
      };
    case Types.FAILURE_EMPLOYEES_EDIT_ACCESS:
      customToast.error(action.message);
      return {
        ...state, itemSelected: {
          ...state.itemSelected,
          error: true,
          loading: false,
          success: false
        }
      };

    case Types.REQUEST_EMPLOYEES_DELETE:
      return { ...state, loadingAction: true, error: false, success: false };
    case Types.SUCCESS_EMPLOYEES_DELETE:
      customToast.success("Funcionário removido com sucesso");
      return { ...state, loadingAction: false, error: false, success: true, data: state.data.filter(item => item.idFuncionario !== action.id) };
    case Types.FAILURE_EMPLOYEES_DELETE:
      customToast.error(action.message);
      return { ...state, loadingAction: false, error: true, success: false };

    case Types.RESET_EMPLOYEES:
      return INITIAL_STATE;

    default:
      return state;
  };
};
