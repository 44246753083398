import styled from 'styled-components';
import colors from '~/assets/js/colors';

export const Container = styled.div`
  width: calc(100% - 250px);
  flex-basis: 100%;
`

export const WrapperPivot = styled.div`
   
`


export const AbbreviationPerfil = styled.div`
    border-radius: 50%;
    border: 4px solid #FFFFFF;
    margin-left: 25px;
    margin-bottom: 15px;
    box-shadow: 0px 1px 3px black;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.primary};
    transition: all 0.3s;

    @media screen and (max-width: 1180px) and (min-width: 992px){
        width: 120px;
        min-width: 120px;
        height: 120px;
    }

    @media screen and (max-width: 992px){
        width: 100px !important;
        min-width: 100px !important;
        height: 100px !important;
    }
`

export const Abbreviation = styled.span`
    font-size: 60px;
    font-weight: 600px;
    transition: all 0.3s;
    color: ${colors.white};

    @media screen and (max-width: 1180px) and (min-width: 992px){
        font-size: 50px;
    }

    @media screen and (max-width: 992px){
        font-size: 40px !important;
    }
`

export const ContainerInfo = styled.div`
    background-color: rgba(0,0,0,0.6);
    display: flex;
    align-items: center;

    @media screen and (max-width: 992px){
        height: 100% !important;
    }
`

export const ContainerImg = styled.div<{ collapse: boolean }>`
    background-image: url("/static/img/bg-marche.png");
    height: ${({ collapse }) => collapse ? 120 : 240}px;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    background-position: top center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    transition: height 0.3s;

    ${ContainerInfo}{
        ${({ collapse }) => collapse ? `
            height: 100%;
            ` : `
            height: 90px;
        `}
    }

    ${AbbreviationPerfil}{
        ${({ collapse }) => collapse ? `
            width: 100px;
            min-width: 100px;
            height: 100px;
            margin-top: 10px;
            ` : `
            width: 168px;
            min-width: 168px;
            height: 168px;
        `}
    }

    ${Abbreviation}{
        ${({ collapse }) => collapse ? `
            font-size: 40px;
            ` : `
            font-size: 60px;
        `}
    }

    @media screen and (max-width: 992px){
        height: 120px !important;
    }
`


export const ImgPefil = styled.img`
    width: 168px;
    min-width: 168px;
    height: 168px;
    border-radius: 50%;
    border: 4px solid #FFFFFF;
    margin-left: 25px;
    margin-bottom: 15px;
    box-shadow: 0px 1px 3px black;
    z-index: 10;

    @media screen and (max-width: 992px){
        width: 100px;
        min-width: 100px;
        height: 100px;
    }
`

export const WrapperInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 30px;
`

export const WrapperPivotItem = styled.div`
    padding: 20px 40px 0px;
`