import React from 'react';

import { ContainerStatus, StatusItem } from './styles';

interface IProps {
    status?: boolean;
    styles?: React.CSSProperties;
    color?: string;
    statusLetter?: string;
}

const Status: React.FC<IProps> = (props) => {
    const status = props.statusLetter ? props.statusLetter : !props.status ? 'i' : 'a';
    const bgColor = props.color ? props.color : status === 'i' ? '#606A70' : '#1FB964';

    return (
        <ContainerStatus style={props.styles}>
            <StatusItem bgColor={bgColor}>
                {status}
            </StatusItem>
        </ContainerStatus>
    )
}

export default Status;