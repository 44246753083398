import { ICompany } from "../companies/types";

/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_UNITS_LOAD = "units/REQUEST_UNITS_LOAD",
    SUCCESS_UNITS_LOAD = "units/SUCCESS_UNITS_LOAD",
    FAILURE_UNITS_LOAD = "units/FAILURE_UNITS_LOAD",
    //GET BY ID
    REQUEST_UNITS_LOAD_ID = "units/REQUEST_UNITS_LOAD_ID",
    SUCCESS_UNITS_LOAD_ID = "units/SUCCESS_UNITS_LOAD_ID",
    FAILURE_UNITS_LOAD_ID = "units/FAILURE_UNITS_LOAD_ID",
    //ADD
    REQUEST_UNIT_ADD = "units/REQUEST_UNIT_ADD",
    SUCCESS_UNIT_ADD = "units/SUCCESS_UNIT_ADD",
    FAILURE_UNIT_ADD = "units/FAILURE_UNIT_ADD",
    //PUT
    REQUEST_UNIT_EDIT = "units/REQUEST_UNIT_EDIT",
    SUCCESS_UNIT_EDIT = "units/SUCCESS_UNIT_EDIT",
    FAILURE_UNIT_EDIT = "units/FAILURE_UNIT_EDIT",
    //DELETE
    REQUEST_UNIT_DELETE = "units/REQUEST_UNIT_DELETE",
    SUCCESS_UNIT_DELETE = "units/SUCCESS_UNIT_DELETE",
    FAILURE_UNIT_DELETE = "units/FAILURE_UNIT_DELETE",
};
/**
 * Data types
 */


export interface IUnit {
    idUnidade: number | null;
    idEmpresa: number | null;
    empresa: ICompany;
    nomeUnidade: string;
    CNPJ: string;
    tipoEndereco: string;
    logEndereco: string;
    numEndereco: string;
    compEndereco: string;
    bairro: string;
    cidade?: string;
    uf: string;
    cep: string;
    flagAtiva: boolean;
};

interface ItemSelected {
    item: IUnit;
    loading: boolean;
    success: boolean;
    error: boolean;
}

interface IUnitsState {
    readonly data: IUnit[];
    readonly loadingData: boolean;
    readonly loading: boolean;
    readonly error: boolean;
    readonly success: boolean;
    readonly itemSelected: ItemSelected;
};

export type DataTypes = IUnitsState;

/**
 * Actions
 */

//GET
export interface IRequestUnitsLoadAction {
    type: typeof Types.REQUEST_UNITS_LOAD;
    search: object;
};

export interface ISuccessUnitsLoadAction {
    type: typeof Types.SUCCESS_UNITS_LOAD;
    payload: IUnit[];
};

interface IFailureUnitsLoadAction {
    type: typeof Types.FAILURE_UNITS_LOAD;
    message: string;
};

type UnitsLoadAction = IRequestUnitsLoadAction | ISuccessUnitsLoadAction | IFailureUnitsLoadAction;

//GET
export interface IRequestUnitsLoadByIdAction {
    type: typeof Types.REQUEST_UNITS_LOAD_ID;
    unit: IUnit;
};

export interface ISuccessUnitsLoadByIdAction {
    type: typeof Types.SUCCESS_UNITS_LOAD_ID;
    unit: IUnit;
};

interface IFailureUnitsLoadByIdAction {
    type: typeof Types.FAILURE_UNITS_LOAD_ID;
    message: string;
};

type UnitsLoadByIdAction = IRequestUnitsLoadByIdAction | ISuccessUnitsLoadByIdAction | IFailureUnitsLoadByIdAction;

//ADD
export interface IRequestUnitAddAction {
    type: typeof Types.REQUEST_UNIT_ADD;
    unit: IUnit;
};

interface ISuccessUnitAddAction {
    type: typeof Types.SUCCESS_UNIT_ADD;
    unit: IUnit;
};

interface IFailureUnitAddAction {
    type: typeof Types.FAILURE_UNIT_ADD;
    message: string;
};

type UnitAddAction = IRequestUnitAddAction | ISuccessUnitAddAction | IFailureUnitAddAction;

//PUT
export interface IRequestUnitEditAction {
    type: typeof Types.REQUEST_UNIT_EDIT;
    unit: IUnit;
};

export interface ISuccessUnitEditAction {
    type: typeof Types.SUCCESS_UNIT_EDIT;
    unit: IUnit;
};

export interface IFailureUnitEditAction {
    type: typeof Types.FAILURE_UNIT_EDIT;
    message: string;
};

type UnitEditAction = IRequestUnitEditAction | ISuccessUnitEditAction | IFailureUnitEditAction;


//DELETE
export interface IRequestUnitDeleteAction {
    type: typeof Types.REQUEST_UNIT_DELETE;
    id: number;
};

export interface ISuccessUnitDeleteAction {
    type: typeof Types.SUCCESS_UNIT_DELETE;
    id: number;
};

export interface IFailureUnitDeleteAction {
    type: typeof Types.FAILURE_UNIT_DELETE;
    message: string;
};

type UnitDeleteAction = IRequestUnitDeleteAction | ISuccessUnitDeleteAction | IFailureUnitDeleteAction;


export type UnitsActionTypes = UnitsLoadAction | UnitAddAction | UnitEditAction | UnitDeleteAction | UnitsLoadByIdAction;