import axios from "axios";

async function refreshToken(error: any) {
  const { baseURL, data, headers, method, url } = error.config;
  new Promise((resolve, reject) => {
    try {
      const header = {
        "Content-Type": "application/json",
        "Accept-Language": "pt-BR",
      };
      const parameters = {
        headers: header,
      };
      const body = {
        tk: localStorage.getItem("user/token"),
      };
      axios
        .post(`${baseURL}/login/refresh-token`, body, parameters)
        .then(async (res: any) => {
          localStorage.setItem("user/token", res.data.token);
          const lastRequestMethod = error.config.method;
          const lastRequestUrl = error.config.url;
          const lastRequestBody = error.config.data;
          const lastRequestHeaders = {
            "Access-Control-Allow-Origin": "*",
            "Accept-Language": "pt-BR",
            "Content-Type": "application/json",
            tk: res.data.token,
          };
          let response = {};
          const parameters = {
            lastRequestMethod,
            headers: lastRequestHeaders,
          };
          switch (lastRequestMethod) {
            case "get":
              response = await axios.get(baseURL + lastRequestUrl, parameters);
              break;
            case "post":
              response = await axios.post(
                baseURL + lastRequestUrl,
                lastRequestBody,
                parameters
              );
              break;
            case "put":
              response = await axios.put(
                baseURL + lastRequestUrl,
                lastRequestBody,
                parameters
              );
              break;
            default: {
              return null;
            }
          }
          return resolve(response);
        })
        .catch((err: any) => reject(error));
    } catch (err) {
      return reject(err);
    }
  });
}

const errorHandler = (error: any) => {
  const errorStatus = error.response.status;
  const { baseURL, data, headers, method, url } = error.config;
  console.log({ baseURL, data, headers, method, url, errorStatus });

  console.log(error.message)
  console.log(error)
  if (error.code === 'ECONNABORTED') {
    throw errorMsgConectServidor;
  }
  if (error.message === "Network Error") {
    throw errorMsgConectServidor;
  }
  switch (errorStatus) {
    case 401:
      localStorage.clear();
      window.location.href = "/";
      // return refreshToken(error);
      break;
    // case 404:
      // throw errorMsg(error.message);
    case 500:
      throw errorMsgServidor;
    default:
      break;
  }
  throw error;
};

const errorMsgConectServidor = {
  response: {
    data: {
      message: "Não foi possível conectar com o servidor. Tente de novo mais tarde."
    }
  }
}

// const errorMsg = (message: string) => ({
//   response: {
//     data: {
//       message: message ?? "Ocorreu um erro. Página não encontrada."
//     },
//     statusCode: 404
//   }
// })

const errorMsgServidor = {
  response: {
    data: {
      message: "Ocorreu um erro no servidor. Tente novamente mais tarde"
    }
  }
}

export default errorHandler;
