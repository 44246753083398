import axios from "axios";
import errorHandler from "./errorHandler";

export const version = "0.5.2";

const baseURL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": "pt-BR",
  },
  timeout: 30000,
  baseURL,
});

function successHandler(response: any) {
  return response;
}

function responseError(error: object) {
  errorHandler(error);
  return error;
}

api.interceptors.response.use(
  (response) => successHandler(response),
  (error) => responseError(error)
);

api.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("user/token");
  if (token) {
    config.headers.tk = token;
  }
  return config;
});

export default api;
