import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DefaultButton, PrimaryButton, IButtonStyles } from 'office-ui-fabric-react';
import { DeleteButton } from '~/components/Buttons';
import { Text } from 'office-ui-fabric-react/lib/Text';

import { Modal } from './styles';

const btnStyles: Partial<IButtonStyles> = {
    root: {
        width: 110
    }
}

interface IProps {
    open: boolean;
    onClickConfirm: (_?: any) => any;
    onClickCancel: () => any;
    title: string;
    subText?: string;
    btnConfirmText?: string;
    btnCancelText?: string;
    width?: 420 | 640 | 850;
    height?: number;
    deleteButton?: boolean;
    disabledBtnConfirm?: boolean;
}

const CustomDialog: React.FC<IProps> = (props) => {
    const {
        width,
        title,
        subText,
        children,
        btnCancelText,
        btnConfirmText,
        open,
        onClickCancel,
        onClickConfirm,
        deleteButton,
        height,
        disabledBtnConfirm
    } = props;

    return (
        <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            width={width ?? 420}
            height={height}
        >
            <DialogTitle id="alert-dialog-title">
                <Text variant="xLarge">
                    {title}
                </Text>
            </DialogTitle>
            <DialogContent>
                <Text variant="medium">
                    {subText}
                </Text>
                {children}
            </DialogContent>
            <DialogActions>
                <DefaultButton styles={btnStyles} onClick={onClickCancel} color="primary">
                    {btnCancelText ? btnCancelText : "Cancelar"}
                </DefaultButton>
                {!deleteButton ?
                    <PrimaryButton styles={btnStyles} onClick={onClickConfirm} color="primary" disabled={disabledBtnConfirm}>
                        {btnConfirmText ? btnConfirmText : "Confirmar"}
                    </PrimaryButton>
                    :
                    <DeleteButton onClick={onClickConfirm}>
                        {btnConfirmText ? btnConfirmText : "Confirmar"}
                    </DeleteButton>
                }
            </DialogActions>
        </Modal>
    );
}

export default CustomDialog;