import React, { useState, useEffect } from 'react';

import Drawer from '@material-ui/core/Drawer';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { IconButton } from 'office-ui-fabric-react';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import {
    DefaultButton,
    PrimaryButton
} from "office-ui-fabric-react";
import {
    Container,
    FormPanelCommand,
    FormPanelContentInner,
    ContainerContent,
    FormPanelContentLeft,
    FormPanelContentRight,
    Loading,
    Footer,
    WrapperTitleStepper,
    WrapperButtons
} from './styles';

import colors from "~/assets/js/colors";

type TitleSteppsType = {
    title: string;
    subText: string;
}

interface IPanel {
    open: boolean;
    title: string;
    onClose: () => void;
    footer?: JSX.Element;
    itemsHeader?: JSX.Element | null;
    hideClose?: boolean;
    isLoading: boolean;
    steps: string[];
    titleSteps: TitleSteppsType[];
    activeStep: number;
    handleNext: () => void;
    handleBack: () => void;
};

const PanelWizard: React.FC<IPanel> = props => {

    const [panel, setPanel] = useState(false);

    useEffect(() => {
        // gerencia o panel
        if (props.open) {
            setPanel(true);
        } else {
            setPanel(false);
        };

    }, [props.open]);

    /**
     * Fecha o Dialog e fecha o Panel
     */
    const handle = () => {
        props.onClose();
    };

    return (
        <>
            <Drawer anchor="right" open={panel} >
                <Container>
                    <FormPanelCommand>
                        <Text variant="smallPlus" styles={{ root: { fontWeight: 600 } }}>{props.title}</Text>
                        <IconButton
                            iconProps={{ iconName: 'ChromeClose' }}
                            styles={{
                                root: {
                                    color: colors.darkGray,
                                    display: props.hideClose ? 'none' : 'block'
                                }
                            }}
                            title="Fechar"
                            ariaLabel="Fechar"
                            onClick={handle}
                        />
                    </FormPanelCommand>
                    <FormPanelContentInner>
                        <Loading isLoading={props.isLoading}>
                            <Stack>
                                <Spinner size={SpinnerSize.medium} />
                            </Stack>
                        </Loading>
                        <ContainerContent>
                            <FormPanelContentLeft>
                                <Stepper activeStep={props.activeStep} orientation="vertical" style={{ padding: 0 }}>
                                    {props.steps.map((label, index) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                            <StepContent>

                                            </StepContent>
                                        </Step>
                                    ))}
                                </Stepper>
                            </FormPanelContentLeft>
                            <FormPanelContentRight>
                                <WrapperTitleStepper>
                                    <Text variant="xxLarge">{props.titleSteps[props.activeStep].title}</Text>
                                </WrapperTitleStepper>
                                <WrapperTitleStepper>
                                    <Text variant="medium">{props.titleSteps[props.activeStep].subText}</Text>
                                </WrapperTitleStepper>
                                {props.children}
                            </FormPanelContentRight>
                        </ContainerContent>
                    </FormPanelContentInner>

                    {props.footer &&
                        <Footer>
                            <div>
                                <DefaultButton
                                    styles={{ root: { marginRight: 10, width: 110 } }}
                                    onClick={props.onClose}
                                >
                                    Cancelar
                                </DefaultButton>
                            </div>
                            <WrapperButtons>
                                <DefaultButton
                                    styles={{ root: { marginRight: 10, width: 110 } }}
                                    disabled={props.activeStep === 0}
                                    onClick={props.handleBack}
                                >
                                    Voltar
                                </DefaultButton>
                                <PrimaryButton
                                    styles={{ root: { width: 110, display: props.activeStep === props.steps.length - 1 ? 'none' : 'initial' } }}
                                    onClick={props.handleNext}
                                >
                                    {
                                        props.activeStep === props.steps.length - 1 ?
                                            'Finalizar'
                                            :
                                            'Próximo'
                                    }
                                </PrimaryButton>
                            </WrapperButtons>
                            <div>
                                {props.footer}
                            </div>
                        </Footer>
                    }

                </Container>
            </Drawer>
        </>
    );
};

export default PanelWizard;