import { takeLatest, put, call, all } from "redux-saga/effects";
import {
  Types,
  ISignInLoginAction,
  LoginType,
  IRequestEmployeeUploadImageAction,
  IRequestEmployeeDelImageAction,
  ISuccessEmployeeUploadImageAction,
  ISuccessEmployeeDelImageAction
} from "../ducks/login/types";
import { getAbbreviatedName } from "~/utils/abbreviatedName"
import api from "~/services/api";

const signIn = (data: ISignInLoginAction) => {
  return api.post('/login', {
    email: data.payload.email,
    password: data.payload.password
  })
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};


const apiCallUploadImage = (payload: IRequestEmployeeUploadImageAction["payload"]) => {
  return api
    .post('/funcionarios/upload',
      { imagem: payload.imagem })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
}

const apiCallDeleteImage = () => {
  return api
    .delete('/funcionarios/upload')
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
}

function* login(action: ISignInLoginAction) {
  try {
    const response = yield call(signIn, action);
    const result: LoginType = {
      name: response.data.name,
      email: action.payload.email,
      abbreviation: getAbbreviatedName(response.data.name),
      imagem: response.data.imagem
    };
    yield localStorage.setItem("user/token", response.token);
    yield put({ type: Types.SUCCESS_USER_LOGIN, payload: result });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Tente novamente mais tarde. Caso contrário, entre em contato com o seu administrador.';
    yield put({ type: Types.FAILURE_USER_LOGIN, message });
  }
};

function* uploadImg(action: IRequestEmployeeUploadImageAction) {
  try {
    yield call(apiCallUploadImage, action.payload);
    yield put({
      type: Types.SUCCESS_EMPLOYEE_UPLOAD,
      payload: {
        ...action.payload
      }
    } as ISuccessEmployeeUploadImageAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível salvar a imagem';
    yield put({ type: Types.FAILURE_EMPLOYEE_UPLOAD, message });
  }
}

function* delImg() {
  try {
    yield call(apiCallDeleteImage);
    yield put({
      type: Types.SUCCESS_EMPLOYEE_DEL_UPLOAD
    } as ISuccessEmployeeDelImageAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível remover a imagem';
    yield put({ type: Types.FAILURE_EMPLOYEE_DEL_UPLOAD, message });
  }
}

export default function* loginRoot() {
  yield all([
    yield takeLatest(Types.REQUEST_USER_LOGIN, login),
    yield takeLatest(Types.REQUEST_EMPLOYEE_UPLOAD, uploadImg),
    yield takeLatest(Types.REQUEST_EMPLOYEE_DEL_UPLOAD, delImg),
  ])
}
