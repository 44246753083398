import { takeLatest, put, call, all } from "redux-saga/effects";
import {
  Types,
  EvaluationType,
  IRequestEvaluationAddAction,
  IRequestEvaluationEditAction,
  IRequestEvaluationDeleteAction
} from "../ducks/evaluations/types";
import api from "~/services/api";
import axios from "axios"

const apiCallGet = () => {
  return axios
    .get("http://localhost:3001/avaliacoes")
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPost = (item: EvaluationType) => {
  return axios
    .post("http://localhost:3001/avaliacoes", {
     ...item
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallPut = (item: EvaluationType) => {
  return axios
    .put(`http://localhost:3001/avaliacoes/${item.idAvaliacao}`, {
      ...item,
    })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

const apiCallDel = (id: number) => {
  return axios
    .delete(`http://localhost:3001/avaliacoes/${id}`)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response;
    });
};

function* load() {
  try {
    const items: EvaluationType = yield call(apiCallGet);
    yield put({ type: Types.SUCCESS_EVALUATIONS_LOAD, payload: items });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar'
    yield put({
      type: Types.FAILURE_EVALUATIONS_LOAD,
      message
    });
  }
}

function* add(action: IRequestEvaluationAddAction) {
  try {
    yield call(apiCallPost, action.evaluation);
    yield call([Types.REQUEST_EVALUATIONS_LOAD, load]);
    yield put({ type: Types.SUCCESS_EVALUATIONS_ADD });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível adicionar avaliação'
    yield put({ type: Types.FAILURE_EVALUATIONS_ADD, message });
  }
}

function* edit(action: IRequestEvaluationEditAction) {
  try {
    yield call(apiCallPut, action.evaluation);
    yield call([Types.REQUEST_EVALUATIONS_LOAD, load]);
    yield put({ type: Types.SUCCESS_EVALUATIONS_EDIT });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível adicionar avaliação'
    yield put({ type: Types.FAILURE_EVALUATIONS_EDIT, message });
  }
}

function* del(action: IRequestEvaluationDeleteAction) {
  try {
    yield call(apiCallDel, action.id);
    yield call([Types.REQUEST_EVALUATIONS_LOAD, load]);
    yield put({ type: Types.SUCCESS_EVALUATIONS_DELETE });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível adicionar avaliação'
    yield put({ type: Types.FAILURE_EVALUATIONS_DELETE, message });
  }
}

export default function* companiesRoot() {
  yield all([
    yield takeLatest(Types.REQUEST_EVALUATIONS_LOAD, load),
    yield takeLatest(Types.REQUEST_EVALUATIONS_ADD, add),
    yield takeLatest(Types.REQUEST_EVALUATIONS_EDIT, edit),
    yield takeLatest(Types.REQUEST_EVALUATIONS_DELETE, del),
  ]);
}
