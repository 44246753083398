import React from 'react';

import { Shimmer, ShimmerElementType } from 'office-ui-fabric-react/lib/Shimmer';


const ShimmerTatic: React.FC = () => {

    const shimmerWithElementFirstRow = [
        { type: ShimmerElementType.line, height: 30, width: 428 },
        { type: ShimmerElementType.gap, width: 12 },
        { type: ShimmerElementType.line, height: 30, width: 68 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 68 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 68 },
        { type: ShimmerElementType.gap, width: 10 },
        { type: ShimmerElementType.line, height: 30, width: 38 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 38 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 38 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 38 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 38 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 38 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 38 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 38 },
        { type: ShimmerElementType.gap, width: "100%" },
    ];

    const shimmerWithElementPillar = [
        { type: ShimmerElementType.line, height: 158, width: 148 },
        { type: ShimmerElementType.gap, width: "100%" },
    ];

    const shimmerWithElementHeader = [
        { type: ShimmerElementType.gap, width: 590 },
        { type: ShimmerElementType.line, height: 30, width: 208 },
        { type: ShimmerElementType.gap, width: 10 },
        { type: ShimmerElementType.line, height: 30, width: 318 },
        { type: ShimmerElementType.gap, width: "100%" },
    ];

    const shimmerWithElementSubHeader = [
        { type: ShimmerElementType.gap, width: 510 },
        { type: ShimmerElementType.line, height: 30, width: 68 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 68 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 68 },
        { type: ShimmerElementType.gap, width: 10 },
        { type: ShimmerElementType.line, height: 30, width: 78 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 118 },
        { type: ShimmerElementType.gap, width: "100%" },
    ];

    const shimmerWithElementSubHeader2 = [
        { type: ShimmerElementType.gap, width: 590 },
        { type: ShimmerElementType.line, height: 30, width: 68 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 68 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 68 },
        { type: ShimmerElementType.gap, width: 10 },
        { type: ShimmerElementType.line, height: 30, width: 38 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 38 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 38 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 38 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 38 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 38 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 38 },
        { type: ShimmerElementType.gap, width: 2 },
        { type: ShimmerElementType.line, height: 30, width: 38 },
        { type: ShimmerElementType.gap, width: "100%" },
    ];


    return (
        <div style={{ marginTop: 18, padding: '0 20px' }}>
            <Shimmer styles={{ shimmerWrapper: { marginTop: 2, width: 'fit-content !important' } }} shimmerElements={shimmerWithElementHeader} />
            <Shimmer styles={{ shimmerWrapper: { marginTop: 2, width: 'fit-content !important' } }} shimmerElements={shimmerWithElementSubHeader2} />

            <div style={{display: 'flex', alignItems: 'flex-end'}}>
                <div>
                    <Shimmer styles={{ shimmerWrapper: { margin: '10 0', marginRight: 2} }} shimmerElements={shimmerWithElementPillar} />
                </div>
                <div>
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 11 } }} shimmerElements={shimmerWithElementFirstRow} />
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 2 } }} shimmerElements={shimmerWithElementFirstRow} />
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 2 } }} shimmerElements={shimmerWithElementFirstRow} />
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 2 } }} shimmerElements={shimmerWithElementFirstRow} />
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 2 } }} shimmerElements={shimmerWithElementFirstRow} />
                </div>
            </div>
            
            <div style={{display: 'flex', alignItems: 'flex-end'}}>
                <div>
                    <Shimmer styles={{ shimmerWrapper: { margin: '10 0', marginRight: 2} }} shimmerElements={shimmerWithElementPillar} />
                </div>
                <div>
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 11 } }} shimmerElements={shimmerWithElementFirstRow} />
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 2 } }} shimmerElements={shimmerWithElementFirstRow} />
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 2 } }} shimmerElements={shimmerWithElementFirstRow} />
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 2 } }} shimmerElements={shimmerWithElementFirstRow} />
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 2 } }} shimmerElements={shimmerWithElementFirstRow} />
                </div>
            </div>

            <div style={{display: 'flex', alignItems: 'flex-end'}}>
                <div>
                    <Shimmer styles={{ shimmerWrapper: { margin: '10 0', marginRight: 2} }} shimmerElements={shimmerWithElementPillar} />
                </div>
                <div>
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 11 } }} shimmerElements={shimmerWithElementFirstRow} />
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 2 } }} shimmerElements={shimmerWithElementFirstRow} />
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 2 } }} shimmerElements={shimmerWithElementFirstRow} />
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 2 } }} shimmerElements={shimmerWithElementFirstRow} />
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 2 } }} shimmerElements={shimmerWithElementFirstRow} />
                </div>
            </div>
            
            <div style={{display: 'flex', alignItems: 'flex-end'}}>
                <div>
                    <Shimmer styles={{ shimmerWrapper: { margin: '10 0', marginRight: 2} }} shimmerElements={shimmerWithElementPillar} />
                </div>
                <div>
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 11 } }} shimmerElements={shimmerWithElementFirstRow} />
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 2 } }} shimmerElements={shimmerWithElementFirstRow} />
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 2 } }} shimmerElements={shimmerWithElementFirstRow} />
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 2 } }} shimmerElements={shimmerWithElementFirstRow} />
                    <Shimmer styles={{ shimmerWrapper: { marginTop: 2 } }} shimmerElements={shimmerWithElementFirstRow} />
                </div>
            </div>

        </div>
    )
}

export default ShimmerTatic;