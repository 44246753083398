import styled from 'styled-components';
import { Container as MaterialContainer } from "@material-ui/core";

export const Container = styled.div`
  position: relative;
  top: 50px;
  overflow: auto;

`;

export const WrapperLevel = styled.div`
    padding-top: 22px;
    padding-bottom: 31px;
    display: flex;
    align-items: center;
    width: 100%;
    position: fixed;
    background-color: white;
    z-index: 99;
    padding-left: 20px;
`

export const InputContainer = styled(MaterialContainer)`
  margin-left: 0px !important;
  padding: 0px !important;
  width: 200px !important;
  margin-right: 0px !important;
`;
