import React, { useState, useEffect } from 'react';

import Drawer from '@material-ui/core/Drawer';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { IconButton } from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

import {
    Container,
    FormPanelCommand,
    FormPanelHeader,
    FormPanelContentInner,
    Footer
} from './styles';

import colors from "~/assets/js/colors";

interface IPanel {
    open: boolean;
    title?: string;
    onClose: () => void;
    panelHeader?: JSX.Element;
    footer?: JSX.Element;
    itemsHeader?: JSX.Element | null;
    hideClose?: boolean;
    isLoading: boolean;
};

const PanelDisplay: React.FC<IPanel> = props => {

    const [panel, setPanel] = useState(false);

    useEffect(() => {
        // gerencia o panel
        if (props.open) {
            setPanel(true);
        } else {
            setPanel(false);
        };

    }, [props.open]);

    /**
     * Fecha o Dialog e fecha o Panel
     */
    const handle = () => {
        props.onClose();
    };

    return (
        <>
            <Drawer anchor="right" open={panel} >
                <Container>
                    <FormPanelCommand>
                        <IconButton
                            iconProps={{ iconName: 'ChromeClose' }}
                            styles={{
                                root: {
                                    color: colors.darkGray,
                                    display: props.hideClose ? 'none' : 'block'
                                }
                            }}
                            title="Fechar"
                            ariaLabel="Fechar"
                            onClick={handle}
                        />
                    </FormPanelCommand>

                    {props.panelHeader &&
                        <FormPanelHeader>
                            {props.panelHeader}
                        </FormPanelHeader>
                    }

                    <FormPanelContentInner>
                        {
                            props.isLoading ?
                                <Stack styles={{ root: { height: '100%' } }}>
                                    <Spinner size={SpinnerSize.large} styles={{ root: { height: '100%' } }} />
                                </Stack>
                                :
                                <>
                                    {props.children}
                                </>
                        }
                    </FormPanelContentInner>
                    {props.footer &&
                        <Footer>
                            {props.footer}
                        </Footer>
                    }


                </Container>
            </Drawer>
        </>
    );
};

export default PanelDisplay;