import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
//FluentUI
import { Text } from 'office-ui-fabric-react/lib/Text';
import { PrimaryButton, SpinnerSize, Spinner } from 'office-ui-fabric-react';
import { Pivot, PivotItem, IPivotStyles } from 'office-ui-fabric-react/lib/Pivot';

//Componentes
import { LabelGroup } from '~/components/label';
import PainelHeader from '~/components/layout/PainelHeader';
import { RootState } from "~/store/ducks";

import CycleAttitudes from './Attitudes';
import Pillars from "./Pillars";
import Goals from "./Goals";
import Dismemberment from "./Dismemberment";
import Validation from "./Validation";

import {
  Wrapper,
  ContainerCycle,
  Title,
  IconCycle,
  DetailsWrapper,
  WrapperButton,
  Div,
} from "./styles";

import { Creators as getCycle } from "~/store/ducks/cycle";
import { Creators as getPillarsCycle } from "~/store/ducks/cycle";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { Creators as startValidationCycle } from "~/store/ducks/cycle";
import { Creators as clearDismemberment } from "~/store/ducks/cycle/dismemberment";
import { Creators as getAttitudes } from "~/store/ducks/attitudes";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { DataTypes } from "~/store/ducks/cycle/types";
import { formattedStatus } from "../Cycles"

interface ICycleState {
  isDialogOpen: boolean;
  redirect: boolean;
  idCycle: number | null;
  currentPivot: string;
  currentPainel: string | null;
};

interface ICycleProps {
  cycle: DataTypes;
  getCycle: (year: number) => void;
  setCurrentPage: (page: Page) => void;
  match: any;
  getPillarsCycle: (id: number) => void;
  startValidationCycle: (idCiclo: number) => void;
  clearDismemberment: () => void;
  getAttitudes: (search?: string) => void;

};

const pivotStyles: Partial<IPivotStyles> = {
  root: {
    borderBottom: '1px solid #CFD3DA',
    padding: '10px 20px 0px',
    position: 'fixed',
    backgroundColor: "#FFFFFF",
    width: '100%',
    zIndex: 999,
  },
  itemContainer: {
    // height: 'calc(100vh - 178px);',
    // selectors: {
    //   "@media screen and (min-width: 624px) and (max-width: 754px)": {
    //     height: 'calc(100vh - 225px);'
    //   },
    //   "@media(max-width: 624px)": {
    //     height: 'calc(100vh - 276px);'
    //   }
    // }
  },
  linkContent: {
    width: 130
  }
}

class Cycle extends Component<ICycleProps, ICycleState> {
  constructor(props: ICycleProps) {
    super(props);
    this.state = {
      isDialogOpen: false,
      redirect: false,
      idCycle: null,
      currentPivot: "0",
      currentPainel: null
    };
  };

  componentDidMount() {
    const { idCiclo } = this.props.match.params;
    this.props.getCycle(idCiclo);
    this.props.clearDismemberment();
    this.props.getAttitudes();

    if (idCiclo !== null) {
      this.props.getPillarsCycle(idCiclo);
    }

  }

  componentDidUpdate(prevProps: ICycleProps, prevState: ICycleState) {
    const { ano: nextAno } = this.props.cycle.data.cycle;
    const { error: prevError } = prevProps.cycle;
    const { error: nextError } = this.props.cycle;

    if (prevError !== nextError) {
      if (nextError && nextAno === null) {
        this.setState({ redirect: true });
      };
    };
    if (prevProps.cycle.successData !== this.props.cycle.successData) {
      this.setState({ idCycle: this.props.match.params.idCiclo })
      if (nextAno !== null) {
        const items: BreadcrumbItems[] = [
          { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
          { text: 'Ciclos', url: "/ciclos" },
          { text: nextAno!.toString(), isCurrentItem: true },
        ];

        const page: Page = {
          key: 'ciclos',
          pages: items
        };

        this.props.setCurrentPage(page);
      }
    }

    if (prevProps.cycle.successValidation !== this.props.cycle.successValidation) {
      if (this.props.cycle.successValidation) {
        this.handlePivot('4')
      }
    }
  }

  toggleDialog = () => {
    this.setState({ isDialogOpen: !this.state.isDialogOpen });
  };

  handlePivot = (idPivot: string) => {
    this.setState({ currentPivot: idPivot })
  }

  handleChangePainel = (nivel: string) => {
    this.setState({ currentPainel: nivel })
  }

  handleValidarCiclo = () => {
    const { idCiclo } = this.props.match.params;

    this.props.startValidationCycle(idCiclo);
  }

  handlePublicarCiclo = () => {
    console.log("Publicar Ciclo")
  }

  render() {
    const { idCycle, currentPivot, currentPainel } = this.state;
    const { cycle } = this.props;
    const { cycle: ciclo } = cycle.data;

    if (this.state.redirect) return (
      <Redirect to="/ciclos" />
    );

    return (
      <Div>
        <PainelHeader
          title={ciclo.ano ? `Ciclo ${String(ciclo.ano)}` : ""}
          icon={{ svgIcon: "/static/icons/ciclo.svg" }}
          labelGroups={[
            {
              icon: "contact",
              label: "Responsável",
              value: ciclo.responsavel ? ciclo.responsavel!.nomeCompleto! : ""
            },
            {
              icon: "Flag",
              label: "Status",
              value: formattedStatus(ciclo.status!)
            }
          ]}
          button={
            ciclo.status === 'P' ?
              {
                text: "Validar Ciclo",
                handleButton: this.handleValidarCiclo,
                isLoadingAction: cycle.loadingValidation
              }
              :
              ciclo.status === 'A' ?
                {
                  text: "Publicar Ciclo",
                  handleButton: () => console.log("Publicar Ciclo")
                }
                :
                null

          }
        />
        {/* <ContainerCycle>
                    <Title>
                        <IconCycle src="/static/icons/ciclo.svg" alt="Ícone Ciclo" />
                        <Text variant="xxLarge">{`Ciclo ${ciclo.ano ? ciclo.ano : ""}`}</Text>
                    </Title>
                    <DetailsWrapper>
                        <LabelGroup icon="contact" label="Responsável" value={ciclo.responsavel ? ciclo.responsavel!.nomeCompleto! : ""} />
                        <LabelGroup icon="Flag" label="Status" value={formattedStatus(ciclo.status!)} />
                        <WrapperButton>
                            {
                                ciclo.status === 'P' &&
                                <PrimaryButton text="Validar Ciclo" onClick={this.handleValidarCiclo} />
                            }
                            {
                                ciclo.status === 'A' &&
                                <PrimaryButton text="Publicar Ciclo" onClick={this.handlePublicarCiclo} />
                            }
                        </WrapperButton>
                    </DetailsWrapper>
                </ContainerCycle> */}
        <Wrapper>
          <Pivot aria-label="Definições do ciclo"
            selectedKey={String(currentPivot)}
            onLinkClick={(e) => this.handlePivot(e?.props.itemKey!)}
            styles={pivotStyles}
          >
            <PivotItem
              headerText="Atitudes"
              itemKey="0"
              key="0"
              style={{
                height: "100%",
                overflow: 'auto',
              }}
            >
              <CycleAttitudes idCycle={idCycle} />
            </PivotItem>
            <PivotItem
              headerText="Pilares"
              style={{
                // height: "calc(100% - 55px)",
                position: 'relative',
                // overflow: 'auto',
                top: 55,
              }}
              itemKey="1"
              key="1"
            >
              <Pillars idCycle={idCycle} />
            </PivotItem>
            <PivotItem
              headerText="Painel estratégico"
              itemKey="2"
              key="2"
            >
              <Goals
                idCycle={this.props.match.params.idCiclo}
                changePivot={this.handlePivot}
                currentPivot={this.state.currentPivot}
                currentPainel={currentPainel}
                handleChangeNivel={this.handleChangePainel}
              />
            </PivotItem>
            <PivotItem
              headerText="Desmembramento das Metas"
              itemKey="3"
              key="3"
            >
              <Dismemberment idCiclo={idCycle} />
            </PivotItem>
            {
              ciclo.status === 'V' &&
              <PivotItem
                headerText="Validação"
                itemKey="4"
                key="4"
              >
                <Validation idCiclo={idCycle!} />
              </PivotItem>
            }

          </Pivot>
        </Wrapper>
      </Div>
    );
  };
};



const mapStateToProps = (state: RootState) => ({
  cycle: state.cycleReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
  ...getCycle,
  ...setCurrentPage,
  ...getPillarsCycle,
  ...startValidationCycle,
  ...clearDismemberment,
  ...getAttitudes
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cycle);
