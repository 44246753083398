// #region Imports
import React, { Component, useState, createRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik } from "formik";
import * as yup from "yup";

//FluentUI
import { Stack } from "office-ui-fabric-react/lib/Stack";
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from "office-ui-fabric-react/lib/DetailsList";
import { ShimmeredDetailsList } from "office-ui-fabric-react/lib/ShimmeredDetailsList";
import { SearchBox } from "office-ui-fabric-react/lib/SearchBox";
import { CommandBarButton, ICommandBarStyles } from "office-ui-fabric-react";
import { MarqueeSelection } from "office-ui-fabric-react/lib/MarqueeSelection";
import {
  Dialog,
  DialogFooter,
} from "office-ui-fabric-react/lib/Dialog";
import { FontIcon } from "office-ui-fabric-react/lib/Icon";
import { SwatchColorPicker } from "office-ui-fabric-react/lib/SwatchColorPicker";
import { DefaultButton as DefaultBtn } from "office-ui-fabric-react";
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

import {
  ColorPicker,
  IColor,
  IRefObject,
  IColorPicker,
} from "office-ui-fabric-react/lib/index";
import { useConstCallback } from "@uifabric/react-hooks";
//MaterialUI
import InputAdornment from "@material-ui/core/InputAdornment";
//Componentes
import CustomDialog from "~/components/CustomDialogFluentUI";
import Panel from "~/components/layout/Panel";
import { InputText } from '~/components/Forms';
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
import colors from "~/assets/js/colors";
import { DeleteButton } from '~/components/Buttons';
//Estilos
import {
  Wrapper,
  ListContainer,
  PrimaryButton,
  DefaultButton,
} from "./styles";

import { Creators as getPillars } from "~/store/ducks/pillars";
import { Creators as addPillar } from "~/store/ducks/pillars";
import { Creators as editPillar } from "~/store/ducks/pillars";
import { Creators as delPillar } from "~/store/ducks/pillars";
import { PillarType, DataTypes } from "~/store/ducks/pillars/types";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { BreadcrumbItems, Page } from "~/store/ducks/home/types";
import { RootState } from "~/store/ducks";

//#endregion

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44
  }
}

/**
 * Validação do formulário
 */
const validationCompanySchema = yup.object().shape({
  nomePilar: yup
    .string()
    .min(3, "O nome não pode ter menos que 3 caracteres")
    .max(100, "O nome não pode ter mais do que 100 caracteres")
    .required("Campo obrigatório"),
  corPilar: yup
    .string()
    .min(6, "A cor deve conter 6 caracteres")
    .max(6, "A cor não pode ter mais do que 6 caracteres")
    .required("Campo obrigatório"),
});

const colorCells = [
  { id: "a", label: "black", color: colors.black },
  { id: "b", label: "gray", color: colors.gray },
  { id: "c", label: "teal", color: colors.greenDarker },
  { id: "d", label: "green", color: colors.green },
  { id: "e", label: "yellow", color: colors.yellow },
  { id: "f", label: "orange", color: colors.orange },
  { id: "g", label: "red", color: colors.red },
  { id: "h", label: "lightBlue", color: colors.romasoftBlue },
  { id: "i", label: "blue", color: colors.blue },
  { id: "j", label: "purple", color: colors.purple },
];

type GroupFilter = {
  stmarche?: boolean;
  emporio?: boolean;
};

type Filter = {
  isOpen?: boolean;
  filteredBy?: string[];
  group?: GroupFilter;
};

interface IPillarstate {
  columns: IColumn[];
  items: PillarType[];
  isPanelOpen: boolean;
  isDialogOpen: boolean;
  isDialogColorsOpen: boolean;
  openPanel: boolean;
  isLoadData: boolean;
  inicialValues: PillarType;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
  filter: Filter;
  colorPicker: string;
}

const itemsBreadCrumb: BreadcrumbItems[] = [
  { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
  { text: "Pilares", isCurrentItem: true },
];

const page: Page = {
  key: "pilares",
  pages: itemsBreadCrumb,
};

interface IProps {
  pillars: DataTypes;
  getPillars: () => void;
  addPillar: (pillar: PillarType) => void;
  editPillar: (pillar: PillarType) => void;
  delPillar: (id: number) => void;
  setCurrentPage: (page: Page) => void;
}

class Pillars extends Component<IProps, IPillarstate> {
  private formRef: any;
  private colorRef: any;
  private inputSearch: any;
  private _selection: Selection;

  constructor(props: IProps) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: "column1",
        name: "ID",
        ariaLabel: "ID",
        fieldName: "idPilar",
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 100,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: "column2",
        name: "Descrição",
        fieldName: "nomePilar",
        minWidth: 210,
        maxWidth: 350,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
      },
      {
        key: "column3",
        name: "Cor",
        fieldName: "cor",
        isRowHeader: true,
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: "string",
        isPadded: true,
        className: "rowColorPillar",
        onRender: (item: PillarType) => {
          return (
            <>
              <FontIcon
                iconName="StatusCircleInner"
                style={{ color: item.corPilar, marginRight: 5 }}
              />
              {`${item.corPilar}`}
            </>
          );
        },
      },
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isDialogOpen: false,
      isPanelOpen: false,
      isDialogColorsOpen: false,
      openPanel: false,
      isLoadData: true,
      selectionDetails: "",
      inicialValues: {
        idPilar: null,
        nomePilar: "",
        corPilar: "",
      },
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      filter: {
        isOpen: false,
        filteredBy: [],
        group: {
          stmarche: false,
          emporio: false,
        },
      },
      colorPicker: "#FFFFFF",
    };
    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = createRef();
    this.colorRef = createRef();
    this.inputSearch = createRef();
    this.swatchColorPickerOnColorChange = this.swatchColorPickerOnColorChange.bind(
      this
    );
  }

  componentDidMount() {
    const { setCurrentPage, getPillars } = this.props;
    setCurrentPage(page);
    getPillars();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.isPanelOpen) {
      if (prevProps.pillars.success !== this.props.pillars.success) {
        if (this.props.pillars.success) {
          this.setState({ isPanelOpen: false })
        }
      }
    }
  }

  // Submit do formulário Formik
  handleSubmit = () => {
    const formRef = this.formRef.current;
    if (formRef) {
      formRef.handleSubmit();
    }
  };

  _onItemInvoked = (item: PillarType): void => {
    item.corPilar = item.corPilar?.replace("#", "");
    this.setState({
      isPanelOpen: true,
      inicialValues: {
        ...item,
      },
    });
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  }

  openEdit = () => {
    let value = this._selection.getSelection()[0] as PillarType;
    value.corPilar = value.corPilar?.replace("#", "");
    this.setState({ isPanelOpen: true, inicialValues: value });
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  excludePillar = () => {
    this.props.delPillar(
      (this._selection.getSelection()[0] as PillarType).idPilar!
    );
    this.setState({ isDialogExcludeOpen: false });
  };

  //#region  Funções do filtro

  // toggleFilter = () => {
  //     this.setState({
  //         filter: {
  //             ...this.state.filter,
  //             isOpen: !this.state.filter.isOpen,
  //         }
  //     })
  // }

  // toggleParamsFilter = (param: string) => {
  //     const filter = this.state.filter
  //     const params = filter.filteredBy
  //     const stmarche = filter.group?.stmarche
  //     const emporio = filter.group?.emporio

  //     const index = params!.indexOf(param);
  //     if (index > -1) {
  //         params!.splice(index, 1);
  //     } else {
  //         params!.push(param)
  //     }

  //     if (param === "ST MARCHE") {
  //         this.setState({ filter: { ...this.state.filter, filteredBy: params, group: { ...this.state.filter.group, stmarche: !stmarche } } })
  //     } else {
  //         this.setState({ filter: { ...this.state.filter, filteredBy: params, group: { ...this.state.filter.group, emporio: !emporio! } } })
  //     }

  //     if (this.inputSearch.current) {
  //         this.filter(this.inputSearch.current.state.value)
  //     }

  // }

  filter = (text: any) => {
    const params = this.state.filter.filteredBy;
    const pillars = this.props.pillars.data;
    let items = text
      ? pillars.filter(
        (i: PillarType) =>
          i.nomePilar!.toLowerCase().indexOf(text.toLowerCase()) > -1
      )
      : pillars;
    if (params!.length > 0) {
      items = items.filter((item: PillarType): string[] => {
        return params!.filter(param => item.corPilar === param)
      });
    }
    this.setState({ items, isFiltering: true });
  };

  // clearFilters = () => {
  //     this.setState({
  //         filter: {
  //             ...this.state.filter,
  //             filteredBy: [],
  //             group:
  //             {
  //                 emporio: false,
  //                 stmarche: false
  //             }
  //         }
  //     }, () => this.filter(""))
  // }

  swatchColorPickerOnColorChange(id?: string, color?: string) {
    const descricao = (document.getElementById("nomePilar") as HTMLInputElement)
      .value;
    this.setState({
      inicialValues: {
        ...this.state.inicialValues,
        nomePilar: descricao,
        corPilar: color!.replace("#", "").toUpperCase(),
      },
      colorPicker: color!,
    });
  }

  setColor = () => {
    if (this.colorRef.current) {
      const descricao = (document.getElementById(
        "nomePilar"
      ) as HTMLInputElement).value;
      const color: string = this.colorRef.current.props.color;
      this.setState({
        inicialValues: {
          ...this.state.inicialValues,
          nomePilar: descricao,
          corPilar: color.toUpperCase(),
        },
        isDialogColorsOpen: false,
      });
    }
  };
  //#endregion

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Edit" }}
            text="Editar"
            onClick={() => this.openEdit()}
          />
          <CommandBarButton
            styles={btnStyle}
            iconProps={{ iconName: "Delete" }}
            text="Excluir"
            onClick={() => this.setState({ isDialogExcludeOpen: true })}
          />
        </>
      );
    } else if (selectionCount > 1) {
      return (
        <CommandBarButton
          styles={btnStyle}
          iconProps={{ iconName: "Delete" }}
          text="Excluir"
          onClick={() => this.setState({ isDialogExcludeOpen: true })}
        />
      );
    }
  };

  render() {
    const {
      isPanelOpen,
      isDialogExcludeOpen,
      isDialogColorsOpen,
      selectionCount,
      inicialValues,
      isFiltering,
      items,
      columns,
    } = this.state;
    const { pillars, addPillar, editPillar, getPillars } = this.props;

    const dialogContentProps = {
      title: "Excluir?",
      closeButtonAriaLabel: "Close",
      subText:
        selectionCount <= 1
          ? "Tem certeza de que deseja excluir este item?"
          : "Tem certeza de que deseja excluir estes items?",
    };

    const dialogContentColorProps = {
      title: "Mais Cores",
      closeButtonAriaLabel: "Close",
    };

    return (
      <>
        <Panel
          title={!inicialValues.idPilar ? "Novo Pilar" : inicialValues.nomePilar}
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton
                onClick={() => this.cancelPanel()}
              >
                Cancelar
              </DefaultButton>
              {
                !pillars.loadingAction ?
                  <PrimaryButton onClick={this.handleSubmit}>Salvar</PrimaryButton>
                  :
                  <Spinner styles={{ root: { width: 110 } }} size={SpinnerSize.small} />
              }
            </Stack>
          }
        >
          <Formik
            enableReinitialize={true}
            innerRef={this.formRef}
            initialValues={inicialValues}
            validationSchema={validationCompanySchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values: PillarType) => {
              if (inicialValues.idPilar === null) {
                addPillar({ ...values, corPilar: `#${values.corPilar}` });
              } else {
                values.idPilar = inicialValues.idPilar;
                editPillar({ ...values, corPilar: `#${values.corPilar}` });
              }
            }}
          >
            {({ handleSubmit, handleChange, values, errors, setFieldError }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <InputText
                    value={values.nomePilar}
                    onChange={(e) => { handleChange(e); setFieldError("nomePilar", "") }}
                    id="nomePilar"
                    error={errors.nomePilar ? true : false}
                    name="nomePilar"
                    type="text"
                    label="Descrição"
                    helperText={errors.nomePilar}
                    className="mt-2"
                    inputProps={{ maxLength: 100 }}
                    autoFocus
                  />
                  <InputText
                    value={values.corPilar}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldError("corPilar", "");
                      this.setState({
                        inicialValues: {
                          ...this.state.inicialValues,
                          nomePilar: values.nomePilar,
                          corPilar: e.target.value,
                        },
                      });
                    }}
                    id="cor"
                    error={errors.corPilar ? true : false}
                    name="corPilar"
                    type="text"
                    label="Cor"
                    helperText={errors.corPilar}
                    className="mt-2"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">#</InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <FontIcon
                            iconName="CircleShapeSolid"
                            style={{ color: `#${values.corPilar}` }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      maxLength: 6,
                    }}
                  />
                  <SwatchColorPicker
                    columnCount={5}
                    cellShape={"circle"}
                    colorCells={colorCells}
                    isControlled={true}
                    onColorChanged={this.swatchColorPickerOnColorChange}
                  />
                  <DefaultBtn
                    iconProps={{ iconName: "color" }}
                    onClick={() =>
                      this.setState({
                        isDialogColorsOpen: true,
                        colorPicker: values.corPilar!,
                      })
                    }
                  >
                    Mais Cores
                  </DefaultBtn>

                  <Dialog
                    minWidth={400}
                    hidden={!isDialogColorsOpen}
                    onDismiss={() =>
                      this.setState({ isDialogColorsOpen: false })
                    }
                    dialogContentProps={dialogContentColorProps}
                  >
                    <ColorPickerBasicExample
                      colorInicial={values.corPilar}
                      ref={this.colorRef as IRefObject<IColorPicker>}
                    />
                    <DialogFooter>
                      <DefaultButton
                        onClick={() =>
                          this.setState({ isDialogColorsOpen: false })
                        }
                        text="Cancelar"
                      />
                      <PrimaryButton onClick={this.setColor} text="Escolher" />
                    </DialogFooter>
                  </Dialog>
                </form>
              );
            }}
          </Formik>
        </Panel>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton
            onClick={() => this.setState({ isDialogExcludeOpen: false })}
            text="Cancelar"
          />
          <DeleteButton
            onClick={() => this.excludePillar()}
            text="Excluir"
          />
        </CustomDialog>
        <Wrapper>
          <HeaderPage
            title="Pilares"
            leftItems={
              <>
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: "Add" }}
                  disabled={this.props.pillars.loadingData}
                  text="Adicionar Pilares"
                  onClick={() =>
                    this.setState({
                      isPanelOpen: true,
                      inicialValues: { idPilar: null, nomePilar: "", corPilar: "" },
                    })
                  }
                />
                {this.commandBarRender()}
              </>
            }
            rightItems={
              <>
                <SearchBox
                  placeholder="Pesquisar"
                  disabled={pillars.data.length === 0}
                  onChange={(e) => this.filter(e?.target.value)}
                  componentRef={this.inputSearch}
                  styles={{
                    root: { border: "none", width: 200, marginRight: 1 },
                  }}
                />
                <CommandBarButton
                  styles={btnStyle}
                  iconProps={{ iconName: "Refresh" }}
                  text="Atualizar"
                  onClick={() => getPillars()}
                />
                {/* <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'filter' }} text="Filtrar" onClick={() => this.toggleFilter()} /> */}
              </>
            }
          />
          {
            !pillars.loadingData && pillars.data.length === 0 ?
              <NoItems
                error={pillars.error}
                text="Não há pilares cadastrados"
                img="/static/icons/pillar.svg"
                alt="Pilar"
              />
              :
              <ListContainer>
                <ShimmeredDetailsList
                  items={isFiltering ? items : pillars.data}
                  enableShimmer={pillars.loadingData}
                  columns={columns}
                  selectionMode={SelectionMode.single}
                  selection={this._selection}
                  getKey={this._getKey}
                  selectionPreservedOnEmptyClick={true}
                  setKey="single"
                  layoutMode={DetailsListLayoutMode.justified}
                  isHeaderVisible={true}
                  onItemInvoked={this._onItemInvoked}
                />
              </ListContainer>
          }
        </Wrapper>
      </>
    );
  }

  private _onColumnClick = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): void => {
    const { columns, isFiltering, items: itemsState } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: PillarType[] = [];
    if (isFiltering) {
      items = itemsState;
    } else {
      items = this.props.pillars.data;
    }
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = this._sort(
      items,
      currColumn.fieldName!,
      currColumn.isSortedDescending
    );
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined) return item.key;
  }

  private _sort<T>(
    items: T[],
    columnKey: string,
    isSortedDescending?: boolean
  ): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    );
    return itemsSorted;
  }
}


const ColorPickerBasicExample: React.FunctionComponent<any> = React.forwardRef<
  any,
  any
>((props, ref: any) => {
  const [color, setColor] = useState(props.colorInicial);
  const updateColor = useConstCallback((ev: any, colorObj: IColor) =>
    setColor(colorObj.hex)
  );
  return (
    <ColorPicker
      color={color}
      onChange={updateColor}
      componentRef={ref}
      showPreview={true}
      alphaType="none"
      styles={{
        root: {
          maxWidth: 352,
          minWidth: 352,
        },
        panel: { padding: 0, paddingBottom: 10 },
        colorRectangle: { height: 268 },
      }}
      // The ColorPicker provides default English strings for visible text.
      // If your app is localized, you MUST provide the `strings` prop with localized strings.
      strings={{
        // By default, the sliders will use the text field labels as their aria labels.
        // If you'd like to provide more detailed instructions, you can use these props.
        alphaAriaLabel:
          "Alpha slider: Use left and right arrow keys to change value, hold shift for a larger jump",
        transparencyAriaLabel:
          "Transparency slider: Use left and right arrow keys to change value, hold shift for a larger jump",
        hueAriaLabel:
          "Hue slider: Use left and right arrow keys to change value, hold shift for a larger jump",
      }}
    />
  );
});

const mapStateToProps = (state: RootState) => ({
  pillars: state.pillarsReducer,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      ...getPillars,
      ...addPillar,
      ...editPillar,
      ...delPillar,
      ...setCurrentPage,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Pillars);
