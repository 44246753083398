import React from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

import { SelectInput, WrapperField } from './styles';
import colors from "~/assets/js/colors"

interface IProps {
    errors: any;
    values?: string;
    handleChange?: any;
    name: string;
    label: string;
    helperText?: string;
    errorText?: string;
    variant?: "filled" | "outlined" | "standard";
    styles?: React.CSSProperties;
    fieldHeight?: number;
    customPadding?: string;
    autofocus?: boolean;
}

const Dropdown: React.FC<IProps> = props => {
    return (
        <WrapperField height={props.fieldHeight}>
            <InputLabel id="idLabel" style={{ color: props.errors ? colors.error : "" }}>{props.label}</InputLabel>
            <SelectInput
                variant={props.variant}
                id={props.name}
                error={props.errors ? true : false}
                labelId="idLabel"
                name={props.name}
                value={props.values}
                onChange={props.handleChange}
                style={props.styles}
                custompadding={props.customPadding}
                autoFocus={props.autofocus}
            >
                {props.children}
            </SelectInput>
            <FormHelperText style={{ color: props.errors ? colors.error : "" }}>{!props.errors ? props.helperText : props.errorText}</FormHelperText>
        </WrapperField>
    );
}

export default Dropdown;