import React, { useCallback } from 'react';
import currency from "currency.js";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

import { TextFieldProps } from '@material-ui/core/TextField';
import { InputContainer, Input } from './styles';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardDatePickerProps
} from '@material-ui/pickers';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';

interface IInputContainerProps {
    styles?: React.CSSProperties;
    height_container?: number | string;
    min_height_container?: number | string;
    smaller?: "small";
}

const InputText: React.FC<TextFieldProps & IInputContainerProps> = (props) => {
    return (
        <InputContainer
            fixed style={props.styles}
            heightcontainer={props.height_container}
            min_height_container={props.min_height_container}
        >
            <Input
                {...props}
                smaller={props.smaller}
                className="mt-2"
            />
        </InputContainer>
    )
}


const InputDate: React.FC<KeyboardDatePickerProps> = (props) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR} >
            <KeyboardDatePicker
                {...props}
                autoOk
                disableToolbar
                variant="inline"
                style={{ width: '100%', height: 70 }}
                value={props.value}
                format="dd/MM/yyyy"
                keyboardIcon={<FontIcon iconName="Calendar" style={{ fontSize: 20 }} />}
                KeyboardButtonProps={{
                    "aria-label": "change date"
                }}
            />
        </MuiPickersUtilsProvider>
    );
}

const InputDateDialog: React.FC<KeyboardDatePickerProps> = (props) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR} >
            <KeyboardDatePicker
                {...props}
                style={{ width: '100%', height: 70 }}
                value={props.value}
                format="dd/MM/yyyy"
                cancelLabel="Cancelar"
                keyboardIcon={<FontIcon iconName="Calendar" style={{ fontSize: 20 }} />}
                KeyboardButtonProps={{
                    "aria-label": "change date"
                }}
            />
        </MuiPickersUtilsProvider>
    );
}

interface IProps {
    label: string | JSX.Element;
}

const InputCheckbox: React.FC<CheckboxProps & IProps> = (props) => {
    return (
        <FormControlLabel
            control={
                <Checkbox
                    {...props}
                    color="primary"
                />
            }
            label={props.label}
        />
    )
}

interface IAutocompleteProps {
    input: {
        helperTextInput?: string;
        errorInput?: boolean;
        idInput: string;
        labelInput: string;
        autoFocus?: boolean;
        variant?: "standard" | "outlined" | "filled";
        size?: "medium" | "small";
        styles?: React.CSSProperties;
        smaller?: "small";
    }
}

function InputAutocomplete<T>(props: Partial<AutocompleteProps<T, false, false, false>> & IAutocompleteProps):
    React.ReactElement<AutocompleteProps<T, false, false, false>> {
    return (
        <Autocomplete
            {...props}
            id={props.input.idInput}
            options={props.options!}
            noOptionsText="Não há opções"
            renderInput={(params) => <InputText
                {...params}
                variant={props.input.variant}
                label={props.input.labelInput}
                helperText={props.input.helperTextInput}
                type="text"
                error={props.input.errorInput}
                name={props.input.idInput}
                autoFocus={props.input.autoFocus}
                size={props.input.size}
                styles={props.input.styles}
                smaller={props.input.smaller}
            />}
        />
    )
}

const VALID_FIRST = /^[1-9]{1}$/;
const VALID_NEXT = /^[0-9]{1}$/;
const DELETE_KEY_CODE = 8;

const CurrencyInput = (props: any) => {
    const {
        className = "",
        style = {},
        currencyConfig = {
            locale: "pt-BR",
            currencyCode: "BRL",
            currencyDisplay: "symbol",
            useGrouping: true,
            minimumFractionDigits: undefined
        },
        customInput = InputText,
        name,
        id,
        max = Number.MAX_SAFE_INTEGER,
        onChange,
        value
    } = props;

    const fakeChangeEvent: any = {
        target: {
            type: "number",
            name,
            id,
        }
    };

    const valueInCents = currency(value).intValue;
    const valueAbsTrunc = Math.trunc(Math.abs(valueInCents));
    if (
        valueInCents !== valueAbsTrunc ||
        !Number.isFinite(valueInCents) ||
        Number.isNaN(valueInCents)
    ) {
        throw new Error(`invalid value property`);
    }
    const handleKeyDown = useCallback(
        e => {
            const { key, keyCode } = e;
            if (
                (valueInCents === 0 && !VALID_FIRST.test(key)) ||
                (valueInCents !== 0 &&
                    !VALID_NEXT.test(key) &&
                    keyCode !== DELETE_KEY_CODE)
            ) {
                return;
            }
            const valueString = valueInCents.toString();
            let nextValue;
            if (keyCode !== DELETE_KEY_CODE) {
                const nextValueString =
                    valueInCents === 0 ? key : `${valueString}${key}`;
                nextValue = Number.parseInt(nextValueString, 10);
            } else {
                const nextValueString = valueString.slice(0, -1);
                nextValue =
                    nextValueString === "" ? 0 : Number.parseInt(nextValueString, 10);
            }
            if (nextValue > max) {
                return;
            }
            // Enforce our division with currency to prevent rounding errors
            fakeChangeEvent.target.value = currency(nextValue / 100).value;
            onChange(fakeChangeEvent);
        },
        [max, onChange, valueInCents, fakeChangeEvent]
    );
    const handleChange = useCallback(() => {
        // DUMMY TO AVOID REACT WARNING
    }, []);

    const {
        locale,
        currencyCode,
        useGrouping,
        minimumFractionDigits
    } = currencyConfig;

    const valueDisplay = currency(valueInCents / 100).value.toLocaleString(
        locale,
        {
            style: "currency",
            currency: currencyCode,
            useGrouping,
            minimumFractionDigits
        }
    );

    const inputProps: any = {
        "data-testid": "currency-input",
        className: className,
        inputMode: "numeric",
        onChange: handleChange,
        onKeyDown: handleKeyDown,
        style: style,
        value: props.value !== "" ? valueDisplay.replace("R$", "") : ""
    };

    if (customInput) {
        const customProps = { ...props, ...inputProps };
        delete customProps.customInput;
        delete customProps.currencyConfig;
        const CustomInput = customInput;
        return <CustomInput {...customProps} />;
    }

    return <input {...inputProps} />;
};



export { InputText, InputDate, InputDateDialog, InputCheckbox, CurrencyInput, InputAutocomplete };