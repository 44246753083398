import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
//FluentUI
import { Text } from 'office-ui-fabric-react/lib/Text';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { PrimaryButton } from 'office-ui-fabric-react';
import { Pivot, PivotItem, IPivotStyles } from 'office-ui-fabric-react/lib/Pivot';

//Componentes
import { LabelGroup } from '~/components/label';
import { RootState } from "~/store/ducks";

import Perfomance from './Perfomance';

import {
    Wrapper,
    ContainerCycle,
    Title,
    DetailsWrapper,
    WrapperButton,
    Div,
} from "./styles";

// import { Creators as getCycle } from "~/store/ducks/cycle";
import { Creators } from "~/store/ducks/home";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { DataTypes } from "~/store/ducks/cycle/types";

const PivotStyles: Partial<IPivotStyles> = {
    root: {
        borderBottom: '1px solid #CFD3DA',
        padding: '10px 20px 0px',
        backgroundColor: "#FFFFFF",
        width: '100%',
        position: 'fixed',
        zIndex: 999
    }
}

interface IProps {
    cycle: DataTypes;
    getCycle: any;
    setCurrentPage: (page: Page) => void;
    match: any;
};

const Calibration: React.FC<IProps> = (props) => {

    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
    const [redirect, setRedirect] = useState<boolean>(false)
    const dispatch = useDispatch();

    useEffect(() => {
        const items: BreadcrumbItems[] = [
            { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
            { text: 'Calibração', url: "/calibracao" },
            { text: props.match.params.year, isCurrentItem: true },
        ];
        const page: Page = {
            key: 'ciclos',
            pages: items
        };
        dispatch(Creators.setCurrentPage(page));
    }, [])

    const toggleDialog = () => {
        setIsDialogOpen(!isDialogOpen)
    };

    // if (redirect) return (
    //     <Redirect to="/" />
    // );

    return (
        <Div>
            <ContainerCycle>
                <Title>
                    <FontIcon iconName="Equalizer" style={{ fontSize: 30, marginRight: 12, alignSelf: 'center' }} />
                    <Text variant="xxLarge">Reunião de Calibração 2019</Text>
                </Title>
                <DetailsWrapper>
                    <LabelGroup icon="HomeGroup" label="Grupo" value="GRUPO RH" />
                    <LabelGroup icon="Contact" label="Líder" value="ANDRÉ PREARO" />
                    <LabelGroup icon="Contact" label="Avaliador" value="FERNANDA DIAS" />
                    <WrapperButton>
                        <PrimaryButton text="Publicar Calibração" />
                    </WrapperButton>
                </DetailsWrapper>
            </ContainerCycle>
            <Wrapper>
                <Pivot aria-label="Definições do ciclo"
                    styles={PivotStyles}>
                    <PivotItem headerText="Curva Perfomance">
                        <Perfomance />
                    </PivotItem>
                    <PivotItem headerText="Histórico de modificações">
                        <h1>Item 2</h1>
                    </PivotItem>
                </Pivot>
            </Wrapper>
        </Div>
    );
};

export default Calibration;