import React, { useState, useEffect } from 'react';

import Drawer from '@material-ui/core/Drawer';

import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { IconButton } from 'office-ui-fabric-react';

import { Container, Footer, WrapperContent } from './styles';
import colors from "~/assets/js/colors";


export enum SizeDialog {
    small = 420,
    large = 640
}

export enum SizePanel {
    normal = 440,
    large = 640
}

interface IPanel {
    open: boolean;
    title?: string;
    onClose: () => void;
    footer?: JSX.Element;
    itemsHeader?: JSX.Element | null;
    hideClose?: boolean;
    width?: SizePanel;
    loading?: boolean;
};

const Panel: React.FC<IPanel> = props => {

    const [panel, setPanel] = useState(false);

    useEffect(() => {
        // gerencia o panel
        if (props.open) {
            setPanel(true);
        } else {
            setPanel(false);
        };

    }, [props.open]);

    return (
        <>
            <Drawer anchor="right" open={panel} >
                <Container width={props.width ? props.width : SizePanel.normal}>
                    {
                        !props.loading &&
                        <Stack horizontal verticalAlign="center" horizontalAlign="space-between" styles={{ root: { padding: 30, alignItems: 'flex-start' } }}>
                            <Text variant="xxLarge">{props.title}</Text>
                            <Stack horizontal verticalAlign="center">
                                {props.itemsHeader}
                                <IconButton
                                    iconProps={{ iconName: 'ChromeClose' }}
                                    styles={{ root: { color: colors.darkGray, display: props.hideClose ? 'none' : 'block' } }}
                                    title="Fechar"
                                    ariaLabel="Fechar"
                                    onClick={props.onClose}
                                />
                            </Stack>
                        </Stack>
                    }
                    <WrapperContent>
                        {props.children}
                    </WrapperContent>
                    <Footer>
                        {props.footer}
                    </Footer>
                </Container>
            </Drawer>
        </>
    );
};

export default Panel;