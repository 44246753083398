import { takeLatest, put, call, all } from "redux-saga/effects";
import {
    Types,
    AttitudeType,
    IRequestAttitudesLoadAction,
    IRequestAttitudesLoadByIdAction,
    IRequestAttitudesPostAction,
    IRequestAttitudesEditAction,
    IRequestAttitudesDeleteAction,
    ISuccessAttitudesLoadAction,
    ISuccessAttitudesLoadByIdAction,
    ISuccessAttitudesPostAction,
    ISuccessAttitudesEditAction,
    ISuccessAttitudesDeleteAction,
} from "../ducks/attitudes/types";
import api from "~/services/api";


const apiCallGet = (search: string) => {
    return api
        .get(`/atitudes${search ? `?SearchText=${search}` : ''}`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};

const apiCallGetById = (id: number) => {
    return api
        .get(`/atitudes/${id}`)
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};

const apiCallPost = (attitude: IRequestAttitudesPostAction["attitude"]) => {
    return api
        .post(`/atitudes`, {
            ...attitude
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};

const apiCallEdit = (attitude: IRequestAttitudesEditAction["attitude"]) => {
    return api
        .put(`/atitudes/${attitude.idAtitude}`, {
            ...attitude
        })
        .then((response) => {
            return response.data;
        })
        .catch((err) => {
            throw err.response;
        });
};

const apiCallDel = (id: number) => {
    return api
        .delete(`/atitudes/${id}`)
        .then((response) => {
            return response.data;
        }).catch((err) => {
            throw err.response;
        });
};


function* load(action: IRequestAttitudesLoadAction) {
    try {
        let items: any = yield call(apiCallGet, action.search);
        yield put({
            type: Types.SUCCESS_ATTITUDES_LOAD,
            payload: items
        } as ISuccessAttitudesLoadAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar'
        yield put({
            type: Types.FAILURE_ATTITUDES_LOAD,
            message
        });
    }
}

function* loadById(action: IRequestAttitudesLoadByIdAction) {
    try {
        let item: AttitudeType = yield call(apiCallGetById, action.id);
        yield put({
            type: Types.SUCCESS_ATTITUDES_LOAD_BY_ID,
            payload: item
        } as ISuccessAttitudesLoadByIdAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar a atitude'
        yield put({
            type: Types.FAILURE_ATTITUDES_LOAD_BY_ID,
            message
        });
    }
}

function* add(action: IRequestAttitudesPostAction) {
    try {
        const idAtitude: any = yield call(apiCallPost, action.attitude);
        yield put({
            type: Types.SUCCESS_ATTITUDES_ADD,
            payload: { ...action.attitude, ...idAtitude }
        } as ISuccessAttitudesPostAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível inserir atitude'
        yield put({
            type: Types.FAILURE_ATTITUDES_ADD,
            message
        });
    }
}

function* edit(action: IRequestAttitudesEditAction) {
    try {
        yield call(apiCallEdit, action.attitude);
        yield put({
            type: Types.SUCCESS_ATTITUDE_EDIT,
            payload: action.attitude
        } as ISuccessAttitudesEditAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível editar a atitude'
        yield put({
            type: Types.FAILURE_ATTITUDE_EDIT,
            message
        });
    }
}

function* del(action: IRequestAttitudesDeleteAction) {
    try {
        yield call(apiCallDel, action.id);
        yield put({
            type: Types.SUCCESS_ATTITUDE_DELETE,
            id: action.id
        } as ISuccessAttitudesDeleteAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluir a atitude'
        yield put({
            type: Types.FAILURE_ATTITUDE_DELETE,
            message
        });
    }
}

export default function* companiesRoot() {
    yield all([
        yield takeLatest(Types.REQUEST_ATTITUDES_LOAD, load),
        yield takeLatest(Types.REQUEST_ATTITUDES_LOAD_BY_ID, loadById),
        yield takeLatest(Types.REQUEST_ATTITUDES_ADD, add),
        yield takeLatest(Types.REQUEST_ATTITUDE_EDIT, edit),
        yield takeLatest(Types.REQUEST_ATTITUDE_DELETE, del),
    ]);
}
