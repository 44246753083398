import React from 'react';

import { Text } from 'office-ui-fabric-react/lib/Text';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';

import { DetailsWrapperItem, DetailsWrapperText } from './styles';

interface ILabelGroupProps {
    icon: string;
    label: string;
    value: string;
    size?: number;
    name?: string;
    spaceBetween?: number;
    styles?: React.CSSProperties;
}

const LabelGroup: React.FC<ILabelGroupProps> = (props) => {
    const { icon, label, value, size, spaceBetween, styles, name } = props;

    return (
        <DetailsWrapperItem spaceBetween={spaceBetween} style={styles} className={name}>
            <FontIcon iconName={icon} style={{ marginRight: 6, color: '#666666' }} />
            <DetailsWrapperText>
                <Text variant="small" styles={{ root: { color: '#666666', marginBottom: 4, fontSize: size ? size : 12 } }}>{label}</Text>
                <Text variant="small" styles={{ root: { fontWeight: 'bold', fontSize: size ? size : 12  } }}>{value}</Text>
            </DetailsWrapperText>
        </DetailsWrapperItem>
    )
}

export { LabelGroup };
