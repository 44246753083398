import { IUnit } from "../units/types";

/**
 * Types
 */
export enum Types {
  //GET
  REQUEST_EMPLOYEES_LOAD = "employees/REQUEST_EMPLOYEES_LOAD",
  SUCCESS_EMPLOYEES_LOAD = "employees/SUCCESS_EMPLOYEES_LOAD",
  FAILURE_EMPLOYEES_LOAD = "employees/FAILURE_EMPLOYEES_LOAD",
  //GET BY ID
  REQUEST_EMPLOYEES_LOAD_ID = "employees/REQUEST_EMPLOYEES_LOAD_ID",
  SUCCESS_EMPLOYEES_LOAD_ID = "employees/SUCCESS_EMPLOYEES_LOAD_ID",
  FAILURE_EMPLOYEES_LOAD_ID = "employees/FAILURE_EMPLOYEES_LOAD_ID",
  //ADD
  REQUEST_EMPLOYEES_ADD = "employees/REQUEST_EMPLOYEES_EMAIL",
  SUCCESS_EMPLOYEES_ADD = "employees/SUCCESS_EMPLOYEES_ADD",
  FAILURE_EMPLOYEES_ADD = "employees/FAILURE_EMPLOYEES_ADD",
  //PUT
  REQUEST_EMPLOYEES_EDIT = "employees/REQUEST_EMPLOYEES_EDIT",
  SUCCESS_EMPLOYEES_EDIT = "employees/SUCCESS_EMPLOYEES_EDIT",
  FAILURE_EMPLOYEES_EDIT = "employees/FAILURE_EMPLOYEES_EDIT",
  //PUT PASSWORD
  REQUEST_EMPLOYEES_EDIT_ACCESS = "employees/REQUEST_EMPLOYEES_EDIT_ACCESS",
  SUCCESS_EMPLOYEES_EDIT_ACCESS = "employees/SUCCESS_EMPLOYEES_EDIT_ACCESS",
  FAILURE_EMPLOYEES_EDIT_ACCESS = "employees/FAILURE_EMPLOYEES_EDIT_ACCESS",
  //PUT ACCESS
  REQUEST_EMPLOYEES_EDIT_PASSWORD = "employees/REQUEST_EMPLOYEES_EDIT_PASSWORD",
  SUCCESS_EMPLOYEES_EDIT_PASSWORD = "employees/SUCCESS_EMPLOYEES_EDIT_PASSWORD",
  FAILURE_EMPLOYEES_EDIT_PASSWORD = "employees/FAILURE_EMPLOYEES_EDIT_PASSWORD",
  //DELETE
  REQUEST_EMPLOYEES_DELETE = "employees/REQUEST_EMPLOYEES_DELETE",
  SUCCESS_EMPLOYEES_DELETE = "employees/SUCCESS_EMPLOYEES_DELETE",
  FAILURE_EMPLOYEES_DELETE = "employees/FAILURE_EMPLOYEES_DELETE",
  //RESET
  RESET_EMPLOYEES = "employees/RESET_EMPLOYEES",

};
/**
 * Data types
 */

type ItemSelected = {
  item: EmployeeType;
  loading: boolean;
  success: boolean;
  error: boolean;
}

export type EmployeeType = {
  idFuncionario: number | null;
  matricula: string;
  nomeCompleto: string;
  dataNascimento?: Date | null;
  email: string;
  status: string;
  sexo: string;
  dataAdmissao: Date | null;
  dataDemissao?: Date | null;
  cpf: string;
  unidade: Partial<IUnit>;
  flagAcessoRHR: boolean;
  imagem: string;
  // cargo: Partial<PositionTypes>;
  // local: Partial<ChartType>;
  password: string;
};

interface IEmployeesState {
  readonly data: EmployeeType[];
  readonly loadingData: boolean;
  readonly loadingAction: boolean;
  readonly itemSelected: ItemSelected;
  readonly error: boolean;
  readonly success: boolean;
  readonly successEdit: boolean;
};

export type DataTypes = IEmployeesState;

/**
 * Actions
 */

//GET
export interface IRequestEmployeesLoadAction {
  type: typeof Types.REQUEST_EMPLOYEES_LOAD;
  search: object;
};

export interface ISuccessEmployeesLoadAction {
  type: typeof Types.SUCCESS_EMPLOYEES_LOAD;
  payload: EmployeeType[];
};

interface IFailureEmployeesLoadAction {
  type: typeof Types.FAILURE_EMPLOYEES_LOAD;
  message: string;
};

type EmployeesLoadAction = IRequestEmployeesLoadAction | ISuccessEmployeesLoadAction | IFailureEmployeesLoadAction;

//GET
export interface IRequestEmployeesLoadIDAction {
  type: typeof Types.REQUEST_EMPLOYEES_LOAD_ID;
  id: number;
};

interface ISuccessEmployeesLoadIDAction {
  type: typeof Types.SUCCESS_EMPLOYEES_LOAD_ID;
  payload: EmployeeType;
};

interface IFailureEmployeesLoadIDAction {
  type: typeof Types.FAILURE_EMPLOYEES_LOAD_ID;
  message: string;
};

type EmployeesLoadIDAction = IRequestEmployeesLoadIDAction | ISuccessEmployeesLoadIDAction | IFailureEmployeesLoadIDAction;

//ADD
export interface IRequestEmployeeAddAction {
  type: typeof Types.REQUEST_EMPLOYEES_ADD;
  employee: EmployeeType;
};

interface ISuccessEmployeeAddAction {
  type: typeof Types.SUCCESS_EMPLOYEES_ADD;
  employee: EmployeeType;
};

interface IFailureEmployeeAddAction {
  type: typeof Types.FAILURE_EMPLOYEES_ADD;
  message: string;
};

type EmployeeAddAction = IRequestEmployeeAddAction | ISuccessEmployeeAddAction | IFailureEmployeeAddAction;

//PUT
export interface IRequestEmployeeEditAction {
  type: typeof Types.REQUEST_EMPLOYEES_EDIT;
  employee: EmployeeType;
};

export interface ISuccessEmployeeEditAction {
  type: typeof Types.SUCCESS_EMPLOYEES_EDIT;
  employee: EmployeeType;
};

export interface IFailureEmployeeEditAction {
  type: typeof Types.FAILURE_EMPLOYEES_EDIT;
  message: string;
};

type EmployeeEditAction = IRequestEmployeeEditAction | ISuccessEmployeeEditAction | IFailureEmployeeEditAction;

//PUT
export interface IRequestEmployeeEditPasswordAction {
  type: typeof Types.REQUEST_EMPLOYEES_EDIT_PASSWORD;
  payload: {
    id: number;
    password: string;
  };
};

interface ISuccessEmployeeEditPasswordAction {
  type: typeof Types.SUCCESS_EMPLOYEES_EDIT_PASSWORD;
};

export interface IFailureEmployeeEditPasswordAction {
  type: typeof Types.FAILURE_EMPLOYEES_EDIT_PASSWORD;
  message: string;
};

type EmployeeEditPasswordAction = IRequestEmployeeEditPasswordAction | ISuccessEmployeeEditPasswordAction | IFailureEmployeeEditPasswordAction;

//PUT
export interface IRequestEmployeeEditAccessAction {
  type: typeof Types.REQUEST_EMPLOYEES_EDIT_ACCESS;
  payload: {
    id: number;
    access: boolean;
  };
};

interface ISuccessEmployeeEditAccessAction {
  type: typeof Types.SUCCESS_EMPLOYEES_EDIT_ACCESS;
  access: boolean;
};

export interface IFailureEmployeeEditAccessAction {
  type: typeof Types.FAILURE_EMPLOYEES_EDIT_ACCESS;
  message: string;
};

type EmployeeEditAccessAction = IRequestEmployeeEditAccessAction | ISuccessEmployeeEditAccessAction | IFailureEmployeeEditAccessAction;


//DELETE
export interface IRequestEmployeeDeleteAction {
  type: typeof Types.REQUEST_EMPLOYEES_DELETE;
  id: number;
};

export interface ISuccessEmployeeDeleteAction {
  type: typeof Types.SUCCESS_EMPLOYEES_DELETE;
  id: number;
};

export interface IFailureEmployeeDeleteAction {
  type: typeof Types.FAILURE_EMPLOYEES_DELETE;
  message: string;
};

type EmployeeDeleteAction = IRequestEmployeeDeleteAction | ISuccessEmployeeDeleteAction | IFailureEmployeeDeleteAction;

interface IResetEmployeesAction {
  type: typeof Types.RESET_EMPLOYEES;
};


export type EmployeesActionTypes =
  EmployeesLoadAction |
  EmployeesLoadIDAction |
  EmployeeAddAction |
  EmployeeEditAction |
  EmployeeEditPasswordAction |
  EmployeeEditAccessAction |
  EmployeeDeleteAction |
  IResetEmployeesAction;
