import { combineReducers } from "redux";

import loginReducer from "./login";
import companiesReducer from "./companies";
import cyclesReducer from "./cycles";
import cycleReducer from "./cycle";
import cycleAttitudeReducer from "./cycle/attitude";
import cyclePainelReducer from "./cycle/painel";
import cycleDismembermentReducer from "./cycle/dismemberment";
import cycleValidationReducer from "./cycle/validation";
import pillarsReducer from "./pillars";
import homeReducer from "./home";
import objectivesReducer from "./objectives";
import chartReducer from "./chart";
import evaluationsReducer from "./evaluations";
import employeesReducer from "./employees";
import unitsReducer from "./units";
import positionsReducer from "./positions";
import tasksReducer from "./tasks";
import profileReducer from "./profile";
import solicitationReducer from "./solicitation";
import attitudesReducer from "./attitudes";

export const reducers = combineReducers({
    loginReducer,
    companiesReducer,
    cyclesReducer,
    cycleReducer,
    cycleAttitudeReducer,
    cyclePainelReducer,
    cycleDismembermentReducer,
    pillarsReducer,
    homeReducer,
    objectivesReducer,
    chartReducer,
    evaluationsReducer,
    employeesReducer,
    unitsReducer,
    positionsReducer,
    cycleValidationReducer,
    tasksReducer,
    profileReducer,
    solicitationReducer,
    attitudesReducer
});

export type RootState = ReturnType<typeof reducers>;