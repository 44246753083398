import React from 'react';
import { PrimaryButton, DefaultButton, IButtonProps } from 'office-ui-fabric-react';
import colors from '~/assets/js/colors';

const DeleteButton: React.FC<IButtonProps> = (props) => {
    return <PrimaryButton styles={
        {
            root:
            {
                backgroundColor: colors.error,
                width: 110,
                borderColor: colors.error
            },
            rootHovered: {
                backgroundColor: '#DF4336',
                borderColor: '#DF4336'
            },
            rootPressed: {
                backgroundColor: '#B74336',
                borderColor: '#B74336'
            }
        }
    } {...props} />
}

interface IOutlinedButtonProps {
    colorBtn?: string;
}

const OutlinedButton: React.FC<IButtonProps & IOutlinedButtonProps> = (props) => {
    const color = props.colorBtn ? props.colorBtn : colors.primary;

    return <DefaultButton
        styles={
            {
                ...props.styles,
                root:
                {
                    backgroundColor: 'transparent',
                    width: 120,
                    borderColor: color,
                    color: color
                },
                rootHovered: {
                    backgroundColor: 'rgba(100,100,100,0.1)',
                    borderColor: color,
                    color: color
                },
                rootPressed: {
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    borderColor: color,
                    color: color
                },
            }
        }
        {...props}
    />
}

export { DeleteButton, OutlinedButton };