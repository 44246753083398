import api from "~/services/api";
import { takeLatest, put, call, all } from "redux-saga/effects";
import {
    Types,
    IRequestSolicitationsAction,
    IRequestSolicitationsByIdAction,
    IRequestApprovalSolicitationAction,
    IRequestLoadApprovalsAction,
    IRequestDisapprovalsAction,
    ISuccessSolicitationsAction,
    ISuccessSolicitationsByIdAction,
    ISuccessApprovalSolicitationAction,
    ISuccessLoadApprovalsAction,
    ISuccessDisapprovalsAction
} from "../ducks/solicitation/types";

const apiCallGetSolicitations = (payload: IRequestSolicitationsAction["payload"]) => {
    const { searchText, status } = payload;
    const searchFilter = searchText !== "" ? `?SearchText=${searchText}` : "?";
    const filter = status ? `&FilterStatus=${status}` : "";

    return api.get(`/solicitacoes${searchFilter}${filter}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallGetSolicitationsById = (id: number) => {
    return api.get(`/solicitacoes/${id}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiReqApproval = (payload: IRequestApprovalSolicitationAction["payload"]) => {
    return api.post(`/solicitacoes/${payload.idSolicitacao}/aprovar`, {
        motivo: payload.motivoCancelamento
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiDisapprovals = (payload: IRequestDisapprovalsAction["payload"]) => {
    return api.post(`/solicitacoes/${payload.idSolicitacao}/negar`, {
        motivo: payload.motivoCancelamento
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiLoadApprovals = (payload: IRequestLoadApprovalsAction["payload"]) => {
    const { searchText, status } = payload;
    const searchFilter = searchText !== "" ? `?SearchText=${searchText}` : "?";
    const filter = status ? `&filterStatus=${status}` : "";

    return api.get(`/aprovacoes${searchFilter}${filter}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};


function* loadSolicitation(action: IRequestSolicitationsAction) {
    try {
        const data = yield call(apiCallGetSolicitations, action.payload);

        yield put({
            type: Types.SUCCESS_SOLICITATION_LOAD,
            payload: data
        } as ISuccessSolicitationsAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar as solicitações.';
        yield put({ type: Types.FAILURE_SOLICITATION_LOAD, message });
    };
};

function* loadSolicitationById(action: IRequestSolicitationsByIdAction) {
    try {
        const data = yield call(apiCallGetSolicitationsById, action.id);

        yield put({
            type: Types.SUCCESS_SOLICITATION_LOAD_BY_ID,
            payload: data
        } as ISuccessSolicitationsByIdAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar a solicitação.';
        yield put({ type: Types.FAILURE_SOLICITATION_LOAD_BY_ID, message });
    };
};

function* approvalSolicitation(action: IRequestApprovalSolicitationAction) {
    try {
        const status = yield call(apiReqApproval, action.payload);

        yield put({
            type: Types.SUCCESS_APPROVAL_SOLICITATION,
            payload: { ...action.payload, ...status }
        } as ISuccessApprovalSolicitationAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível aprovar a solicitação.';
        yield put({ type: Types.FAILURE_APPROVAL_SOLICITATION, message });
    };
};

function* disapprovalSolicitation(action: IRequestDisapprovalsAction) {
    try {
        const status = yield call(apiDisapprovals, action.payload);
        yield put({
            type: Types.SUCCESS_DISAPPROVAL_SOLICITATION,
            payload: { ...action.payload, ...status }
        } as ISuccessDisapprovalsAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível negar a solicitação.';
        yield put({ type: Types.FAILURE_DISAPPROVAL_SOLICITATION, message });
    };
};

function* loadApprovals(action: IRequestLoadApprovalsAction) {
    try {
        const data = yield call(apiLoadApprovals, action.payload);

        yield put({
            type: Types.SUCCESS_LOAD_APPROVALS,
            payload: data
        } as ISuccessLoadApprovalsAction);
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar as aprovações.';
        yield put({ type: Types.FAILURE_LOAD_APPROVALS, message });
    };
};

export default function* solicitationRoot() {
    yield all([
        yield takeLatest(Types.REQUEST_SOLICITATION_LOAD, loadSolicitation),
        yield takeLatest(Types.REQUEST_SOLICITATION_LOAD_BY_ID, loadSolicitationById),
        yield takeLatest(Types.REQUEST_APPROVAL_SOLICITATION, approvalSolicitation),
        yield takeLatest(Types.REQUEST_DISAPPROVAL_SOLICITATION, disapprovalSolicitation),
        yield takeLatest(Types.REQUEST_LOAD_APPROVALS, loadApprovals),
    ]);
};