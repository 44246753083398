import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from "formik";
import * as yup from 'yup';

import {
  Wrapper,
  WrapperStatus,
  ContainerContent
} from "./styles";

//FluentUI
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
  IGroup
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBarButton, IconButton, ICommandBarStyles } from 'office-ui-fabric-react';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import CustomDialog from "~/components/CustomDialogFluentUI";
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
//MaterialUI
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
//Componentes
import Panel from "~/components/layout/Panel";
import { DataTypes as DataTypesEmployees, EmployeeType } from "~/store/ducks/employees/types";
import { InputText, InputDate } from '~/components/Forms';
import NoItems from '~/components/layout/NoItems';
import { DeleteButton } from '~/components/Buttons';
import Dropdown from "~/components/layout/Dropdown";
import Autocomplete from '@material-ui/lab/Autocomplete';

import Status from "~/components/Status";
import TreeView from "~/components/TreeView";

import { DataTypes, ValidationType, ValidationsType } from "~/store/ducks/cycle/validation/types";
import { Creators } from "~/store/ducks/cycle/validation";

import { RootState } from "~/store/ducks";
import Colors from "~/assets/js/colors";

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44
  }
};

interface IValidationCycleState {
  columns: IColumn[];
  items: ValidationType[];
  groups: IGroup[];
  isPanelOpen: boolean;
  isDialogOpen: boolean;
  selectionDetails: string;
  inicialValues: ValidationType;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
  employee: Partial<EmployeeType>;
  itemSelected: ValidationType;
};


const validationCyclesSchema = yup.object().shape({
  ano: yup.number().nullable().required("Campo obrigatório"),
  status: yup.string().required("Campo obrigatório"),
  prazoPlanejamento: yup.date().nullable().required("Campo obrigatório"),
  responsavel: yup.object().shape({
    idFuncionario: yup.number().nullable().required("Campo obrigatório")
  })
});


const inicialValueValidation: ValidationType = {
  status: '',
  validacoes: []
};

interface IValidationCycleProps {
  idCiclo: number;
}

const Validation: React.FC<IValidationCycleProps> = props => {
  const [idSelected, setIdSelected] = useState<number | null>(null);

  const validationsState = useSelector<RootState, DataTypes>(state => state.cycleValidationReducer);

  const dispatch = useDispatch();
  const idCiclo = props.idCiclo;

  useEffect(() => {
    if(!validationsState.flagStartValidation){
      dispatch(Creators.getValidationCycle(idCiclo))
    }else{
      dispatch(Creators.resetCanRefreshValidation())
    }
  }, []);

  const handleOnItemInvoked = () => {

  }

  return (
    <>
      <Wrapper >
        <ContainerContent>
          <Stack horizontal horizontalAlign="end">
            <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Refresh' }} text="Atualizar" onClick={() => dispatch(Creators.getValidationCycle(idCiclo))} />
          </Stack>
          <TreeView
            columns={[
              {
                name: 'Regra',
                fieldName: 'Area',
                onRender: convertArea
              },
              {
                name: 'Quantidade',
                fieldName: 'qtdePesos',
                onRender: convertQtde,
                width: 150,
                justifyHeader: 'center',
                justifyValue: 'center',
              },
              {
                name: 'Peso',
                fieldName: 'somaPesos',
                onRender: convertPeso,
                width: 150,
                justifyHeader: 'center',
                justifyValue: 'center',
              }
            ]}
            state={{ ...validationsState, data: validationsState.data.validacoes }}
            fieldId="idCicloValidacao"
            idItemSelected={idSelected}
            isNotSelectable
            handleIdItemSelected={id => setIdSelected(id)}
            handleOnItemInvoked={handleOnItemInvoked}
          />
        </ContainerContent>
      </Wrapper>
    </>
  );
};

const convertArea = (area: any, values: any) => {
  return area.nomeLocal;
}

const convertQtde = (qtde: string, values: any): string | JSX.Element => {
  switch (values.statusQtde) {
    case 'O':
      return (
        <Stack horizontal verticalAlign="center" styles={{ root: { width: 40, justifyContent: 'flex-end' } }}>
          {String(qtde)}
          <FontIcon iconName="CheckMark" style={{ color: Colors.primary, marginLeft: 5 }} />
        </Stack>
      );
    case 'A':
      return (
        <Stack horizontal verticalAlign="center" styles={{ root: { width: 40, justifyContent: 'flex-end', color: Colors.error } }}>
          {String(qtde)}
          <FontIcon iconName="WarningSolid" style={{ color: Colors.error, marginLeft: 5 }} />
        </Stack>
      );
    default:
      return (
        <Stack horizontal verticalAlign="center" styles={{ root: { width: 40, justifyContent: 'flex-end', paddingRight: 19 } }}>
          {String(qtde)}
        </Stack>
      )
  }
}

const convertPeso = (peso: string, values: any): string | JSX.Element => {
  switch (values.statusQtde) {
    case 'O':
      return (
        <Stack horizontal verticalAlign="center" styles={{ root: { width: 58, justifyContent: 'flex-end' } }}>
          {String(peso)}%
          <FontIcon iconName="CheckMark" style={{ color: Colors.primary, marginLeft: 5 }} />
        </Stack>
      );
    case 'A':
      return (
        <Stack horizontal verticalAlign="center" style={{ color: Colors.error }} styles={{ root: { width: 58, justifyContent: 'flex-end' } }}>
          {String(peso)}%
          <FontIcon iconName="WarningSolid" style={{ color: Colors.error, marginLeft: 5 }} />
        </Stack>
      );
    default:
      return (
        <Stack horizontal verticalAlign="center" styles={{ root: { width: 58, justifyContent: 'flex-end', paddingRight: 19 } }}>
          {`${peso}%`}
        </Stack>

      )
  }
}

export default Validation;
