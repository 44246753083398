/**
 * Types
 */
export enum Types {
    //GET
    SET_CURRENT_PAGE = "home/SET_CURRENT_PAGE",
    SET_MENU_EXPANDED = "home/SET_MENU_EXPANDED"
}
/**
 * Data types
 */

export type BreadcrumbItems = {
    isCurrentItem?: boolean;
    text: string;
    url?: string;
    onlyIcon ?: boolean;
    icon?: string;
}

export type Page = {
    key: string;
    pages: BreadcrumbItems[];
}

export type HomeType = {
    page: Page;
}

interface IHomeState {
    readonly page: Page;
    readonly isMenuExpanded: boolean;
}

export type DataTypes = IHomeState

/**
 * Actions
 */

//GET
interface ICurrentPageAction {
    type: typeof Types.SET_CURRENT_PAGE,
    payload: Page
}

type HomeAction = ICurrentPageAction

interface IMenuExpandedAction {
    type: typeof Types.SET_MENU_EXPANDED,
    isExpanded: boolean
}

type MenuAction = IMenuExpandedAction;

// //ADD
// export interface IRequestCyclesAddAction {
//     type: typeof Types.REQUEST_CYCLES_ADD,
//     cycle: CycleType
// }

// interface ISuccessCyclesAddAction {
//     type: typeof Types.SUCCESS_CYCLES_ADD
// }

// interface IFailureCyclesAddAction {
//     type: typeof Types.FAILURE_CYCLES_ADD
// }

// type CyclesAddAction = IRequestCyclesAddAction | ISuccessCyclesAddAction | IFailureCyclesAddAction

// //PUT
// export interface IRequestCyclesEditAction {
//     type: typeof Types.REQUEST_CYCLES_EDIT,
//     cycle: CycleType
// }

// export interface ISuccessCyclesEditAction {
//     type: typeof Types.SUCCESS_CYCLES_EDIT,
// }

// export interface IFailureCyclesEditAction {
//     type: typeof Types.FAILURE_CYCLES_EDIT
// }

// type CyclesEditAction = IRequestCyclesEditAction | ISuccessCyclesEditAction | IFailureCyclesEditAction


// //DELETE
// export interface IRequestCyclesDeleteAction {
//     type: typeof Types.REQUEST_CYCLES_DELETE,
//     id: number
// }

// export interface ISuccessCyclesDeleteAction {
//     type: typeof Types.SUCCESS_CYCLES_DELETE,
// }

// export interface IFailureCyclesDeleteAction {
//     type: typeof Types.FAILURE_CYCLES_DELETE
// }

//type CyclesDeleteAction = IRequestCyclesDeleteAction | ISuccessCyclesDeleteAction | IFailureCyclesDeleteAction

export type HomeActionTypes = HomeAction | MenuAction;