import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import ContainerPrivateRouter from "~/components/layout/ContainerPrivateRouter";

import SignIn from "~/pages/SignIn";
import RetrieveAccount from "~/pages/RetrieveAccount";
import Home from "~/pages/Home";
import Companies from "~/pages/Companies";
import Cycles from "~/pages/Cycles";
import Cycle from "~/pages/Cycle";
import Pillars from "~/pages/Pillars";
import Profile from "~/pages/Profile";
import Objectives from "~/pages/Objectives";
import Chart from "~/pages/Chart";
import Evaluations from "~/pages/Evaluations";
import Employees from "~/pages/Employees";
import Units from "~/pages/Units";
import Positions from "~/pages/Positions";
import Calibration from "~/pages/Calibration";
import WorkFlow from "~/pages/WorkFlow";
import Task from "~/pages/WorkFlow/IndividualTask";
import SelfEvaluation from "~/pages/WorkFlow/SelfEvaluation";
import TeamEvaluation from "~/pages/WorkFlow/TeamEvaluation";
import Attitudes from "~/pages/Attitudes";

import NavBar from "~/components/layout/NavBar";
import { RootState } from "~/store/ducks";
import { DataTypes } from "~/store/ducks/login/types";

const style = {
  display: "flex",
};

const PublicRoute: React.FC = () => (
  <BrowserRouter basename="/">
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/recuperar/:type" component={RetrieveAccount} />
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>
);

const PrivateRoute: React.FC = () => (
  <>
    <Route path="/" exact component={Home} />
    <Route path="/empresas" exact component={Companies} />
    <Route path="/ciclos" exact component={Cycles} />
    <Route path="/ciclos/:idCiclo" exact component={Cycle} />
    <Route path="/pilares" exact component={Pillars} />
    <Route path="/perfil" exact component={Profile} />
    <Route path="/objetivos" exact component={Objectives} />
    <Route path="/organograma" exact component={Chart} />
    <Route path="/avaliacoes" exact component={Evaluations} />
    <Route path="/funcionarios" exact component={Employees} />
    <Route path="/unidades" exact component={Units} />
    <Route path="/cargos" exact component={Positions} />
    <Route path="/calibracao" exact component={Calibration} />
    <Route path="/workflow" exact component={WorkFlow} />
    <Route path="/workflow/autoavaliacao" exact component={SelfEvaluation} />
    <Route path="/workflow/timeavaliacao" exact component={TeamEvaluation} />
    <Route path="/workflow/solicitacao/:id" exact component={Task} />
    <Route path="/atitudes" exact component={Attitudes} />
  </>
);

export default () => {
  const { isLogged } = useSelector<RootState, DataTypes>(
    (state) => state.loginReducer
  );
  const tk = localStorage.getItem("user/token");
  if (isLogged && tk) {
    return (
      <BrowserRouter basename="/">
        <Switch>
          <ContainerPrivateRouter>
            <div style={style}>
              <NavBar />
              <PrivateRoute />
            </div>
          </ContainerPrivateRouter>
          <Redirect from="*" to="/" />
        </Switch>
      </BrowserRouter>
    );
  } else {
    return <PublicRoute />;
  }
};
