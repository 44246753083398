import styled from "styled-components";
import {
  DefaultButton as DefaultBtn,
  PrimaryButton as PrimaryBtn,
} from "office-ui-fabric-react";

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: row;
  `;

export const Wrapper = styled.div`
  height: calc(100vh - 50px);
  width: calc(100% - 250px);
  flex-basis: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
`;

export const ListContainer = styled.div`
  width: 100%;
  height: calc(100% - 82px);
  overflow-y: auto;
`;

export const PrimaryButton = styled(PrimaryBtn)`
  width: 110px;
`

export const PrimaryButtonDisplay = styled(PrimaryBtn)`
  width: 110px;
  position: relative;
  left: 196px;
`

export const DefaultButton = styled(DefaultBtn)`
  width: 110px;
`

export const GroupCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`

export const Status = styled.span<{bgColor: string}>`
    width: 15px;
    height: 15px;
    background-color: ${props => props.bgColor};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    margin-right: 5px;
    color: #FFFFFF;
    line-height: 1px;
`

export const WrapperMatricula = styled.div`
  display: flex;
`

export const ImgPerfil = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 32px;
`