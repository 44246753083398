import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux'

import { ActionButton, Stack } from 'office-ui-fabric-react';
import { Text, ITextStyles } from 'office-ui-fabric-react/lib/Text';

import MenuItem from '@material-ui/core/MenuItem';
import Dropdown from '~/components/layout/Dropdown';
import { InputText } from '~/components/Forms';

import PainelHeader from '~/components/layout/PainelHeader';

import {
    Container,
    Content,
    ContainerTitle,
    WrapperResults,
    WrapperForm,
    WrapperDropdown,
    ContainerForm,
    Status
} from './styles';

import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { Creators } from '~/store/ducks/home'
import Colors from '~/assets/js/colors';

const itemsBreadCrumb: BreadcrumbItems[] = [
    { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
    { text: "Painel Workflow", isCurrentItem: false, url: "/workflow" },
    { text: "Auto Avaliação", isCurrentItem: true },
];

const page: Page = {
    key: 'autoavaliacao',
    pages: itemsBreadCrumb
};

const SelfEvaluation: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(Creators.setCurrentPage(page));
    }, [])

    return (
        <Container>
            <PainelHeader
                title="Auto Avaliação"
                icon={{ icon: "Equalizer" }}
                spaceBetween={200}
                labelGroups={[
                    {
                        label: "Ciclo",
                        value: "2020",
                        icon: "HomeGroup"
                    },
                    {
                        label: "Colaborador",
                        value: "Fernanda",
                        icon: "Contact"
                    },
                    {
                        label: "Status",
                        value: "Aguardando Finalização",
                        icon: "Flag"
                    },
                ]}
            />
            <Content>
                <ActionButton iconProps={{ iconName: "Send" }}>
                    Enviar
                </ActionButton>
                <WrapperResults>
                    <ContainerTitle>
                        <Text variant="mediumPlus" styles={textStyles}>Resultados</Text>
                    </ContainerTitle>
                    <Stack horizontal styles={{ root: { marginTop: 10 } }}>
                        <WrapperDropdown>
                            <Dropdown
                                errors={false}
                                label="Avaliação do Resultado"
                                name="resultado"
                                errorText={""}
                            >
                                <MenuItem value={"B"}>Abaixo da expectativa</MenuItem>
                                <MenuItem value={"D"}>Dentro da expectativa</MenuItem>
                                <MenuItem value={"A"}>Acima da expectativa</MenuItem>
                            </Dropdown>
                        </WrapperDropdown>
                        <InputText type="text" label="Comentários" />
                    </Stack>
                    <ContainerTitle>
                        <Text variant="mediumPlus" styles={textStyles}>Atitudes Vencedoras</Text>
                    </ContainerTitle>
                    <WrapperForm>

                        {/* ---------------------------- FOCO -------------------- */}

                        <Text variant="mediumPlus" styles={textStyles}>1. FOCO</Text>
                        <ContainerForm>
                            <Text variant="smallPlus" styles={descriptionStyles}>
                                Eu entendo nossas prioridades estratégicas e me concentro naquilo que é importante para a
                                Companhia; eu sei dizer ‘não’ para aquilo que não é essencial; eu encanto o cliente (interno
                                e/ou externo); eu tenho um alto nível de compromisso e cumpro aquilo que eu prometo; eu sou
                                persistente, aprendo com os erros e tento de novo; eu evito o retrabalho com todas as minhas
                                forças, por isso eu invisto tempo no planejamento e dou meu máximo para fazer certo logo na
                                primeira vez; eu uso indicadores para avaliar o sucesso nas minhas metas e só meço o que
                                ‘move o ponteiro; eu entrego meus resultados!
                            </Text>
                            <Stack horizontal styles={{ root: { marginTop: 10 } }}>
                                <WrapperDropdown>
                                    <Dropdown
                                        errors={false}
                                        label="Avaliação do Resultado"
                                        name="resultado"
                                        errorText={""}
                                    >
                                        <MenuItem value={"B"}>Abaixo da expectativa</MenuItem>
                                        <MenuItem value={"D"}>Dentro da expectativa</MenuItem>
                                        <MenuItem value={"A"}>Acima da expectativa</MenuItem>
                                    </Dropdown>
                                </WrapperDropdown>
                                <InputText type="text" label="Comentários" />
                            </Stack>
                        </ContainerForm>

                        {/* ---------------------------- SENSO E URGÊNCIA -------------------- */}

                        <Text variant="mediumPlus" styles={textStyles}>2. SENSO DE URGÊNCIA</Text>
                        <ContainerForm>
                            <Text variant="smallPlus" styles={descriptionStyles}>
                                Eu entendo perfeitamente que a velocidade é tão importante quanto a qualidade nas entregas;
                                eu faço escolhas considerando que, muitas vezes, o ‘ótimo é inimigo do bom’; eu invisto o
                                meu tempo na procura de uma solução para o problema e não em saber de quem é a culpa; eu
                                sou proativo(a) e não espero que me peçam por algo que sei que precisa ser feito; eu sou
                                inimigo da burocracia e estou sempre em busca de formas de simplificar os processos para
                                que as coisas aconteçam o mais rapidamente; eu sou flexível e encontro um jeito de fazer
                                ao invés de tentar achar uma desculpa!
                            </Text>
                            <Stack horizontal styles={{ root: { marginTop: 10 } }}>
                                <WrapperDropdown>
                                    <Dropdown
                                        errors={false}
                                        label="Avaliação do Resultado"
                                        name="resultado"
                                        errorText={""}
                                    >
                                        <MenuItem value={"B"}>Abaixo da expectativa</MenuItem>
                                        <MenuItem value={"D"}>Dentro da expectativa</MenuItem>
                                        <MenuItem value={"A"}>Acima da expectativa</MenuItem>
                                    </Dropdown>
                                </WrapperDropdown>
                                <InputText type="text" label="Comentários" />
                            </Stack>
                        </ContainerForm>

                        {/* ---------------------------- 3. PROTAGONISMO -------------------- */}

                        <Text variant="mediumPlus" styles={textStyles}>3. PROTAGONISMO</Text>
                        <ContainerForm>
                            <Text variant="smallPlus" styles={descriptionStyles}>
                                Eu entendo perfeitamente que a velocidade é tão importante quanto a qualidade nas entregas; eu
                                faço escolhas considerando que, muitas vezes, o ‘ótimo é inimigo do bom’; eu invisto o meu tempo
                                na procura de uma solução para o problema e não em saber de quem é a culpa; eu sou proativo(a) e
                                não espero que me peçam por algo que sei que precisa ser feito; eu sou inimigo da burocracia e
                                estou sempre em busca de formas de simplificar os processos para que as coisas aconteçam o mais
                                rapidamente; eu sou flexível e encontro um jeito de fazer ao invés de tentar achar uma desculpa!
                            </Text>
                            <Stack horizontal styles={{ root: { marginTop: 10 } }}>
                                <WrapperDropdown>
                                    <Dropdown
                                        errors={false}
                                        label="Avaliação do Resultado"
                                        name="resultado"
                                        errorText={""}
                                    >
                                        <MenuItem value={"B"}>Abaixo da expectativa</MenuItem>
                                        <MenuItem value={"D"}>Dentro da expectativa</MenuItem>
                                        <MenuItem value={"A"}>Acima da expectativa</MenuItem>
                                    </Dropdown>
                                </WrapperDropdown>
                                <InputText type="text" label="Comentários" />
                            </Stack>
                        </ContainerForm>

                        {/* ---------------------------- 4. INOVAÇÃO -------------------- */}

                        <Text variant="mediumPlus" styles={textStyles}>4. INOVAÇÃO</Text>
                        <ContainerForm>
                            <Text variant="smallPlus" styles={descriptionStyles}>
                                Eu sou aberto a novas ideias e nunca estou satisfeito(a) com a maneira como as coisas são, pois
                                sei que tudo que é bom, por melhor que seja, sempre pode melhorar – e, claro, o que não é bom
                                tem mesmo que evoluir; eu busco novas perspectivas e alternativas e sou capaz de desenvolver
                                diferentes soluções para um mesmo problema; eu penso ‘fora da caixa’ e procuro por soluções
                                realmente novas ao invés de sempre me basear na minha experiência anterior; eu sou flexível e
                                busco, proativamente, por referências com os melhores para fazer o meu trabalho subir de nível!
                            </Text>
                            <Stack horizontal styles={{ root: { marginTop: 10 } }}>
                                <WrapperDropdown>
                                    <Dropdown
                                        errors={false}
                                        label="Avaliação do Resultado"
                                        name="resultado"
                                        errorText={""}
                                    >
                                        <MenuItem value={"B"}>Abaixo da expectativa</MenuItem>
                                        <MenuItem value={"D"}>Dentro da expectativa</MenuItem>
                                        <MenuItem value={"A"}>Acima da expectativa</MenuItem>
                                    </Dropdown>
                                </WrapperDropdown>
                                <InputText type="text" label="Comentários" />
                            </Stack>
                        </ContainerForm>

                        {/* ---------------------------- 5. SINERGIA -------------------- */}

                        <Text variant="mediumPlus" styles={textStyles}>5. SINERGIA</Text>
                        <ContainerForm>
                            <Text variant="smallPlus" styles={descriptionStyles}>
                                Eu sou íntegro(a) e confiável; e eu sou capaz de confiar, porque sei que dependemos uns dos outros
                                para vencer; eu sou transparente e tenho sincero respeito pelos(as) meus/minhas colegas de trabalho,
                                independente de hierarquias; eu sou capaz de ‘me colocar nos sapatos do outro’ para ter uma melhor
                                percepção de um problema; eu sempre penso no que é o melhor para a Companhia antes de tomar uma
                                decisão; eu incentivo a diversidade de ideias numa discussão, mas ‘somos um’ na hora da execução;
                                eu valorizo a informalidade, a parceria e a integração, porque sei que pessoas conectadas produzem
                                mais e melhor!
                            </Text>
                            <Stack horizontal styles={{ root: { marginTop: 10 } }}>
                                <WrapperDropdown>
                                    <Dropdown
                                        errors={false}
                                        label="Avaliação do Resultado"
                                        name="resultado"
                                        errorText={""}
                                    >
                                        <MenuItem value={"B"}>Abaixo da expectativa</MenuItem>
                                        <MenuItem value={"D"}>Dentro da expectativa</MenuItem>
                                        <MenuItem value={"A"}>Acima da expectativa</MenuItem>
                                    </Dropdown>
                                </WrapperDropdown>
                                <InputText type="text" label="Comentários" />
                            </Stack>
                        </ContainerForm>

                        {/* ---------------------------- 6. LIDERANÇA TRANSFORMACIONAL -------------------- */}

                        <Text variant="mediumPlus" styles={textStyles}>6. LIDERANÇA TRANSFORMACIONAL</Text>
                        <ContainerForm>
                            <Text variant="smallPlus" styles={descriptionStyles}>
                                Eu lidero pelo exemplo e sou a mudança que quero na minha equipe; eu escolho os melhores profissionais
                                para compor a minha equipe e considero que diferentes perfis são melhores que ‘mais do mesmo’ na montagem
                                de um time vencedor; eu ajudo meu pessoal a se desenvolver e a realizar seus objetivos de carreira,
                                porque sei que o insubstitituível é também impromovível; eu dou feedback constante ao meu time para
                                que tenham a clara percepção de onde estão com seu desempenho; eu acredito e fomento a meritocracia;
                                eu celebro ‘cada gol’ e reconheço a boa performance; eu faço a nossa jornada ‘valer a pena’; eu sou
                                capaz de fazer com que meu time produza no seu máximo porque estamos ‘fazendo história’!
                            </Text>
                            <Stack horizontal styles={{ root: { marginTop: 10 } }}>
                                <WrapperDropdown>
                                    <Dropdown
                                        errors={false}
                                        label="Avaliação do Resultado"
                                        name="resultado"
                                        errorText={""}
                                    >
                                        <MenuItem value={"B"}>Abaixo da expectativa</MenuItem>
                                        <MenuItem value={"D"}>Dentro da expectativa</MenuItem>
                                        <MenuItem value={"A"}>Acima da expectativa</MenuItem>
                                    </Dropdown>
                                </WrapperDropdown>
                                <InputText type="text" label="Comentários" />
                            </Stack>
                        </ContainerForm>

                        <ContainerTitle>
                            <Text variant="mediumPlus" styles={textStyles}>Resumo das competências</Text>
                        </ContainerTitle>
                    </WrapperForm>

                    <WrapperForm>
                        <Stack horizontal verticalAlign="center" styles={{ root: { padding: '10px 0' } }}>
                            <Status bgColor={Colors.primary} />
                            <Text variant="medium">Dentro da Expectativa</Text>
                        </Stack>
                    </WrapperForm>
                </WrapperResults>
            </Content>
        </Container>
    );
}

const textStyles: ITextStyles = {
    root: {
        fontWeight: 500,
        marginTop: 10,
        display: 'block'
    }
}

const descriptionStyles: ITextStyles = {
    root: {
        opacity: 0.83,
    }
}

export default SelfEvaluation;