import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    DetailsListLayoutMode,
    SelectionMode,
    Selection,
    IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { DataTypes, ObjectivesType } from "~/store/ducks/objectives/types";
import { RootState } from "~/store/ducks";
import { Creators as getObjectives } from "~/store/ducks/objectives";

interface IListObjectiveState {
    columns: IColumn[];
    selectionDetails: string;
    selectionCount: number;
    itemSelected: ObjectivesType;
};


const inicialValueCycle: ObjectivesType = {
    idObjetivo: null,
    codObjetivo: "",
    descObjetivo: "",
    descIngles: "",
    nivel: "",
    unidade: "",
    abrangencia: "",
    descricaoMecanica: "",
    idObjetivoPai: null,
    children: [],
    flagMostrarTodos: false,
    objPai: null,
    mecanicaCalculo: "",
    flagAtivo: false,
};


interface IListObjectivesProps {
    items: DataTypes;
    textSearch: string;
    objsAdded: (number | undefined)[];
    getItemSelected: (item: ObjectivesType) => void;
    onItemInvoked: (item: ObjectivesType) => void;
}

class ListObjectives extends Component<IListObjectivesProps, IListObjectiveState>{
    private _selection: Selection;

    constructor(props: IListObjectivesProps) {
        super(props);

        const columns: IColumn[] = [
            {
                key: 'column1',
                name: 'Descrição',
                fieldName: 'descObjetivo',
                minWidth: 150,
                maxWidth: 210,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
        ];

        this.state = {
            columns: columns,
            selectionDetails: "",
            selectionCount: 0,
            itemSelected: inicialValueCycle,
        };

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.props.getItemSelected(this._getSelectionDetails())
                this.setState({
                    selectionDetails: this._getSelectionDetails(),
                });
            },
        });
    };

    _getSelectionDetails(): any {
        const selectionCount = this._selection.getSelectedCount();
        this.setState({ selectionCount });
        this._selection.getSelection();

        return this._selection.getSelection()[0];
    };


    _onItemInvoked = (item: ObjectivesType) => {
        this.props.onItemInvoked(item);
    }

    filterSearch = (): ObjectivesType[] => {
        const text = this.props.textSearch;

        const items = this.props.items.data.filter(item => {
            if (!this.props.objsAdded.includes(item.idObjetivo!)) {
                return item;
            }
        });

        const itemsFiltered = text
            ? items.filter((i: ObjectivesType) =>
                i.descObjetivo!.toLowerCase().indexOf(text.toLowerCase()) > -1
            )
            : items;

        return itemsFiltered;
    }

    render() {
        const { columns } = this.state;
        const { items } = this.props;


        return (
            <MarqueeSelection selection={this._selection}>
                <ShimmeredDetailsList
                    items={this.filterSearch()}
                    enableShimmer={items.loadingData}
                    columns={columns}
                    selectionMode={SelectionMode.single}
                    selection={this._selection}
                    getKey={this._getKey}
                    selectionPreservedOnEmptyClick={true}
                    setKey="single"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    onItemInvoked={this._onItemInvoked}
                />
            </MarqueeSelection>
        );
    };

    private _getKey(item: any, index?: number): any {
        if (item !== undefined)
            return item.key;
    };

    private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
        return itemsSorted;
    };
};

const mapStateToProps = (state: RootState) => ({
    cycles: state.cyclesReducer,
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...getObjectives,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListObjectives);