// #region Imports
import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Formik } from "formik";
import * as yup from 'yup';

//FluentUI
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
  DetailsListLayoutMode,
  SelectionMode,
  Selection,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBarButton, IconButton, ICommandBarStyles } from 'office-ui-fabric-react';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';

//MaterialUI
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

//Componentes
import CustomDialog from "~/components/CustomDialogFluentUI";
import Panel from "~/components/layout/Panel";
import Dropdown from "~/components/layout/Dropdown";
import colors from "~/assets/js/colors";
import { CustomDrawer as Drawer } from "~/components/layout/Drawer";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { InputText } from '~/components/Forms';
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
import { DeleteButton } from '~/components/Buttons';
//Estilos
import {
  Wrapper,
  ListContainer,
  PrimaryButton,
  DefaultButton,
  GroupCheckbox,
  ContainerContent
} from "./styles";


import evaluationsReducer, { Creators as getEvaluations } from "~/store/ducks/evaluations";
import { Creators as addEvaluation } from "~/store/ducks/evaluations";
import { Creators as editEvaluation } from "~/store/ducks/evaluations";
import { Creators as delEvaluation } from "~/store/ducks/evaluations";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { EvaluationType, DataTypes } from "~/store/ducks/evaluations/types";
import { RootState } from "~/store/ducks";
//#endregion


/**
 * Validação do formulário
 */
// const validationEvaluationsSchema = yup.object().shape({
//   code: yup.string().required("Campo obrigatório"),
//   companyName: yup.string().required("Campo obrigatório"),
//   group: yup.string().required("Campo obrigatório"),
//   // inactivate: yup.boolean().required("Campo obrigatório")
// })

//#region Types e Interfaces

const btnStyle: Partial<ICommandBarStyles> = {
  root: {
    height: 44
  }
};

type Filter = {
  isOpen?: boolean;
  filteredBy?: string[];
  departamento?: any;
};

interface IEvaluationsState {
  columns: IColumn[];
  items: EvaluationType[];
  isPanelOpen: boolean;
  isDialogOpen: boolean;
  openPanel: boolean;
  isLoadData: boolean;
  inicialValues: EvaluationType;
  isFiltering: boolean;
  selectionDetails: string;
  selectionCount: number;
  isDialogExcludeOpen: boolean;
  filter: Filter;
};

interface IProps {
  getEvaluations: () => void;
  addEvaluation: (evaluation: EvaluationType) => void;
  editEvaluation: (evaluation: EvaluationType) => void;
  delEvaluation: (id: number) => void;
  setCurrentPage: (page: Page) => void;
  evaluations: DataTypes;
};
//#endregion

const initial: EvaluationType = {
  idAvaliacao: null,
  colaborador: "",
  departamento: "",
  matricula: "",
  avaliacao: null
};

const itemsBreadCrumb: BreadcrumbItems[] = [
  { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
  { text: 'Avaliações', isCurrentItem: true }
];

class Evaluations extends Component<IProps, IEvaluationsState> {
  private formRef: any;
  private inputSearch: any;
  private _selection: Selection;

  constructor(props: IProps) {
    super(props);
    //#region Colunas
    const columns: IColumn[] = [
      {
        key: 'column1',
        name: 'Matrícula',
        ariaLabel: 'Matrícula do Colaborador',
        fieldName: 'matricula',
        isRowHeader: true,
        minWidth: 75,
        maxWidth: 100,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
      },
      {
        key: 'column2',
        name: 'Departamento',
        fieldName: 'departamento',
        ariaLabel: 'Departamento',
        isRowHeader: true,
        minWidth: 90,
        maxWidth: 120,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column3',
        name: 'Cargo',
        fieldName: 'cargo',
        ariaLabel: 'Cargo do Colaborador',
        isRowHeader: true,
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column4',
        name: 'Colaborador',
        fieldName: 'colaborador',
        ariaLabel: 'Nome do Colaborador',
        isRowHeader: true,
        minWidth: 210,
        maxWidth: 350,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column5',
        name: 'Avaliação',
        fieldName: 'avaliacao',
        ariaLabel: 'Avaliação do Colaborador',
        isRowHeader: true,
        minWidth: 210,
        maxWidth: 350,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        isPadded: true,
        onRender: (item: EvaluationType) => {
          if (item.avaliacao !== null && item.avaliacao !== undefined) {
            return [1, 2, 3, 4, 5].map(num => {
              if (item.avaliacao! <= num) {
                return <FontIcon key={num} style={{ fontSize: 16, marginRight: 5 }} iconName={"FavoriteStar"} />
              } else {
                return <FontIcon key={num} style={{ fontSize: 16, marginRight: 5 }} iconName={"FavoriteStarFill"} />
              }
            })
          }
        }
      }
    ];
    //#endregion

    this.state = {
      columns: columns,
      items: [],
      isDialogOpen: false,
      isPanelOpen: false,
      openPanel: false,
      isLoadData: true,
      selectionDetails: "",
      inicialValues: initial,
      isFiltering: false,
      selectionCount: 0,
      isDialogExcludeOpen: false,
      filter: {
        isOpen: false,
        filteredBy: [],
        departamento: {}
      }
    };

    this._selection = new Selection({
      onSelectionChanged: () => {
        this.setState({
          selectionDetails: this._getSelectionDetails(),
        });
      },
    });

    this.formRef = React.createRef();
    this.inputSearch = React.createRef();
  };

  componentDidMount() {
    const page: Page = {
      key: 'avaliacoes',
      pages: itemsBreadCrumb
    };
    this.props.setCurrentPage(page);
    this.props.getEvaluations();

    const evaluations = this.props.evaluations.data;
    const departamentos = evaluations.map(item => item.departamento)
      .filter((item, pos, self) => self.indexOf(item) === pos)
      .map(item => [item, false]);


    const filters = Object.fromEntries(departamentos);

    this.setState({
      filter: {
        departamento: {
          ...filters
        }
      }
    });
  };

  // Submit do formulário Formik
  handleSubmit = () => {
    if (this.formRef.current) {
      this.formRef.current.handleSubmit();
    };
  };

  _onItemInvoked = (item: EvaluationType): void => {
    this.setState({
      isPanelOpen: true,
      inicialValues: {
        ...item
      }
    });
  };

  _getSelectionDetails(): any {
    const selectionCount = this._selection.getSelectedCount();
    this.setState({ selectionCount });
    this._selection.getSelection();
  };

  openEdit = () => {
    this.setState({ isPanelOpen: true, inicialValues: (this._selection.getSelection()[0] as EvaluationType) });
  };

  cancelPanel = () => {
    this._selection.setAllSelected(false);
    this.setState({ isPanelOpen: false });
  };

  excludeEvaluation = () => {
    this.props.delEvaluation((this._selection.getSelection()[0] as EvaluationType).idAvaliacao!);
    this.setState({ isDialogExcludeOpen: false });
  };

  //#region  Funções do filtro

  toggleFilter = () => {
    this.setState({
      filter: {
        ...this.state.filter,
        isOpen: !this.state.filter.isOpen,
      }
    });
  };

  toggleParamsFilter = (param: string) => {
    // const filter = this.state.filter
    // const params = filter.filteredBy
    // const stmarche = filter.group?.stmarche
    // const emporio = filter.group?.emporio

    // const index = params!.indexOf(param);
    // if (index > -1) {
    //   params!.splice(index, 1);
    // } else {
    //   params!.push(param)
    // }

    // if (param === "ST MARCHE") {
    //   this.setState({ filter: { ...this.state.filter, filteredBy: params, group: { ...this.state.filter.group, stmarche: !stmarche } } })
    // } else {
    //   this.setState({ filter: { ...this.state.filter, filteredBy: params, group: { ...this.state.filter.group, emporio: !emporio! } } })
    // }

    // if (this.inputSearch.current) {
    //   this.filter(this.inputSearch.current.state.value)
    // }

  }

  renderFilters = () => {
    const evaluations = this.props.evaluations.data;
    const departamentos = evaluations.map(item => item.departamento)
      .filter((item, pos, self) => self.indexOf(item) === pos);


    return departamentos.map((item, i) => {
      return (
        <FormControlLabel
          key={i}
          control={
            <Checkbox
              checked={this.state.filter.departamento[item]}
              onChange={() => this.filterDepartament(item)}
              name={item}
              color="primary"
            />
          }
          label={<span style={{ fontSize: 14 }}>{item}</span>}
        />
      )
    });
  };

  filterDepartament = (filtro: string) => {
    const dep = this.state.filter.departamento;
    this.setState({
      filter: {
        ...this.state.filter,
        departamento: {
          ...dep,
          [filtro]: !dep[filtro]
        }
      }
    }, () => this.setDepartments());
  };

  setDepartments = () => {
    const dep = this.state.filter.departamento;
    const items = this.props.evaluations.data;
    let newItems: any[] = [];

    const isFiltered = Object.values(dep).some((depart: any) => depart === true);
    if (isFiltered) {
      Object.keys(dep).forEach((depart, i) => {
        if (Object.values(dep)[i]) {
          items.forEach(item => {
            if (depart === item.departamento) {
              newItems.push(item);
            };
          });
        }
      });
      this.setState({ items: newItems, isFiltering: true });
    } else {
      this.setState({ items: items, isFiltering: false });
    };
  };

  filter = (text: any) => {
    const evaluations = this.props.evaluations.data;
    const dep = this.state.filter.departamento;

    const isFiltered = Object.values(dep).some((depart: any) => depart === true);
    let items = text ? evaluations.filter((i: EvaluationType) => i.colaborador.toLowerCase().indexOf(text.toLowerCase()) > -1) : evaluations;
    if (isFiltered) {

      let itemsFiltered: any[] = [];
      Object.keys(dep).forEach((depart, i) => {
        if (Object.values(dep)[i]) {
          items.filter((item: EvaluationType) => {
            if (item.departamento === depart) {
              itemsFiltered.push(item);
            };
          });
        };
      });

      this.setState({ items: itemsFiltered, isFiltering: true });
    } else {
      this.setState({ items, isFiltering: true });
    };
  };

  clearFilters = () => {
    this.setState({
      filter: {
        ...this.state.filter,
        filteredBy: [],
        // group:
        // {
        //   emporio: false,
        //   stmarche: false
        // }
      }
    }, () => this.filter(""));
  };

  //#endregion

  commandBarRender = () => {
    const { selectionCount } = this.state;
    if (selectionCount === 1) {
      return (
        <>
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Edit' }} text="Editar" onClick={() => this.openEdit()} />
          <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
        </>
      );
    } else if (selectionCount > 1) {
      return <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />;
    };
  };

  render() {
    const { isPanelOpen, isDialogExcludeOpen, selectionCount, inicialValues, isFiltering, items, columns, filter } = this.state;
    const { evaluations, addEvaluation, editEvaluation, getEvaluations } = this.props;

    const dialogContentProps = {
      title: 'Excluir?',
      closeButtonAriaLabel: 'Close',
      subText: selectionCount <= 1 ? 'Tem certeza de que deseja excluir este item?' : 'Tem certeza de que deseja excluir estes items?',
    };

    return (
      <>
        <Panel
          title="Nova empresa"
          open={isPanelOpen}
          onClose={() => this.cancelPanel()}
          footer={
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <DefaultButton onClick={() => this.cancelPanel()}>Cancelar</DefaultButton>
              <PrimaryButton onClick={this.handleSubmit}>Salvar</PrimaryButton>
            </Stack>
          }
        >
          <Formik
            innerRef={this.formRef}
            initialValues={inicialValues}
            // validationSchema={validationEvaluationsSchema}
            validateOnChange={false}
            validateOnBlur={true}
            onSubmit={(values: EvaluationType) => {
              if (inicialValues.idAvaliacao === null) {
                addEvaluation(values)
              } else {
                values.idAvaliacao = inicialValues.idAvaliacao
                editEvaluation(values)
              }
              this.setState({ isPanelOpen: false })
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <form onSubmit={handleSubmit}>
                {/* <InputContainer fixed>
                  <Input
                    value={values.code}
                    onChange={handleChange}
                    id="code"
                    error={errors.code ? true : false}
                    name="code"
                    type="text"
                    label="Código"
                    helperText={errors.code}
                    className="mt-2" />
                </InputContainer>
                <InputContainer fixed>
                  <Input
                    value={values.companyName}
                    onChange={handleChange}
                    id="companyName"
                    error={errors.companyName ? true : false}
                    name="companyName"
                    type="text"
                    label="Razão Social"
                    helperText={errors.companyName}
                    className="mt-2" />
                </InputContainer>
                <InputContainer>
                  <Dropdown
                    errors={errors.group}
                    label="Grupo Empresarial"
                    name="group"
                    values={values.group}
                    handleChange={handleChange}
                    errorText={errors.group}
                  >
                    <MenuItem value={"ST MARCHE"}>ST MARCHE</MenuItem>
                    <MenuItem value={"EMPORIO SANTA MARIA"}>EMPORIO SANTA MARIA</MenuItem>
                  </Dropdown>
                </InputContainer> */}
                {/* <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.inactivate}
                        onChange={handleChange}
                        name="inactivate"
                        color="primary"
                      />
                    }
                    label="Inativar Empresa"
                  /> */}
              </form>
            )}
          </Formik>
        </Panel>

        <CustomDialog
          hidden={!isDialogExcludeOpen}
          onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
          dialogContentProps={dialogContentProps}
        >
          <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
          <DeleteButton onClick={() => this.excludeEvaluation()} text="Excluir" />
        </CustomDialog>
        <Wrapper>
          <Drawer
            isOpen={filter.isOpen}
            content={
              <ContainerContent>
                <HeaderPage
                  title="Avaliações 2019"
                  leftItems={
                    <>
                      <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: 'Add' }}
                        text="Adicionar Avaliação"
                        onClick={() => this.setState({ isPanelOpen: true, inicialValues: initial })}
                        disabled={evaluations.loading}
                      />
                      {this.commandBarRender()}
                    </>
                  }
                  rightItems={
                    <>
                      <SearchBox
                        placeholder="Pesquisar"
                        onChange={(e) => this.filter(e?.target.value)}
                        componentRef={this.inputSearch}
                        styles={{ root: { border: 'none', width: 200, marginRight: 1 } }}
                        disabled={evaluations.data.length === 0}
                      />
                      <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: 'Refresh' }}
                        text="Atualizar"
                        onClick={() => getEvaluations()}
                      />
                      {/* <CommandBarButton
                        styles={btnStyle}
                        iconProps={{ iconName: 'filter' }}
                        text="Filtrar"
                        onClick={() => this.toggleFilter()}
                        disabled={evaluations.data.length === 0}
                      /> */}
                    </>
                  }
                />
                {!evaluations.loading && evaluations.data.length === 0 ?
                  <NoItems
                    error={evaluations.error}
                    text="Não há avaliações cadastradas"
                    icon="AccountActivity"
                  />
                  :
                  <ListContainer>
                    <MarqueeSelection selection={this._selection}>
                      <ShimmeredDetailsList
                        items={isFiltering ? items : evaluations.data}
                        enableShimmer={evaluations.loading}
                        columns={columns}
                        selectionMode={SelectionMode.multiple}
                        selection={this._selection}
                        getKey={this._getKey}
                        selectionPreservedOnEmptyClick={true}
                        setKey="single"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        onItemInvoked={this._onItemInvoked}
                      />
                    </MarqueeSelection>
                  </ListContainer>
                }
              </ContainerContent>
            }>
            <>
              <Stack horizontal horizontalAlign="space-between">
                <Text variant="xLarge">Filtros</Text>
                <Stack horizontal>
                  {/* <IconButton iconProps={{ iconName: 'ClearFilter' }} title="Limpar Filtros" disabled={filter.filteredBy!.length > 0 ? false : true} onClick={() => this.clearFilters()} ariaLabel="Limpar Filtros" /> */}
                  <IconButton
                    iconProps={{ iconName: 'ChromeClose' }}
                    styles={{ root: { color: colors.darkGray } }}
                    title="Fechar"
                    ariaLabel="Fechar"
                    onClick={() => this.setState({ filter: { ...filter, isOpen: false } })}
                  />
                </Stack>
              </Stack>
              <GroupCheckbox>
                <Text variant="mediumPlus" styles={{ root: { marginBottom: 10 } }}>Departamento</Text>

                {
                  this.renderFilters()
                }
                {/* <FormControlLabel
                  control={
                    <Checkbox
                      checked={filter.group?.emporio}
                      onChange={() => this.toggleParamsFilter("EMPORIO SANTA MARIA")}
                      name="inactivate"
                      color="primary"
                    />
                  }
                  label={<span style={{ fontSize: 14 }}>Empório Santa Maria</span>}
                /> */}
              </GroupCheckbox >
            </>
          </Drawer>
        </Wrapper>
      </>
    );
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns } = this.state;
    const newColumns: IColumn[] = columns.slice();
    let items: EvaluationType[] = [];
    if (this.state.isFiltering) {
      items = this.state.items;
    } else {
      items = this.props.evaluations.data;
    };
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      };
    });
    const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  };

  private _getKey(item: any, index?: number): any {
    if (item !== undefined)
      return item.key;
  };

  private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    return itemsSorted;
  };

}
const mapStateToProps = (state: RootState) => ({
  evaluations: state.evaluationsReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({ ...getEvaluations, ...addEvaluation, ...delEvaluation, ...editEvaluation, ...setCurrentPage }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Evaluations);
