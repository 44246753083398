import React from 'react';

import { Text } from 'office-ui-fabric-react/lib/Text';
import { PrimaryButton, Spinner, SpinnerSize, Stack } from 'office-ui-fabric-react';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';

import { LabelGroup } from '~/components/label';

import {
  ContainerCycle,
  Title,
  IconCycle,
  DetailsWrapper,
  WrapperButton,
} from './styles';

interface IGroup {
  label: string;
  value: string;
  icon: string;
}

interface IPainelHeaderProps {
  title: string;
  icon: {
    icon?: string;
    svgIcon?: string;
  };
  labelGroups: IGroup[];
  button?: {
    text: string;
    handleButton: () => any;
    isLoadingAction?: boolean;
  } | null;
  spaceBetween?: number;
}

const PainelHeader: React.FC<IPainelHeaderProps> = (props) => {
  const { title, icon, labelGroups, button, spaceBetween } = props;
  return (
    <ContainerCycle>
      <Title>
        {
          icon.svgIcon ?
            <IconCycle src="/static/icons/ciclo.svg" alt="Ícone Ciclo" />
            :
            <FontIcon
              iconName={icon.icon}
              style={{
                fontSize: 30,
                alignSelf: 'center',
                marginRight: 12
              }}
            />
        }
        <Text variant="xxLarge">{title}</Text>
      </Title>
      <DetailsWrapper>
        <Stack horizontal styles={{ root: {flexWrap: 'wrap'} }}>
          {
            labelGroups.map((group, i) => (
              <LabelGroup key={i} icon={group.icon} label={group.label} value={group.value} spaceBetween={spaceBetween} />
            ))
          }
        </Stack>
        <WrapperButton>
          {
            button ?
              button.isLoadingAction ?
                <Spinner size={SpinnerSize.medium} styles={{ root: { width: 130 } }} />
                :
                <PrimaryButton text={button?.text} onClick={button?.handleButton} styles={{ root: { width: 130 } }} />
              :
              null
          }
        </WrapperButton>
      </DetailsWrapper>
    </ContainerCycle>
  );
}

export default PainelHeader;
