import React from 'react';
import { Text } from "office-ui-fabric-react/lib/Text";

import {
    TitleFieldStyles,
    TitleHeaderStyles,
    WrapperGroupItemLeft,
    WrapperGroupItemRight,
    WrapperGroupItems,
    WrapperItems,
    WrapperGroupItemUmTerco,
    WrapperGroupItemDoisTerco,
    TitleGroupStyles
} from './styles';

interface IFieldProps {
    text: string;
};

export const TitleGroup: React.FC<IFieldProps> = (props) => (
    <Text variant="medium" styles={TitleGroupStyles}>{props.text}</Text>
);

export const TitleField: React.FC<IFieldProps> = (props) => (
    <Text variant="medium" styles={TitleFieldStyles}>{props.text}</Text>
);

export const GroupItemLeft: React.FC = (props) => {
    return (
        <WrapperGroupItemLeft>
            {props.children}
        </WrapperGroupItemLeft>
    );
};

export const GroupItemRight: React.FC = (props) => {
    return (
        <WrapperGroupItemRight>
            {props.children}
        </WrapperGroupItemRight>
    );
};

export const GroupItemUmTerco: React.FC = (props) => {
    return (
        <WrapperGroupItemUmTerco>
            {props.children}
        </WrapperGroupItemUmTerco>
    );
};

export const GroupItemDoisTerco: React.FC = (props) => {
    return (
        <WrapperGroupItemDoisTerco>
            {props.children}
        </WrapperGroupItemDoisTerco>
    );
};

interface IGroupItemsProps {
    title?: string;
    hasMultiItems?: boolean;
};

export const GroupItems: React.FC<IGroupItemsProps> = (props) => {
    return (
        <WrapperGroupItems>
            {props.title && <TitleGroup text={props.title!} />}
            <WrapperItems hasMultiItems={props.hasMultiItems ? props.hasMultiItems : false}>
                {props.children}
            </WrapperItems>
        </WrapperGroupItems>
    );
};


export const FormHeaderStyle: React.FC<IFieldProps> = (props) => {
    return (
        <TitleHeaderStyles>
            <Text variant="xxLarge" >{props.text}</Text>
        </TitleHeaderStyles>
    );
};