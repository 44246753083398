import {
  Types,
  DataTypes,
  ProfileMetasActionTypes,
  IProfileMetasType,
  ICicloPesosPerfil,
  ISuccessAcceptMetaAction,
  IProjectType
} from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */

const initialProjectSelected: IProjectType = {
  idCiclo: null,
  descProjeto: "",
  idCicloColaboradorProjeto: null,
  idCicloPesoPai: null,
  peso: "",
  prazoEntrega: null,
  idFuncionario: null,
  nomeProjeto: ''
}

const initialData: IProfileMetasType = {
  posicao: {
    area: {
      nomeLocal: "",
      idAreaPai: null
    },
    projetos: [],
    status: '',
    descPeriodo: '',
    flagSolicitarAprovacao: false,
    idArea: null,
    idVaga: null,
    metas: [],
    vigencia: null,
    idCicloColaborador: null,
    vaga: {
      area: {
        nomeLocal: "",
        idAreaPai: null
      },
      cargo: {
        codCargo: '',
        titulo: ''
      },
      idArea: null,
      idCargo: null,
      idFuncionario: null,
      idUnidade: null,
      status: ''
    }
  },
  posicoes: [],
  temporadas: [],
  objetivosPesoAreas: [],
  FuncionarioOwner: {
    idFuncionario: null,
    matricula: '',
    nomeCompleto: '',
    status: ''
  },
  projectSelected: {
    item: initialProjectSelected,
    loading: false,
    success: false
  }
}

const INITIAL_STATE: DataTypes = {
  currentTab: "0",
  dataProfile: {
    idFuncionario: null,
    matricula: '',
    nomeCompleto: '',
    status: '',
    Area: {
      codArea: '',
      idArea: null,
      nomeLocal: ''
    },
    Cargo: {
      codCargo: '',
      idCargo: null,
      titulo: ''
    }
  },
  data: initialData,
  loadingData: false,
  error: false,
  success: false,
  hasMetas: false,
  loadingProject: false,
  canRequestApproval: true,
  sendRequestApproval: false
};


/**
 * Actions
 */

export const Creators = {
  setCurrentTab: (indexTab: string): ProfileMetasActionTypes => {
    return ({
      type: Types.SET_CURRENT_TAB,
      indexTab
    })
  },

  getProfile: (id?: number): ProfileMetasActionTypes => {
    return ({
      type: Types.REQUEST_PROFILE_LOAD,
      id
    })
  },

  getProfileMetas: (idFuncionario?: number | null, idCiclo?: number, idCicloColaborador?: number): ProfileMetasActionTypes => {
    return ({
      type: Types.REQUEST_PROFILE_METAS_LOAD,
      payload: {
        idFuncionario,
        idCiclo,
        idCicloColaborador
      }
    })
  },

  // setFlagAceite: (idCicloMetaColaborador: number, flagAceite: boolean): ProfileMetasActionTypes => {
  //     return ({
  //         type: Types.REQUEST_ACCEPT_META,
  //         payload: {
  //             idCicloMetaColaborador,
  //             flagAceite
  //         }
  //     })
  // },

  getProjects: (idCiclo: number, idCicloColaborador: number, idFuncionario?: number): ProfileMetasActionTypes => ({
    type: Types.REQUEST_GET_PROJECTS,
    payload: {
      idCiclo,
      idCicloColaborador,
      idFuncionario
    }
  }),

  getProjectsById: (idCicloColaborador: number, idCicloColaboradorProjeto: number, idFuncionario?: number): ProfileMetasActionTypes => ({
    type: Types.REQUEST_GET_PROJECT_BY_ID,
    payload: {
      idCicloColaboradorProjeto,
      idFuncionario,
      idCicloColaborador,
    }
  }),

  addProject: (project: Partial<IProjectType>, idCicloColaborador: number): ProfileMetasActionTypes => ({
    type: Types.REQUEST_ADD_PROJECT,
    payload: {
      project,
      idCicloColaborador
    }
  }),


  editProject: (project: IProjectType, idCicloColaborador: number): ProfileMetasActionTypes => ({
    type: Types.REQUEST_EDIT_PROJECT,
    payload: {
      project,
      idCicloColaborador
    }
  }),

  delProject: (idCicloColaborador: number, idCicloColaboradorProjeto: number, idFuncionario?: number): ProfileMetasActionTypes => ({
    type: Types.REQUEST_DEL_PROJECT,
    payload: {
      idCicloColaboradorProjeto,
      idFuncionario,
      idCicloColaborador
    }
  }),

  reqAprovacao: (idCiclo: number, idCicloColaborador: number, idFuncionario?: number): ProfileMetasActionTypes => ({
    type: Types.REQUEST_APROVACAO,
    payload: {
      idCiclo,
      idCicloColaborador,
      idFuncionario
    }
  })


};


export default function profileReducer(state = INITIAL_STATE, action: ProfileMetasActionTypes): DataTypes {
  switch (action.type) {

    case Types.SET_CURRENT_TAB:
      return { ...state, currentTab: action.indexTab };

    case Types.REQUEST_PROFILE_LOAD:
      return { ...state }
    case Types.SUCCESS_PROFILE_LOAD:
      return { ...state, dataProfile: action.payload }
    case Types.FAILURE_PROFILE_LOAD:
      customToast.error(action.message);
      return { ...state }

    case Types.REQUEST_PROFILE_METAS_LOAD:
      return {
        ...state,
        loadingData: true,
        success: false,
        error: false,
        data: {
          ...initialData,
          projectSelected: {
            item: initialProjectSelected,
            success: false,
            loading: false
          }
        }
      };
    case Types.SUCCESS_PROFILE_METAS_LOAD:
      if (action.payload) {
        return {
          ...state,
          loadingData: false,
          success: true,
          error: false,
          data: {
            ...action.payload,
            projectSelected: {
              item: initialProjectSelected,
              success: false,
              loading: false
            }
          },
          hasMetas: true
        };
      } else {
        return {
          ...state,
          loadingData: false,
          success: true,
          error: false,
          hasMetas: false
        };
      }
    case Types.FAILURE_PROFILE_METAS_LOAD:
      customToast.error(action.message);
      return { ...state, loadingData: false, success: false, error: true };

    //SET FLAG ACEITE
    // case Types.REQUEST_ACCEPT_META:
    //     return state;
    // case Types.SUCCESS_ACCEPT_METAS:
    //     return { ...state, data: { ...state.data, posicao: { ...state.data.posicao, vCicloPeso: handleSetAcceptMeta(state.data.posicao.vCicloPeso, action.payload) } } };
    // case Types.FAILURE_ACCEPT_METAS:
    //     customToast.error(action.message);
    //     return state;

    case Types.REQUEST_GET_PROJECTS:
      return { ...state, error: false, loadingProject: true };
    case Types.SUCCESS_GET_PROJECTS:
      return { ...state, data: { ...state.data, posicao: { ...state.data.posicao, projetos: action.payload } }, error: false, success: true, loadingProject: false };
    case Types.FAILURE_GET_PROJECTS:
      customToast.error(action.message);
      return { ...state, error: true, loadingProject: false };

    case Types.REQUEST_GET_PROJECT_BY_ID:
      return {
        ...state,
        data: {
          ...state.data,
          projectSelected: {
            item: initialProjectSelected,
            loading: true,
            success: false
          }
        }
      };
    case Types.SUCCESS_GET_PROJECT_BY_ID:
      return {
        ...state,
        data: {
          ...state.data,
          projectSelected: {
            item: action.payload,
            loading: false,
            success: true
          }
        }
      };
    case Types.FAILURE_GET_PROJECT_BY_ID:
      customToast.error(action.message);
      return {
        ...state,
        data: {
          ...state.data,
          projectSelected: {
            item: initialProjectSelected,
            loading: false,
            success: false
          }
        }
      };

    case Types.REQUEST_ADD_PROJECT:
      return { ...state, error: false, success: false, loadingProject: true };
    case Types.SUCCESS_ADD_PROJECT:
      customToast.success("Iniciativa chave adicionada com sucesso");
      return { ...state, data: { ...state.data, posicao: { ...state.data.posicao, projetos: [...state.data.posicao.projetos, action.payload] } }, error: false, success: true, loadingProject: false };
    case Types.FAILURE_ADD_PROJECT:
      customToast.error(action.message);
      return { ...state, error: true, success: false, loadingProject: false };

    case Types.REQUEST_EDIT_PROJECT:
      return { ...state, error: false, success: false, loadingProject: true };
    case Types.SUCCESS_EDIT_PROJECT:
      customToast.success("Iniciativa chave editado com sucesso");
      return {
        ...state, data: {
          ...state.data,
          posicao: {
            ...state.data.posicao,
            projetos: state.data.posicao.projetos.map(project => {
              if (project.idCicloColaboradorProjeto !== action.payload.idCicloColaboradorProjeto) {
                return project;
              } else {
                return action.payload;
              }
            })
          }
        }, error: false,
        success: true,
        loadingProject: false
      };
    case Types.FAILURE_EDIT_PROJECT:
      customToast.error(action.message);
      return { ...state, error: true, success: false, loadingProject: false };

    case Types.REQUEST_DEL_PROJECT:
      return { ...state, error: false, success: false, loadingProject: true };
    case Types.SUCCESS_DEL_PROJECT:
      customToast.success("Iniciativa chave excluído com sucesso");
      return {
        ...state,
        data: {
          ...state.data,
          posicao: {
            ...state.data.posicao,
            projetos: state.data.posicao.projetos.filter(project => project.idCicloColaboradorProjeto !== action.idCicloColaboradorProjeto)
          }
        },
        error: false,
        success: true,
        loadingProject: false
      };
    case Types.FAILURE_DEL_PROJECT:
      customToast.error(action.message);
      return { ...state, error: true, success: false, loadingProject: false };

    case Types.REQUEST_APROVACAO:
      return { ...state };
    case Types.SUCCESS_APROVACAO:
      customToast.success("Solicitação enviada com sucesso");
      return { ...state, data: { ...state.data, posicao: {...state.data.posicao, flagSolicitarAprovacao: false, status: 'AGUARDANDO APROVAÇÃO'}} };
    case Types.FAILURE_APROVACAO:
      customToast.error(action.message);
      return { ...state, data: { ...state.data, posicao: {...state.data.posicao, flagSolicitarAprovacao: true}} }

    default:
      return state;
  }
}

// const handleSetAcceptMeta = (data: ICicloPesosPerfil[], values: ISuccessAcceptMetaAction["payload"]): ICicloPesosPerfil[] => {
//     return data.map(cicloPeso => {
//         if (cicloPeso.idCicloMetaColaborador !== values.idCicloMetaColaborador) {
//             return cicloPeso;
//         } else {
//             return {
//                 ...cicloPeso,
//                 flagAceiteLocal: values.flagAceite
//             };
//         }
//     });
// }

