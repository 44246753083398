import { Types, DataTypes, AttitudesActionTypes, AttitudeType } from "./types";
import customToast from "~/components/Toast/index";

/**
 * Reducers
 */
const INITIAL_STATE: DataTypes = {
    data: [],
    itemSelected: {
        item: {
            idAtitude: null,
            descAtitude: '',
            nomeAtitude: ''
        },
        error: false,
        loading: false,
        success: false
    },
    error: false,
    loadingData: false,
    loadingAction: false,
    success: false
};

/**
 * Actions
 */

export const Creators = {
    getAttitudes: (search?: string): AttitudesActionTypes => {
        search = search ? search : "";
        return {
            type: Types.REQUEST_ATTITUDES_LOAD,
            search
        }
    },

    getAttitudeById: (id: number): AttitudesActionTypes => ({
        type: Types.REQUEST_ATTITUDES_LOAD_BY_ID,
        id
    }),

    addAttitude: (attitude: AttitudeType): AttitudesActionTypes => ({
        type: Types.REQUEST_ATTITUDES_ADD,
        attitude
    }),

    editAttitude: (attitude: AttitudeType): AttitudesActionTypes => ({
        type: Types.REQUEST_ATTITUDE_EDIT,
        attitude
    }),

    delAttitude: (id: number): AttitudesActionTypes => ({
        type: Types.REQUEST_ATTITUDE_DELETE,
        id
    }),

};


export default function attitudesReducer(state = INITIAL_STATE, action: AttitudesActionTypes): DataTypes {

    switch (action.type) {
        case Types.REQUEST_ATTITUDES_LOAD:
            return { ...state, loadingData: true, error: false, success: false };
        case Types.SUCCESS_ATTITUDES_LOAD:
            return { ...state, data: action.payload, loadingData: false, error: false, success: true };
        case Types.FAILURE_ATTITUDES_LOAD:
            customToast.error(action.message);
            return { ...state, data: [], loadingData: false, error: true, success: false };

        case Types.REQUEST_ATTITUDES_LOAD_BY_ID:
            return { ...state, itemSelected: { ...state.itemSelected, success: false, loading: true, error: false } };
        case Types.SUCCESS_ATTITUDES_LOAD_BY_ID:
            return { ...state, itemSelected: { ...state.itemSelected, item: action.payload, success: true, loading: false, error: false } };
        case Types.FAILURE_ATTITUDES_LOAD_BY_ID:
            customToast.error(action.message);
            return { ...state, itemSelected: { ...state.itemSelected, success: false, loading: false, error: true } };

        case Types.REQUEST_ATTITUDES_ADD:
            return { ...state, loadingAction: true, error: false, success: false };
        case Types.SUCCESS_ATTITUDES_ADD:
            return { ...state, data: [...state.data, action.payload], loadingAction: false, error: false, success: true };
        case Types.FAILURE_ATTITUDES_ADD:
            customToast.error(action.message);
            return { ...state, data: [], loadingAction: false, error: true, success: false };

        case Types.REQUEST_ATTITUDE_EDIT:
            return { ...state, loadingAction: true, error: false, success: false };
        case Types.SUCCESS_ATTITUDE_EDIT:
            return {
                ...state, data: state.data.map(item => {
                    if (item.idAtitude !== action.payload.idAtitude) {
                        return item;
                    } else {
                        return action.payload
                    }
                }),
                loadingAction: false, error: false, success: true
            };
        case Types.FAILURE_ATTITUDE_EDIT:
            customToast.error(action.message);
            return { ...state, data: [], loadingAction: false, error: true, success: false };

        case Types.REQUEST_ATTITUDE_DELETE:
            return { ...state, loadingAction: true, error: false, success: false };
        case Types.SUCCESS_ATTITUDE_DELETE:
            return { ...state, data: state.data.filter(item => item.idAtitude !== action.id), loadingAction: false, error: false, success: true };
        case Types.FAILURE_ATTITUDE_DELETE:
            customToast.error(action.message);
            return { ...state, data: [], loadingAction: false, error: true, success: false };

        default:
            return state;

    }
}