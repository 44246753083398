import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #1d1d1d;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
`;

export const Left = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 50px;
  background-color: #027636;
  box-sizing: border-box;
  transition: min-width 0.3s ease, max-width 0.3s ease;
  min-width: ${({ isExpanded }) => isExpanded ? "202px" : '0px'};
  max-width: ${({ isExpanded }) => isExpanded ? "202px" : '0px'};

  ${({ isExpanded }) => isExpanded ? `
    img{
      opacity: 1;
      transition: opacity 0.6s;
    }
    ` : `
    img{
      opacity: 0;
      transition: opacity 0.1s;
    }
    
  `}

`;

export const StMarcheLogo = styled.img`
  height: auto;
  padding-left: 13px;
`;

// export const StMarcheLogo = styled.img`
//   width: 55%;
//   height: auto;
// `;


export const Badge = styled.span`
  background-color: green;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  font-weight: bold;
  left: 15px;
  z-index: 999;
  font-size: 11px;
  top: 8px;
  color: #ffffff;
`

export const BtnMenu = styled.div`
  width: 48px;
  min-width: 48px;
  height: 50px;
  background-color: #027636;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover{
    background-color: #027636CC
  }
`

export const WrapperBreadcrumbs = styled.div`
  width: 100%;

  @media screen and (max-width: 960px){
    display: none;
  }
`

export const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`

export const WrapperSearchBox = styled.div<{ show: boolean }>`
    width: 315px;
    
  @media screen and (max-width: 960px){
    width: ${({ show }) => show ? '315' : '0'}px;
    opacity: ${({ show }) => show ? '1' : '0'};
    margin-right: ${({ show }) => show ? '15' : '0'}px;
    transition: width 0.3s, opacity 0.3s;
  }
`