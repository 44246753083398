import { all } from "redux-saga/effects";

import login from "./login";
import companies from "./companies";
import cycles from "./cycles";
import cycle from "./cycle";
import cycleAttitudes from "./cycleAttitudes";
import cyclePainel from "./cyclePainel";
import cycleDismemberment from "./cycleDismemberment";
import cycleValidation from "./cycleValidation";
import pillars from "./pillars";
import objectives from "./objectives";
import chart from "./chart";
import evaluations from "./evaluations";
import employees from "./employees";
import units from "./units";
import positions from "./positions";
import tasks from "./tasks";
import profile from "./profile";
import solicitation from "./solicitation";
import attitudes from "./attitudes";

export default function* rootSaga(){
    yield all([
        login(),
        companies(),
        cycles(),
        cycle(),
        cycleAttitudes(),
        cyclePainel(),
        cycleDismemberment(),
        cycleValidation(),
        pillars(),
        objectives(),
        chart(),
        evaluations(),
        employees(),
        units(),
        positions(),
        tasks(),
        profile(),
        solicitation(),
        attitudes()
    ]);
};