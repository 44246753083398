import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Formik } from "formik";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { History } from 'history';
import * as yup from 'yup';

import {
    Wrapper,
    PrimaryButton,
    DefaultButton,
    ListContainer,
    GroupCheckbox,
    ContainerContent
} from "./styles";

//FluentUI
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import {
    DetailsListLayoutMode,
    SelectionMode,
    Selection,
    IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { ShimmeredDetailsList } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { SearchBox } from 'office-ui-fabric-react/lib/SearchBox';
import { CommandBarButton, IconButton, ICommandBarStyles } from 'office-ui-fabric-react';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import CustomDialog from "~/components/CustomDialogFluentUI";
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
//MaterialUI
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
//Componentes
import Panel from "~/components/layout/Panel";
import { CycleType, DataTypes } from "~/store/ducks/cycles/types";
import { DataTypes as DataTypesEmployees, EmployeeType } from "~/store/ducks/employees/types";
import { RootState } from "~/store/ducks";
import { CustomDrawer as Drawer } from "~/components/layout/Drawer";
import { InputText, InputDate } from '~/components/Forms';
import HeaderPage from '~/components/layout/HeaderPage';
import NoItems from '~/components/layout/NoItems';
import { DeleteButton } from '~/components/Buttons';
import Dropdown from "~/components/layout/Dropdown";
import Autocomplete from '@material-ui/lab/Autocomplete';
import colors from "~/assets/js/colors";
import { Creators as getEmployees } from "~/store/ducks/employees";

import {
    Creators as getCycles,
    Creators as getCycleById
} from "~/store/ducks/cycles";
import { Creators as addCycle } from "~/store/ducks/cycles";
import { Creators as delCycle } from "~/store/ducks/cycles";
import { Creators as editCycle } from "~/store/ducks/cycles";
import { Creators as setCurrentPage } from "~/store/ducks/home";
import { Page, BreadcrumbItems } from "~/store/ducks/home/types";
import { ChartType, DataTypes as DataTypesChart } from "~/store/ducks/chart/types";
import { Creators as getChart } from "~/store/ducks/chart";

const btnStyle: Partial<ICommandBarStyles> = {
    root: {
        height: 44
    }
};

type GroupFilter = {
    stmarche?: boolean;
    emporio?: boolean;
};

type Filter = {
    isOpen?: boolean;
    filteredBy?: string[];
    group?: GroupFilter;
};

interface ICycleState {
    columns: IColumn[];
    items: CycleType[];
    isPanelOpen: boolean;
    isDialogOpen: boolean;
    selectionDetails: string;
    inicialValues: CycleType;
    selectionCount: number;
    isFiltering: boolean;
    isDialogExcludeOpen: boolean;
    filter: Filter;
    employee: Partial<EmployeeType>;
    chart: Partial<ChartType>;
    itemSelected: CycleType;
};


const validationCyclesSchema = yup.object().shape({
    ano: yup.number().nullable().required("Campo obrigatório"),
    idAreaPai: yup.number().nullable().required("Campo obrigatório"),
    // status: yup.string().required("Campo obrigatório"),
    prazoPlanejamento: yup.date().nullable().required("Campo obrigatório"),
    responsavel: yup.object().shape({
        idFuncionario: yup.number().nullable().required("Campo obrigatório")
    }),

});


const inicialValueCycle: CycleType = {
    idCiclo: null,
    idAreaPai: null,
    ano: null,
    status: '',
    responsavel: {
        idFuncionario: null,
        nomeCompleto: ""
    },
    area: {
        nomeLocal: ""
    },
    prazoPlanejamento: null,
    dataPublicacao: null
};

const itemsBreadCrumb: BreadcrumbItems[] = [
    { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
    { text: 'Ciclos', isCurrentItem: true },
];

const page: Page = {
    key: 'ciclos',
    pages: itemsBreadCrumb
};

interface ICyclesProps extends RouteComponentProps {
    cycles: DataTypes;
    employees: DataTypesEmployees;
    charts: DataTypesChart;
    setCurrentPage: (page: Page) => void;
    getCycles: (year?: string) => void;
    getCycleById: (id: number) => void;
    addCycle: (cycle: CycleType) => void;
    editCycle: (cycle: CycleType) => void;
    delCycle: (id: number) => void;
    getEmployees: (search?: string, filter?: boolean | null) => void;
    getChart: (search?: string, filter?: boolean | null, treeview?: boolean) => void;
    history: History;
}

class Cycles extends Component<ICyclesProps, ICycleState>{
    private formRef: any;
    private inputSearch: any;
    private _selection: Selection;
    private timeout: number;

    constructor(props: ICyclesProps) {
        super(props);

        const columns: IColumn[] = [
            {
                key: 'column1',
                name: 'Ano',
                ariaLabel: 'Ano',
                fieldName: 'ano',
                isRowHeader: true,
                minWidth: 75,
                maxWidth: 100,
                isSortedDescending: false,
                onColumnClick: this._onColumnClick,
            },
            {
                key: 'column2',
                name: 'Área Principal',
                fieldName: 'area',
                minWidth: 150,
                maxWidth: 210,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: CycleType) => {
                    return item.area ? item.area.nomeLocal : ""
                },
            },
            {
                key: 'column3',
                name: 'Status',
                fieldName: 'status',
                minWidth: 150,
                maxWidth: 210,
                isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                onRender: (item: CycleType) => formattedStatus(item.status)
            },
            // {
            //     key: 'column3',
            //     name: 'Responsável',
            //     isRowHeader: true,
            //     minWidth: 70,
            //     maxWidth: 90,
            //     isResizable: true,
            //     onColumnClick: this._onColumnClick,
            //     data: 'string',
            //     isPadded: true
            // }
        ];

        this.state = {
            columns: columns,
            items: [],
            isPanelOpen: false,
            isDialogOpen: false,
            isDialogExcludeOpen: false,
            selectionDetails: "",
            isFiltering: false,
            inicialValues: inicialValueCycle,
            selectionCount: 0,
            filter: {
                isOpen: false,
                filteredBy: [],
                group: {
                    stmarche: false,
                    emporio: false,
                }
            },
            employee: {
                idFuncionario: null,
                nomeCompleto: ""
            },
            chart: {
                idArea: null,
                nomeLocal: ""
            },
            itemSelected: inicialValueCycle,
        };
        this.formRef = React.createRef();
        this.inputSearch = React.createRef();
        this.timeout = 0;

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails(),
                });
            },
        });
    };

    componentDidMount() {
        this.props.setCurrentPage(page);
        this.props.getCycles();
    };


    componentDidUpdate(prevProps: ICyclesProps, prevState: ICycleState) {
        const { itemSelected: prevItem, success: prevSuccess } = prevProps.cycles;
        const { itemSelected: nextItem, success: nextSuccess } = this.props.cycles;

        if (prevItem.item !== nextItem.item) {
            if (nextItem.success) {
                const { responsavel, area, idAreaPai } = nextItem.item!;
                this.setState({
                    inicialValues: nextItem.item!,
                    employee: {
                        idFuncionario: responsavel.idFuncionario,
                        nomeCompleto: responsavel.nomeCompleto
                    },
                    chart: {
                        idArea: idAreaPai,
                        nomeLocal: area ? area.nomeLocal : ""
                    }
                });
            };
        };

        if (prevSuccess !== nextSuccess) {
            if (nextSuccess) {
                this.setState({ isPanelOpen: false });
            }
        }
    };

    _getSelectionDetails(): any {
        const selectionCount = this._selection.getSelectedCount();
        this.setState({ selectionCount });
        this._selection.getSelection();
    };

    // Submit do formulário Formik
    handleSubmit = () => {
        if (this.formRef.current) {
            this.formRef.current.handleSubmit();
        };
    };

    cancelPanel = () => {
        this._selection.setAllSelected(false);
        this.setState({ isPanelOpen: false });
    };

    openAdd = () => {
        this.setState({
            isPanelOpen: true,
            inicialValues: inicialValueCycle,
            employee: {
                idFuncionario: null,
                nomeCompleto: ""
            },
            chart: {
                idArea: null,
                nomeLocal: ""
            }
        })
    }

    openEdit = () => {
        this.setState({ isPanelOpen: true });
        const idSelected = (this._selection.getSelection()[0] as CycleType).idCiclo!;
        this.props.getCycleById(idSelected);
    };


    excludeCompanies = () => {
        this.props.delCycle((this._selection.getSelection()[0] as CycleType).idCiclo!);
        this.setState({ isDialogExcludeOpen: false });
    };

    search = (text: string, type: string) => {
        clearTimeout(this.timeout);
        if (text.trim() !== "") {
            this.timeout = setTimeout(() => {
                if (type === 'responsavel') {
                    this.props.getEmployees(text)
                } else if (type === 'areaSearch') {
                    this.props.getChart(text, true, false);
                }
            }, 700);
        }
    };

    filter = (text: any) => {
        // const params = this.state.filter.filteredBy;
        // const cycles = this.props.cycles.data;
        // let items = text ? cycles.filter((i: CycleType) => i.FuncionarioOwner!.NomeFuncionario.toLowerCase().indexOf(text.toLowerCase()) > -1) : cycles;
        // if (params!.length > 0) {
        //     items = items.filter((item: CycleType) => {
        //         for (let i = 0; i < params!.length; i++) {
        //             if (item.status === params![i]) {
        //                 return item;
        //             };
        //         };
        //     });
        // };
        // this.setState({ items, isFiltering: true });
    };

    toggleFilter = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                isOpen: !this.state.filter.isOpen,
            }
        });
    };

    toggleParamsFilter = (param: string) => {
        const filter = this.state.filter;
        const params = filter.filteredBy;
        const stmarche = filter.group?.stmarche;
        const emporio = filter.group?.emporio;

        const index = params!.indexOf(param);
        if (index > -1) {
            params!.splice(index, 1);
        } else {
            params!.push(param);
        };

        if (param === "ST MARCHE") {
            this.setState({ filter: { ...this.state.filter, filteredBy: params, group: { ...this.state.filter.group, stmarche: !stmarche } } });
        } else {
            this.setState({ filter: { ...this.state.filter, filteredBy: params, group: { ...this.state.filter.group, emporio: !emporio! } } });
        };

        if (this.inputSearch.current) {
            this.filter(this.inputSearch.current.state.value);
        };

    };


    clearFilters = () => {
        this.setState({
            filter: {
                ...this.state.filter,
                filteredBy: [],
                group:
                {
                    emporio: false,
                    stmarche: false
                }
            }
        }, () => this.filter(""));
    };

    _onItemInvoked = (item: CycleType) => {
        this.props.history.push({
            pathname: `/ciclos/${(this._selection.getSelection()[0] as CycleType).idCiclo}`
        })
    }


    commandBarRender = () => {
        const { selectionCount } = this.state;
        if (selectionCount === 1) {
            return (
                <>
                    <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Edit' }} text="Editar" onClick={() => this.openEdit()} />
                    <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />
                    <Link to={`/ciclos/${(this._selection.getSelection()[0] as CycleType).idCiclo}`}
                    >
                        <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Settings' }} text="Definições" />
                    </Link>
                </>
            );
        } else if (selectionCount > 1) {
            return <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Delete' }} text="Excluir" onClick={() => this.setState({ isDialogExcludeOpen: true })} />;
        };
    };

    render() {
        const { isPanelOpen, inicialValues, selectionCount, columns, isFiltering, isDialogExcludeOpen, items, filter, employee, chart } = this.state;
        const { cycles, addCycle, editCycle, getCycles, employees, charts } = this.props;
        const { itemSelected } = cycles;

        const dialogContentProps = {
            title: 'Excluir?',
            closeButtonAriaLabel: 'Close',
            subText: selectionCount <= 1 ? 'Tem certeza de que deseja excluir este item?' : 'Tem certeza de que deseja excluir estes items?',
        };

        return (
            <>
                <Panel
                    title={!inicialValues.idCiclo ? "Novo Ciclo" : inicialValues.ano!.toString()}
                    open={isPanelOpen}
                    loading={itemSelected.loading}
                    onClose={() => this.cancelPanel()}
                    footer={
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <DefaultButton onClick={() => this.cancelPanel()}>Cancelar</DefaultButton>
                            {
                                !cycles.loadingAction ?
                                    <PrimaryButton onClick={this.handleSubmit}>Salvar</PrimaryButton>
                                    :
                                    <Spinner styles={{ root: { width: 110 } }} size={SpinnerSize.small} />
                            }
                        </Stack>
                    }
                >
                    {
                        cycles.itemSelected.loading ?
                            <Spinner size={SpinnerSize.large} styles={{ root: { height: 'calc(100% - 50px)' } }} />
                            :
                            <Formik
                                innerRef={this.formRef}
                                initialValues={inicialValues}
                                validationSchema={validationCyclesSchema}
                                enableReinitialize
                                validateOnChange={false}
                                validateOnBlur={true}
                                onSubmit={(values: any) => {
                                    values.area.nomeLocal = this.state.chart.nomeLocal;
                                    if (inicialValues.idCiclo === null) {
                                        addCycle(values);
                                    } else {
                                        values.id = inicialValues.idCiclo
                                        editCycle(values);
                                    };
                                }}
                            >
                                {({ handleSubmit, handleChange, values, errors, setFieldValue, setFieldError }) => (
                                    <form onSubmit={handleSubmit}>
                                        <InputText
                                            value={values.ano !== null ? values.ano : ""}
                                            onChange={e => { handleChange(e); setFieldError('ano', '') }}
                                            id="ano"
                                            error={errors.ano ? true : false}
                                            name="ano"
                                            type="number"
                                            label="Ano"
                                            helperText={errors.ano}
                                            className="mt-2"
                                            autoFocus
                                        />

                                        <Autocomplete
                                            value={(employee as EmployeeType)}
                                            onChange={(_, newValue) => {
                                                this.setState({ employee: newValue! })
                                                setFieldValue("responsavel", { idFuncionario: newValue?.idFuncionario! });
                                            }}
                                            onInputChange={(_, newInputValue) => {
                                                setFieldError("responsavel", "");
                                                this.search(newInputValue, "responsavel");
                                            }}
                                            getOptionLabel={(employee: EmployeeType) => employee.nomeCompleto}
                                            getOptionSelected={(option, value) => option.idFuncionario === value.idFuncionario}
                                            id="responsavel"
                                            noOptionsText="Não há opções"
                                            options={employees.data}
                                            renderInput={(params) => <InputText
                                                {...params}
                                                label="Funcionário Responsável"
                                                helperText={errors.responsavel?.idFuncionario}
                                                type="text"
                                                error={errors.responsavel?.idFuncionario ? true : false}
                                                name="funcionarioResponsavel"
                                            />}
                                        />

                                        <Autocomplete
                                            value={(chart as ChartType)}
                                            onChange={(_, newValue) => {
                                                this.setState({ chart: newValue! })
                                                setFieldValue("idAreaPai", newValue?.idArea!);
                                            }}
                                            onInputChange={(_, newInputValue) => {
                                                setFieldError("idAreaPai", "");
                                                this.search(newInputValue, "areaSearch");
                                            }}
                                            getOptionLabel={(chart: ChartType) => chart.nomeLocal}
                                            getOptionSelected={(option, value) => option.idArea === value.idArea}
                                            id="idAreaPai"
                                            noOptionsText="Não há opções"
                                            options={charts.data}
                                            renderInput={(params) => <InputText
                                                {...params}
                                                label="Área Principal"
                                                helperText={errors.idAreaPai}
                                                type="text"
                                                error={errors.idAreaPai ? true : false}
                                                name="idAreaPai"
                                            />}
                                        />



                                        <InputDate
                                            value={values.prazoPlanejamento}
                                            onChange={value => setFieldValue('prazoPlanejamento', value)}
                                            onKeyDown={() => setFieldError('prazoPlanejamento', '')}
                                            id="prazoPlanejamento"
                                            error={errors.prazoPlanejamento ? true : false}
                                            name="prazoPlanejamento"
                                            label="Prazo do planejamento"
                                            helperText={errors.prazoPlanejamento}
                                        />
                                        {/* <Dropdown
                                                        errors={errors.status}
                                                        label="Status"
                                                        name="status"
                                                        values={values.status}
                                                        handleChange={(e: any) => { handleChange(e); setFieldError("status", "") }}
                                                        errorText={errors.status}
                                                    >
                                                        <MenuItem value={"P"}>Em Planejamento</MenuItem>
                                                        <MenuItem value={"V"}>Em Validação</MenuItem>
                                                        <MenuItem value={"F"}>Finalizado</MenuItem>
                                                    </Dropdown> */}
                                    </form>
                                )}
                            </Formik>
                    }
                </Panel>
                <CustomDialog
                    hidden={!isDialogExcludeOpen}
                    onDismiss={() => this.setState({ isDialogExcludeOpen: false })}
                    dialogContentProps={dialogContentProps}
                >
                    <DefaultButton onClick={() => this.setState({ isDialogExcludeOpen: false })} text="Cancelar" />
                    <DeleteButton onClick={() => this.excludeCompanies()} text="Excluir" />
                </CustomDialog>
                <Wrapper >
                    <Drawer
                        isOpen={filter.isOpen}
                        content={
                            <ContainerContent>
                                <HeaderPage
                                    title="Ciclos"
                                    leftItems={
                                        <>
                                            <CommandBarButton
                                                styles={btnStyle}
                                                iconProps={{ iconName: 'Add' }}
                                                text="Adicionar Ciclo"
                                                onClick={this.openAdd}
                                                disabled={cycles.loadingData}
                                            />
                                            {this.commandBarRender()}
                                        </>
                                    }
                                    rightItems={
                                        <>
                                            <SearchBox
                                                placeholder="Pesquisar"
                                                onChange={(e) => this.filter(e?.target.value)}
                                                componentRef={this.inputSearch}
                                                styles={{ root: { border: 'none', width: 200, marginRight: 1 } }}
                                                disabled={cycles.data.length === 0}
                                            />
                                            <CommandBarButton styles={btnStyle} iconProps={{ iconName: 'Refresh' }} text="Atualizar" onClick={() => getCycles()} />

                                            {/* <CommandBarButton
                                                styles={btnStyle}
                                                iconProps={{ iconName: 'filter' }}
                                                text="Filtrar"
                                                onClick={() => this.toggleFilter()}
                                                disabled={cycles.data.length === 0}
                                            /> */}
                                        </>
                                    }
                                />
                                {!cycles.loadingData && cycles.data.length === 0 ?
                                    <NoItems
                                        error={cycles.error}
                                        text="Não há ciclos cadastrados"
                                        img="/static/icons/cycle.svg"
                                        alt="Ciclos"
                                    />
                                    :
                                    <ListContainer>
                                        <ShimmeredDetailsList
                                            items={isFiltering ? items : cycles.data}
                                            enableShimmer={cycles.loadingData}
                                            columns={columns}
                                            selectionMode={SelectionMode.single}
                                            selection={this._selection}
                                            getKey={this._getKey}
                                            selectionPreservedOnEmptyClick={true}
                                            setKey="single"
                                            layoutMode={DetailsListLayoutMode.justified}
                                            isHeaderVisible={true}
                                            onItemInvoked={this._onItemInvoked}
                                        />
                                    </ListContainer>
                                }
                            </ContainerContent>
                        }>
                        <>
                            <Stack horizontal horizontalAlign="space-between">
                                <Text variant="xLarge">Filtros</Text>
                                <Stack horizontal>
                                    <IconButton iconProps={{ iconName: 'ClearFilter' }} title="Limpar Filtros" disabled={filter.filteredBy!.length > 0 ? false : true} onClick={() => this.clearFilters()} ariaLabel="Limpar Filtros" />
                                    <IconButton iconProps={{ iconName: 'ChromeClose' }} styles={{ root: { color: colors.darkGray } }} title="Fechar" ariaLabel="Fechar" onClick={() => this.setState({ filter: { ...filter, isOpen: false } })} />
                                </Stack>
                            </Stack>
                            <GroupCheckbox>
                                <Text variant="mediumPlus" styles={{ root: { marginBottom: 10 } }}>Grupo Empresarial</Text>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={filter.group?.stmarche}
                                            onChange={() => this.toggleParamsFilter("ST MARCHE")}
                                            name="inactivate"
                                            color="primary"
                                        />
                                    }
                                    label={<span style={{ fontSize: 14 }}>St Marche</span>}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={filter.group?.emporio}
                                            onChange={() => this.toggleParamsFilter("EMPORIO SANTA MARIA")}
                                            name="inactivate"
                                            color="primary"
                                        />
                                    }
                                    label={<span style={{ fontSize: 14 }}>Empório Santa Maria</span>}
                                />
                            </GroupCheckbox >
                        </>
                    </Drawer>
                </Wrapper>
            </>
        );
    };

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns } = this.state;
        const newColumns: IColumn[] = columns.slice();
        let items: CycleType[] = [];
        if (this.state.isFiltering) {
            items = this.state.items;
        } else {
            items = this.props.cycles.data;
        };
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            };
        });
        const newItems = this._sort(items, currColumn.fieldName!, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems,
        });
    };

    private _getKey(item: any, index?: number): any {
        if (item !== undefined)
            return item.key;
    };

    private _sort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        const itemsSorted = items.sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
        return itemsSorted;
    };
};

const mapStateToProps = (state: RootState) => ({
    cycles: state.cyclesReducer,
    employees: state.employeesReducer,
    charts: state.chartReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    ...getCycles,
    ...getCycleById,
    ...addCycle,
    ...delCycle,
    ...editCycle,
    ...getEmployees,
    ...setCurrentPage,
    ...getChart
}, dispatch);

export const formattedStatus = (status: string): string => {
    switch (status) {
        case 'P':
            return 'Em planejamento';
        case 'V':
            return 'Em validação';
        case 'I':
            return 'Inconsistência na validação';
        case 'A':
            return 'Aguardando publicação';
        case 'N':
            return 'Em publicação';
        case 'E':
            return 'Erro na publicação';
        case 'D':
            return 'Publicado'
        case 'F':
            return 'Finalizado';
        default:
            return '';
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cycles));