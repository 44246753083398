import styled from 'styled-components';
import { ITextStyles } from "office-ui-fabric-react/lib/Text";

export const TitleGroupStyles: Partial<ITextStyles> = {
    root: {
        marginBottom: 4,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        fontWeight: 600
    }
}

export const TitleFieldStyles: Partial<ITextStyles> = {
    root: {
        marginBottom: 4,
        height: 20,
        display: 'flex',
        alignItems: 'center',
    }
}

export const TitleHeaderStyles = styled.div`
    margin-top: 5px;
    margin-bottom: 32px;
    height: 32px;
`

export const WrapperGroupItemLeft = styled.div`
    width: 290px;
    margin-right: 10px;
`

export const WrapperGroupItemRight = styled.div`
    width: 290px;
`

export const WrapperGroupItems = styled.div`
    margin-bottom: 24px;
    
`

export const WrapperItems = styled.div<{ hasMultiItems: boolean }>`
    display: ${props => props.hasMultiItems ? 'block' : 'flex'};
    flex-direction: row;
    div{
        margin-right: 10px;
    }
    div:last-child{
        margin-right: 0px;
    }
`

export const WrapperGroupItemUmTerco = styled.div`
    width: 190px;
`

export const WrapperGroupItemDoisTerco = styled.div`
    width: 390px;
`