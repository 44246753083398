//#region IMPORTS
import { takeLatest, put, call, all } from "redux-saga/effects";
import { Types as TypesValidation, ISuccessValidationCycleAction} from '../ducks/cycle/validation/types'
import {
  Types,
  ObjectiveCycleType,
  PillarsType,
  IRequestCycleLoadAction,
  IRequestCyclePillarsLoadAction,
  IRequestCyclesAddPillarAction,
  IRequestCyclesAddObjectiveAction,
  IRequestCyclesDeleteObjectiveAction,
  IRequestCyclesDeletePillarAction,
  IRequestCyclesPillarNextAction,
  IRequestCyclesPillarPriorAction,
  ISuccessCycleLoadAction,
  ISuccessCyclesAddPillarAction,
  ISuccessCyclesDeletePillarAction,
  ISuccessCyclesAddObjectiveAction,
  ISuccessCyclesDeleteObjectiveAction,
  ISuccessCyclesPillarNextAction,
  ISuccessCyclesPillarPriorAction,
  IRequestCycleValidationAction,
  ISuccessCycleValidationAction,
} from "../ducks/cycle/types";

import api from "~/services/api";

//#endregion

const apiCallGet = (id: IRequestCycleLoadAction["id"]) => {
  return api.get(`/ciclos/${id}`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

//#region  API PILAR

const apiCallGetPillars = (id: number) => {
  return api.get(`/ciclos/${id}/pilares`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiPostPillar = (payload: IRequestCyclesAddPillarAction["payload"]) => {
  return api.post(`/ciclos/${payload.idCiclo}/pilares`, {
    idPilar: payload.pillar.idPilar
  })
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiDelPillar = (payload: IRequestCyclesDeletePillarAction["payload"]) => {
  return api.delete(`/ciclos/${payload.idCiclo}/pilares/${payload.idCicloPilar}`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

//#endregion
//#region  API OBJETIVO

const apiPostObjective = (payload: IRequestCyclesAddObjectiveAction["payload"]) => {
  return api.post(`/ciclos/${payload.idCiclo}/objetivos`, {
    idCicloPilar: payload.idCicloPilar,
    idObjetivo: payload.objetivo.idObjetivo
  })
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiDelObjective = (payload: IRequestCyclesDeleteObjectiveAction["payload"]) => {
  return api.delete(`/ciclos/${payload.idCiclo}/objetivos/${payload.idCicloObjetivo}`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiNextPilar = (payload: IRequestCyclesPillarNextAction["payload"]) => {
  return api.get(`/ciclos/${payload.idCiclo}/pilares/${payload.idCicloPilar}/next`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiPriorPilar = (payload: IRequestCyclesPillarPriorAction["payload"]) => {
  return api.get(`/ciclos/${payload.idCiclo}/pilares/${payload.idCicloPilar}/prior`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

const apiCallValidationCycle = (idCiclo: number) => {
  return api.get(`/ciclos/${idCiclo}/validar`)
    .then(response => response.data)
    .catch(err => {
      throw err.response;
    });
};

//#endregion

function* load(action: IRequestCycleLoadAction) {
  try {
    const items = yield call(apiCallGet, action.id);
    yield put({ type: Types.SUCCESS_CYCLE_LOAD, payload: items } as ISuccessCycleLoadAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar o ciclo';
    yield put({
      type: Types.FAILURE_CYCLE_LOAD,
      message
    });
  };
};
//#region PILARES
function* loadPillars(action: IRequestCyclePillarsLoadAction) {
  try {
    const items = yield call(apiCallGetPillars, action.id);
    yield put({ type: Types.SUCCESS_CYCLE_PILLARS_LOAD, payload: items });
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível carregar os pilares';
    yield put({
      type: Types.FAILURE_CYCLE_PILLARS_LOAD,
      message
    });
  };
};

function* addPillar(action: IRequestCyclesAddPillarAction) {
  try {
    const idCicloPilar = yield call(apiPostPillar, action.payload);
    const pillar: PillarsType = {
      idCiclo: action.payload.idCiclo,
      ...idCicloPilar,
      idPilar: action.payload.pillar.idPilar!,
      objetivos: [],
      pilar: {
        corPilar: action.payload.pillar.corPilar,
        nomePilar: action.payload.pillar.nomePilar
      }
    };
    yield put({ type: Types.SUCCESS_CYCLES_ADD_PILLAR, pillar: pillar } as ISuccessCyclesAddPillarAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível adicionar pilar';
    yield put({
      type: Types.FAILURE_CYCLES_ADD_PILLAR,
      message
    });
  };
};

function* deletePillar(action: IRequestCyclesDeletePillarAction) {
  try {
    yield call(apiDelPillar, action.payload);
    yield put({
      type: Types.SUCCESS_CYCLES_DELETE_PILLAR,
      idCicloPilar: action.payload.idCicloPilar
    } as ISuccessCyclesDeletePillarAction);
  } catch (error) {
    const message = error !== undefined ? error.data.data ? error.data.data : error.data.message : 'Não foi possível remover pilar do ciclo';
    yield put({
      type: Types.FAILURE_CYCLES_DELETE_PILLAR,
      message
    });
  };
};

//#endregion
//#region Objetivos
function* addObjective(action: IRequestCyclesAddObjectiveAction) {
  try {
    const idCicloObjetivo = yield call(apiPostObjective, action.payload);
    const objective: ObjectiveCycleType = {
      ...idCicloObjetivo,
      idObjetivo: action.payload.objetivo.idObjetivo,
      objetivo: {
        descObjetivo: action.payload.objetivo.objetivo.descObjetivo
      },
      meta: null
    };
    yield put({
      type: Types.SUCCESS_CYCLES_ADD_OBJECTIVE,
      payload: {
        objective: objective,
        idCicloPilar: action.payload.idCicloPilar
      }
    } as ISuccessCyclesAddObjectiveAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível adicionar objetivo ao pilar';
    yield put({
      type: Types.FAILURE_CYCLES_ADD_OBJECTIVE,
      message
    });
  };
};


function* deleteObjective(action: IRequestCyclesDeleteObjectiveAction) {
  try {
    yield call(apiDelObjective, action.payload);
    yield put({
      type: Types.SUCCESS_CYCLES_DELETE_OBJECTIVE,
      idCicloObjetivo: action.payload.idCicloObjetivo
    } as ISuccessCyclesDeleteObjectiveAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível remover objetivo ao pilar';
    yield put({
      type: Types.FAILURE_CYCLES_DELETE_OBJECTIVE,
      message
    });
  };
};

function* nextPilar(action: IRequestCyclesPillarNextAction) {
  try {
    yield call(apiNextPilar, action.payload);
    yield put({
      type: Types.SUCCESS_CYCLES_PILLAR_NEXT,
      idCicloPilar: action.payload.idCicloPilar
    } as ISuccessCyclesPillarNextAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível mover o pilar';
    yield put({
      type: Types.FAILURE_CYCLES_PILLAR_NEXT,
      message
    });
  };
};

function* priorPilar(action: IRequestCyclesPillarPriorAction) {
  try {
    yield call(apiPriorPilar, action.payload);
    yield put({
      type: Types.SUCCESS_CYCLES_PILLAR_PRIOR,
      idCicloPilar: action.payload.idCicloPilar
    } as ISuccessCyclesPillarPriorAction);
  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível mover o pilar';
    yield put({
      type: Types.FAILURE_CYCLES_PILLAR_PRIOR,
      message
    });
  };
};


function* validationCycle(action: IRequestCycleValidationAction) {
  try {
    const data = yield call(apiCallValidationCycle, action.idCiclo);
    yield put({
        type: TypesValidation.SUCCESS_CYCLE_VALIDATION,
        payload: {
          data,
          flagStartValidation: true
        }
    } as ISuccessValidationCycleAction)

    yield put({
      type: Types.SUCCESS_VALIDATION_CYCLE,
      status: data.status
    } as ISuccessCycleValidationAction);

  } catch (error) {
    const message = error !== undefined ? error.data.message : 'Não foi possível começar a validar o ciclo.';
    yield put({ type: Types.FAILURE_VALIDATION_CYCLE, message });
  };
};

//#endregion

export default function* companiesRoot() {
  yield all([
    yield takeLatest(Types.REQUEST_CYCLE_LOAD, load),
    yield takeLatest(Types.REQUEST_CYCLE_PILLARS_LOAD, loadPillars),
    yield takeLatest(Types.REQUEST_CYCLES_ADD_PILLAR, addPillar),
    yield takeLatest(Types.REQUEST_CYCLES_ADD_OBJECTIVE, addObjective),
    yield takeLatest(Types.REQUEST_CYCLES_DELETE_OBJECTIVE, deleteObjective),
    yield takeLatest(Types.REQUEST_CYCLES_DELETE_PILLAR, deletePillar),
    yield takeLatest(Types.REQUEST_CYCLES_PILLAR_NEXT, nextPilar),
    yield takeLatest(Types.REQUEST_CYCLES_PILLAR_PRIOR, priorPilar),
    yield takeLatest(Types.REQUEST_VALIDATION_CYCLE, validationCycle)

  ]);
};
