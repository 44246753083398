import styled from 'styled-components';
import { FontIcon } from 'office-ui-fabric-react';
import colors from '~/assets/js/colors';

export const Ul = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`

export const Check = styled(FontIcon)`
    font-size: 16px;
    position: absolute;
    width: 18px;
    height: 18px;
    text-align: center;
    vertical-align: middle;
    opacity: 0;
    color: #605E5C;
    cursor: default;
    &:hover{
        opacity: 1;
    }
`

export const IconCheck = styled(FontIcon)`
    font-size: 18px;
    color:  #605E5C;
    opacity: 0;
    background-color:  ${colors.white};
    border-radius: 50%;
    cursor: default;
`

export const WrapperRow = styled.div`
    background-color: #FFFFFF;
    min-height: 42px;
    width: 100%;
    border-bottom: 1px solid #F3F2F1;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: default;
    &:hover{
        background-color: #EDEBE9;
        ${IconCheck}{
            opacity: 1;
        }
    }

`;

export const WrapperIconSelect = styled.div`
    width: 48px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-shrink: 0;
`
export const WrapperColumnFlexWidth = styled.div`
    padding: 0 15px;
    flex: 1;
    font-size: 14px;
`
export const WrapperColumnStatus = styled.div`
    padding: 0 15px;
    flex-basis: 40px;
    box-sizing: border-box;
`

export const WrapperColumn = styled.div<{width?: number, justify?: string}>`
    padding: 0 15px;
    flex-basis: 200px;
    ${({width}) => width ? `max-width: ${width}px` : ''};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    transition: flex-basis 0.2s;
    font-size: 14px;
    ${({justify}) => justify ? `justify-content: ${justify}` : ''};

    @media screen and (min-width: 992px) {
    flex-basis: 170px;
    }

    @media screen and (min-width: 1200px) {
    flex-basis: 190px;
    }

    @media screen and (min-width: 1400px) {
    flex-basis: 200px;
    }

    @media screen and (min-width: 1800px) {
    flex-basis: 300px;
    }

    @media screen and (min-width: 2000px) {
    flex-basis: 350px;
    }
`

export const WrapperColumnGap = styled.div<{ numColumn: number }>`
    transition: flex-basis 0.2s;

    @media screen and (min-width: 1200px) {
    flex-basis: 0px;
    }

    @media screen and (min-width: 1600px) {
    flex-basis: ${props => props.numColumn > 2 ? 0 : 150}px;
    }

    @media screen and (min-width: 1800px) {
    flex-basis: ${props => props.numColumn > 2 ? 0 : 200}px;
    }

    /* @media screen and (min-width: 2000px) {
    flex-basis: ${props => props.numColumn > 2 ? 0 : 245}px;
    }

    @media screen and (min-width: 2200px) {
    flex-basis: ${props => props.numColumn > 2 ? 0 : 400}px;
    }

    @media screen and (min-width: 2400px) {
    flex-basis: ${props => props.numColumn > 2 ? 0 : 500}px;
    } */
`

export const WrapperContent = styled.div<{ level: number }>`
    display: flex;
    margin-left: ${props => props.level * 20}px;
    width: 100%;
`

export const WrapperHeader = styled.div`
    margin-top: 16px;
    display: flex;
    height: 42px;
    border-bottom: 1px solid #EDEBE9;
    box-sizing: border-box;
    align-items: center;
`
