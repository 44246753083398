import { takeLatest, put, call, all } from "redux-saga/effects";
import {
    Types,
    ChartType,
    IRequestChartAction,
    IRequestChartLoadByIdAction,
    IRequestChartAddAction,
    IRequestChartEditAction,
    IRequestChartDelAction
} from "../ducks/chart/types";
import api from "~/services/api";

const apiCallGet = (payload: IRequestChartAction["payload"]) => {
    const { search, treeview, filterAtivo } = payload;
    return api.get(`/areas?SearchText=${search}${treeview ? '&TreeView=true' : ''}${filterAtivo ? `&FilterAtivo=${filterAtivo}` : ''}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};


const apiCallGetById = (id: number) => {
    return api.get(`/areas/${id}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};


const apiCallPost = (item: ChartType) => {
    return api.post('/areas', {
        ...item,
        flagAtiva: true
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallPut = (item: ChartType) => {
    return api.put(`/areas/${item.idArea}`, {
        ...item
    })
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

const apiCallDel = (id: number) => {
    return api.delete(`/areas/${id}`)
        .then(response => response.data)
        .catch(err => {
            throw err.response;
        });
};

function* load(action: IRequestChartAction) {
    try {
        const items: ChartType = yield call(apiCallGet, action.payload);
        yield put({ type: Types.SUCCESS_LOAD_CHART, payload: items });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar o organograma'
        yield put({ type: Types.FAILURE_LOAD_CHART, message });
    }
};


function* loadUnitsById(action: IRequestChartLoadByIdAction) {
    try {
        const item: ChartType = yield call(apiCallGetById, action.id);
        item.nivel = item.nivel.toUpperCase();
        item.tipoLocal = item.tipoLocal.toUpperCase();
        yield put({ type: Types.SUCCESS_CHART_LOAD_ID, chart: item });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível carregar o local';
        yield put({ type: Types.FAILURE_CHART_LOAD_ID, message });
    };
};

function* add(action: IRequestChartAddAction) {
    try {
        const idArea = yield call(apiCallPost, action.chart);
        yield put({ type: Types.SUCCESS_ADD_CHART, chart: { ...action.chart, ...idArea, children: [] } });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível adicionar local';
        yield put({ type: Types.FAILURE_ADD_CHART, message });
    }
};

function* edit(action: IRequestChartEditAction) {
    try {
        yield call(apiCallPut, action.chart);
        yield put({ type: Types.SUCCESS_EDIT_CHART, chart: action.chart });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível editar local';
        yield put({ type: Types.FAILURE_EDIT_CHART, message });
    }
};

function* del(action: IRequestChartDelAction) {
    try {
        yield call(apiCallDel, action.id);
        yield put({ type: Types.SUCCESS_DEL_CHART, id: action.id });
    } catch (error) {
        const message = error !== undefined ? error.data.message : 'Não foi possível excluír o local';
        yield put({ type: Types.FAILURE_DEL_CHART, message });
    }
};

export default function* companiesRoot() {
    yield all([
        yield takeLatest(Types.REQUEST_LOAD_CHART, load),
        yield takeLatest(Types.REQUEST_CHART_LOAD_ID, loadUnitsById),
        yield takeLatest(Types.REQUEST_ADD_CHART, add),
        yield takeLatest(Types.REQUEST_EDIT_CHART, edit),
        yield takeLatest(Types.REQUEST_DEL_CHART, del)
    ])
};