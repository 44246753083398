/**
 * Types
 */
export enum Types {
    //GET
    REQUEST_TASKS_LOAD = "tasks/REQUEST_TASKS_LOAD",
    SUCCESS_TASKS_LOAD = "tasks/SUCCESS_TASKS_LOAD",
    FAILURE_TASKS_LOAD = "tasks/FAILURE_TASKS_LOAD",

    REQUEST_TASKS_LOAD_ID = "tasks/REQUEST_TASKS_LOAD_ID",
    SUCCESS_TASKS_LOAD_ID = "tasks/SUCCESS_TASKS_LOAD_ID",
    FAILURE_TASKS_LOAD_ID = "tasks/FAILURE_TASKS_LOAD_ID"
};

/**
 * Data types
 */
export interface TaskType {
    idTarefa: number | null;
    tituloTarefa: string;
    flagFinalizada?: boolean;
    dataExpiracao: Date | null;
    idFuncionarioOwner?: number;
    tipoTarefa: string;
    dataHoraFinalizada?: Date | null;
    statusTarefa: string;
    TarefaMeta?: TaskMetaType;
};

export interface TaskMetaType {
    idTarefaMeta: number,
    idTarefa: number,
    idFuncionario: number,
    idVaga: number,
    idFuncionarioLider: number,
    idCiclo: number,
}

interface ITasksState {
    readonly data: TaskType[];
    readonly selected: TaskType | null;
    readonly loadingData: boolean;
    readonly loadingAction: boolean;
    readonly error: boolean;
    readonly success: boolean;
};

export type DataTypes = ITasksState;


/**
 * Actions
 */

//GET
export interface IRequestTasksLoadAction {
    type: typeof Types.REQUEST_TASKS_LOAD;
    payload: {
        searchText: string,
        filterStatus?: string[]
    };
};

export interface ISuccessTasksLoadAction {
    type: typeof Types.SUCCESS_TASKS_LOAD,
    payload: TaskType[];
}

export interface IFailureTasksLoadAction {
    type: typeof Types.FAILURE_TASKS_LOAD,
    message: string;
}

type TaskLoadAction = IRequestTasksLoadAction | ISuccessTasksLoadAction | IFailureTasksLoadAction;

//GET BY ID
export interface IRequestTasksLoadByIdAction {
    type: typeof Types.REQUEST_TASKS_LOAD_ID;
    idTarefa: number;
};

export interface ISuccessTasksLoadByIdAction {
    type: typeof Types.SUCCESS_TASKS_LOAD_ID,
    payload: TaskType;
}

export interface IFailureTasksLoadByIdAction {
    type: typeof Types.FAILURE_TASKS_LOAD_ID,
    message: string;
}

type TaskLoadByIdAction = IRequestTasksLoadByIdAction | ISuccessTasksLoadByIdAction | IFailureTasksLoadByIdAction;


export type TaskActionTypes = TaskLoadAction | TaskLoadByIdAction;