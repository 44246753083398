import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { Formik } from "formik";

import { ActionButton } from 'office-ui-fabric-react';
import { Dialog, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { InputText } from '~/components/Forms';
import { GroupItems, GroupItemUmTerco, TitleField } from "~/components/FormGroup";

import { formattedMeta } from '~/pages/Cycle/Stategic';

import {
  Table,
  Tr,
  TdNumber,
  TdPillar,
  TdGoal,
  TdPeso,
  TdCorpGoal,
  TdPesoGoal,
  Th,
  ContainerCorpoGoal,
  ContainerPesoGoal,
  Gap,
  Tbody,
  LeftItemMeta,
  RightItemMeta,
  TdFlagAceite,
  BlankTd
} from './styles';

import { MetaType } from '~/store/ducks/cycle/painel/types';
import { ICicloPesosPerfil, ICicloPesoPerfil } from '~/store/ducks/profile/types';
import { Creators } from '~/store/ducks/profile';

const dialogContentProps = {
  title: 'Definir Meta',
  closeButtonAriaLabel: 'Close',
  subText: "",
};

const validationDefineGoalSchema = yup.object().shape({
  definirMeta: yup.bool(),
  minimo: yup.number().nullable().when("definirMeta", {
    is: true,
    then: yup.number().required("Campo obrigatório")
  }),
  target: yup.number().nullable().when("definirMeta", {
    is: true,
    then: yup.number().required("Campo obrigatório")
  }),
  habilitarReferencia: yup.bool(),
  minimoRef: yup.number().nullable().when("habilitarReferencia", {
    is: true,
    then: yup.number().required("Campo obrigatório")
  }),
  targetRef: yup.number().nullable().when("habilitarReferencia", {
    is: true,
    then: yup.number().required("Campo obrigatório")
  }),
});


const valuesMeta: MetaType = {
  idCicloMeta: null,
  objetivo: {
    descObjetivo: '',
    mecanicaCalculo: '',
    unidade: ''
  },
  minimo: '',
  target: '',
  maximo: '',
  minimoRef: '',
  maximoRef: '',
  targetRef: '',
  habilitarReferencia: false
}

interface ITableGoalsProps {
  cicloPesos: ICicloPesosPerfil[];
  disabled?: boolean;
  removeMetas?: boolean;
}


const TableGoals: React.FC<ITableGoalsProps> = (props) => {
  const [isDialogMetaOpen, setIsDialogMetaOpen] = useState<boolean>(false);
  const [defineMeta, setDefineMeta] = useState(true);
  const [initialValuesMeta, setInitialValuesMeta] = useState(valuesMeta);
  const dispatch = useDispatch();
  const cicloPesosData: ICicloPesosPerfil[] = props.cicloPesos;

  const calcMax = (min: string, target: string): string => {
    const minNum = !isNaN(parseFloat(min)) ? parseFloat(min) : 0;
    const targetNum = !isNaN(parseFloat(target)) ? parseFloat(target) : 0;
    const diff = Math.abs(minNum - targetNum);

    if (initialValuesMeta.objetivo.mecanicaCalculo === "-") {
      return (targetNum - diff).toFixed(2);
    } else {
      return (targetNum + diff).toFixed(2);
    }
  }

  const handleDefineGoal = (cicloPeso: ICicloPesoPerfil) => {
    const { objetivo } = cicloPeso;
    setInitialValuesMeta({
      ...initialValuesMeta,
      objetivo: {
        ...objetivo
      }
    })
    setIsDialogMetaOpen(true)
  };

  // const handleAcceptMeta = (e: any, idCicloMetaColaborador: number) => {
  //     const isMetaAccept = e.target.checked;
  //     dispatch(Creators.setFlagAceite(idCicloMetaColaborador, isMetaAccept))
  // };

  const renderMetas = () => {
    return cicloPesosData.map((item, i) => {
      const { contMeta, cicloPeso, pilar, meta, cicloPesoPai } = item;
      const { corPilar, nomePilar } = pilar;
      const { objetivo, peso } = cicloPeso;
      const { objetivo: objPai } = cicloPesoPai ?? {};

      // const { maximo, minimo, target } = meta[0];
      return (
        <Tr pillarColor={corPilar} key={i}>
          <TdNumber>{contMeta}</TdNumber>
          <Gap />
          {
            objetivo.unidade !== 'A' ?
              <>
                <TdPillar>{nomePilar}</TdPillar>
                <TdGoal>
                  <Stack>
                    <Text variant="smallPlus" styles={{ root: { color: "#989898", marginBottom: 5 } }}>{objPai ? objPai.descObjetivo : ''}</Text>
                    <Text>{objetivo.descObjetivo}</Text>
                  </Stack>
                </TdGoal>
                <Gap />

                {
                  meta.length > 0 ?
                    <TdCorpGoal>
                      <ContainerCorpoGoal>
                        <LeftItemMeta>Min</LeftItemMeta>
                        <RightItemMeta>{formattedMeta(String(meta[0].minimo), objetivo.unidade)}</RightItemMeta>
                      </ContainerCorpoGoal>
                      <ContainerCorpoGoal>
                        <LeftItemMeta>Target</LeftItemMeta>
                        <RightItemMeta>{formattedMeta(String(meta[0].target), objetivo.unidade)}</RightItemMeta>
                      </ContainerCorpoGoal>
                      <ContainerCorpoGoal>
                        <LeftItemMeta>Max</LeftItemMeta>
                        <RightItemMeta>{formattedMeta(String(meta[0].maximo), objetivo.unidade)}</RightItemMeta>
                      </ContainerCorpoGoal>
                    </TdCorpGoal>
                    :
                    <BlankTd />
                }
              </>
              :
              <>
                <TdPillar colSpan={2}>{objetivo.descObjetivo}</TdPillar>
                <Gap colSpan={2} />
              </>
          }


          <Gap />
          <TdPeso>{Number(peso)}%</TdPeso>
          {/* <TdPesoGoal hasGoals>
                        <ContainerPesoGoal>
                            <LeftItemMeta>Min</LeftItemMeta>
                            <RightItemMeta>5%</RightItemMeta>
                        </ContainerPesoGoal>
                        <ContainerPesoGoal>
                            <LeftItemMeta>Target</LeftItemMeta>
                            <RightItemMeta>5%</RightItemMeta>
                        </ContainerPesoGoal>
                        <ContainerPesoGoal>
                            <LeftItemMeta>Max</LeftItemMeta>
                            <RightItemMeta>5%</RightItemMeta>
                        </ContainerPesoGoal>
                    </TdPesoGoal> */}
          {/* <TdPesoGoal>
                        <ActionButton iconProps={{ iconName: 'Edit' }} onClick={() => handleDefineGoal(item.ciclopeso)} allowDisabledFocus>
                            Definir Meta
                        </ActionButton>
                    </TdPesoGoal> */}
        </Tr>
      )
    })
  }

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th colSpan={5}></th>
            <Th>META</Th>
            <Gap />
            <Th>PESO</Th>
            <Gap />
            {/* <Th>META PESSOAL</Th> */}
          </tr>
        </thead>
        <Tbody>
          {
            renderMetas()
          }
          {/* <Tr pillarColor="#FFD531">
                        <TdNumber>01</TdNumber>
                        <Gap />
                        <TdPillar>Time Integrado de Alta Perfomance</TdPillar>
                        <TdGoal>Preços Agressivos nos Produtos Promocionados</TdGoal>
                        <Gap />
                        <TdPeso>20%</TdPeso>
                        <Gap />
                        <TdCorpGoal>
                            <ContainerCorpoGoal>
                                <LeftItemMeta>Min</LeftItemMeta>
                                <RightItemMeta>5%</RightItemMeta>
                            </ContainerCorpoGoal>
                            <ContainerCorpoGoal>
                                <LeftItemMeta>Target</LeftItemMeta>
                                <RightItemMeta>5%</RightItemMeta>
                            </ContainerCorpoGoal>
                            <ContainerCorpoGoal>
                                <LeftItemMeta>Max</LeftItemMeta>
                                <RightItemMeta>5%</RightItemMeta>
                            </ContainerCorpoGoal>
                        </TdCorpGoal>
                        <Gap />
                        <TdPesoGoal>
                            <ActionButton iconProps={{ iconName: 'Edit' }} onClick={() => setIsDialogMetaOpen(true)} allowDisabledFocus>
                                Definir Meta
                        </ActionButton>
                        </TdPesoGoal>
                    </Tr>
                    <Tr pillarColor="#000000">
                        <TdNumber>01</TdNumber>
                        <Gap />
                        <TdPillar>PROJETOS CHAVES</TdPillar>
                        <TdGoal>Melhorar Produtividade & Gestão de Capital de Giro</TdGoal>
                        <Gap />
                        <TdPeso>20%</TdPeso>
                    </Tr> */}
        </Tbody>
      </Table>

      {/* <Dialog
                hidden={!isDialogMetaOpen}
                dialogContentProps={dialogContentProps}
                minWidth={640}
            >
                {
                    cycleTatic.loadingMeta ?
                        <Stack styles={{ root: { height: 347 } }} verticalAlign="center">
                            <Spinner size={SpinnerSize.large} />
                        </Stack>
                        :
                    <Formik
                        initialValues={{ ...initialValuesMeta, definirMeta: defineMeta, justificativa: '' }}
                        validationSchema={validationDefineGoalSchema}
                        validateOnChange={false}
                        validate={values => {
                            const { minimo, target, definirMeta, habilitarReferencia, targetRef, minimoRef } = values;
                            const errors: any = {};
                            if (definirMeta && target !== "") {
                                if (initialValuesMeta.objetivo.mecanicaCalculo === "-" && minimo <= target) {
                                    errors.target = "O target não pode ser maior ou igual que o valor mínimo";
                                } else if (initialValuesMeta.objetivo.mecanicaCalculo === "+" && minimo >= target) {
                                    errors.target = "O target não pode ser menor ou igual que o valor mínimo";
                                }

                                if (habilitarReferencia && targetRef !== "") {
                                    if (initialValuesMeta.objetivo.mecanicaCalculo === "-" && minimoRef <= targetRef) {
                                        errors.targetRef = "A referência do target não pode ser maior ou igual que a referência do mínimo";
                                    } else if (initialValuesMeta.objetivo.mecanicaCalculo === "+" && minimoRef >= targetRef) {
                                        errors.targetRef = "A referência do target não pode ser menor ou igual que a referência do mínimo";
                                    }
                                }
                            }
                            return errors;
                        }}
                        enableReinitialize
                        validateOnBlur={true}
                        onSubmit={(values: any) => {
                            console.log(values)
                            // let valuesRef: Partial<MetaType> = {};
                            // if (!values.habilitarReferencia) {
                            //     valuesRef = {
                            //         minimoRef: "0",
                            //         targetRef: "0",
                            //         maximoRef: "0"
                            //     }
                            // }

                            // if (!values.idCicloMeta) {
                            //     dispatch(Creators.addMeta(idCycle, idObjetivoSelected, pillarSelected, { ...values, ...valuesRef }))
                            // } else {
                            //     if (values.definirMeta) {
                            //         dispatch(Creators.editMeta(idCycle, idObjetivoSelected, pillarSelected, values.idCicloMeta, { ...values, ...valuesRef }))
                            //     } else {
                            //         dispatch(Creators.delMeta(idCycle, values.idCicloMeta, pillarSelected, idObjetivoSelected));
                            //     };
                            // };
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors, setFieldValue, setFieldError }) => {
                            const isUnitPorcent: boolean = values.objetivo.unidade === 'P' || values.objetivo.unidade === 'PL';
                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0 }}>
                                                <Stack styles={{ root: { marginBottom: 24 } }}>
                                                    <TitleField text="Objetivo" />
                                                    <Text variant="medium" styles={{ root: { fontWeight: 500, color: "#FF6B0B" } }}>{values.objetivo.descObjetivo}</Text>
                                                </Stack>
                                            </div>
                                            <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0 }}>
                                                <GroupItems title="Meta da Organização">
                                                    <GroupItemUmTerco>
                                                        <TitleField text="Mínimo" />
                                                        <Text variant="medium">5%</Text>
                                                    </GroupItemUmTerco>
                                                    <GroupItemUmTerco>
                                                        <TitleField text="Target" />
                                                        <Text variant="medium">10%</Text>
                                                    </GroupItemUmTerco>
                                                    <GroupItemUmTerco>
                                                        <TitleField text="Máximo" />
                                                        <Text variant="medium">15%</Text>
                                                    </GroupItemUmTerco>
                                                </GroupItems>
                                            </div>

                                            <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0 }}>
                                                <FormControlLabel style={{ padding: '5px 0 5px' }}
                                                    control={
                                                        <Switch size="small"
                                                            type="checkbox"
                                                            checked={defineMeta}
                                                            onChange={() => setDefineMeta(!defineMeta)}
                                                            name="definirMeta"
                                                            id="definirMeta"
                                                            color="primary"
                                                        />}
                                                    label="Definir meta individual"
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4" style={{ paddingLeft: 0 }}>
                                                <InputText
                                                    value={values.minimo ?? ''}
                                                    onChange={e => {
                                                        setFieldValue('maximo', calcMax(e.target.value, values.target));
                                                        handleChange(e);
                                                        setFieldError("minimo", "");
                                                    }}
                                                    id="minimo"
                                                    error={errors.minimo ? true : false}
                                                    name="minimo"
                                                    type="text"
                                                    label="Mínimo"
                                                    className="mt-2"
                                                    disabled={!defineMeta}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment
                                                            position="end"
                                                            style={{ paddingRight: 20 }}>
                                                            {isUnitPorcent ? '%' : ''}
                                                        </InputAdornment>,
                                                        autoComplete: 'off'
                                                    }}
                                                    helperText={errors.minimo}
                                                    autoFocus
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                                <InputText
                                                    value={values.target ?? ''}
                                                    onChange={e => {
                                                        setFieldValue('maximo', calcMax(values.minimo, e.target.value));
                                                        handleChange(e);
                                                        setFieldError("target", "");
                                                    }}
                                                    id="target"
                                                    error={errors.target ? true : false}
                                                    name="target"
                                                    type="number"
                                                    label="Target"
                                                    className="mt-2"
                                                    disabled={!defineMeta}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment
                                                            position="end"
                                                            style={{ paddingRight: 20 }}>
                                                            {isUnitPorcent ? '%' : ''}
                                                        </InputAdornment>,
                                                        autoComplete: 'off'
                                                    }}
                                                    helperText={errors.target}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                                <InputText
                                                    value={values.maximo}
                                                    onChange={handleChange}
                                                    id="maximo"
                                                    error={errors.maximo ? true : false}
                                                    name="maximo"
                                                    type="number"
                                                    label="Máximo"
                                                    className="mt-2"
                                                    disabled={true}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment
                                                            position="end"
                                                            style={{ paddingRight: 20 }}>
                                                            {isUnitPorcent ? '%' : ''}
                                                        </InputAdornment>,
                                                    }}
                                                    helperText={errors.maximo}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm12" style={{ paddingLeft: 0 }}>
                                                <InputText
                                                    value={values.justificativa}
                                                    onChange={handleChange}
                                                    id="justificativa"
                                                    error={errors.justificativa ? true : false}
                                                    name="justificativa"
                                                    type="text"
                                                    label="Justificativa"
                                                    className="mt-2"
                                                    helperText={errors.justificativa}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ paddingRight: 5 }}>
                                        <DialogFooter>
                                            <DefaultButton styles={{ root: { width: 110 } }} onClick={() => setIsDialogMetaOpen(false)} text="Cancelar" />
                                            <PrimaryButton styles={{ root: { width: 110 } }} type="submit" text="Salvar" />
                                        </DialogFooter>
                                    </div>
                                </form>
                            )
                        }}
                    </Formik>
                }
            </Dialog> */}
    </>
  );
}

export default TableGoals;
