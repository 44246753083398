//#region Imports
import React, { useState } from "react";
import * as yup from 'yup';
import ReCAPTCHA from "react-google-recaptcha";
import { Icon } from '@fluentui/react/lib/Icon';
import { Link, useParams } from "react-router-dom";
import { Link as LinkUI } from 'office-ui-fabric-react/lib/Link';
import { Formik } from "formik"
import colors from "~/assets/js/colors";
import InputMask from "react-input-mask";
import { InputText, InputDate } from '~/components/Forms'
import { isValidCPF, } from "@brazilian-utils/brazilian-utils"

import { PrimaryButton, DefaultButton, Stack } from "office-ui-fabric-react";
import { Text } from 'office-ui-fabric-react/lib/Text';

import {
    Container,
    Content,
    Card,
    CardContent,
    RomasoftLogo,
    ImageContainer,
    ButtonContainer,
    WrapperReCAPTCHA,
    ErrorMessage,
    FeedbackMessage,
    WrapperResult,
    WrapperText
} from "./styles";

//#endregion

const validationAccountSchema = yup.object().shape({
    cpf: yup.string().length(14, "CPF inválido").test('Validar CPF', 'CPF inválido', value => isValidCPF(value || '')).required("CPF obrigatório"),
    dNasc: yup.date()
        .min(new Date("01/01/1900"), "Data de nascimento inválida")
        .max(new Date(), "Data de nascimento inválida")
        .typeError('Data de nascimento inválida')
        .required("Data de nascimento obrigatório"),
    recaptcha: yup.string().required("reCAPTCHA inválido, tente novamente.").nullable()
})

const validationPasswordSchema = yup.object().shape({
    recaptcha: yup.string().required("reCAPTCHA inválido, tente novamente.")
})


function RetrieveAccount() {
    const [credentials, setCredentials] = useState<boolean>(false)
    const [retrieveAccount, setRetrieveAccount] = useState<boolean>(false)
    let { type }:any = useParams()


    const account = () => (
        <CardContent>
            {!credentials && (
                <>
                    <Text variant="xxLarge" styles={{ root: { margin: '20px 0', fontSize: 24 } }}>Recuperar Conta</Text>
                    <Text variant="smallPlus" styles={{ root: { color: '#666666', marginBottom: 20 } }}>Para recuperar sua conta, informe seus dados cadastrais e os caracteres na imagem abaixo.</Text>
                    <Formik
                        initialValues={{ cpf: "", dNasc: null, recaptcha: "" }}
                        validationSchema={validationAccountSchema}
                        validateOnChange={false}
                        validateOnBlur={true}
                        onSubmit={values => {
                            // TODO - request API 
                            setCredentials(true)
                            setRetrieveAccount(true)
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors, setFieldValue, setFieldError }) => (
                            <form onSubmit={handleSubmit}>
                                <InputMask
                                    mask='999.999.999-99'
                                    maskChar={null}
                                    onChange={(e) => { handleChange(e); setFieldError("cpf", ""); }}
                                    value={values.cpf}
                                >
                                    {() =>
                                        <InputText
                                            id="cpf"
                                            error={errors.cpf ? true : false}
                                            name="cpf"
                                            type="text"
                                            label="CPF"
                                            className="mt-2"
                                            helperText={errors.cpf} />
                                    }
                                </InputMask>
                                <InputDate
                                    id="dNasc"
                                    error={errors.dNasc ? true : false}
                                    name="dNasc"
                                    label="Data de Nascimento"
                                    value={values.dNasc}
                                    onChange={date => setFieldValue("dNasc", date)}
                                    className="mt-2"
                                    helperText={errors.dNasc}
                                />
                                <WrapperReCAPTCHA>
                                    <ReCAPTCHA
                                        sitekey="6LcdDQAVAAAAAAvfj18AS0gTOwwEMw_oxb5rK9bn"
                                        onChange={(response) => { setFieldValue("recaptcha", response); setFieldError("recaptcha", ""); }}
                                    />
                                    {errors.recaptcha !== "" && (
                                        <Text variant="smallPlus" styles={{ root: { color: colors.error, fontWeight: 400 } }}>{errors.recaptcha}</Text >
                                    )}
                                </WrapperReCAPTCHA>
                                <ButtonContainer>
                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <Link to="/">
                                            <DefaultButton text="Voltar" styles={{ root: { width: 100 } }} />
                                        </Link>
                                        <PrimaryButton text="Próximo" type="submit" styles={{ root: { width: 100 } }} />
                                    </Stack>
                                </ButtonContainer>
                            </form>
                        )}
                    </Formik>
                </>
            )}

            {credentials && result()}
        </CardContent>
    )

    const password = () => (
        <CardContent>
            {!credentials && (
                <>
                    <Text variant="xxLarge" styles={{ root: { margin: '20px 0', fontSize: 24 } }}>Recuperar Senha</Text>
                    <Text variant={"smallPlus"} styles={{ root: { color: '#666666' } }}>Para recuperar sua senha, informe os caracteres na imagem abaixo.</Text>
                    <Formik
                        initialValues={{ recaptcha: "" }}
                        validationSchema={validationPasswordSchema}
                        onSubmit={values => {
                            // TODO - request API 
                            setCredentials(true)
                            setRetrieveAccount(false)
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors, setFieldValue, setFieldError }) => (
                            <form onSubmit={handleSubmit}>
                                <WrapperReCAPTCHA>
                                    <ReCAPTCHA
                                        sitekey="6LcdDQAVAAAAAAvfj18AS0gTOwwEMw_oxb5rK9bn"
                                        onChange={(response) => { setFieldValue("recaptcha", response); setFieldError("recaptcha", ""); }}
                                    />
                                    {errors.recaptcha && (
                                        <ErrorMessage>{errors.recaptcha}</ErrorMessage>
                                    )}
                                </WrapperReCAPTCHA>
                                <ButtonContainer>
                                    <Stack horizontal tokens={{ childrenGap: 10 }}>
                                        <Link to="/">
                                            <DefaultButton text="Voltar" styles={{ root: { width: 100 } }} />
                                        </Link>
                                        <PrimaryButton text="Próximo" type="submit" styles={{ root: { width: 100 } }} />
                                    </Stack>
                                </ButtonContainer>
                            </form>
                        )}
                    </Formik>
                </>
            )}

            {credentials && result()}
        </CardContent>
    )


    const result = () => {
        return (
            <WrapperResult>
                <Text variant="xxLarge" styles={{ root: { margin: '20px 0', display: 'block', fontSize: 24 } }}>Recuperar {`${type === "conta" ? "Conta" : "Senha"}`}</Text>
                {retrieveAccount ? (
                    <>
                        <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { display: "flex", alignItems: "center" } }}>
                            <img src="/static/icons/check.svg" alt="" />
                            <FeedbackMessage color={`${colors.greenDarker}`}>E-mail enviado com sucesso !</FeedbackMessage>
                        </Stack>
                        <WrapperText>
                            <Text variant="mediumPlus" styles={{ root: { marginTop: 20 } }}>
                                Foi enviado um e-mail com as instruções de recuperação da
                                sua conta para rsi*******************@rom****
                                <br /><br />
                                    Verifique sua caixa de entrada e lixo eletrônico.
                                <br /><br />
                                    Caso não tenha recebido as instruções, por favor entre em
                                    contato com nossa <Link to="/" style={{ textDecoration: 'none' }}><LinkUI>central de relacionamento</LinkUI></Link>.
                                </Text>
                        </WrapperText>
                    </>
                )
                    :
                    (
                        <>
                            <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { display: "flex", alignItems: "center" } }}>
                                <Icon iconName="ErrorBadge" className="ms-IconExample" styles={{ root: { color: "red", fontSize: 38 } }} />
                                <FeedbackMessage color={`${colors.red}`}>{`${type === "conta" ? "Não foi possível identificar suas credenciais" : "Não foi possível recuperar sua senha"}`}</FeedbackMessage>
                            </Stack>
                            <WrapperText>
                                <Text variant="mediumPlus">
                                    Entre em contato com o administrador do sistema,
                                    ou com nossa <Link to="/" style={{ textDecoration: 'none' }}><LinkUI>central de relacionamento</LinkUI></Link>.
                                    </Text>
                            </WrapperText>
                        </>
                    )
                }
                <ButtonContainer>
                    <Link to="/">
                        <DefaultButton text="OK" styles={{ root: { width: 100 } }} />
                    </Link>
                </ButtonContainer>
            </WrapperResult>
        )
    }

    return (
        <Container>
            <Content>
                <Card>
                    <ImageContainer>
                        <RomasoftLogo src="/static/img/st-marche.png" />
                    </ImageContainer>
                    <WrapperResult>
                        {type === "conta" && account()}
                        {type === "senha" && password()}
                    </WrapperResult>
                </Card>
            </Content>
        </Container>
    );
}

export default RetrieveAccount;
