import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Creators } from '~/store/ducks/home'
import { Page, BreadcrumbItems } from '~/store/ducks/home/types'

import { Text, ITextStyles } from 'office-ui-fabric-react/lib/Text';
import { Pivot, PivotItem, IPivotStyles } from 'office-ui-fabric-react/lib/Pivot';
import { IconButton, IIconProps } from 'office-ui-fabric-react';

import { Container, ContainerImg, ContainerInfo, ImgPefil, WrapperInfo, WrapperPivotItem, AbbreviationPerfil, Abbreviation, WrapperPivot } from './styles';
import colors from "~/assets/js/colors"

import Perfomance from "./Perfomance"
import Goals from "./Goals"

import { LoginType } from "~/store/ducks/login/types"
import { DataTypes } from "~/store/ducks/profile/types"
import { Creators as CreatorsProfile } from "~/store/ducks/profile"
import { RootState } from "~/store/ducks"

const itemsBreadCrumb: BreadcrumbItems[] = [
  { text: "Home", isCurrentItem: false, icon: "HomeSolid", onlyIcon: true, url: "/" },
  { text: 'Perfil', isCurrentItem: true },
];

const nameStyle: Partial<ITextStyles> = {
  root: {
    color: colors.white,
    textTransform: 'upperCase',
    fontWeight: 'bold',
    selectors: {
      '@media screen and (max-width: 992px)': {
        fontSize: 20
      }
    }
  }
}


const Profile: React.FC = () => {
  const dispatch = useDispatch();
  const page: Page = {
    key: 'perfil',
    pages: itemsBreadCrumb
  };

  const [collapse, setCollapse] = useState(false);

  const loginData = useSelector<RootState, LoginType>(state => state.loginReducer.data);
  const { currentTab, dataProfile } = useSelector<RootState, DataTypes>(state => state.profileReducer);

  const [currentPivot, setCurrentPivot] = useState<string>(currentTab);

  useEffect(() => {
    dispatch(Creators.setCurrentPage(page))
    dispatch(CreatorsProfile.getProfile())
  }, [])

  const handlePivot = (idPivot: string) => {
    setCurrentPivot(idPivot);
  }

  const AbbreviationBg = () => (
    <AbbreviationPerfil>
      <Abbreviation>
        {loginData.abbreviation}
      </Abbreviation>
    </AbbreviationPerfil>
  )

  const pivotStyle: Partial<IPivotStyles> = {
    root: {
      borderBottom: '1px solid #CFD3DA',
      padding: '5px 20px 0px 232px',
      width: '100%',
      overflow: 'hidden',
      selectors: {
        '@media screen and (max-width: 1410px) and (min-width: 1180px)': {
          paddingLeft: 150,
        },
        '@media screen and (max-width: 1180px) and (min-width: 992px)': {
          paddingLeft: 150,
        },
        '@media screen and (max-width: 992px)': {
          paddingLeft: 20,
        },
        '&:hover': {
          selectors: {
            '@media screen and (max-width: 850px)': {
              overflowX: 'auto',
              overflowY: 'hidden'
            }
          }
        }
      }
    },
    itemContainer: {
      height: `calc(100vh - ${collapse ? 220 : 340}px)`,
      overflow: 'auto',
      selectors: {
        '@media screen and (max-width: 992px)': {
          height: 'calc(100vh - 226px)',
        },
      }
    },
    linkContent: {
      width: 130,
      selectors: {
        '@media screen and (max-width: 1410px) and (min-width: 1180px)': {
          width: 100,
        },
        '@media screen and (max-width: 1180px) and (min-width: 992px)': {
          width: 80,
        },
        '@media screen and (max-width: 992px)': {
          width: 'auto',
        },
      }
    }
  }

  const stylesPivotItem = {
    height: `calc(100vh - ${collapse ? 220 : 340}px)`,
    overflow: 'auto'
  }

  return (
    <Container>
      <ContainerImg collapse={collapse}>
        <ContainerInfo>
          {loginData.imagem !== null ?
            <ImgPefil src={loginData.imagem} /> :
            AbbreviationBg()
          }
          <WrapperInfo>
            <Text variant="xxLarge" styles={nameStyle}>{loginData.name}</Text>
            <Text variant="medium" styles={{ root: { color: colors.white } }}>{dataProfile.Cargo.titulo}</Text>
            <Text variant="medium" styles={{ root: { color: colors.white, opacity: 0.6 } }}>{dataProfile.Area.nomeLocal}</Text>
          </WrapperInfo>
        </ContainerInfo>
        <IconButton
          iconProps={{ iconName: collapse ? "ChevronUpMed" : "ChevronDownMed" }}
          onClick={() => setCollapse(!collapse)}
          ariaLabel="Expandir/Recolher" styles={{
            root: {
              position: 'absolute',
              top: 0,
              right: 0,
              selectors: {
                '@media screen and (max-width: 992px)': {
                  display: 'none'
                }
              }
            }
          }} />
      </ContainerImg>
      <WrapperPivot>
        <Pivot aria-label="Definições do ciclo"
          selectedKey={String(currentPivot)}
          onLinkClick={(e) => handlePivot(e?.props.itemKey!)}
          styles={pivotStyle}>
          {/* <PivotItem
                        headerText="Perfil"
                        itemKey="0"
                        key="0">
                        <WrapperPivotItem>
                            <h1>1</h1>
                        </WrapperPivotItem>
                    </PivotItem>
                    <PivotItem
                        headerText="Dados Cadastrais"
                        itemKey="1"
                        key="1">
                        <WrapperPivotItem>
                            <h1>2</h1>
                        </WrapperPivotItem>
                    </PivotItem>
                    <PivotItem
                        headerText="Desempenho"
                        itemKey="2"
                        key="2"
                    >
                        <WrapperPivotItem>
                            <Perfomance />
                        </WrapperPivotItem>
                    </PivotItem> */}
          <PivotItem
            headerText="Metas"
            // style={stylesPivotItem}
            itemKey="3"
            key="3"
          >
            <WrapperPivotItem>
              <Goals />
            </WrapperPivotItem>
          </PivotItem>
          {/* <PivotItem
                        headerText="Solicitações"
                        itemKey="4"
                        key="4">
                        <WrapperPivotItem>
                            <h1>2</h1>
                        </WrapperPivotItem>
                    </PivotItem>
                    <PivotItem
                        headerText="Demonstrativos"
                        itemKey="5"
                        key="5">
                        <WrapperPivotItem>
                            <h1>2</h1>
                        </WrapperPivotItem>
                    </PivotItem> */}
        </Pivot>
      </WrapperPivot>
    </Container >
  );
}

export default Profile;
