/**
 * Types
 */
export enum Types {
  REQUEST_USER_EMAIL = "login/REQUEST_USER_EMAIL",
  SUCCESS_USER_EMAIL = "login/SUCCESS_USER_EMAIL",
  FAILURE_USER_EMAIL = "login/FAILURE_USER_EMAIL",
  REQUEST_USER_LOGIN = "login/REQUEST_USER_LOGIN",
  SUCCESS_USER_LOGIN = "login/SUCCESS_USER_LOGIN",
  FAILURE_USER_LOGIN = "login/FAILURE_USER_LOGIN",
  USER_SIGNOUT = "login/USER_SIGNOUT",
  CLEAR_ERROR_LOGIN = "login/CLEAR_ERROR_LOGIN",
  CLEAR_LOADING_LOGIN = "login/CLEAR_LOADING_LOGIN",
  // UPLOAD IMAGE
  REQUEST_EMPLOYEE_UPLOAD = "login/REQUEST_EMPLOYEE_UPLOAD",
  SUCCESS_EMPLOYEE_UPLOAD = "login/SUCCESS_EMPLOYEE_UPLOAD",
  FAILURE_EMPLOYEE_UPLOAD = "login/FAILURE_EMPLOYEE_UPLOAD",
  // DELETE IMAGE
  REQUEST_EMPLOYEE_DEL_UPLOAD = "login/REQUEST_EMPLOYEE_DEL_UPLOAD",
  SUCCESS_EMPLOYEE_DEL_UPLOAD = "login/SUCCESS_EMPLOYEE_DEL_UPLOAD",
  FAILURE_EMPLOYEE_DEL_UPLOAD = "login/FAILURE_EMPLOYEE_DEL_UPLOAD",
};

/**
 * Data types
 */

export type LoginType = {
  email: string;
  name: string;
  abbreviation: string;
  imagem: string | null;
};

interface ILoginState {
  data: LoginType;
  isLogged: boolean;
  loading: boolean;
  error: string;
  isUploading: boolean;
  successUpload: boolean;
};

type SignIn = {
  email: string;
  password: string;
};

export type DataTypes = ILoginState;

/**
 * Actions
 */

interface IRequestUserEmailAction {
  type: typeof Types.REQUEST_USER_EMAIL;
  email: string;
};

export interface ISuccessUserEmailAction {
  type: typeof Types.SUCCESS_USER_EMAIL;
  email: string;
};

export interface ISignInLoginAction {
  type: typeof Types.REQUEST_USER_LOGIN;
  payload: SignIn;
};

interface ISuccessSignInLoginAction {
  type: typeof Types.SUCCESS_USER_LOGIN;
  payload: LoginType;
}

interface IFailureSignInLoginAction {
  type: typeof Types.FAILURE_USER_LOGIN;
  message: string;
}

interface ISignOutLoginAction {
  type: typeof Types.USER_SIGNOUT;
};

interface IClearErrorLoginAction {
  type: typeof Types.CLEAR_ERROR_LOGIN;
};


//UPLOAD
export interface IRequestEmployeeUploadImageAction {
  type: typeof Types.REQUEST_EMPLOYEE_UPLOAD;
  payload: {
    imagem: string;
  }
};

export interface ISuccessEmployeeUploadImageAction {
  type: typeof Types.SUCCESS_EMPLOYEE_UPLOAD;
  payload: {
    imagem: string;
  }
};

export interface IFailureEmployeeUploadImageAction {
  type: typeof Types.FAILURE_EMPLOYEE_UPLOAD;
  message: string;
};

type EmployeeUploadImageAction = IRequestEmployeeUploadImageAction | ISuccessEmployeeUploadImageAction | IFailureEmployeeUploadImageAction;

//DELETE UPLOAD
export interface IRequestEmployeeDelImageAction {
  type: typeof Types.REQUEST_EMPLOYEE_DEL_UPLOAD;
};

export interface ISuccessEmployeeDelImageAction {
  type: typeof Types.SUCCESS_EMPLOYEE_DEL_UPLOAD;
};

export interface IFailureEmployeeDelImageAction {
  type: typeof Types.FAILURE_EMPLOYEE_DEL_UPLOAD;
  message: string;
};

type EmployeeDelImageAction = IRequestEmployeeDelImageAction | ISuccessEmployeeDelImageAction | IFailureEmployeeDelImageAction;


export type LoginActionTypes =
  ISuccessUserEmailAction |
  ISignInLoginAction |
  ISuccessSignInLoginAction |
  IFailureSignInLoginAction |
  IRequestUserEmailAction |
  IClearErrorLoginAction |
  ISignOutLoginAction |
  EmployeeUploadImageAction |
  EmployeeDelImageAction;
