import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from "formik";
import * as yup from 'yup';

import { Dialog, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Stack } from 'office-ui-fabric-react/lib/Stack';

import { convertToNumber } from '~/utils/texts';
import { InputText, CurrencyInput } from '~/components/Forms/index';

import InputAdornment from '@material-ui/core/InputAdornment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { MetaType } from '~/store/ducks/cycle/painel/types';


const dialogContentProps = {
    title: 'Definir Meta',
    closeButtonAriaLabel: 'Close',
    subText: "",
};

interface IFormMetasProps {
    isOpen: boolean;
    setIsOpen: () => void;
    isLoading: boolean;
    isLoadingAction: boolean;
    valuesInitialMeta: MetaType;
    onAddMeta: (values: MetaType) => void;
    onEditMeta: (values: MetaType) => void;
    onDeleteMeta: () => void;
}

const validationDefineGoalSchema = yup.object().shape({
    definirMeta: yup.bool(),
    mecanicaCalculo: yup.string(),
    minimo: yup.number().nullable().when("definirMeta", {
        is: true,
        then: yup.number().required("Campo obrigatório")
    }),
    target: yup.number().nullable().when("definirMeta", {
        is: true,
        then: yup.number().required("Campo obrigatório")
    }),
    habilitarReferencia: yup.bool(),
    minimoRef: yup.number().nullable().when("habilitarReferencia", {
        is: true,
        then: yup.number().required("Campo obrigatório")
    }),
    targetRef: yup.number().nullable().when("habilitarReferencia", {
        is: true,
        then: yup.number().required("Campo obrigatório")
    }),
});

const FormMetas: React.FC<IFormMetasProps> = (props) => {
    const {
        isOpen,
        isLoading,
        isLoadingAction,
        valuesInitialMeta,
        setIsOpen,
        onAddMeta,
        onEditMeta,
        onDeleteMeta,
    } = props;
    // const [definirMeta, setDefineMeta] = useState(true);
    const dispatch = useDispatch();

    return (
        <Dialog
            hidden={!isOpen}
            dialogContentProps={dialogContentProps}
            minWidth={640}
        >
            {
                isLoading ?
                    <Stack styles={{ root: { height: 347 } }} verticalAlign="center">
                        <Spinner size={SpinnerSize.large} />
                    </Stack>
                    :
                    <Formik
                        initialValues={{ ...valuesInitialMeta, definirMeta: true }}
                        validationSchema={validationDefineGoalSchema}
                        validateOnChange={false}
                        enableReinitialize
                        validateOnBlur={true}
                        validate={values => {
                            const { minimo, target, definirMeta, habilitarReferencia, targetRef, minimoRef } = values;
                            const errors: any = {};

                            const minimoNum = convertToNumber(minimo);
                            const targetNum = convertToNumber(target);

                            if (definirMeta && target !== "") {
                                if (valuesInitialMeta.objetivo.mecanicaCalculo === "-" && minimoNum <= targetNum) {
                                    errors.target = "O target não pode ser maior ou igual que o valor mínimo";
                                } else if (valuesInitialMeta.objetivo.mecanicaCalculo === "+" && minimoNum >= targetNum) {
                                    errors.target = "O target não pode ser menor ou igual que o valor mínimo";
                                }

                                if (habilitarReferencia && targetRef !== "") {
                                    if (valuesInitialMeta.objetivo.mecanicaCalculo === "-" && minimoRef <= targetRef) {
                                        errors.targetRef = "A referência do target não pode ser maior ou igual que a referência do mínimo";
                                    } else if (valuesInitialMeta.objetivo.mecanicaCalculo === "+" && minimoRef >= targetRef) {
                                        errors.targetRef = "A referência do target não pode ser menor ou igual que a referência do mínimo";
                                    }
                                }

                            }
                            return errors;
                        }}
                        onSubmit={(values: any) => {
                            // const unidade = values.objetivo.unidade;
                            let valuesRef: Partial<MetaType> = {};

                            if (!values.habilitarReferencia) {
                                valuesRef = {
                                    minimoRef: "0",
                                    targetRef: "0",
                                    maximoRef: "0"
                                }
                            }

                            const max = convertToNumber(values.maximo);
                            const maxRef = convertToNumber(valuesRef.maximoRef ?? values.maximoRef);

                            if (!values.idCicloMeta) {
                                dispatch(onAddMeta({ ...values, ...valuesRef, maximo: max, maximoRef: maxRef }));
                            } else {
                                if (values.definirMeta) {
                                    dispatch(onEditMeta({ ...values, ...valuesRef, maximo: max, maximoRef: maxRef }));
                                } else {
                                    dispatch(onDeleteMeta());
                                };
                            };
                        }}
                    >
                        {({ handleSubmit, handleChange, values, errors, setFieldValue, setFieldError }) => {
                            const unidade: string = values.objetivo.unidade;
                            const isCurrency: boolean = unidade === 'M' || unidade === 'L';

                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="ms-Grid" dir="ltr">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12">
                                                <InputText
                                                    value={values.objetivo.descObjetivo}
                                                    onChange={handleChange}
                                                    id="objetivo"
                                                    error={errors.objetivo?.descObjetivo ? true : false}
                                                    name="objetivo"
                                                    type="text"
                                                    label="Objetivo"
                                                    disabled={true}
                                                    helperText={errors.objetivo?.descObjetivo}
                                                    className="mt-2"
                                                />
                                            </div>
                                            {
                                                (values.idCicloMeta || values.idCicloMeta !== 0) &&
                                                <div className="ms-Grid-col ms-sm12">
                                                    <FormControlLabel style={{ padding: '5px 0 15px' }}
                                                        control={
                                                            <Switch size="small"
                                                                type="checkbox"
                                                                checked={values.definirMeta}
                                                                onChange={handleChange}
                                                                name="definirMeta"
                                                                id="definirMeta"
                                                                color="primary"
                                                            />}
                                                        label="Definir Meta"
                                                    />
                                                </div>
                                            }

                                            {
                                                isCurrency ?
                                                    <>
                                                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                                            <CurrencyInput
                                                                value={values.minimo ?? ""}
                                                                onChange={(e: any) => {
                                                                    setFieldValue('maximo', calcMax(e.target.value, values.target, valuesInitialMeta));
                                                                    handleChange(e);
                                                                    setFieldError("minimo", "");
                                                                }}
                                                                id="minimo"
                                                                name="minimo"
                                                                label="Mínimo"
                                                                helperText={errors.minimo}
                                                                error={errors.minimo ? true : false}
                                                                autoFocus
                                                            />
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                                            <CurrencyInput
                                                                value={values.target ?? ""}
                                                                onChange={(e: any) => {
                                                                    setFieldValue('maximo', calcMax(values.minimo, e.target.value, valuesInitialMeta));
                                                                    handleChange(e);
                                                                    setFieldError("target", "");
                                                                }}
                                                                id="target"
                                                                name="target"
                                                                label="Target"
                                                                helperText={errors.target}
                                                                error={errors.target ? true : false}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                                            <InputText
                                                                value={values.minimo ?? ''}
                                                                onChange={e => {
                                                                    setFieldValue('maximo', calcMax(e.target.value, values.target, valuesInitialMeta));
                                                                    handleChange(e);
                                                                    setFieldError("minimo", "");
                                                                }}
                                                                id="minimo"
                                                                error={errors.minimo ? true : false}
                                                                name="minimo"
                                                                type="number"
                                                                label="Mínimo"
                                                                className="mt-2"
                                                                disabled={!values.definirMeta}
                                                                autoFocus
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment
                                                                        position="end"
                                                                        style={{ paddingRight: 20 }}>
                                                                        {unidade === 'P' ? '%' : ''}
                                                                    </InputAdornment>,
                                                                    autoComplete: "off"
                                                                }}
                                                                helperText={errors.minimo}
                                                            />
                                                        </div>
                                                        <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                                            <InputText
                                                                value={values.target ?? ''}
                                                                onChange={(e: any) => {
                                                                    setFieldValue('maximo', calcMax(values.minimo, e.target.value, valuesInitialMeta));
                                                                    handleChange(e);
                                                                    setFieldError("target", "");
                                                                }}
                                                                id="target"
                                                                error={errors.target ? true : false}
                                                                name="target"
                                                                type="number"
                                                                label="Target"
                                                                className="mt-2"
                                                                disabled={!values.definirMeta}
                                                                InputProps={{
                                                                    endAdornment: <InputAdornment
                                                                        position="end"
                                                                        style={{ paddingRight: 20 }}>
                                                                        {unidade === 'P' ? '%' : ''}
                                                                    </InputAdornment>,
                                                                    autoComplete: "off"
                                                                }}
                                                                helperText={errors.target}
                                                            />
                                                        </div>
                                                    </>
                                            }

                                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                                <InputText
                                                    value={values.maximo}
                                                    onChange={handleChange}
                                                    id="maximo"
                                                    error={errors.maximo ? true : false}
                                                    name="maximo"
                                                    type="text"
                                                    label="Máximo"
                                                    className="mt-2"
                                                    disabled={true}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment
                                                            position="end"
                                                            style={{ paddingRight: 20 }}>
                                                            {unidade === 'P' ? '%' : ''}
                                                        </InputAdornment>,
                                                    }}
                                                    helperText={errors.maximo}
                                                />
                                            </div>
                                        </div>
                                        <FormControlLabel style={{ padding: '5px 0 15px' }}
                                            control={
                                                <Switch size="small"
                                                    type="checkbox"
                                                    checked={values.habilitarReferencia}
                                                    onChange={handleChange}
                                                    name="habilitarReferencia"
                                                    id="habilitarReferencia"
                                                    color="primary"
                                                    disabled={!values.definirMeta}
                                                />}
                                            label="Habilitar Referência"
                                        />
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                                <InputText
                                                    value={values.minimoRef ?? ''}
                                                    onChange={e => {
                                                        setFieldValue('maximoRef', calcMax(e.target.value, values.targetRef, valuesInitialMeta));
                                                        handleChange(e);
                                                        setFieldError("minimoRef", "")
                                                    }}
                                                    id="minimoRef"
                                                    error={errors.minimoRef ? true : false}
                                                    name="minimoRef"
                                                    type="number"
                                                    label="Mínimo"
                                                    className="mt-2"
                                                    InputProps={{
                                                        autoComplete: 'off'
                                                    }}
                                                    disabled={(!values.definirMeta || !values.habilitarReferencia)}
                                                    helperText={errors.minimoRef}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                                <InputText
                                                    value={values.targetRef ?? ''}
                                                    onChange={e => {
                                                        setFieldValue('maximoRef', calcMax(values.minimoRef, e.target.value, valuesInitialMeta));
                                                        handleChange(e);
                                                        setFieldError("targetRef", "")
                                                    }}
                                                    id="targetRef"
                                                    error={errors.targetRef ? true : false}
                                                    name="targetRef"
                                                    type="number"
                                                    label="Target"
                                                    className="mt-2"
                                                    InputProps={{
                                                        autoComplete: 'off'
                                                    }}
                                                    disabled={(!values.definirMeta || !values.habilitarReferencia)}
                                                    helperText={errors.targetRef}
                                                />
                                            </div>
                                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4">
                                                <InputText
                                                    value={values.maximoRef}
                                                    onChange={handleChange}
                                                    id="maximoRef"
                                                    error={errors.maximoRef ? true : false}
                                                    name="maximoRef"
                                                    type="text"
                                                    label="Máximo"
                                                    className="mt-2"
                                                    disabled={true}
                                                    helperText={errors.maximoRef}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ paddingRight: 5 }}>
                                        <DialogFooter styles={{ actionsRight: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' } }}>
                                            <DefaultButton styles={{ root: { width: 110 } }} onClick={setIsOpen} text="Cancelar" />
                                            {
                                                isLoadingAction ?
                                                    <Spinner size={SpinnerSize.small} styles={{ root: { width: 110 } }} />
                                                    :
                                                    <PrimaryButton styles={{ root: { width: 110 } }} type="submit" text="Salvar" />
                                            }
                                        </DialogFooter>
                                    </div>
                                </form>
                            )
                        }}
                    </Formik>
            }
        </Dialog>
    );
}

const calcMax = (min: string, target: string, meta: MetaType): string => {
    const minNum = !isNaN(parseFloat(min)) ? parseFloat(min) : 0;
    const targetNum = !isNaN(parseFloat(target)) ? parseFloat(target) : 0;
    const diff = Math.abs(minNum - targetNum);

    const unit = meta.objetivo.unidade;
    const isCurrency = unit === 'M' || unit === 'L';

    let valueSum = targetNum + diff;
    let valueSubt = targetNum - diff;


    if (isCurrency || unit === 'P') {

        if (unit === 'P') {
            valueSum = valueSum / 100;
            valueSubt = valueSubt / 100;
        }

        const option = {
            style: isCurrency ? 'currency' : 'percent',
            currency: 'BRL',
            minimumFractionDigits: isCurrency ? 2 : 0,
            maximumFractionDigits: 2
        };

        const formatter = new Intl.NumberFormat("pt-BR", option);
        if (meta.objetivo.mecanicaCalculo === "-") {
            return formatter.format(valueSubt).replace("%", "").replace("R$", "");
        } else {
            return formatter.format(valueSum).replace("%", "").replace("R$", "");
        }
    } else {
        if (meta.objetivo.mecanicaCalculo === "-") {
            return valueSubt.toString();
        } else {
            return valueSum.toString();
        }
    }
}

export default FormMetas;