import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PrimaryButton, DefaultButton, Spinner, SpinnerSize, Stack, IButtonStyles } from 'office-ui-fabric-react';
import { Text, ITextStyles } from 'office-ui-fabric-react/lib/Text';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';

import MuiAccordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';

import TableGoals from "./TableGoals";
import KeyProjects from "./KeyProjects";

import {
  ContainerHeader,
  ContainerDropdownInput,
  InputContainer,
  WrapperLabelGroup,
  WrapperBtnSolicita
} from './styles';

import { LabelGroup } from '~/components/label';
import NoItems from '~/components/layout/NoItems';
import Dropdown from "~/components/layout/Dropdown"
import MenuItem from '@material-ui/core/MenuItem';

import { Creators as CreatorsProfile } from "~/store/ducks/profile";
import { DataTypes as DataTypesProfileGoals, IProfileMetasType } from "~/store/ducks/profile/types";
import { RootState } from "~/store/ducks";
import { profile } from 'console';
// import { DataTypes } from "~/store/ducks/tasks/types";

const textStyles: ITextStyles = {
  root: {
    fontWeight: 500
  }
}

const dialogContentProps = {
  type: DialogType.normal,
  title: 'Solicitar aprovação',
  closeButtonAriaLabel: 'Fechar',
  subText: 'Ao solicitar aprovação você está ciente e de acordo com as metas estabelecidas?'
};

const Accordion = withStyles({
  root: {
    padding: '0px !important',
    paddingBottom: 10,
    boxShadow: 'none',

    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {
    borderBottom: 0,
  },
})(MuiAccordion);


const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid #CFD3DA",
    height: 50,
    minHeight: '50px !important',
    '&:hover': {
      backgroundColor: '#F3F2F1'
    },
    expanded: {
    },
  }
})(MuiAccordionSummary);


const Goals: React.FC = () => {
  // const TaskState = useSelector<RootState, DataTypes>(state => state.tasksReducer);
  const [isModalSolicitacaoOpen, setIsModalSolicitacaoOpen] = useState<boolean>(false);
  const profileState = useSelector<RootState, DataTypesProfileGoals>(state => state.profileReducer);
  const dispatch = useDispatch();

  const profileData: IProfileMetasType = profileState.data;


  const [season, setSeason] = useState('');
  const [position, setPosition] = useState('');

  // const canRequestApproval: boolean = profileState.hasMetas ?
  //     profileData.CiclosPesos.every(item => item.flagAceite === true)
  //     :
  //     false;

  useEffect(() => {
    dispatch(CreatorsProfile.getProfileMetas());

  }, []);

  useEffect(() => {
    if (profileData.temporadas.length > 0) {
      const temporada = profileData.temporadas[0];
      // const temporada = profileData.temporadas.find(temp => temp.idCiclo === profileData.Ciclo.idCiclo)
      setSeason(temporada?.idCiclo.toString() ?? '')
    }
  }, [profileData.temporadas])

  useEffect(() => {
    if (profileData.posicoes.length > 0) {
      const posicoes = profileData.posicoes.find(pos => pos.idVaga === profileData.posicao.idVaga)
      setPosition(String(posicoes?.idCicloColaborador) ?? '')
    }
  }, [profileData.posicoes])

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const idCicloSelected: number = event.target.value as number;
    setSeason(event.target.value as string);
    dispatch(CreatorsProfile.getProfileMetas(null, idCicloSelected));
  };

  const handleChangePosition = (event: React.ChangeEvent<{ value: unknown }>) => {
    const idCiclo = season === '' ? profileData.temporadas[0].idCiclo : Number(season);
    setPosition(event.target.value as string);
    console.log(idCiclo)
    console.log(Number(event.target.value!))
    dispatch(CreatorsProfile.getProfileMetas(null, idCiclo, Number(event.target.value!)))
  };

  const handleSolicitarApr = () => {
    setIsModalSolicitacaoOpen(true);
  }

  const handleConfirmSol = () => {
    // ? Verificar se não deve incluir o idCiclo em posicao!
    const idCiclo = season === '' ? profileData.temporadas[0].idCiclo : Number(season);
    const { idCicloColaborador } = profileData.posicao;

    dispatch(CreatorsProfile.reqAprovacao(idCiclo, idCicloColaborador!));
    // dispatch(CreatorsProfile.reqAprovacao(profileData.Ciclo.idCiclo!));
    setIsModalSolicitacaoOpen(false);
  }

  return (
    <>
      {
        profileState.loadingData ?
          <Spinner
            size={SpinnerSize.medium}
            title="Carregando as metas"
            label="Carregando as metas"
            styles={{
              root: {
                height: 400
              }
            }}
          />
          :
          !profileState.hasMetas ?
            <NoItems
              error={false}
              text="Não há metas cadastradas"
              icon="Bullseye"
              alt="Empresas"
            />
            :
            <>
              <ContainerHeader>
                <ContainerDropdownInput style={{ marginRight: 40 }}>
                  <Text variant="medium" styles={{ root: { marginRight: 20 } }}>Ciclo</Text>
                  <InputContainer>
                    <Dropdown
                      errors=""
                      variant="outlined"
                      label=""
                      name="season"
                      values={season}
                      handleChange={handleChange}
                      fieldHeight={32}
                      styles={{
                        boxSizing: 'border-box',
                        height: 32,
                        backgroundColor: 'transparent',
                      }}
                      autofocus
                      customPadding="6.5px 14px"
                    >
                      {
                        profileData.temporadas.map(temp =>
                          <MenuItem
                            key={temp.idCiclo}
                            value={temp.idCiclo}
                          // defaultChecked={5 === temp.idCiclo}
                          >
                            {temp.ano}
                          </MenuItem>
                        )
                        // profileData.temporadas.map(temp => <MenuItem key={temp.idCiclo} value={temp.ano} defaultChecked={profileData.Ciclo.idCiclo === temp.idCiclo}>{temp.ano}</MenuItem>)
                      }
                    </Dropdown>
                  </InputContainer>
                </ContainerDropdownInput>
                <ContainerDropdownInput>
                  <Text variant="medium" styles={{ root: { marginRight: 20 } }}>Posição</Text>
                  <InputContainer>
                    <Dropdown
                      errors=""
                      variant="outlined"
                      label=""
                      name="season"
                      values={position}
                      handleChange={handleChangePosition}
                      fieldHeight={32}
                      styles={{
                        boxSizing: 'border-box',
                        height: 32,
                        backgroundColor: 'transparent',
                        width: 380
                      }}
                      customPadding="6.5px 14px"
                    >
                      {
                        profileData.posicoes.map(pos =>
                          <MenuItem
                            key={pos.idCicloColaborador}
                            value={pos.idCicloColaborador}
                          // defaultChecked={profileData.posicao.idVaga === pos.idVaga}
                          >
                            {pos.titulo}
                          </MenuItem>
                        )
                      }
                    </Dropdown>
                  </InputContainer>
                </ContainerDropdownInput>
                {/* {
                                    profileData.Ciclo.flagSolicitarAprovacao &&
                                    // <PrimaryButton text="Solicitar Aprovação" disabled={!canRequestApproval} onClick={handleSolicitarApr} />
                                    <PrimaryButton text="Solicitar Aprovação" onClick={handleSolicitarApr} />
                                } */}
              </ContainerHeader>
              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<FontIcon iconName="ChevronDown" style={{ fontSize: 16 }} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Text variant="mediumPlus" styles={textStyles}>Informações Básicas</Text>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack styles={{ root: { width: '100%' } }}>
                    <WrapperLabelGroup>
                      <LabelGroup name="lg" spaceBetween={1} size={14} icon="Org" label="Área" value={profileData.posicao.area.nomeLocal} />
                      <LabelGroup name="lg" spaceBetween={1} size={14} icon="contact" label="Líder" value={profileData.FuncionarioOwner.nomeCompleto} />
                      <LabelGroup name="lg" styles={{ minWidth: "auto" }} spaceBetween={10} size={14} icon="Flag" label="Status" value={profileData.posicao.status.toUpperCase()} />
                      <LabelGroup name="lg" spaceBetween={1} size={14} icon="MapPin" label="Vaga" value={profileData.posicao.vaga.cargo.titulo} />
                      <LabelGroup name="lg" spaceBetween={1} size={14} icon="Calendar" label="Período" value={profileData.posicao.descPeriodo} />
                      {
                        profileData.posicao.flagSolicitarAprovacao &&
                        // <PrimaryButton text="Solicitar Aprovação" disabled={!canRequestApproval} onClick={handleSolicitarApr} />
                        <WrapperBtnSolicita>
                          <PrimaryButton text="Solicitar Aprovação" onClick={handleSolicitarApr} styles={btnSolicitaStyle} />
                        </WrapperBtnSolicita>
                      }
                    </WrapperLabelGroup>
                  </Stack>
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  expandIcon={<FontIcon iconName="ChevronDown" style={{ fontSize: 16 }} />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Text variant="mediumPlus" styles={textStyles}>Metas</Text>
                </AccordionSummary>
                <AccordionDetails style={{ overflow: 'auto' }}>
                  <TableGoals cicloPesos={profileData.posicao.metas} />
                </AccordionDetails>
              </Accordion>

              <Accordion defaultExpanded={true}>
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                  expandIcon={<FontIcon iconName="ChevronDown" style={{ fontSize: 16 }} />}
                >
                  <Text variant="mediumPlus" styles={textStyles}>Iniciativas Chave</Text>
                </AccordionSummary>
                <AccordionDetails>
                  <KeyProjects
                    idCiclo={Number(season)}
                    idCicloColaborador={profileData.posicao.idCicloColaborador}
                    allProjects={profileData.posicao.projetos}
                    errorProjects={profileState.error}
                    isLoadingProjects={profileState.loadingProject}
                    successProjects={profileState.success}
                    objetivosPesoAreas={profileData.objetivosPesoAreas}
                    readOnly={!profileData.posicao.flagSolicitarAprovacao} />
                </AccordionDetails>
              </Accordion>
            </>
      }

      <Dialog
        hidden={!isModalSolicitacaoOpen}
        onDismiss={() => setIsModalSolicitacaoOpen(false)}
        dialogContentProps={dialogContentProps}
      >
        <DialogFooter>
          <DefaultButton onClick={() => setIsModalSolicitacaoOpen(false)} text="Cancelar" />
          <PrimaryButton onClick={handleConfirmSol} text="Confirmar" />
        </DialogFooter>
      </Dialog>
    </>
  );
}

const btnSolicitaStyle: Partial<IButtonStyles> = {
  root: {
    maxWidth: 165,
    minWidth: 165,
    marginTop: 20,
    display: 'flex',
    alignItems: 'center'
  }
}


export default Goals;
