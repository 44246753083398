import React, { useEffect, useState } from 'react';

import { IconButton, Text, ITextStyles } from 'office-ui-fabric-react';
import {
  WrapperRow,
  WrapperIconSelect,
  IconCheck,
  Check,
  WrapperColumn,
  WrapperContent,
  WrapperColumnFlexWidth,
  WrapperColumnGap,
  WrapperHeader,
  WrapperColumnStatus,
  Ul
} from './styles';

import Status from "~/components/Status";
import Shimmer from '~/components/Shimmer/shimmerTreeview';

import './styles.css';

type converterField = {
  fieldValue: string;
  converterFunction: (text: string, values: any) => string | JSX.Element;
};

type StatusType = {
  hasStatus: boolean;
  fieldName: string;
}

interface IStateProps {
  data: any[],
  success: boolean,
  loadingData: boolean
}

interface IFlattenProps {
  hasToFlatten: boolean;
  idFieldParent: string;
  getItemSelected: (item: any) => void;
}

interface IColumnType {
  name: string;
  fieldName: string;
  width?: number;
  justifyHeader?: 'center' | 'flex-start' | 'flex-end';
  justifyValue?: 'center' | 'flex-start' | 'flex-end';
  onRender?: (text: string, values: any) => string | JSX.Element;
}

interface ITreeViewProps<T = IStateProps> {
  state: T;
  status?: StatusType;
  statusFieldName?: string;
  idItemSelected: number | null;
  fieldId: string;
  columns: IColumnType[];
  flatten?: IFlattenProps;
  isNotSelectable?: boolean;
  handleOnItemInvoked: () => void;
  handleIdItemSelected: (id: number | null) => void;
  converterFieldId?: (fieldId: string) => any;
};

const TreeView: React.FC<ITreeViewProps> = (props) => {
  const {
    idItemSelected,
    handleIdItemSelected,
    state,
    fieldId,
    // fieldValues,
    // fieldConverterValue,
    // rowHeader,
    status,
    columns,
    statusFieldName,
    converterFieldId,
    flatten,
    isNotSelectable
  } = props;

  useEffect(() => {
    var toggler = document.getElementsByClassName("group");
    for (let i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener("click", function () {
        toggler[i].parentElement?.parentElement?.parentElement!.querySelector(".nested")!.classList.toggle("active");
      });
    };
  }, [state]);

  const findIdElement = (el: HTMLElement): any => {
    try {
      if (el.hasAttribute('id') ?? false) {
        return el;
      } else {
        return findIdElement((el!.parentNode! as HTMLElement));
      };
    } catch {
      return el;
    }
  };

  const handleClick = (e: any) => {
    if (!isNotSelectable) {
      const currentEl = e.target;
      const el = findIdElement(e.target);
      const id = el.id;
      const lastEl = document.getElementById(idItemSelected?.toString() ?? '');
      const itemSelected = state.data.find(item => item[fieldId] === parseInt(id));

      if (currentEl.tagName !== 'UL') {
        if (currentEl.hasAttribute('data-icon') || currentEl.parentNode.hasAttribute('data-icon')) {
          if (lastEl?.classList.contains('itemSelected') && lastEl.id === id) {
            el.classList.remove('itemSelected');
            handleIdItemSelected(null);
            flatten?.getItemSelected(null);
          } else {
            lastEl?.classList.remove('itemSelected');
            el.classList.add('itemSelected');
            handleIdItemSelected(parseInt(id));
            flatten?.getItemSelected(itemSelected);

          }
        } else {
          if (currentEl.children[0]) {
            if (currentEl.tagName !== 'I' && currentEl.children[0].tagName !== 'I') {
              try {
                lastEl?.classList.remove('itemSelected');
                el.classList.add('itemSelected');
                handleIdItemSelected(parseInt(id));
                flatten?.getItemSelected(itemSelected);
              } catch {

              }
            }
          } else {
            if (e.target.tagName !== 'I') {
              lastEl?.classList.remove('itemSelected');
              el.classList.add('itemSelected');
              handleIdItemSelected(parseInt(id));
              flatten?.getItemSelected(itemSelected);
            };
          };
        };
      };
    }
  };



  const handleDoubleClick = (e: any) => {
    if (!isNotSelectable) {
      const currentEl = e.target;
      if (currentEl.children[0]) {
        if (currentEl.tagName !== 'I' && currentEl.children[0].tagName !== 'I') {
          props.handleOnItemInvoked!();
        }
      } else {
        if (e.target.tagName !== 'I') {
          props.handleOnItemInvoked!();
        };
      };
    }
  }

  const renderItemsMemo = React.useMemo(() => {
    const renderTreeView = (data: any[], level: number = 0) => {
      return data.map((item, i) => {
        if (item.children && item[fieldId] !== undefined) {
          const id = converterFieldId?.(item) ?? item[fieldId];
          // console.log("id", id)
          // console.log("item", item)
          return (
            <li key={i}>
              <Row
                isNotSelectable={isNotSelectable}
                status={status}
                statusFieldName={statusFieldName}
                hasChildren={item.children.length > 0 ? true : false}
                id={id}
                level={level}
                values={item}
                columns={columns}
                // fieldConverterValue={fieldConverterValue}
              />
              {
                item.children.length > 0 &&
                <Ul className="nested">
                  {renderTreeView(item.children, level + 1)}
                </Ul>
              }
            </li>
          );
        } else {
          return null;
        }
      });
    };
    const data = !flatten?.hasToFlatten ? state.data : listToTree(state.data, 0, fieldId, flatten.idFieldParent);
    return renderTreeView(data);
  }, [state.success]);

  return (
    <>
      <Header columns={columns} status={status} isNotSelectable={isNotSelectable} />
      {
        state.loadingData ?
          <Shimmer />
          :
          <Ul onClick={handleClick} onDoubleClick={handleDoubleClick} className="noselect">
            {renderItemsMemo}
          </Ul>
      }
    </>
  );
}

interface IRowProps {
  hasChildren?: boolean;
  status?: StatusType;
  statusFieldName?: string;
  id: string;
  level: number;
  values: any;
  columns: IColumnType[];
  // fieldConverterValue?: converterField[];
  isNotSelectable?: boolean;
};

const Row: React.FC<IRowProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { id, hasChildren, level, values, columns, status, isNotSelectable } = props;

  const handleIcon = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <WrapperRow id={id}>
      {
        !isNotSelectable &&
        <WrapperIconSelect data-icon={id}>
          <IconCheck iconName="CircleRing" />
          <Check iconName="StatusCircleCheckmark" />
        </WrapperIconSelect>
      }
      <WrapperIconSelect >
        {hasChildren &&
          <IconButton
            iconProps={{ iconName: isExpanded ? "ChevronDown" : "ChevronRight" }}
            className="group"
            title="Expandir"
            ariaLabel="Expandir"
            onClick={handleIcon}
            styles={{
              root: {
                height: '100%',
                cursor: 'pointer',
                userSelect: 'none',
                width: 48
              },
              icon: { color: "#323130" }
            }}
          />
        }
      </WrapperIconSelect>
      <WrapperContent level={level} data-id={id}>
        {
          status &&
          <WrapperColumnStatus >
            <Status status={values[status.fieldName]} />
          </WrapperColumnStatus>
        }
        {
          columns.map((item, i) => {
            const { fieldName, onRender, justifyValue, width } = item;

            let val = values[fieldName];


            if(onRender){
              val = onRender(values[fieldName], values);
            }

            if (i === 0) {
              return (
                <WrapperColumnFlexWidth key={i}>
                  {val}
                </WrapperColumnFlexWidth>
              )
            } else {
              return (
                <WrapperColumn justify={justifyValue} width={width} key={i}>
                  {val}
                </WrapperColumn>
              )
            }
          })
        }
        <WrapperColumnGap numColumn={columns.length} />
      </WrapperContent>
    </WrapperRow>
  );
};

interface IHeaderProps {
  columns: IColumnType[];
  status?: StatusType;
  isNotSelectable?: boolean;
};

const TextStyle: Partial<ITextStyles> = {
  root: {
    fontWeight: 600
  }
};

const Header: React.FC<IHeaderProps> = (props) => {
  const { columns, isNotSelectable, status } = props;
  const marginHeader = (!isNotSelectable ? 48 : 0) + (status ? 45 : 0) + 49
  return (
    <WrapperHeader>
      {
        columns.map((item, i) => {
          const { name, width, justifyHeader } = item;
          if (i === 0) {
            return (
              <WrapperColumnFlexWidth style={{ marginLeft: marginHeader }} key={i}>
                <Text variant="medium" styles={TextStyle}>{name}</Text>
              </WrapperColumnFlexWidth>
            )
          } else {
            return (
              <WrapperColumn width={width} key={i} justify={justifyHeader}>
                <Text variant="medium" styles={TextStyle}>{name}</Text>
              </WrapperColumn>
            )
          }
        })
      }
      <WrapperColumnGap numColumn={columns.length} />
    </WrapperHeader>
  );
};


function listToTree(list: any[], idPai: number, idField: string, idFieldParent: string) {
  let tree: any[] = [];

  idPai = typeof idPai !== 'undefined' ? idPai : 0;

  var childrenArray = list.filter(function (child: any) {
    return child.idAreaPai === idPai;
  });

  if (childrenArray.length > 0) {

    childrenArray.forEach(function (child: any) {
      child.children = listToTree(list, child[idField], idField, idFieldParent);
      tree.push(child);
    });
  }

  return tree;
}

export default TreeView;
