import customToast from "~/components/Toast/index";

import {
    Types,
    DataTypes,
    CyclePainel,
    CyclePainelActionTypes,
    MetaType,
    PesoType,
    SubPesoType,
    PillarsPainelType
} from "./types";

/**
 * Reducers
 */
const initialCycle: CyclePainel = {
    painel: {
        header: [],
        body: []
    },
    metaSelected: null,
    pesoSelected: null
};

const INITIAL_STATE: DataTypes = {
    data: initialCycle,
    loading: false,
    loadingPainel: false,
    loadingMeta: false,
    loadingPeso: false,
    error: false,
    successAction: false,
};


/**
 * Actions
 */

export const Creators = {
    getPainel: (idCiclo: number, nivel: string): CyclePainelActionTypes => ({
        type: Types.REQUEST_CYCLES_LOAD_PAINEL,
        payload: {
            idCiclo,
            nivel
        }
    }),

    getMetaById: (idCiclo: number, idCicloMeta: number): CyclePainelActionTypes => ({
        type: Types.REQUEST_CYCLES_META_LOAD_BY_ID,
        payload: {
            idCiclo,
            idCicloMeta
        }
    }),

    getMetaByIdObjective: (idCiclo: number, idObjective: number): CyclePainelActionTypes => ({
        type: Types.REQUEST_CYCLES_META_LOAD_BY_ID_OBJECTIVE,
        payload: {
            idCiclo,
            idObjective
        }
    }),

    addMeta: (idCiclo: number, idObjetivo: number, idCicloPilar: number, meta: MetaType): CyclePainelActionTypes => ({
        type: Types.REQUEST_CYCLES_META_ADD_BY_ID,
        payload: {
            idCiclo,
            idObjetivo,
            idCicloPilar,
            meta
        }
    }),

    editMeta: (idCiclo: number, idObjetivo: number, idCicloPilar: number, idCicloMeta: number, meta: MetaType): CyclePainelActionTypes => ({
        type: Types.REQUEST_CYCLES_META_EDIT_BY_ID,
        payload: {
            idCiclo,
            idObjetivo,
            idCicloPilar,
            idCicloMeta,
            meta
        }
    }),

    delMeta: (idCiclo: number, idCicloMeta: number, idCicloPilar: number, idObjetivo: number): CyclePainelActionTypes => ({
        type: Types.REQUEST_CYCLES_META_DELETE,
        payload: {
            idCiclo,
            idCicloMeta,
            idCicloPilar,
            idObjetivo
        }
    }),

    getPesoById: (idCiclo: number, idCicloPeso: number): CyclePainelActionTypes => ({
        type: Types.REQUEST_CYCLES_LOAD_PESO_BY_ID,
        payload: {
            idCiclo,
            idCicloPeso
        }
    }),


    getPesoByArea: (idCiclo: number, idObjetivo: number, idArea: number): CyclePainelActionTypes => ({
        type: Types.REQUEST_CYCLES_LOAD_PESO_BY_AREA,
        payload: {
            idCiclo,
            idObjetivo,
            idArea
        }
    }),

    addPeso: (idCiclo: number, idObjetivo: number, idCicloPilar: number, peso: PesoType): CyclePainelActionTypes => ({
        type: Types.REQUEST_CYCLES_ADD_PESO,
        payload: {
            idCiclo,
            idCicloPilar,
            idObjetivo,
            peso
        }
    }),

    editPeso: (idCiclo: number, idObjetivo: number, idCicloPilar: number, idCicloPeso: number, peso: PesoType): CyclePainelActionTypes => ({
        type: Types.REQUEST_CYCLES_EDIT_PESO,
        payload: {
            idCiclo,
            idCicloPilar,
            idCicloPeso,
            idObjetivo,
            peso
        }
    }),

    deletePeso: (idCiclo: number, idCicloPilar: number, idObjetivo: number, idCicloPeso: number): CyclePainelActionTypes => ({
        type: Types.REQUEST_CYCLES_DELETE_PESO,
        payload: {
            idCiclo,
            idCicloPeso,
            idCicloPilar,
            idObjetivo
        }
    }),
};

export default function cyclePainelReducer(state = INITIAL_STATE, action: CyclePainelActionTypes): DataTypes {
    switch (action.type) {

        case Types.REQUEST_CYCLES_LOAD_PAINEL:
            return { ...state, loadingPainel: true, error: false };
        case Types.SUCCESS_CYCLES_LOAD_PAINEL:
            return {
                ...state,
                loadingPainel: false,
                error: false,
                data: {
                    ...state.data,
                    painel: action.payload
                }
            };
        case Types.FAILURE_CYCLES_LOAD_PAINEL:
            customToast.error(action.message);
            return { ...state, loadingPainel: false, error: true };

        // REQUEST_CYCLES_META_LOAD_BY_ID

        case Types.REQUEST_CYCLES_META_LOAD_BY_ID:
            return { ...state, loadingMeta: true, error: false };
        case Types.SUCCESS_CYCLES_META_LOAD_BY_ID:
            return { ...state, loadingMeta: false, error: false, data: { ...state.data, metaSelected: action.payload } };
        case Types.FAILURE_CYCLES_META_LOAD_BY_ID:
            customToast.error(action.message);
            return { ...state, loadingMeta: false, error: true };

        // REQUEST_CYCLES_META_LOAD_BY_ID_OBJECTIVE

        case Types.REQUEST_CYCLES_META_LOAD_BY_ID_OBJECTIVE:
            return { ...state, loadingMeta: true, error: false };
        case Types.SUCCESS_CYCLES_META_LOAD_BY_ID_OBJECTIVE:
            return { ...state, loadingMeta: false, error: false };
        case Types.FAILURE_CYCLES_META_LOAD_BY_ID_OBJECTIVE:
            customToast.error(action.message);
            return { ...state, loadingMeta: false, error: true };

        //REQUEST_CYCLES_META_ADD_BY_ID

        case Types.REQUEST_CYCLES_META_ADD_BY_ID:
            return { ...state, loading: true, error: false, successAction: false };
        case Types.SUCCESS_CYCLES_META_ADD_BY_ID:
            customToast.success("Meta adicionada com sucesso");
            return {
                ...state,
                loading: false,
                error: false,
                successAction: true,
                data: {
                    ...state.data,
                    painel: {
                        ...state.data.painel,
                        body: handleMeta(state.data.painel.body, action.meta, action.idObjetivo, action.idCicloPilar)
                    }
                }
            };
        case Types.FAILURE_CYCLES_META_ADD_BY_ID:
            customToast.error(action.message);
            return { ...state, loading: false, error: true, successAction: false };

        // REQUEST_CYCLES_META_EDIT_BY_ID

        case Types.REQUEST_CYCLES_META_EDIT_BY_ID:
            return { ...state, loading: true, error: false, successAction: false };
        case Types.SUCCESS_CYCLES_META_EDIT_BY_ID:
            customToast.success("Meta editada com sucesso");
            return {
                ...state,
                loading: false,
                error: false,
                successAction: true,
                data: {
                    ...state.data,
                    painel: {
                        ...state.data.painel,
                        body: handleMeta(state.data.painel.body, action.meta, action.idObjetivo, action.idCicloPilar)
                    }
                }
            };
        case Types.FAILURE_CYCLES_META_EDIT_BY_ID:
            customToast.error(action.message);
            return { ...state, loading: false, error: true, successAction: false };

        //REQUEST_CYCLES_META_DELETE

        case Types.REQUEST_CYCLES_META_DELETE:
            return { ...state, loading: true, error: false, successAction: false };
        case Types.SUCCESS_CYCLES_META_DELETE:
            customToast.success("Meta excluída com sucesso");
            return {
                ...state,
                loading: false,
                error: false,
                successAction: true,
                data: {
                    ...state.data,
                    painel: {
                        ...state.data.painel,
                        body: handleDelMeta(state.data.painel.body, action.idObjetivo, action.idCicloPilar)
                    }
                }
            };
        case Types.FAILURE_CYCLES_META_DELETE:
            customToast.error(action.message);
            return { ...state, loading: false, error: true, successAction: false };

        //REQUEST_CYCLES_LOAD_PESO_BY_ID

        case Types.REQUEST_CYCLES_LOAD_PESO_BY_ID:
            return { ...state, loadingPeso: true, error: false }
        case Types.SUCCESS_CYCLES_LOAD_PESO_BY_ID:
            return {
                ...state,
                loadingPeso: false,
                error: false,
                data: { ...state.data, pesoSelected: action.payload }
            }
        case Types.FAILURE_CYCLES_LOAD_PESO_BY_ID:
            customToast.error(action.message);
            return { ...state, loadingPeso: false, error: true }

        //REQUEST_CYCLES_ADD_PESO

        case Types.REQUEST_CYCLES_ADD_PESO:
            return { ...state, loading: true, error: false, successAction: false }
        case Types.SUCCESS_CYCLES_ADD_PESO:
            customToast.success("Peso adicionado com sucesso");
            return {
                ...state,
                loading: false,
                error: false,
                successAction: true,
                data: {
                    ...state.data,
                    painel: {
                        ...state.data.painel,
                        body: handlePeso(state.data.painel.body, action.payload.peso, action.payload.idObjetivo, action.payload.idCicloPilar)
                    }
                }
            }
        case Types.FAILURE_CYCLES_ADD_PESO:
            customToast.error(action.message);
            return { ...state, loading: false, error: true, successAction: false }

        // REQUEST_CYCLES_EDIT_PESO

        case Types.REQUEST_CYCLES_EDIT_PESO:
            return { ...state, loading: true, error: false, successAction: false }
        case Types.SUCCESS_CYCLES_EDIT_PESO:
            customToast.success("Peso editado com sucesso");
            return {
                ...state,
                loading: false,
                error: false,
                successAction: true,
                data: {
                    ...state.data,
                    painel: {
                        ...state.data.painel,
                        body: handlePeso(state.data.painel.body, action.payload.peso, action.payload.idObjetivo, action.payload.idCicloPilar)
                    }
                }
            }
        case Types.FAILURE_CYCLES_EDIT_PESO:
            customToast.error(action.message);
            return { ...state, loading: false, error: true, successAction: false }

        // REQUEST_CYCLES_DELETE_PESO

        case Types.REQUEST_CYCLES_DELETE_PESO:
            return { ...state, loading: true, error: false, successAction: false }
        case Types.SUCCESS_CYCLES_DELETE_PESO:
            customToast.success("Peso excluído com sucesso");
            return {
                ...state,
                loading: false,
                error: false,
                successAction: true,
                data: {
                    ...state.data,
                    painel: {
                        ...state.data.painel,
                        body: handleDelPeso(state.data.painel.body, action.payload.idObjetivo, action.payload.idCicloPilar, action.payload.idCicloPeso)
                    }
                }
            }
        case Types.FAILURE_CYCLES_DELETE_PESO:
            customToast.error(action.message);
            return { ...state, loading: false, error: true, successAction: false }
        default:
            return state;
    };
};


const handleMeta = (data: PillarsPainelType[], meta: MetaType, idObjetivo: number, idCicloPilar: number): PillarsPainelType[] => {

    const result = data.map(pillar => {
        if (pillar.idCicloPilar === idCicloPilar) {
            return {
                ...pillar, objetivos: pillar.objetivos.map(obj => {
                    if (obj.objetivos && obj.objetivos.length > 0) {
                        return {
                            ...obj,
                            objetivos: obj.objetivos.map(subObj => {
                                if (subObj.idObjetivo !== idObjetivo) {
                                    return subObj;
                                } else {
                                    subObj.meta = meta;
                                    return subObj;
                                };
                            })
                        };
                    } else {
                        if (obj.idObjetivo !== idObjetivo) {
                            return obj;
                        } else {
                            obj.meta = meta;
                            return obj;
                        };
                    };
                })
            };
        } else {
            return pillar;
        };
    });

    return result;
};

const handleDelMeta = (data: PillarsPainelType[], idObjetivo: number, idCicloPilar: number): PillarsPainelType[] => {
    const metaDefault: MetaType = {
        minimo: '0',
        target: '0',
        maximo: '0',
        minimoRef: '0',
        targetRef: '0',
        maximoRef: '0',
        habilitarReferencia: false,
        objetivo: {
            descObjetivo: '',
            mecanicaCalculo: '',
            unidade: ''
        },
        idCicloMeta: 0
    };

    const result = data.map(pillar => {
        if (pillar.idCicloPilar === idCicloPilar) {
            return {
                ...pillar, objetivos: pillar.objetivos.map(obj => {
                    if (obj.objetivos && obj.objetivos.length > 0) {
                        return {
                            ...obj,
                            objetivos: obj.objetivos.map(subObj => {
                                if (subObj.idObjetivo !== idObjetivo) {
                                    return subObj;
                                } else {
                                    subObj.meta = metaDefault;
                                    return subObj;
                                };
                            })
                        };
                    } else {
                        if (obj.idObjetivo !== idObjetivo) {
                            return obj;
                        } else {
                            obj.meta = metaDefault;
                            return obj;
                        };
                    };
                })
            };
        } else {
            return pillar;
        };
    });

    return result;
};

const handlePeso = (data: PillarsPainelType[], peso: SubPesoType, idObjetivo: number, idCicloPilar: number): PillarsPainelType[] => {
    const result = data.map(pillar => {
        if (pillar.idCicloPilar === idCicloPilar) {
            return {
                ...pillar, objetivos: pillar.objetivos.map(obj => {
                    if (obj.objetivos && obj.objetivos.length > 0) {
                        return {
                            ...obj,
                            objetivos: obj.objetivos.map(subObj => {
                                if (subObj.idObjetivo !== idObjetivo) {
                                    return subObj;
                                } else {
                                    return {
                                        ...subObj,
                                        pesos: subObj.pesos!.map(pesoSubItem => {
                                            if (pesoSubItem.idArea !== peso.idArea) {
                                                return pesoSubItem;
                                            } else {
                                                return {
                                                    idArea: peso.idArea,
                                                    peso: {
                                                        idCicloPeso: peso.peso.idCicloPeso,
                                                        peso: peso.peso.peso,
                                                        definirResponsabilidade: peso.peso.definirResponsabilidade,
                                                    }
                                                };
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    } else {
                        if (obj.idObjetivo !== idObjetivo) {
                            return obj;
                        } else {
                            return {
                                ...obj, pesos: obj.pesos!.map(pesoItem => {
                                    if (pesoItem.idArea !== peso.idArea) {
                                        return pesoItem;
                                    } else {
                                        return {
                                            idArea: peso.idArea,
                                            peso: {
                                                idCicloPeso: peso.peso.idCicloPeso,
                                                peso: peso.peso.peso,
                                                definirResponsabilidade: peso.peso.definirResponsabilidade,
                                            }
                                        };
                                    }
                                })
                            }
                        }
                    }
                })
            };
        } else {
            return pillar;
        };
    });

    return result;
};

const handleDelPeso = (data: PillarsPainelType[], idObjetivo: number, idCicloPilar: number, idCicloPeso: number): PillarsPainelType[] => {
    const result = data.map(pillar => {
        if (pillar.idCicloPilar === idCicloPilar) {
            return {
                ...pillar, objetivos: pillar.objetivos.map(obj => {
                    if (obj.objetivos && obj.objetivos.length > 0) {
                        return {
                            ...obj,
                            objetivos: obj.objetivos.map(subObj => {
                                if (subObj.idObjetivo !== idObjetivo) {
                                    return subObj;
                                } else {
                                    return {
                                        ...subObj,
                                        pesos: subObj.pesos!.map(pesoSubItem => {
                                            if (pesoSubItem.peso.idCicloPeso !== idCicloPeso) {
                                                return pesoSubItem;
                                            } else {
                                                return {
                                                    peso: {
                                                        definirResponsabilidade: false,
                                                        peso: 0,
                                                        idCicloPeso: 0,
                                                    },
                                                    idArea: pesoSubItem.idArea,
                                                    area: {
                                                        nomeLocal: ''
                                                    },
                                                    idObjetivo: null,
                                                    objetivo: {
                                                        descObjetivo: '',
                                                        mecanicaCalculo: ''
                                                    }
                                                };
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    } else {
                        if (obj.idObjetivo !== idObjetivo) {
                            return obj;
                        } else {
                            return {
                                ...obj, pesos: obj.pesos!.map(pesoItem => {
                                    if (pesoItem.peso.idCicloPeso !== idCicloPeso) {
                                        return pesoItem;
                                    } else {
                                        return {
                                            peso: {
                                                definirResponsabilidade: false,
                                                peso: 0,
                                                idCicloPeso: 0,
                                            },
                                            idArea: pesoItem.idArea,
                                            area: {
                                                nomeLocal: ''
                                            },
                                            idObjetivo: null,
                                            objetivo: {
                                                descObjetivo: '',
                                                mecanicaCalculo: '',
                                            }
                                        };
                                    }
                                })
                            }
                        };
                    }
                })
            };
        } else {
            return pillar;
        };
    });

    return result;
};