import styled from 'styled-components';
import { calcConstrast } from "~/assets/js/colors"
import { Container as MaterialContainer } from "@material-ui/core";


export const ContainerDropdownInput = styled.div`
    width: auto;
    display: flex;
    align-items: center;

`

export const ContainerHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @media screen and (max-width: 768px){
      flex-direction: column;
      align-items: flex-start;

     ${ContainerDropdownInput}:last-child{
       margin-top: 10px;
     }
    }
`;


export const Tr = styled.tr<{ pillarColor: string }>`
    height: 60px;
    &:hover{
        background-color: #FAFAFA;
    }
    td:nth-child(3){
        background-color: ${props => props.pillarColor};
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        color: ${props => calcConstrast(props.pillarColor) ? "#FFFFFF" : "#000000"};
    }
    td:nth-child(4){
        border: 1px solid ${props => props.pillarColor};
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }
`

export const InputContainer = styled(MaterialContainer)`
  margin-left: 0px !important;
  padding: 0px !important;
  width: 115px !important;
  margin-right: 0px !important;
`;

export const WrapperLabelGroup = styled.div`
    padding: 16px;
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between;
    flex-wrap: wrap; */

    div.lg{
        width: 330px;
        min-width: 330px;
        padding-right: 10px;
        box-sizing: border-box;
        margin-right: 0px;
        margin-top: 20px;

        @media screen and (max-width: 767px){
          min-width: auto;
        }
    }

    div.lg:nth-child(1), div.lg:nth-child(2), div.lg:nth-child(3) {
        margin-top: 0px;
    }

    @media screen and (min-width: 1067px) and (max-width: 1357px){
        div.lg:nth-child(3) {
            margin-top: 20px;
        }
    }

    @media screen and (max-width: 1067px){
        div.lg:nth-child(2), div.lg:nth-child(3) {
            margin-top: 20px;
        }
    }
`
export const WrapperBtnSolicita = styled.div`
  width: 330px;
  min-width: 330px;
  display: flex;
  align-items: center;
`
