import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Fabric } from "office-ui-fabric-react/lib/Fabric";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { ToastContainer } from 'react-toastify';
import { loadTheme } from '@fluentui/react';
import { store, persistor } from "./store";
import Routes from "./services/routes";
import "~/assets/css/global.css";
import 'react-toastify/dist/ReactToastify.css';
import 'office-ui-fabric-react/dist/css/fabric.css';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      "'Segoe UI'",
      "Tahoma",
      "Geneva",
      "Verdana",
      "sans-serif"
    ].join(','),
  },
  palette:{
    primary: {
      main: '#027636',
    },
  }
});

 function App(){
  loadTheme({
    palette: {
      themePrimary: '#027636',
      themeLighterAlt: '#f0f9f4',
      themeLighter: '#c4e9d5',
      themeLight: '#97d6b3',
      themeTertiary: '#47ac75',
      themeSecondary: '#128646',
      themeDarkAlt: '#026a31',
      themeDark: '#025929',
      themeDarker: '#01421e',
      neutralLighterAlt: '#faf9f8',
      neutralLighter: '#f3f2f1',
      neutralLight: '#edebe9',
      neutralQuaternaryAlt: '#e1dfdd',
      neutralQuaternary: '#d0d0d0',
      neutralTertiaryAlt: '#c8c6c4',
      neutralTertiary: '#a19f9d',
      neutralSecondary: '#605e5c',
      neutralPrimaryAlt: '#3b3a39',
      neutralPrimary: '#323130',
      neutralDark: '#201f1e',
      black: '#000000',
      white: '#ffffff',
    }});
    
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Fabric>
            <ToastContainer position="bottom-center"/>
            <Routes />
          </Fabric>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App