import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
    Container,
} from './styles';

import Strategic from "../Stategic";
import { Creators } from "~/store/ducks/cycle/painel";

interface Iprops {
    idCycle: number;
    changePivot: (id: string) => void;
    currentPivot: string;
    currentPainel: string | null;
    handleChangeNivel: (nivel: string) => void;
};

const Goals: React.FC<Iprops> = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(Creators.getPainel(props.idCycle, "T"));
    }, [dispatch])

    return (
        <div>
            <Container>
                <Strategic idCycle={props.idCycle} changePivot={props.changePivot} />
            </Container>
        </div>
    );
};

export default Goals;